import React, { useState, useEffect, useContext } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { ReactComponent as MoreVerticalSVG } from '../icons/MoreVertical.svg';
import { ReactComponent as QuestionMarkSVG } from '../icons/QuestionMark.svg';
import { ReactComponent as LockSVG } from '../icons/Lock.svg';
import { ReactComponent as StatusGreenSVG } from '../icons/StatusGreen.svg';
import { ReactComponent as StatusErrorSVG } from '../icons/StatusError.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Stack, Chip } from '@mui/material';

export default function PlaybookCard({
  title,
  name,
  isLocked,
  status,
  message,
  tags,
  options,
  faq,
  onClickHandler,
}) {
  const tagStyles = {
    Dashboard: {
      borderRadius: '4px',
      background: '#3BA0EB',
      color: '#ffffff',
      height: '26px',
      fontSize: '12px',
      marginRight: '2px',
    },
    Marketing: {
      borderRadius: '4px',
      background: '#FD5B84',
      color: '#ffffff',
      height: '26px',
      fontSize: '12px',
      marginRight: '2px',
    },
    Analytics: {
      borderRadius: '4px',
      background: '#51C2C0',
      color: '#ffffff',
      height: '26px',
      fontSize: '12px',
      marginRight: '2px',
    },
    Creative: {
      borderRadius: '4px',
      background: '#9859FF',
      color: '#ffffff',
      height: '26px',
      fontSize: '12px',
      marginRight: '2px',
    },
    Dynamic: {
      borderRadius: '4px',
      background: '#242B35',
      color: '#ffffff',
      height: '26px',
      fontSize: '12px',
      marginRight: '2px',
    },
    Paid: {
      borderRadius: '4px',
      background: '#FF2869',
      color: '#ffffff',
      height: '26px',
      fontSize: '12px',
      marginRight: '2px',
    },
    Owned: {
      borderRadius: '4px',
      background: '#FECF57',
      color: '#ffffff',
      height: '26px',
      fontSize: '12px',
      marginRight: '2px',
    },
  };

  const handleClick = () => {
    if (!isLocked) {
      onClickHandler(name);
    }
  };

  return (
    <>
      <CardWrapper
        onClick={(e) => {
          if (!isLocked) {
            onClickHandler(name);
          }
        }}
        isLocked={isLocked}
      >
        <TitleArea>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <TitleText>{title}</TitleText>
            {isLocked ? (
              <LockSVG />
            ) : status && status === 'error' ? (
              <StatusErrorSVG />
            ) : (
              <StatusGreenSVG />
            )}
          </Stack>
        </TitleArea>
        {options && (
          <OptionArea>
            <MoreVerticalSVG />
          </OptionArea>
        )}
        <BodyArea>
          <BodyText>{message}</BodyText>
        </BodyArea>
        <TagArea>
          <Stack direction='row'>
            {Object.entries(tags).map(([key, value]) => (
              <Chip
                key={key}
                label={value}
                sx={
                  tagStyles[value]
                    ? tagStyles[value]
                    : {
                        borderRadius: '4px',
                        background: '#242B35',
                        color: '#ffffff',
                        height: '26px',
                        fontSize: '12px',
                        marginRight: '2px',
                      }
                }
              ></Chip>
            ))}
          </Stack>
        </TagArea>
        <BottomRightArea>{faq && <QuestionMarkSVG />}</BottomRightArea>
      </CardWrapper>
    </>
  );
}

const CardWrapper = styled.div`
  position: relative;
  min-width: 360px;
  width: 100%;
  height: 140px;
  padding: 20px;
  border: 1px solid #f5f5f5;

  background: '#ffffff';
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  border-radius: 8px;

  &:hover {
    box-shadow: 0px 3px 7px rgba(9, 16, 55, 0.25);
  }

  transition: width 0.3s ease;
  cursor: ${(props) => (props.isLocked ? 'default' : 'pointer')};
`;
const TitleArea = styled.div`
  position: absolute;
  display: flex;
  top: 20px;
  width: 100%;
  alignt-items: center;
  justify-content: space-between;
`;
const TitleText = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  font-family: 'Arial';
  font-weight: 400;
  margin-right: 4px;
`;
const OptionArea = styled.div`
  position: absolute;
  right: 0px;
  padding-right: 20px;
`;
const BodyArea = styled.div`
  position: relative;
  top 28px;
  width: 100%;
`;
const BodyText = styled.p`
  font-size: 12px;
  line-height: 14px;
  font-family: 'Arial';
  color: #4c4e51;
`;
const TagArea = styled.div`
  position: absolute;
  bottom: 20px;
`;
const BottomRightArea = styled.div`
  position: absolute;
  right: 0px;
  padding-right: 20px;
  bottom: 20px;
`;
