import React from 'react';
import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';
import { capitalizeChar } from '../../../../hooks/textFormatter';

export default function ModelPerfTable({ title, models }) {
  const columns = [
    {
      field: 'category',
      headerName: 'Category',
      flex: 1.2,
    },
    {
      field: 'type',
      headerName: 'Type',
      valueGetter: (params) => params.row.sk,
      flex: 1.2,
    },
    {
      field: 'name',
      headerName: 'Name',
      valueGetter: (params) => params.row.sk,
      flex: 1.2,
    },
    {
      field: 'periodContribution',
      headerName: 'Contribution',
      type: 'number',
      flex: 1.2,
    },
  ];

  return (
    <Wrapper>
      <Title>{capitalizeChar(title)}</Title>
      <DataGrid
        rows={models}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10]}
        autoHeight
        disableRowSelectionOnClick
        getRowId={(row) => row.sk}
        style={{ width: '100%' }}
      />
    </Wrapper>
  );
}

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.pb.black};
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  font-family: 'Arial';
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  transition: all ease 0.7s;
  ${({ theme }) => theme.animation.fadeInBottom}
`;
