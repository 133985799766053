import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset}

  * { 
    box-sizing: border-box;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
  }


  body{
    font-family: Arial, Helvetica, sans-serif;
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto; */

    &::-webkit-scrollbar {
    display: none;
    }
  }

  input,
  button{
    border:none;
  }

  button {
    background: transparent;
  }

  input:focus,
  input:active,
  button:focus,
  button:active {
    outline: none;
  }


  .warning {
  margin-top: 1rem;
  border-color: #d0312d;
  color: #d0312d;
  text-align: center;
}

.warning:hover, .warning.is-hovered {
  border-color: #d0312d;
  color: #d0312d;
}

.warning:focus,  .warning.is-focused {
  border-color: #d0312d;
  color: #d0312d;
}

  .warning:focus:not(:active),  .warning.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(241, 70, 104, 0.25);
}

  .warning:active,  .warning.is-active {
  border-color: #d0312d;
  color: #d0312d;
}
`;

export default GlobalStyle;
