import React from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
export default function LineChart({
  title,
  labels,
  timeSeriesData,
  xAxisKey,
  yAxisKey,
}) {
  const data = {
    labels: labels,
    datasets: timeSeriesData,
  };
  const options = {
    elements: {
      line: {
        tension: 0.1,
        borderWidth: 2,
      },
    },
    parsing: {
      xAxisKey: xAxisKey,
      yAxisKey: yAxisKey,
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          font: {
            size: 12,
            family: 'Arial',
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: 'Arial',
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          font: {
            size: 12,
            weight: 400,
            family: 'Arial',
          },
        },
      },
    },
  };

  return (
    <Wrapper>
      <TitleArea>{title}</TitleArea>
      <Line options={options} data={data} />
    </Wrapper>
  );
}

const TitleArea = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.pb.black};
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  font-family: 'Arial';
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px;
  height: auto;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  transition: all ease 0.7s;
  ${({ theme }) => theme.animation.fadeInBottom}
`;
