import React, { useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { LanguageContext } from '../../../contextApi/LanguageContext';
import UserPool from '../../../UserPool';

import PopupModal from '../../../components/PopupModal';
import ContentModal from '../../../components/ContentModal';
import Privacy from './Privacy';
import { SIGNUP_TEXT } from '../AUTH_TEXT';
import Terms from './Terms';
import styled from 'styled-components';

export default function SignUp({ setModalType }) {
  const isUnder1440 = useMediaQuery({ maxWidth: 1440 });
  const { language } = useContext(LanguageContext);

  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    username: '',
    password: '',
    confirmpassword: '',
    verifyEmailSent: false,
    isSignUpCompleted: false,
    signUpError: {},
  });

  const {
    username,
    password,
    confirmpassword,
    verifyEmailSent,
    isSignUpCompleted,
  } = userData;
  const [checked, setChecked] = useState(false);

  const toggle = () => {
    setChecked(checked => !checked);
  };

  const usernameCheck = username?.includes('@') && username?.includes('.');
  const pwdMatchCheck = password === confirmpassword;
  const pwdValidCheck = password?.match(
    /(?=.*\d)(?=.*[a-zA-ZS])(?=.*?[#?!@$%^&*-]).{8,}/
  );
  const btnValidCheck = usernameCheck && pwdMatchCheck && pwdValidCheck;
  const usernameInput = useRef();
  const emailDuplicateMsg = 'An account with the given email already exists.';

  const [popupModalStatus, setPopupModalStatus] = useState(false);
  const closePopupModal = () => {
    setPopupModalStatus(false);
  };

  const [isShowModal, setIsShowModal] = useState(false);
  const toggleModal = () => {
    setIsShowModal(false);
  };

  const resetEmailInput = () => {
    closePopupModal();
    setUserData({
      username: '',
      password: password,
      confirmpassword: confirmpassword,
    });

    usernameInput.current.focus();
  };

  const onInputChange = event => {
    const { id, value } = event.target;
    setUserData(prev => ({ ...prev, [id]: value, signUpError: {} }));
  };
  const handleUserDataSubmit = event => {
    event.preventDefault();
    UserPool.signUp(username, password, [], null, (err, data) => {
      if (err) {
        console.error(err);
        if (err.message === emailDuplicateMsg) {
          setPopupModalStatus(true);
        }
      } else {
        setUserData({ verifyEmailSent: true });
      }
    });
  };

  const showTermsOfServiceModal = () => {
    setIsShowModal(true);
    ContentModal.title = 'Terms of Service';
    ContentModal.contentData = Terms();
  };

  const showPrivacyPolicyModal = () => {
    setIsShowModal(true);
    ContentModal.title = 'Privacy Policy';
    ContentModal.contentData = Privacy();
  };

  return isSignUpCompleted ? (
    navigate('/signin')
  ) : (
    <>
      <Wrapper isUnder1440={isUnder1440}>
        {!verifyEmailSent && (
          <>
            <Header>{SIGNUP_TEXT[language].headline}</Header>
            <RegisterForm
              isUnder1440={isUnder1440}
              onSubmit={handleUserDataSubmit}
            >
              <InputArea>
                <Input
                  type="email"
                  id="username"
                  name="username"
                  placeholder={SIGNUP_TEXT[language].id.placeholder}
                  value={username}
                  onChange={onInputChange}
                  ref={usernameInput}
                />
                {username && !usernameCheck && (
                  <Warning>{SIGNUP_TEXT[language].id.warning}</Warning>
                )}
              </InputArea>
              <InputArea>
                <Input
                  type="password"
                  id="password"
                  name="password"
                  placeholder={SIGNUP_TEXT[language].pw.placeholder}
                  value={password}
                  onChange={onInputChange}
                />
                {password && !pwdValidCheck && (
                  <Warning>{SIGNUP_TEXT[language].pw.warning}</Warning>
                )}
              </InputArea>
              <InputArea>
                <Input
                  type="password"
                  id="confirmpassword"
                  name="confirmpassword"
                  placeholder={SIGNUP_TEXT[language].confirmPw.placeholder}
                  value={confirmpassword}
                  onChange={onInputChange}
                />
                {confirmpassword && !pwdMatchCheck && (
                  <Warning>{SIGNUP_TEXT[language].confirmPw.warning}</Warning>
                )}
              </InputArea>
              <Agreement>
                <AgreementText isUnder1440={isUnder1440}>
                  <Checkbox
                    type="checkbox"
                    value={checked}
                    onChange={toggle}
                    isActive={checked}
                  />
                  {language === 'EN' && (
                    <>
                      &nbsp;
                      {SIGNUP_TEXT[language].confirmTerms.description}
                      &nbsp;
                    </>
                  )}
                  <TermsModal onClick={showTermsOfServiceModal}>
                    {SIGNUP_TEXT[language].confirmTerms.terms}
                  </TermsModal>
                  &nbsp;&amp;&nbsp;
                  <TermsModal onClick={showPrivacyPolicyModal}>
                    {SIGNUP_TEXT[language].confirmTerms.privacy}
                  </TermsModal>
                  {language === 'KR' &&
                    SIGNUP_TEXT[language].confirmTerms.description}
                </AgreementText>
                {btnValidCheck && !checked ? (
                  <Warning>
                    {SIGNUP_TEXT[language].confirmTerms.warning}
                  </Warning>
                ) : null}
              </Agreement>
              <ContentModal
                isShowModal={isShowModal}
                title={ContentModal.title}
                contentData={ContentModal.contentData}
                confirmBtnName="Close"
                handleConfirmAction={toggleModal}
              />
              <SubmitButton
                type="submit"
                disabled={!checked || !btnValidCheck ? true : false}
              >
                {SIGNUP_TEXT[language].signUpButton}
              </SubmitButton>
              <Border />

              <Description>
                {SIGNUP_TEXT[language].signIn.description}
              </Description>
              <SubmitButton
                onClick={e => {
                  e.stopPropagation();
                  setModalType('signIn');
                }}
                color="black"
              >
                {SIGNUP_TEXT[language].signIn.buttonName}
              </SubmitButton>
            </RegisterForm>
          </>
        )}

        {verifyEmailSent && (
          <>
            <Header>{SIGNUP_TEXT[language].successMessage.title}</Header>
            <Description needSpace={true}>
              {SIGNUP_TEXT[language].successMessage.description}
            </Description>
            <SubmitButton
              onClick={e => {
                e.stopPropagation();
                setModalType('signIn');
              }}
              color="black"
            >
              {SIGNUP_TEXT[language].successMessage.confirmButton}
            </SubmitButton>
          </>
        )}
      </Wrapper>
      <PopupModal
        popupModalStatus={popupModalStatus}
        closePopupModal={resetEmailInput}
        handleConfirmAction={resetEmailInput}
        popupMessage={emailDuplicateMsg}
        confirmBtnName="Confirm"
      />
    </>
  );
}

const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ isUnder1440 }) => (isUnder1440 ? '35vw' : '100%')};
  max-width: 486px;
  max-height: 700px;
  padding: 55px 71px 45px 71px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.neutralColor.dark1};
  box-shadow: 0 0 10 rgba(0, 0, 0, 0.25);
  z-index: 20;
`;

const Header = styled.h2`
  margin-bottom: 30px;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: ${({ theme }) => theme.pb.green};
`;

const RegisterForm = styled.form`
  width: ${({ isUnder1440 }) => (isUnder1440 ? '25vw' : '100%')};
  max-width: 340px;
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-bottom: 16px;
`;

const Input = styled.input`
  width: 100%;
  max-width: 340px;
  height: 35px;
  padding: 10px 15px;
  border-radius: 5px;

  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralColor.dark4};
  background-color: ${({ theme }) => theme.pb.gray};

  &:focus {
    background-color: #fff;
  }

  &::placeholder {
    color: #6f6f6f;
  }
`;

const Warning = styled.p`
  width: 100%;
  max-width: 340px;
  padding: 10px 0px;

  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.pb.yellow};
`;

const Agreement = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.flexDefault}
  margin-top:1rem;
`;

const AgreementText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  font-size: ${({ isUnder1440 }) => (isUnder1440 ? '11.5px' : '13px')};
  color: ${({ theme }) => theme.pb.gray};
`;

const Checkbox = styled.input`
  margin-top: 0.07rem;
  width: 15px;
  height: 15px;
`;

const TermsModal = styled.p`
  display: flex;

  color: ${({ theme }) => theme.pb.cyanBlue};
  cursor: pointer;
`;

const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 340px;
  padding: 10px 15px;

  border-radius: 5px;
  background-color: ${({ color, theme }) =>
    color === 'black' ? theme.pb.black : theme.pb.cyanBlue};

  font-weight: 700;
  font-size: 16px;
  color: #fff;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 3px 7px rgba(9, 16, 55, 0.25);
  }

  &:disabled {
    cursor: default;
  }
`;

const Border = styled.p`
  margin: 24px 0px;
  width: 100%;
  max-width: 340px;
  border-bottom: 1px solid ${({ theme }) => theme.pb.gray};
`;

const Description = styled.p`
  margin-bottom: ${({ needSpace }) => (needSpace ? '30px' : '8px')};
  color: ${({ theme }) => theme.pb.gray};
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
`;
