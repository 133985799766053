import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const TiktokOrganic = () => {
  const { workspaceId } = useParams();

  const {
    REACT_APP_TIKTOKORGANIC_CLIENT_KEY,
    REACT_APP_TIKTOKORGANIC_REDIRECT_URI,
  } = process.env;

  const csrfState = Math.random().toString(36).substring(2);
  const authRequestURL = `https://www.tiktok.com/v2/auth/authorize/?client_key=${REACT_APP_TIKTOKORGANIC_CLIENT_KEY}&response_type=code&state=${csrfState}&redirect_uri=${REACT_APP_TIKTOKORGANIC_REDIRECT_URI}&scope=user.info.basic,video.list`;

  const AuthLink = () => {
    window.localStorage.setItem('workspaceId', workspaceId);
    window.location.assign(authRequestURL);
  };

  return (
    <Wrapper>
      <TiktokButton id='Tiktok' onClick={AuthLink}>
        <IntegrateText>Integrate</IntegrateText>
      </TiktokButton>
    </Wrapper>
  );
};

export default TiktokOrganic;

const Wrapper = styled.div`
  position: relative;
`;

const TiktokButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.pb.cyanBlue};
  border-radius: 43px;
  padding: 8px 14px;
  background-color: white;
  cursor: pointer;
`;

const IntegrateText = styled.span`
  width: 56px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.pb.cyanBlue};
`;
