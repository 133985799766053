import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../contextApi/AccountContext';
import { ChannelsContext } from '../../../contextApi/ChannelsContext';
import { AnimationContext } from '../../../contextApi/AnimationContext';
import { capitalizeChar } from '../../../hooks/textFormatter';
import { APIs } from '../../../config';

import LoadingPage from '../../../components/LoadingPage';
import styled, { css } from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import EmptyContentPage from '../../../components/EmptyContentPage';
import { Button, Link } from '@mui/material';
import { ReactComponent as IntegrationSVG } from '../../../icons/Integrations.svg';
import PlaybookCard from '../../../components/PlaybookCards';
import { Stack } from '@mui/system';
import DataIntegration from './DataIntegration/DataIntegration';

export default function Integration() {
  const { sessionJWT } = useContext(AccountContext);
  const { integratedList } = useContext(ChannelsContext);
  const { workspaceId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [hasIntegration, setHasIntegration] = useState(false);
  const [integrationData, setIntegrationData] = useState([]);

  useEffect(() => {
    if (integratedList?.length === 0) {
      return;
    } else {
      setHasIntegration(true);
    }
  }, [integratedList, setHasIntegration]);

  useEffect(() => {
    if (workspaceId && sessionJWT) {
      setIsLoading(true);
      fetch(`${APIs('integration', workspaceId)}?channelName=all`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionJWT}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setIntegrationData(data);
        });
      setIsLoading(false);
    }
  }, [workspaceId, sessionJWT]);

  return (
    <>
      <IntegrationWrapper>
        <IntegrationTitle>
          {hasIntegration ? '🤖 Thanks for connecting!' : '🤖 Hello!'}
        </IntegrationTitle>
        {hasIntegration ? (
          <IntegrationDescription>
            We are constantly adding more integrations! Learn more about them by
            visiting <span style={{ fontWeight: 'bold' }}>FAQ</span>!
          </IntegrationDescription>
        ) : (
          <IntegrationDescription>
            Let's connect your data! We will do the rest in creating{' '}
            <Link
              href='https://panomix.notion.site/Playbook-123aaf87136e418ba7c80e694ffe32de?p=b7a48ce1871b4291b003f7d8f9893e4c&pm=c'
              color='#0B8FFC'
            >
              models
            </Link>{' '}
            and{' '}
            <Link
              href='https://panomix.notion.site/Playbook-123aaf87136e418ba7c80e694ffe32de?p=b7a48ce1871b4291b003f7d8f9893e4c&pm=c'
              color='#05ED94'
            >
              dashboards
            </Link>
            .
          </IntegrationDescription>
        )}
        <CustomButton
          key='AddDataSource'
          variant='contained'
          startIcon={<IntegrationSVG />}
          disableRipple
          onClick={() =>
            navigate(`/myworkspace/${workspaceId}/integration/connect`)
          }
        >
          Add Data Source
        </CustomButton>
        {hasIntegration && integrationData && (
          <IntegrationCardArea>
            {integrationData.map((item, idx) => (
              <PlaybookCard
                key={idx}
                title={capitalizeChar(item.channelName)}
                name={item.channelName}
                isLocked={false}
                status={item.status ? item.status : 'active'}
                message={item.message ? item.message : item.sk}
                tags={item.tags}
              />
            ))}
          </IntegrationCardArea>
        )}
      </IntegrationWrapper>
    </>
  );
}

const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 260px);
  left: 260px;
  margin-top: 60px;
`;

const IntegrationWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: calc(100% - 260px);
  left: 260px;
`;

const IntegrationTitle = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.cyanBlue};
  font-size: 24px;
  font-family: 'Arial';
  padding: 5px;
`;

const IntegrationDescription = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.realBlack};
  font-size: 14px;
  padding: 5px;
  width: 340px;
  line-height: 19px;
  font-family: 'Arial';
  text-align: center;
`;

const CustomButton = MuiStyled(Button)({
  height: '40px',
  width: '180px',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  padding: '6px 12px',
  borderRadius: '8px',
  backgroundColor: '#1A1F27',
  lineHeight: 1.5,
  fontFamily: 'Arial',
  outline: 'none',
  color: 'white',
  '&:hover': {
    backgroundColor: '#242B35',
    boxShadow: 'none',
  },
  cursor: 'pointer',
  marginBottom: '20px',
});

const IntegrationCardArea = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  transition: all ease 0.3s;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1900px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
