import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../contextApi/AccountContext';
import { ChannelsContext } from '../../../contextApi/ChannelsContext';
import { APIs } from '../../../config';

import LoadingPage from '../../../components/LoadingPage';
import styled from 'styled-components';
import { Link, Button } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import PlaybookCard from '../../../components/PlaybookCards';
import { ReactComponent as IntegrationSVG } from '../../../icons/Integrations.svg';

export default function Dashboards() {
  const { sessionJWT } = useContext(AccountContext);
  const { integratedList } = useContext(ChannelsContext);
  const { workspaceId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [hasIntegration, setHasIntegration] = useState(false);
  const [feature, setFeature] = useState();

  const navigate = useNavigate();

  const goToChart = (dashboardName) => {
    navigate(`/myworkspace/${workspaceId}/dashboard/${dashboardName}`);
  };

  useEffect(() => {
    if (integratedList?.length === 0) {
      return;
    } else {
      setHasIntegration(true);
    }
  }, [integratedList, setHasIntegration]);

  useEffect(() => {
    if (workspaceId && sessionJWT) {
      setIsLoading(true);
      const fetchFeature = async () => {
        const request = await fetch(`${APIs('feature', workspaceId)}`, {
          headers: { Authorization: `Bearer ${sessionJWT}` },
        });
        const data = await request.json();
        setFeature(data);
      };
      fetchFeature();
      setIsLoading(false);
    }
  }, [workspaceId, sessionJWT]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <DashboardWrapper>
          <DashboardTitle>
            {hasIntegration ? '🤖 Hello!' : '🤖 Hello!'}
          </DashboardTitle>
          {hasIntegration ? (
            <DashboardDescription>
              We are constantly adding more dashboards! Learn more about them by
              visiting <span style={{ fontWeight: 'bold' }}>FAQ</span>!
            </DashboardDescription>
          ) : (
            <DashboardDescription>
              Let's connect your data! We will do the rest in creating{' '}
              <Link
                href='https://panomix.notion.site/Playbook-123aaf87136e418ba7c80e694ffe32de?p=b7a48ce1871b4291b003f7d8f9893e4c&pm=c'
                color='#0B8FFC'
              >
                models
              </Link>{' '}
              and{' '}
              <Link
                href='https://panomix.notion.site/Playbook-123aaf87136e418ba7c80e694ffe32de?p=b7a48ce1871b4291b003f7d8f9893e4c&pm=c'
                color='#05ED94'
              >
                dashboards
              </Link>
              .
            </DashboardDescription>
          )}
          {hasIntegration ? (
            <DashboardCardArea>
              {OPTION_DATA.map((item, index) => (
                <PlaybookCard
                  key={index}
                  title={item.title}
                  name={item.name}
                  isLocked={
                    feature?.dashboards ? feature.dashboards[item.name] : true
                  }
                  message={item.description}
                  tags={item.tags}
                  faq={item.faq}
                  onClickHandler={goToChart}
                />
              ))}
            </DashboardCardArea>
          ) : (
            <CustomButton
              key='AddDataSource'
              variant='contained'
              startIcon={<IntegrationSVG />}
              disableRipple
              onClick={() =>
                navigate(`/myworkspace/${workspaceId}/integration/connect`)
              }
            >
              Add Data Source
            </CustomButton>
          )}
        </DashboardWrapper>
      )}
    </>
  );
}

const DashboardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: calc(100% - 260px);
  left: 260px;
`;

const DashboardTitle = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.cyanBlue};
  font-size: 24px;
  font-family: 'Arial';
  padding: 5px;
`;

const DashboardDescription = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.realBlack};
  font-size: 14px;
  padding: 5px;
  width: 340px;
  line-height: 19px;
  font-family: 'Arial';
  text-align: center;
`;

const DashboardCardArea = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  transition: all ease 0.3s;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1900px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const OPTION_DATA = [
  {
    title: 'KPI Analytics',
    name: 'kpiAnalytics',
    description: 'KPI Performances in one view.',
    status: 'locked',
    tags: {
      category: 'Dashboard',
      subCategory: 'Analytics',
    },
    faq: 'https://panomix.notion.site/Media-Mix-Modeling-b7a48ce1871b4291b003f7d8f9893e4c',
  },
  {
    title: 'Paid Channel Analytics',
    name: 'paidChannelAnalytics',
    description: 'Channels and campaign performances.',
    status: 'locked',
    tags: {
      category: 'Dashboard',
      subCategory: 'Marketing',
    },
    faq: 'https://panomix.notion.site/Media-Mix-Modeling-b7a48ce1871b4291b003f7d8f9893e4c',
  },
  // {
  //   title: 'Organic Channel Analytics',
  //   name: 'organicChannelAnalytics',
  //   description: 'Organic contents and their impacts on KPIs.',
  //   status: 'locked',
  //   tags: {
  //     category: 'Dashboard',
  //     subCategory: 'Analytics',
  //   },
  //   faq: 'https://panomix.notion.site/Media-Mix-Modeling-b7a48ce1871b4291b003f7d8f9893e4c',
  // },
  {
    title: 'Model Performance',
    name: 'modelPerformance',
    description: 'Model Performances on KPIs.',
    status: 'locked',
    tags: {
      category: 'Dashboard',
      subCategory: 'ML',
    },
    faq: 'https://panomix.notion.site/Media-Mix-Modeling-b7a48ce1871b4291b003f7d8f9893e4c',
  },
  // {
  //   title: 'Creative Analytics',
  //   name: 'creativeAnalytics',
  //   description: 'Creative breakdowns and their impacts on KPIs.',
  //   status: 'locked',
  //   tags: {
  //     category: 'Dashboard',
  //     subCategory: 'Creative',
  //   },
  //   faq: 'https://panomix.notion.site/Media-Mix-Modeling-b7a48ce1871b4291b003f7d8f9893e4c',
  // },
  // {
  //   title: 'Sales Analytics',
  //   name: 'salesAnalytics',
  //   description: 'Sales details in one view.',
  //   status: 'locked',
  //   tags: {
  //     category: 'Dashboard',
  //     subCategory: 'Analytics',
  //   },
  //   faq: 'https://panomix.notion.site/Media-Mix-Modeling-b7a48ce1871b4291b003f7d8f9893e4c',
  // },
];

const CustomButton = MuiStyled(Button)({
  height: '40px',
  width: '180px',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  padding: '6px 12px',
  borderRadius: '8px',
  backgroundColor: '#1A1F27',
  lineHeight: 1.5,
  fontFamily: 'Arial',
  outline: 'none',
  color: 'white',
  '&:hover': {
    backgroundColor: '#242B35',
    boxShadow: 'none',
  },
  cursor: 'pointer',
});
