import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { APIs } from '../../../config';
import { WorkspaceContext } from '../../../contextApi/WorkspaceContext';
import { ReactComponent as IntegrationSVG } from '../../../icons/Integrations.svg';
import { styled as MuiStyled } from '@mui/material/styles';
import { Button } from '@mui/material';

import PopupModal from '../../../components/PopupModal';
import DateSelect from '../../../components/InputComponents/DateSelect';

import { changeDateFormatForSubmit } from '../../../hooks/changeDateFormat';
import DeltaCard from './Charts/DeltaCard';
import StackedAreaChart from './Charts/StackedAreaChart';
import ModelPerfTable from './Charts/ModelPerfTable';

export default function KpiAnalytics(props) {
  const {
    hasRequired,
    sessionJWT,
    workspaceId,
    chartData,
    selectedPeriod,
    setSelectedPeriod,
    labelDates,
  } = props;

  // Get organic channel list if empty set empty page to connect organic channels
  // if media is not found empty media page?

  const { workspaceData, getWorkspaceData } = useContext(WorkspaceContext);
  const [showPopupModal, setShowPopupModal] = useState(false);
  const [kpis, setKpis] = useState([]);

  const navigate = useNavigate();

  const openCautionPopup = () => {
    const goToPrev = () => navigate(`/myworkspace/${workspaceId}/dashboard`);
    PopupModal.closePopupModal = () => goToPrev();
    PopupModal.popupMessage =
      'Your dashboard is currently locked. Please contact us.';
    PopupModal.handleConfirmAction = () => goToPrev();
    setShowPopupModal(true);
  };
  useEffect(() => {
    if (workspaceData.feature && workspaceData.feature.dashboards) {
      if (workspaceData.feature.dashboards.kpiAnalytics) {
        openCautionPopup();
      }
    }
  }, [workspaceData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sessionJWT && workspaceId) {
      getWorkspaceData('feature');
    }
  }, [sessionJWT, workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Call API to get choices and set state
    if (sessionJWT && workspaceId) {
      const fetchKpis = async () => {
        const request = await fetch(
          `${APIs(
            'model',
            workspaceId
          )}/mmm/kpi?startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
          {
            headers: { Authorization: `Bearer ${sessionJWT}` },
          }
        );
        const data = await request.json();

        if (data.length > 0) {
          setKpis(data);
        } else return;
      };
      fetchKpis();
    }
  }, [sessionJWT, workspaceId, selectedPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  if (hasRequired) {
    return (
      <>
        <DateSelectWrapper>
          <DateSelect
            dateRange={selectedPeriod}
            setDateRange={setSelectedPeriod}
            type='dashboard'
          />
        </DateSelectWrapper>
        <Container>
          <CardArea>
            <DeltaCard
              title='Sessions'
              value={
                chartData.sessions ? chartData.sessions[0].sessions : undefined
              }
              prevValue={
                chartData.sessionsPrev
                  ? chartData.sessionsPrev[0].sessions
                  : undefined
              }
            />
            <DeltaCard
              title='Avg. Daily Total Users'
              value={
                chartData.avgTotalUser
                  ? chartData.avgTotalUser[0].totalUsers
                  : undefined
              }
              prevValue={
                chartData.avgTotalUserPrev
                  ? chartData.avgTotalUserPrev[0].totalUsers
                  : undefined
              }
            />
            <DeltaCard
              title='Avg. Daily New Users'
              value={
                chartData.avgNewUser
                  ? chartData.avgNewUser[0].newUsers
                  : undefined
              }
              prevValue={
                chartData.avgNewUserPrev
                  ? chartData.avgNewUserPrev[0].newUsers
                  : undefined
              }
            />
            <DeltaCard
              title='Total Ad Spend'
              value={
                chartData.totalAdSpends
                  ? chartData.totalAdSpends[0].spends
                  : undefined
              }
              prevValue={
                chartData.totalAdSpendsPrev
                  ? chartData.totalAdSpendsPrev[0].spends
                  : undefined
              }
            />
            <DeltaCard
              title='Revenue'
              value={
                chartData.revenueCafe &&
                chartData.revenueCafe[0].revenue !== null
                  ? chartData.revenueCafe[0].revenue
                  : chartData.revenueGA &&
                    chartData.revenueGA[0].revenue !== null
                  ? chartData.revenueGA[0].revenue
                  : undefined
              }
              prevValue={
                chartData.revenueCafePrev &&
                chartData.revenueCafePrev[0].revenue !== null
                  ? chartData.revenueCafePrev[0].revenue
                  : chartData.revenueGAPrev &&
                    chartData.revenueGAPrev[0].revenue !== null
                  ? chartData.revenueGAPrev[0].revenue
                  : undefined
              }
            />
            <DeltaCard
              title='Sales'
              value={
                chartData.salesCafe && chartData.salesCafe[0].sales !== 0
                  ? chartData.salesCafe[0].sales
                  : chartData.salesGA && chartData.salesGA[0].sales !== 0
                  ? chartData.salesGA[0].sales
                  : undefined
              }
              prevValue={
                chartData.salesCafePrev &&
                chartData.salesCafePrev[0].sales !== 0
                  ? chartData.salesCafePrev[0].sales
                  : chartData.salesGAPrev &&
                    chartData.salesGAPrev[0].sales !== 0
                  ? chartData.salesGAPrev[0].sales
                  : undefined
              }
            />
            <DeltaCard
              title='Avg. Revenue Per Sale'
              value={
                chartData.revenueCafe &&
                chartData.revenueCafe[0].revenue !== null &&
                chartData.salesCafe &&
                chartData.salesCafe[0].sales !== 0
                  ? chartData.revenueCafe[0].revenue /
                    chartData.salesCafe[0].sales
                  : chartData.revenueGA &&
                    chartData.revenueGA[0].revenue !== null &&
                    chartData.salesGA &&
                    chartData.salesGA[0].sales !== 0
                  ? chartData.revenueGA[0].revenue / chartData.salesGA[0].sales
                  : undefined
              }
              prevValue={
                chartData.revenueCafePrev &&
                chartData.revenueCafePrev[0].revenue !== null &&
                chartData.salesCafePrev &&
                chartData.salesCafePrev[0].sales !== 0
                  ? chartData.revenueCafePrev[0].revenue /
                    chartData.salesCafePrev[0].sales
                  : chartData.revenueGAPrev &&
                    chartData.revenueGAPrev[0].revenue !== null &&
                    chartData.salesGAPrev &&
                    chartData.salesGAPrev[0].sales !== 0
                  ? chartData.revenueGAPrev[0].revenue /
                    chartData.salesGAPrev[0].sales
                  : undefined
              }
            />
            <DeltaCard
              title='Revenue Per Session'
              value={
                chartData.revenueCafe &&
                chartData.revenueCafe[0].revenue !== null &&
                chartData.sessions &&
                chartData.sessions.length > 0
                  ? chartData.revenueCafe[0].revenue /
                    chartData.sessions[0].sessions
                  : chartData.revenueGA &&
                    chartData.revenueGA[0].revenue !== null &&
                    chartData.sessions &&
                    chartData.sessions.length > 0
                  ? chartData.revenueGA[0].revenue /
                    chartData.sessions[0].sessions
                  : undefined
              }
              prevValue={
                chartData.revenueCafePrev &&
                chartData.revenueCafePrev[0].revenue !== null &&
                chartData.sessionsPrev &&
                chartData.sessionsPrev.length > 0
                  ? chartData.revenueCafePrev[0].revenue /
                    chartData.sessionsPrev[0].sessions
                  : chartData.revenueGAPrev &&
                    chartData.revenueGAPrev[0].revenue !== null &&
                    chartData.sessionsPrev &&
                    chartData.sessionsPrev.length > 0
                  ? chartData.revenueGAPrev[0].revenue /
                    chartData.sessionsPrev[0].sessions
                  : undefined
              }
            />
          </CardArea>
          {kpis &&
            kpis.length > 0 &&
            kpis.map((kpi, idx) => (
              <DoubleChartArea key={idx}>
                <StackedAreaChart
                  title={kpi.kpi.sourceName}
                  labels={labelDates}
                  models={kpi.models}
                  xAxisKey='date'
                  yAxisKey='contribution'
                />
                <ModelPerfTable
                  title={kpi.kpi.sourceName}
                  models={kpi.models}
                />
              </DoubleChartArea>
            ))}
        </Container>

        <PopupModal
          popupModalStatus={showPopupModal}
          popupMessage={PopupModal.popupMessage}
          confirmBtnName={PopupModal.confirmBtnName}
          handleConfirmAction={PopupModal.handleConfirmAction}
          extraBtnName={PopupModal.extraBtnName}
          handleExtraAction={PopupModal.handleExtraAction}
          closePopupModal={PopupModal.closePopupModal}
        />
      </>
    );
  } else
    return (
      <EmptyWrapper>
        <EmptyTitle>🤖 Hello!</EmptyTitle>
        <EmptyDescription>
          You need to connect at least one analytics channel for this dashboard.
        </EmptyDescription>
        <CustomButton
          key='AddDataSource'
          variant='contained'
          startIcon={<IntegrationSVG />}
          disableRipple
          onClick={() =>
            navigate(`/myworkspace/${workspaceId}/integration/connect`)
          }
        >
          Add Data Source
        </CustomButton>
      </EmptyWrapper>
    );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 260px; /* Sidebar width */
  margin-top: 80px;
  margin-bottom: 80px;
`;

const EmptyWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: calc(100% - 260px);
  left: 260px;
`;

const DateSelectWrapper = styled.div`
  position: fixed;
  width: 230px;
  right: 20px;
  top: 20px;
  width: 230px;
  z-index: 2;
`;

const CardArea = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2px;
  margin-left: 20px;
  margin-right: 20px;
`;

const EmptyTitle = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.cyanBlue};
  font-size: 24px;
  font-family: 'Arial';
  padding: 5px;
`;

const EmptyDescription = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.realBlack};
  font-size: 14px;
  padding: 5px;
  width: 340px;
  line-height: 19px;
  font-family: 'Arial';
  text-align: center;
`;

const CustomButton = MuiStyled(Button)({
  height: '40px',
  width: '180px',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  padding: '6px 12px',
  borderRadius: '8px',
  backgroundColor: '#1A1F27',
  lineHeight: 1.5,
  fontFamily: 'Arial',
  outline: 'none',
  color: 'white',
  '&:hover': {
    backgroundColor: '#242B35',
    boxShadow: 'none',
  },
  cursor: 'pointer',
});

const DoubleChartArea = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4px;
  margin-top: 4px;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 1600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }
`;
