import React from 'react';
import styled from 'styled-components';

const Cafe24 = (props) => {
  const { setShowModal, setContent } = props;

  return (
    <Wrapper>
      <CafeButton
        id='Cafe24'
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setContent('cafe24');
          setShowModal(true);
        }}
      >
        <IntegrateText>Integrate</IntegrateText>
      </CafeButton>
    </Wrapper>
  );
};

export default Cafe24;

const Wrapper = styled.div`
  position: relative;
`;

const CafeButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.pb.cyanBlue};
  border-radius: 43px;
  padding: 8px 14px;
  background-color: white;
  cursor: pointer;
`;

const IntegrateText = styled.span`
  width: 56px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.pb.cyanBlue};
`;
