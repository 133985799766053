import React, { useContext } from 'react';
import { LanguageContext } from '../../contextApi/LanguageContext';
import { useMediaQuery } from 'react-responsive';

import { GRAPHIC_TEXT } from './GRAPHIC_TEXT';

import MMMImage from '../../assets/images/SignInGraphics/MMM.png';
import KPIsPlanImage from '../../assets/images/SignInGraphics/KPIsPlan.png';
import CampaignManageImage from '../../assets/images/SignInGraphics/CampaignManage.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Mousewheel, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import styled, { css } from 'styled-components';

export default function Graphics() {
  SwiperCore.use([Mousewheel, Autoplay]);
  const isUnder1440 = useMediaQuery({ maxWidth: 1440 });

  const { language } = useContext(LanguageContext);
  const IMAGES = [MMMImage, KPIsPlanImage, CampaignManageImage];

  const options = {
    direction: 'vertical',
    slidesPerView: 1,
    mousewheel: {
      invert: false,
    },
    height: window.innerHeight,

    autoplay: {
      delay: 10000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    speed: 800,
    loop: true,
  };

  return (
    <Wrapper>
      <StyledSwiper {...options}>
        {[0, 1, 2, 3].map((item) => (
          <StyledSlide key={item}>
            <AlignBox isUnder1440={isUnder1440}>
              {item === 0 && (
                <Headline isUnder1440={isUnder1440}>
                  {GRAPHIC_TEXT[language][item]?.headline}
                </Headline>
              )}
              {item > 0 && (
                <>
                  <Title>{GRAPHIC_TEXT[language][item]?.title}</Title>
                  <Description>
                    {GRAPHIC_TEXT[language][item]?.description}
                  </Description>
                  <Image
                    src={IMAGES[item - 1]}
                    index={item}
                    isUnder1440={isUnder1440}
                  />
                </>
              )}
            </AlignBox>
          </StyledSlide>
        ))}
      </StyledSwiper>
    </Wrapper>
  );
}

const Wrapper = styled.figure`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;

  color: #fff;
`;

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
`;

const StyledSlide = styled(SwiperSlide)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const AlignBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: ${({ isUnder1440 }) => (isUnder1440 ? '50%' : '100%')};
`;

const Headline = styled.h1`
  text-align: left;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: ${({ isUnder1440 }) => (isUnder1440 ? '60px' : '72px')};

  line-height: ${({ isUnder1440 }) => (isUnder1440 ? '70px' : '85px')};
  letter-spacing: -0.03rem;
`;

const Title = styled.h3`
  text-align: left;
  font-weight: 700;
  font-size: 46px;
  line-height: 53px;
`;

const Description = styled.span`
  margin-top: 20px;
  text-align: left;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
`;

const Image = styled.img`
  width: ${({ isUnder1440 }) => (isUnder1440 ? '360px' : '500px')};
  margin-top: 50px;

  ${({ index }) =>
    index === 2 &&
    css`
      width: ${({ isUnder1440 }) => (isUnder1440 ? '360px' : '600px')};
    `}
`;
