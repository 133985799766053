import React, { createContext, useContext, useState, useEffect } from 'react';
import { AccountContext } from './AccountContext';
import { useParams } from 'react-router-dom';
import { APIs } from '../config';

export const ChannelsContext = createContext();

export const ChannelsProvider = ({ children }) => {
  const { sessionJWT } = useContext(AccountContext);
  const { workspaceId } = useParams();
  const [integratedList, setIntegratedList] = useState();
  const [authenticateError, setAuthenticateError] = useState('');

  const getIntegratedList = async () => {
    try {
      const request = await fetch(APIs('integratedList', workspaceId), {
        headers: { Authorization: `Bearer ${sessionJWT}` },
      });
      const response = request.json();
      const data = await response;

      if (request.ok) {
        setIntegratedList(data);
      } else if (request.status === 400) {
        setAuthenticateError(data.detail);
      }

      // fetch('/data/channelListData.json')
      //   .then(res => res.json())
      //   .then(data => {
      //     setIntegratedList(data);
      //   });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (sessionJWT && workspaceId) {
      getIntegratedList();
    } else return;
  }, [workspaceId, sessionJWT, window.location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ChannelsContext.Provider
      value={{
        integratedList,
        getIntegratedList,
        authenticateError,
      }}
    >
      {children}
    </ChannelsContext.Provider>
  );
};

export const useChannelsContext = () => {
  const context = useContext(ChannelsContext);
  if (!context) {
    throw new Error('Cannot get integrated channels');
  }
  return context;
};
