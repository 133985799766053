import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import KPISelector from '../Components/KPISelector';
export default function StackedLineChart({
  kpiChannel,
  setKpiChannel,
  kpiOptions,
  title,
  labels,
  channelPredictions,
  xAxisKey,
  yAxisKey,
}) {
  const [timeSeries, setTimeSeries] = useState([]);

  const getChannelKpiStacked = () => {
    if (kpiChannel) {
      const timeSeriesData = [];

      const filtered = channelPredictions.filter(
        (item) =>
          item.kpi === kpiChannel.kpi.sourceName &&
          item.sourceOfTruth === kpiChannel.sourceOfTruth
      );
      for (let pred of filtered) {
        timeSeriesData.push({
          label: pred.channelName,
          data: pred.timeSeriesData,
          fill: true,
        });
      }
      setTimeSeries(timeSeriesData);
    } else return;
  };

  useEffect(() => {
    getChannelKpiStacked();
  }, [channelPredictions]); // eslint-disable-line react-hooks/exhaustive-deps

  const data = {
    labels: labels,
    datasets: timeSeries,
  };

  const options = {
    elements: {
      line: {
        tension: 0.1,
        borderWidth: 2,
      },
    },
    parsing: {
      xAxisKey: xAxisKey,
      yAxisKey: yAxisKey,
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          font: {
            size: 12,
            family: 'Arial',
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: 'Arial',
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          font: {
            size: 12,
            weight: 400,
            family: 'Arial',
          },
        },
      },
    },
  };

  const handleChoiceClick = (choice) => {
    setKpiChannel(choice);
  };

  return (
    <Wrapper>
      <TitleArea>{title}</TitleArea>
      <KPISelector
        defaultValue={kpiChannel.sk}
        options={kpiOptions}
        onClickHandler={handleChoiceClick}
      />
      <Line options={options} data={data} />
    </Wrapper>
  );
}

const TitleArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.pb.black};
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  font-family: 'Arial';
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px;
  height: auto;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  transition: all ease 0.7s;
  ${({ theme }) => theme.animation.fadeInBottom}
`;
