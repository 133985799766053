import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import BudgetSimulationCard from './BudgetSimulationCard';
import KPISimulationChart from './KPISimulationChart';
import { capitalizeChar } from '../../../../hooks/textFormatter';

export default function DrawerComponents({ items, kpis, budgetChangeHandler }) {
  const [adsets, setAdsets] = useState();

  useEffect(() => {
    setAdsets(items);
  }, [items]);

  return (
    <Container>
      <Title>AdSet Details({adsets?.length})</Title>
      {adsets &&
        adsets.length > 0 &&
        adsets.map((adset, index) => (
          <CardArea key={index} length={kpis.length}>
            <BudgetSimulationCard
              item={adset}
              title={adset.adsetname}
              value={adset.recommendedbudget}
              prevValue={adset.average_spend}
              budgetChangeHandler={budgetChangeHandler}
            />
            {kpis.map((kpi, index) => (
              <KPISimulationChart
                key={index}
                title={capitalizeChar(kpi.kpi.sourceName)}
                xAxisKey={'date'}
                yAxisKey={'metric'}
                kpi={kpi}
                adset={adset}
              />
            ))}
          </CardArea>
        ))}
    </Container>
  );
}

const Container = styled.div`
  margin: 40px 40px 40px 40px;
`;
const Title = styled.div`
  font-family: 'Arial';
  font-size: 24px;
  margin-bottom: 30px;
`;

const CardArea = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ length }) => length + 1},
    1fr
  ); // Remove the extra semicolon here
  grid-gap: 8px;
  margin-top: 20px;
  margin-bottom: 40px;
`;
