import React from 'react';
import styled from 'styled-components';
import Loader from './Loader';

const ModalLoading = () => {
  return (
    <Container>
      <Loader />
    </Container>
  );
};
export default ModalLoading;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
`;
