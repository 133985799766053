import deletePermissionFB from './FacebookDeletePermission';
import ErrorMessageBox from '../../../../../components/ErrorMessageBox';

export default function getFBPagesData({
  APIOptionByType,
  businessAccountId,
  accessToken,
  integrationData,
  setIntegrationData,
  openCautionPopup,
  setShowModal,
  disconnectAllFBChannel,
  reconnectFromTheBaseMessage,
  reconnectFB,
}) {
  const integrationType = 'owned_pages';
  const apiUrl = `/${businessAccountId}/${integrationType}`;

  window.FB.api(
    apiUrl,
    'GET',
    {
      access_token: `${accessToken}`,
      fields: APIOptionByType[integrationType].fields,
    },
    function (response) {
      if (!response.error) {
        if (response?.data?.length === 0) {
          deletePermissionFB({
            userID: integrationData?.userId,
            accessToken,
            deleteScope: APIOptionByType[integrationType].coreScope,
            openCautionPopup,
          });
          openCautionPopup({
            message: ErrorMessageBox({
              errorDescription: APIOptionByType[integrationType].errorMessage,
              warningBoxTitle: reconnectFromTheBaseMessage.warningBoxTitle,
              warningBoxDescription:
                reconnectFromTheBaseMessage.warningBoxDescription,
            }),
            extraButton: {
              buttonName: 'Reconnect',
              buttonAction: () => {
                setIntegrationData({
                  selectedIntegrateType: integrationType,
                });
                deletePermissionFB({
                  userID: integrationData?.userId,
                  accessToken,
                  deleteScope: 'all',
                  openCautionPopup,
                  callbackFunction: () => {
                    disconnectAllFBChannel({
                      callbackFunction: () =>
                        reconnectFB({
                          integrationType,
                          scope: APIOptionByType[integrationType].scope,
                          authType: 'reauthenticate',
                        }),
                    });
                  },
                });
              },
            },
            confirmOption: 'refresh',
            buttonName: 'Cancel',
          });
        } else {
          setIntegrationData(prev => ({
            ...prev,
            [integrationType]: response?.data?.map(account => ({
              label: account.name,
              value: account.id,
              ...(account?.access_token && {
                accessToken: account?.access_token,
              }),
              isPrimary: false,
            })),
          }));
        }
      } else {
        setShowModal(false);
        console.error(response.error);
        let message = `${integrationType} Error :: ${response.error.message}`;
        openCautionPopup({
          message,
          confirmOption: 'refresh',
          buttonName: 'Confirm',
        });
      }
    }
  );
}
