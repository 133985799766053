import React from 'react';

import styled from 'styled-components';

export default function MobileGraphics() {
  return (
    <>
      <Wrapper>
        <TopPage>
          <Title>Thanks for choosing Playbook</Title>
          <TitleDescription>
            Signin is only available above iPad width.
          </TitleDescription>
        </TopPage>
      </Wrapper>
    </>
  );
}
const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: auto;
`;

const TopPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 32px;
  font-weight: medium;
  line-height: 42px;
  color: white;
  text-align: center;
  width: 360px;
`;

const TitleDescription = styled.div`
  font-family: 'Poppins';
  font-size: 18px;
  line-height: 24px;
  font-weight: 200;
  color: white;
  text-align: center;
  width: 240px;
`;
