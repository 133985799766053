import React from 'react';
import DatePicker from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';
import Footer from 'react-multi-date-picker/plugins/range_picker_footer';
import styled, { css } from 'styled-components';

export default function DateSelect({ type, title, dateRange, setDateRange }) {
  const styleByType = {
    sideModal: {
      calendarPosition: 'bottom-left',
      numberOfMonths: 1,
      style: {
        calendarWrapper: {
          width: '81%',
        },
        calendarWeek: {
          width: '103%',
        },
        input: css`
          width: inherit;

          padding: 18px 12px;

          border: 1px solid ${({ theme }) => theme.pb.darkGray};
          border-radius: 8px;
          background-color: transparent;
          color: ${({ theme }) => theme.pb.midBlue};

          font-size: 14px;
          line-height: 16px;

          :focus {
            border: 1px solid ${({ theme }) => theme.pb.cyanBlue};
            box-shadow: none;
          }
        `,
      },
    },
    dashboard: {
      calendarPosition: 'bottom',
      numberOfMonths: 2,
      style: {
        calendarWrapper: { width: '89%', paddingRight: '6px' },
        calendar: {
          width: '90%',
        },
        calendarHeader: {
          paddingLeft: '40px',
        },
        calendarWeek: {
          width: '86%',
        },
        icon: css`
          margin-right: 196px;
          color: ${({ theme }) => theme.pb.black};
        `,
      },
    },
  };

  return (
    <DateContainer>
      {title && <Header>{title}</Header>}
      <DateWrapper customStyle={styleByType[type].style}>
        <DatePicker
          onChange={setDateRange}
          format="MMM D, YYYY"
          numberOfMonths={styleByType[type].numberOfMonths}
          editable={true}
          arrow={false}
          offsetY={styleByType[type].offsetY || 5}
          offsetX={styleByType[type].offsetX || 0}
          calendarPosition={styleByType[type].calendarPosition}
          fixRelativePosition={styleByType[type].fixRelativePosition || false}
          range
          value={dateRange}
          render={styleByType[type].style.icon && <InputIcon />}
          plugins={[<Footer position="bottom" key={1} />]}
        />
      </DateWrapper>
    </DateContainer>
  );
}

const DateWrapper = styled.div`
  .rmdp-container {
    font-size: 14px;
    line-height: 16px;

    width: 100%;
    height: 40px;
    cursor: pointer;
  }

  .rmdp-input {
    ${({ customStyle }) =>
      customStyle?.input
        ? customStyle?.input
        : css`
            display: flex;
            align-items: center;
            width: 100%;
            height: 40px;

            padding-left: 38px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
            border: 1px solid #eaeaea;
            border-radius: 8px;
            background-color: #eaeaea;

            font-size: 13px;

            :focus {
              border: 1px solid ${({ theme }) => theme.pb.gray};
              background-color: #fff;
            }
            :hover {
              margin-top: 2px;
              box-shadow: 0px 3px 7px rgba(9, 16, 55, 0.2) !important;
            }
          `}
    cursor: pointer;
  }

  .icon-tabler-calendar-event {
    ${({ customStyle }) => customStyle?.icon}
  }

  .rmdp-wrapper {
    width: ${({ customStyle }) => customStyle?.calendarWrapper?.width || '89%'};
    margin-left: 25px;
    padding-right: ${({ customStyle }) =>
      customStyle?.calendarWrapper?.paddingRight || '0px'};
    color: ${({ theme }) => theme.pb.cyanBlue};
    background: #fff;
    border: 1px solid #f5f5f5;
    border-radius: 16px;
    font-size: 12px;
    line-height: 16px;
    box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
    overflow: hidden;
  }
  .rmdp-calendar {
    width: ${({ customStyle }) => customStyle?.calendar?.width || '100%'};
  }
  .rmdp-header {
    padding-left: ${({ customStyle }) =>
      customStyle?.calendarHeader?.paddingLeft || '0px'};
  }
  .rmdp-week {
    width: ${({ customStyle }) => customStyle?.calendarWeek?.width || '100%'};
  }
  .rmdp-day {
    height: 30px;
  }
`;

const Header = styled.div`
  margin-top: 2.5em;
  margin-bottom: 0.5em;
  padding-bottom: 0.25rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #000f1f;
`;

const DateContainer = styled.div``;
