import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountContext } from '../../../contextApi/AccountContext';
import { APIs } from '../../../config';
import styled from 'styled-components';
import PopupModal from '../../../components/PopupModal';
import MyAccount from './Account/MyAccount';
import WorkspaceSettings from './Workspace/WorkspaceSettings';
import PaymentAndMembers from './Payment&Members/PaymentAndMembers';

export default function Setting() {
  const navigate = useNavigate();
  const { sessionJWT } = useContext(AccountContext);
  const { workspaceId } = useParams();
  const [popupModalStatus, setPopupModalStatus] = useState(false);

  const [userInfo, setUserInfo] = useState({
    email: '',
    preferredName: '',
    profilePicture: '',
  });

  const [workspaceInfo, setWorkspaceInfo] = useState({
    name: '',
    icon: '',
    timezone: '',
    currency: '',
  });

  const [membersInfo, setMembersInfo] = useState([]);
  const [costData, setCostData] = useState([]);

  const [isAdmin, setIsAdmin] = useState(false);

  const openErrorPopup = (err) => {
    PopupModal.popupMessage = err || 'Sorry, something has gone wrong';
    PopupModal.confirmBtnName = 'Confirm';
    PopupModal.handleConfirmAction = err
      ? () => window.location.reload(true)
      : navigate('/signin');

    PopupModal.closePopupModal = err
      ? () => window.location.reload(true)
      : navigate('/signin');

    setPopupModalStatus(true);
  };

  useEffect(() => {
    if (sessionJWT && workspaceId) {
      async function getSettingsData(url) {
        return new Promise((resolve, reject) => {
          resolve(
            fetch(`${url}`, {
              headers: { Authorization: `Bearer ${sessionJWT}` },
            }).then((res) => res.json())
          );
        });
      }

      let getAllData = async () => {
        try {
          const [userDataset, workspaceDataset, membersDataset, costDataset] =
            await Promise.all([
              getSettingsData(`${APIs('myaccount')}`),
              getSettingsData(`${APIs('workspace', workspaceId)}`),
              getSettingsData(`${APIs('member', workspaceId)}`),
              getSettingsData(`${APIs('cost', workspaceId)}`),
            ]);

          setUserInfo(userDataset);
          setWorkspaceInfo(workspaceDataset);

          let thisUser = membersDataset.find(
            (item) => item.id === userDataset.id
          );

          setIsAdmin(thisUser.isAdmin);

          let withOutUsr = membersDataset.filter((data) => data !== thisUser);
          withOutUsr.unshift(thisUser);
          setMembersInfo(withOutUsr);
          setCostData(costDataset);
        } catch (err) {
          console.error(err);
          openErrorPopup();
        }
      };
      getAllData();
    } else return;
  }, [sessionJWT, workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SettingWrapper>
        <MyAccount userInfo={userInfo} />
        <WorkspaceSettings workspaceInfo={workspaceInfo} />
        <PaymentAndMembers
          membersInfo={membersInfo}
          costData={costData}
          isAdmin={isAdmin}
        />
      </SettingWrapper>

      <PopupModal
        popupModalStatus={popupModalStatus}
        popupMessage={PopupModal.popupMessage}
        confirmBtnName={PopupModal.confirmBtnName}
        handleConfirmAction={PopupModal.handleConfirmAction}
        closePopupModal={PopupModal.closePopupModal}
      />
    </>
  );
}
const SettingWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 260px);
  margin-left: 260px;
  top: 20px;
`;
