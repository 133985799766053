import React, { useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountContext } from '../../../../../contextApi/AccountContext';
import { APIs } from '../../../../../config';
import Select from 'react-select';
import styled from 'styled-components';
import { customStyles } from '../../../../../styles/ReactSelectStyle';

export default function GoogleAnalyticsModal({
  integrationData,
  setIntegrationData,
  isSubmitClicked,
  setIsSubmitClicked,
  setIsFormReady,
  setShowModal,
  openCautionPopup,
}) {
  const { sessionJWT } = useContext(AccountContext);
  const { workspaceId } = useParams();

  const navigate = useNavigate();

  const dataBySelectType = {
    account: {
      title: 'Account',
      placeholder: 'Select Account',
      caution: 'Choose your Google Analytics account',
    },
    webProperties: {
      title: 'Property',
      placeholder: 'Select Property',
      caution: 'Choose your Property',
    },
    profiles: {
      title: 'View',
      placeholder: 'Select View',
      caution: 'Choose your View',
    },
  };

  const findSelectedElement = type => {
    const selectedAccountIdx = integrationData?.findIndex(
      item => item.isPrimary === true
    );

    if (type === 'account') {
      return integrationData?.[selectedAccountIdx];
    } else {
      return integrationData?.[selectedAccountIdx]?.[type]?.find(
        item => item.isPrimary === true
      );
    }
  };

  const isNeedSelectProfile =
    findSelectedElement('account')?.profiles !== undefined &&
    findSelectedElement('account')?.profiles?.find(
      item => item.propertyId === findSelectedElement('webProperties')?.value
    );

  const isReadyToSubmit = type => {
    if (type === 'account') {
      return !integrationData?.every(data => data.isPrimary === false);
    } else if (type === 'webProperties') {
      return !findSelectedElement('account')?.webProperties?.every(
        data => data.isPrimary === false
      );
    } else if (type === 'profiles') {
      return !findSelectedElement('account')?.profiles?.every(
        data => data.isPrimary === false
      );
    } else if (type === 'final') {
      if (isNeedSelectProfile) {
        return (
          !integrationData?.every(data => data.isPrimary === false) &&
          !findSelectedElement('account')?.webProperties?.every(
            data => data.isPrimary === false
          ) &&
          !findSelectedElement('account')?.profiles?.every(
            data => data.isPrimary === false
          )
        );
      } else {
        return (
          !integrationData?.every(data => data.isPrimary === false) &&
          !findSelectedElement('account')?.webProperties?.every(
            data => data.isPrimary === false
          )
        );
      }
    }
  };

  const selectHandler = (e, type) => {
    let updatedData = [...integrationData];

    const selectedAccountIdx = integrationData?.findIndex(
      item => item.isPrimary === true
    );
    const unSelect = list => {
      return list?.map(item => ({ ...item, isPrimary: false }));
    };

    if (type === 'account') {
      const accountIndex = integrationData?.findIndex(
        item => item.value === e.value
      );

      updatedData = unSelect(updatedData);
      updatedData.splice(accountIndex, 1, {
        ...updatedData[accountIndex],
        isPrimary: true,
      });

      setIntegrationData(updatedData);
    } else if (type === 'webProperties') {
      const propertyIdx = integrationData?.[
        selectedAccountIdx
      ]?.webProperties?.findIndex(item => item.value === e.value);
      let updatedProps = updatedData?.[selectedAccountIdx]?.webProperties;
      let updatedProfiles = updatedData?.[selectedAccountIdx]?.profiles;

      updatedProfiles = unSelect(updatedProfiles);
      updatedProps = unSelect(updatedProps);
      updatedProps.splice(propertyIdx, 1, {
        ...updatedProps[propertyIdx],
        isPrimary: true,
      });

      updatedData.splice(selectedAccountIdx, 1, {
        ...updatedData[selectedAccountIdx],
        webProperties: updatedProps,
        profiles: updatedProfiles,
      });

      setIntegrationData(updatedData);
    } else if (type === 'profiles') {
      const profileIdx = integrationData?.[
        selectedAccountIdx
      ]?.profiles?.findIndex(item => item.value === e.value);
      let updatedProfiles = updatedData?.[selectedAccountIdx]?.profiles;

      updatedProfiles = unSelect(updatedProfiles);
      updatedProfiles.splice(profileIdx, 1, {
        ...updatedProfiles[profileIdx],
        isPrimary: true,
      });

      updatedData.splice(selectedAccountIdx, 1, {
        ...updatedData[selectedAccountIdx],
        profiles: updatedProfiles,
      });

      setIntegrationData(updatedData);
    } else return;
  };

  const sortOptions = type => {
    if (type === 'account') {
      return integrationData?.filter(data => !data.isPrimary);
    } else {
      return findSelectedElement('account')?.[type]?.filter(
        data => !data.isPrimary
      );
    }
  };

  const clearState = () => {
    setIsFormReady(prev => ({ ...prev, googleAnalytics: false }));
    setIsSubmitClicked(prev => ({ ...prev, googleAnalytics: false }));
  };

  const handleSubmit = () => {
    if (isSubmitClicked) {
      if (isReadyToSubmit('final')) {
        const selectedAccount = findSelectedElement('account');
        const selectedProperty = findSelectedElement('webProperties');
        const selectedProfile = findSelectedElement('profiles');

        let submitData = {
          category: 'sot',
          channelName: 'googleAnalytics',
          accessToken: sessionStorage.getItem('accessToken'),
          refreshToken: sessionStorage.getItem('refreshToken'),
          account: {
            id: selectedAccount?.value,
            descriptiveName: selectedAccount?.label,
          },

          webProperty: {
            id: selectedProperty?.value,
            name: selectedProperty?.label,
          },
        };

        if (isNeedSelectProfile) {
          submitData = {
            ...submitData,
            webProperty: {
              ...submitData.webProperty,
              profile: {
                id: selectedProfile?.value,
                name: selectedProfile?.label,
              },
            },
          };
        }

        sendAdsData(submitData);
      } else {
        clearState();
      }
    } else return;
  };

  const sendAdsData = async data => {
    try {
      const request = await fetch(
        `${APIs('integration', workspaceId)}?channelName=googleAnalytics`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionJWT}`,
          },
          body: JSON.stringify(data),
        }
      );
      const result = await request.json();
      if (request.ok) {
        navigate('.', { state: null });
        window.location.reload(true);
      } else if (request.status === 400) {
        let message;
        if (result?.[0] === 'channel already integrated') {
          message = 'Google Analytics is already integrated';
        } else {
          message = result[0];
        }
        setShowModal(prev => !prev);
        openCautionPopup({
          message: message,
          confirmOption: 'refresh',
          buttonName: 'Close',
        });
      }
    } catch (error) {
      setShowModal(prev => !prev);
      const message =
        'Something went wrong and we could not process your request';
      openCautionPopup({
        message: message,
        confirmOption: 'refresh',
        buttonName: 'Close',
      });
      throw new Error('Issue with Google Analytics Integration', error.message);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [isSubmitClicked]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SelectWrapper>
      <Header>{dataBySelectType?.account?.title}</Header>
      <Select
        name="account"
        options={sortOptions('account')}
        onChange={e => selectHandler(e, 'account')}
        styles={customStyles}
        placeholder={dataBySelectType?.webProperties?.account}
      />
      {!isReadyToSubmit('account') ? (
        <FormErrors>
          <Span>{dataBySelectType?.webProperties?.account}</Span>
        </FormErrors>
      ) : (
        <>
          <Header>{dataBySelectType?.webProperties?.title}</Header>
          <Select
            name="webProperties"
            options={sortOptions('webProperties')}
            onChange={e => selectHandler(e, 'webProperties')}
            styles={customStyles}
            placeholder={dataBySelectType?.webProperties?.placeholder}
          />
          {!isReadyToSubmit('webProperties') ? (
            <FormErrors>
              <Span>{dataBySelectType?.webProperties?.caution}</Span>
            </FormErrors>
          ) : (
            isNeedSelectProfile && (
              <>
                <Header>{dataBySelectType?.profiles?.title}</Header>
                <Select
                  name="profiles"
                  options={sortOptions('profiles')}
                  onChange={e => selectHandler(e, 'profiles')}
                  styles={customStyles}
                  placeholder={dataBySelectType?.profiles?.placeholder}
                />
                {!isReadyToSubmit('profiles') && (
                  <FormErrors>
                    <Span>{dataBySelectType?.profiles?.caution}</Span>
                  </FormErrors>
                )}
              </>
            )
          )}
        </>
      )}
    </SelectWrapper>
  );
}

const SelectWrapper = styled.div`
  margin-top: 50px;
`;
const Header = styled.div`
  box-sizing: border-box;
  margin-top: 1.875rem;
  padding-bottom: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  color: #000f1f;
`;

const FormErrors = styled.div``;

const Span = styled.span`
  display: block;
  box-sizing: inherit;
  padding-top: 0.1875rem;
  padding-left: 1rem;
  margin-bottom: -1.25rem;
  font-size: 0.75rem;
  color: red;
`;

const Loading = styled.div`
  margin-top: 1.875rem;
  color: ${({ theme }) => theme.pm.green};
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
`;
