export const Analytics4Api = async ({ setIsLoading, token, data, setData }) => {
  try {
    const request = await fetch(
      'https://analyticsadmin.googleapis.com/v1alpha/accountSummaries',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const resp = await request.json();
    if (!resp.error) {
      const { accountSummaries } = resp;
      let previousData = data;

      const extractID = str => {
        const id = str.split('/')[1];
        return id;
      };

      const accounts = accountSummaries.map(account => ({
        id: extractID(account.account),
        webProperties: account.propertySummaries?.map(property => ({
          accountId: extractID(account.account),
          value: extractID(property.property),
          label: property.displayName,
          isPrimary: false,
        })),
      }));

      accounts.forEach(el => {
        const index = previousData.findIndex(acct => acct.value === el.id);

        if (el?.webProperties !== undefined) {
          previousData[index].webProperties = previousData[
            index
          ].webProperties.concat(el?.webProperties);
        }
      });
      setData(previousData);
    } else if (
      resp.error.code === 403 &&
      resp.error.message === 'User does not have any Google Analytics account.'
    ) {
      let message = 'User does not have any Google Analytics account.';
      setData({ errorMessage: message });
    } else {
      let message =
        'Something went wrong and we could not process your request';
      setData({ errorMessage: message });
    }
  } catch (error) {
    setData({ errorMessage: error.message });
    throw new Error(
      'Analytics4Api error getting analytics data',
      error.message
    );
  } finally {
    return setIsLoading(false);
  }
};
