import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './pages/Main/Main';

import SelectWorkspace from './pages/WorkspaceList/SelectWorkspace/SelectWorkspace';
import CreateWorkspace from './pages/WorkspaceList/CreateWorkspace/CreateWorkspace';
import ApproveJoinWorkspace from './pages/WorkspaceList/ApproveJoinWorkspace/ApproveJoinWorkspace';

import AnalyticsAuthenticate from './pages/Workspace/Integration/DataIntegration/GoogleAnalytics/AnalyticsAuthenticate';
import GoogleAdsAuthenticate from './pages/Workspace/Integration/DataIntegration/GoogleAds/GoogleAdsAuthenticate';
import TiktokAdsAuth from './pages/Workspace/Integration/DataIntegration/TikTok/TiktokAdsAuth';
import YoutubeAuth from './pages/Workspace/Integration/DataIntegration/Youtube/YoutubeAuth';
import Cafe24Auth from './pages/Workspace/Integration/DataIntegration/Cafe24/Cafe24Auth';
// import ShopifyAuth from './pages/Workspace/Dashboard/DataIntegration/Shopify/ShopifyAuth';
// import MailchimpAuth from './pages/Workspace/Dashboard/DataIntegration/Mailchimp/MailchimpAuth';

import Workspace from './pages/Workspace/Workspace';
import TiktokOrganicAuth from './pages/Workspace/Integration/DataIntegration/TikTokOrganic/TiktokOrganicAuth';

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Main defaultModal='signIn' />} />
        <Route path='signin' element={<Main defaultModal='signIn' />} />
        <Route
          path='changepassword'
          element={<Main defaultModal='changePW' />}
        />
        <Route path='terms' element={<Main type='terms' />} />
        <Route path='cookies' element={<Main type='cookies' />} />
        <Route path='privacy' element={<Main type='privacy' />} />

        <Route path='workspacelist' element={<SelectWorkspace />} />
        <Route path='createworkspace' element={<CreateWorkspace />} />
        <Route path='/confirm/:token' element={<ApproveJoinWorkspace />} />

        <Route path='myworkspace/:workspaceId/*' element={<Workspace />} />
        <Route path='/analytics' element={<AnalyticsAuthenticate />} />
        <Route path='/ads' element={<GoogleAdsAuthenticate />} />
        <Route path='/tiktokAds' element={<TiktokAdsAuth />} />
        <Route path='/tiktok' element={<TiktokOrganicAuth />} />
        <Route path='/youtube' element={<YoutubeAuth />} />
        <Route path='/cafe24' element={<Cafe24Auth />} />
        {/* <Route path="/shopify" element={<ShopifyAuth />} /> */}
        {/* <Route path="/mailchimp" element={<MailchimpAuth />} /> */}

        <Route path='*' element={<Main defaultModal='signIn' />} />
      </Routes>
    </BrowserRouter>
  );
}
