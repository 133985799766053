import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { changeDateFormatForSubmit } from '../../../../hooks/changeDateFormat';
export default function KPISimulationChart({
  title,
  xAxisKey,
  yAxisKey,
  kpi,
  adset,
}) {
  const getDateLabels = (range) => {
    const dates = [];
    let tempDate = new Date(range[0]); // Start with the start date of the range

    for (let i = 0; i <= 6; i++) {
      dates.push(changeDateFormatForSubmit(new Date(tempDate)));
      tempDate.setDate(tempDate.getDate() + 1); // Increment tempDate by 1 day
    }

    return dates;
  };

  const today = new Date();
  const futureDate = new Date();
  futureDate.setDate(today.getDate() + 6); // Set futureDate 7 days ahead

  const [labelDates, setLabelDates] = useState(
    getDateLabels([today, futureDate]) // Use [today, futureDate] to specify the range from today to 7 days forward
  );

  const getTimeSeries = (kpi, adset) => {
    const { asIs, recommended } = adset;
    const asIsTs =
      asIs.find((ts) => ts.kpi === kpi.kpi.sourceName)?.timeSeries ?? [];
    const recommendedTs =
      recommended.find((ts) => ts.kpi === kpi.kpi.sourceName)?.timeSeries ?? [];

    const datasets = [
      {
        label: 'As-Is',
        data: asIsTs,
      },
      {
        label: 'Simulated',
        data: recommendedTs,
      },
    ];
    return datasets;
  };

  const [data, setData] = useState();

  useEffect(() => {
    setData({
      labels: labelDates,
      datasets: getTimeSeries(kpi, adset),
    });
  }, [kpi, adset]); // eslint-disable-line react-hooks/exhaustive-deps

  const options = {
    elements: {
      line: {
        tension: 0.1,
        borderWidth: 2,
      },
    },
    parsing: {
      xAxisKey: xAxisKey,
      yAxisKey: yAxisKey,
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          font: {
            size: 12,
            family: 'Arial',
          },
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: 'Arial',
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          font: {
            size: 12,
            weight: 400,
            family: 'Arial',
          },
        },
      },
    },
  };

  return (
    <Wrapper>
      <TitleArea>{title}</TitleArea>
      {data && <Line options={options} data={data} />}
    </Wrapper>
  );
}

const TitleArea = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.pb.black};
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  font-family: 'Arial';
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 18px 18px 18px 18px;
  height: auto;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  transition: all ease 0.7s;
  ${({ theme }) => theme.animation.fadeInBottom}
`;
