export const GRAPHIC_TEXT = {
  KR: [
    {
      headline: (
        <>
          Predict Future
          <br /> with Playbook
        </>
      ),
    },
    {
      title: 'ROI를 확인하는 플레이북의 머신러닝',
      description: '다양한 머신러닝들을 통해 마케팅 채널들의 ROI를 확인하세요.',
    },
    {
      title: '미디어 믹스 전략 시뮬레이션',
      description:
        '달성하려는 KPI들을 미디어 믹스 전략과 결합하여 시각화하세요.',
    },
    {
      title: '데이터 인테그레이션',
      description: '다양한 데이터 소스들을 쉽고 빠르게 연결하세요.',
    },
  ],
  EN: [
    {
      headline: (
        <>
          Predict Future
          <br /> with Playbook
        </>
      ),
    },
    {
      title: 'Actionable Insights from ML',
      description: 'Understand the ROI of all of your marketing efforts.',
    },
    {
      title: 'Media Mix Simulation',
      description: 'Simulate to get recommended campaigns with target KPIs.',
    },
    {
      title: 'Seamless Data Integration',
      description:
        'Our platform supports a wide range of data sources, plus custom ETL.',
    },
  ],
};
