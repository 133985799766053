import React, { useState, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { ListSubheader, IconButton } from '@mui/material';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { capitalizeChar } from '../../../../hooks/textFormatter';
import { Close as CloseIcon } from '@mui/icons-material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function KPISelect(props) {
  const { kpis, simulationOptions, setSimulationOptions } = props;
  const [sources, setSources] = useState([]);

  useEffect(() => {
    if (kpis) {
      const options = [];
      for (let kpi of kpis) {
        options.push({
          sourceOfTruth: kpi.channelName,
          name: kpi.sourceName,
          target: '',
        });
      }
      setSources(options);
    }
  }, [kpis]);

  const handleSourceChange = (index) => (event) => {
    const newSources = [...sources];
    newSources[index] = {
      ...newSources[index],
      name: event.target.value,
    };
    setSources(newSources);
  };

  const handleInputValueChange = (index) => (event) => {
    const newSources = [...sources];
    newSources[index] = {
      ...newSources[index],
      target: parseInt(event.target.value),
    };
    setSources(newSources);
  };

  const handleAddAnother = () => {
    setSources([...sources, { sourceOfTruth: '', name: '', target: '' }]);
  };

  const handleRemoveSource = (index) => () => {
    const newSources = [...sources];
    newSources.splice(index, 1);
    setSources(newSources);
    //Gotta remove kpi from simulationOptions
  };

  const renderMenuItem = (item) => (
    <MenuItem key={item.sourceName} value={item.sourceName}>
      {capitalizeChar(item.sourceName)}
    </MenuItem>
  );

  const renderSubheader = (channel) => (
    <ListSubheader key={channel} value={channel} disableSticky>
      {capitalizeChar(channel)}
    </ListSubheader>
  );

  const renderGroupedItems = () => {
    const channels = new Set(kpis.map((item) => item.channelName));
    const items = [];
    for (let channel of channels) {
      items.push(renderSubheader(channel));
      const channelItems = kpis.filter((item) => item.channelName === channel);
      for (let kpi of channelItems) {
        items.push(renderMenuItem(kpi));
      }
    }
    return items;
  };
  useEffect(() => {
    for (let source of sources) {
      if (
        source.sourceOfTruth !== '' &&
        source.name !== '' &&
        source.target !== ''
      ) {
        const kpis = [...simulationOptions.kpis];
        if (kpis.length > 0) {
          const matched = kpis.findIndex(
            (item) =>
              item.name === source.name &&
              item.channelName === source.sourceOfTruth
          );
          if (matched !== -1) {
            const updated = [...simulationOptions.kpis];
            updated.splice(matched, 1);
            updated.push({
              channelName: source.sourceOfTruth,
              name: source.name,
              target: source.target,
            });
            setSimulationOptions((prev) => ({
              ...prev,
              kpis: updated,
            }));
          } else {
            setSimulationOptions((prev) => ({
              ...prev,
              kpis: [
                ...prev.kpis,
                {
                  channelName: source.sourceOfTruth,
                  name: source.name,
                  target: source.target,
                },
              ],
            }));
          }
        } else {
          setSimulationOptions((prev) => ({
            ...prev,
            kpis: [
              {
                channelName: source.sourceOfTruth,
                name: source.name,
                target: source.target,
              },
            ],
          }));
        }
      }
    }
  }, [sources]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {sources.map((source, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
          <FormControl
            sx={{ width: 220, marginRight: '5px', marginBottom: '5px' }}
          >
            <InputLabel id={`demo-simple-select-label-${index}`}>
              KPI {index + 1}
            </InputLabel>
            <Select
              defaultValue=''
              labelId={`demo-simple-select-label-${index}`}
              id={`demo-simple-select-${index}`}
              value={source.name}
              onChange={handleSourceChange(index)}
              input={<OutlinedInput label={`KPI ${index + 1}`} />}
              MenuProps={MenuProps}
            >
              {renderGroupedItems()}
            </Select>
          </FormControl>
          <TextField
            sx={{ width: 220, marginBottom: '5px' }}
            label='Target Value'
            type='number'
            value={source.target}
            onChange={handleInputValueChange(index)}
          />
          <IconButton onClick={handleRemoveSource(index)}>
            <CloseIcon />
          </IconButton>
        </div>
      ))}
      <Button onClick={handleAddAnother}>Add Another</Button>
    </div>
  );
}
