import React from 'react';

import ModalLoading from './ModalLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

export default function StaticSideModal({
  isLoading,
  showModal,
  modalOptions,
}) {
  // const modalOptions = {
  //   title: 'About this chart',
  //     titleSize: '18px',
  //     icon: <FontAwesomeIcon icon={faInfoCircle} />,
  //     content: '',
  //     closeModal:()=>{}
  //     // modalHeight:'',
  // }

  return (
    <SideModalWrapper
      showModal={showModal}
      style={{ width: showModal ? '330px' : '0px' }}
      customHeight={modalOptions?.modalHeight}
    >
      <TitleArea>
        <OptionTitle fontSize={modalOptions?.titleSize}>
          <TitleIcon marginRight={modalOptions?.titleSize}>
            {modalOptions?.icon}
          </TitleIcon>
          <Title>{modalOptions?.title}</Title>
        </OptionTitle>

        {modalOptions?.hideCloseBtn || (
          <OptionCloseButton
            onClick={() => {
              modalOptions?.closeModal();
            }}
          >
            <FontAwesomeIcon icon={faAnglesRight} />
          </OptionCloseButton>
        )}
      </TitleArea>
      <ModalContent>
        {isLoading ? (
          <LoadingWrapper>
            <ModalLoading />
          </LoadingWrapper>
        ) : (
          <Content>{modalOptions?.content}</Content>
        )}
      </ModalContent>
      <BottomArea>{modalOptions?.bottomContent}</BottomArea>
    </SideModalWrapper>
  );
}

const SideModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;

  border: 1px solid #f5f5f5;
  border-radius: 8px;

  margin: 3px;
  padding: 0px 25px 25px 25px;

  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  background: #ffffff;

  @keyframes open {
    from {
      width: 0;
      height: 0;
      opacity: 0;
    }
    to {
      width: 330px;
      opacity: 1;
    }
  }
  @keyframes close {
    from {
      width: 330px;
      opacity: 1;
    }
    to {
      width: 0;
      opacity: 0;
    }
  }

  animation: ${({ showModal }) =>
    showModal ? 'open 0.7s forwards' : 'close 0.4s forwards'};

  transition: all 0.4s ease;
`;

const TitleArea = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0px;
  background-color: #fff;
  z-index: 5;
`;

const OptionTitle = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 30px;
  font-size: ${({ fontSize }) => fontSize || '24px'};
`;

const TitleIcon = styled.div`
  width: 15%;
  color: ${({ color }) => color};
  margin-right: ${({ marginRight }) =>
    marginRight ? `{calc(${marginRight} / 2)}` : '15px'};
`;

const Title = styled.div`
  width: 90%;
  font-family: 'Poppins';
  font-style: normal;
  letter-spacing: -0.04em;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const OptionCloseButton = styled.div`
  width: 20px;
  height: 31px;
  font-size: 24px;
  letter-spacing: -24px;
  color: ${({ theme }) => theme.pb.realBlack};
  cursor: pointer;
`;

const Content = styled.div`
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const LoadingWrapper = styled.div`
  width: 300px;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomArea = styled.div``;
