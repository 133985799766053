import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EditButtonIcon } from '../assets/icons/Edit.svg';

export default function EditBtn({ onClickHandler }) {
  return (
    <EditButton onClick={onClickHandler}>
      <EditIcon />
      <EditText>Edit</EditText>
    </EditButton>
  );
}

const EditIcon = styled(EditButtonIcon)`
  width: 8.7px;
  fill: ${({ theme }) => theme.pb.cyanBlue};
`;

const EditText = styled.span`
  padding-left: 0.15rem;
  font-size: 10px;
  color: ${({ theme }) => theme.pb.cyanBlue};
`;

const EditButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  width: 2.8rem;
  height: 1rem;
  border: 1px solid ${({ theme }) => theme.pb.cyanBlue};
  background-color: transparent;
  border-radius: 10rem;
  cursor: pointer;
  &:hover {
    width: 2.8rem;
    height: 1rem;
    background-color: ${({ theme }) => theme.pb.cyanBlue};
    ${EditIcon}, ${EditText} {
      fill: #fff;
      color: #fff;
    }
  }
`;
