import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

export default function InputBox(props) {
  const { value, setValue } = props;

  return (
    <Box
      component='form'
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete='off'
    >
      <div>
        <TextField
          id='outlined-number'
          label='Percentage'
          type='number'
          value={value}
          onChange={(e) => setValue(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position='end'>%</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </Box>
  );
}
