import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { URL } from '../../assets/data/SERVICE_LINKS';
import { LanguageContext } from '../../contextApi/LanguageContext';
import { ReactComponent as PlaybookLogo } from '../../assets/icons/logo/PlaybookLogoWhite.svg';
import styled from 'styled-components';

export default function PanomixNav() {
  const isUnder1440 = useMediaQuery({ maxWidth: 1440 });
  const { language, setLanguage } = useContext(LanguageContext);
  const openLink = (url) => {
    if (url === '/') {
      window.location.replace('/');
    } else {
      window.open(url, '_blank');
    }
  };

  const DEMO_BUTTON_NAME = {
    KR: '데모 신청',
    EN: 'Request Demo',
  };

  return (
    <Wrapper>
      <AlignBox isUnder1440={isUnder1440}>
        <Logo onClick={() => openLink('/')} />
        <ButtonContainer>
          <SimpleButton onClick={() => openLink('https://docs.panomix.io')}>
            Documentation
          </SimpleButton>
          <LanguageButton>
            <TextButton
              onClick={() => setLanguage('EN')}
              isActive={language === 'EN'}
            >
              EN
            </TextButton>
            |
            <TextButton
              onClick={() => setLanguage('KR')}
              isActive={language === 'KR'}
            >
              KR
            </TextButton>
          </LanguageButton>
          <LinkButtonWrapper>
            <LinkButton
              isContactUs={true}
              onClick={() => openLink(URL.contactUs)}
            >
              {DEMO_BUTTON_NAME[language]}
            </LinkButton>

            {/* <LinkButton onClick={() => openLink(URL.faqs[language])}>
              <Question>?</Question>FAQ
            </LinkButton> */}
          </LinkButtonWrapper>
        </ButtonContainer>
      </AlignBox>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  position: fixed;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  background-color: black;
  height: 72px;

  z-index: 50;
`;

const AlignBox = styled.div`
  width: 100%;
  max-width: 1300px;
  padding: ${({ isUnder1440 }) => (isUnder1440 ? '0px' : '0px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled(PlaybookLogo)`
  width: 165px;
  height: 30px;
  cursor: pointer;
`;

const ButtonContainer = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LanguageButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
  color: #fff;
`;

const TextButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ isActive }) => (isActive ? '#fff' : '#888888')};
  cursor: pointer;
`;

const SimpleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  color: white;
  cursor: pointer;
  margin-right: 14px;
`;

const LinkButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const LinkButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ isContactUs }) => (isContactUs ? '150px' : 'auto')};
  padding: 8px 13px;

  font-size: 15px;
  font-weight: 400;
  line-height: 20px;

  background-color: ${({ theme }) => theme.pm.blue};
  color: #fff;
  cursor: pointer;
`;

const Question = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 8px;
  width: 18px;
  height: 18px;
  cursor: pointer;
`;
