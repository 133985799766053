import React, {
  useEffect,
  // useContext
} from 'react';
// import { useParams } from 'react-router-dom';
// import { AccountContext } from '../../../../../contextApi/AccountContext';
// import { APIs } from '../../../../../config';
import Select from 'react-select';

import styled from 'styled-components/macro';
import { customStyles } from '../../../../../styles/ReactSelectStyle';

export default function MailchimpModal({
  integrationData,
  setIntegrationData,
  isSubmitClicked,
  setIsSubmitClicked,
  setIsFormReady,
  setShowModal,
  openCautionPopup,
}) {
  // console.log('INTEG DATA CHIMP MODAL: ', integrationData);

  // const { sessionJWT } = useContext(AccountContext);
  // const { workspaceId } = useParams();
  // const { userId, accessToken } = integrationData;

  // const isReadyToSelect = integrationData?.length > 0 ? true : false;

  // const isReadyToSubmit = type => {
  //   if (!integrationData[type]?.every(data => data.isPrimary === false)) {
  //     return true;
  //   } else return false;
  // };

  const selectHandler = (e, type) => {
    setIntegrationData(prev => ({
      ...prev,
      [type]: [
        ...prev[type]
          .filter(item => item.value !== e.value)
          .map(data => ({ ...data, isPrimary: false })),
        { value: e.value, label: e.label, isPrimary: true },
      ],
    }));
  };

  // const sortOptions = list => {
  //   const options = list?.filter(data => !data.isPrimary);
  //   return options;
  // };

  // const findSelectedValue = list => {
  //   return list?.find(item => item.isPrimary === true);
  // };

  const clearState = () => {
    setIsFormReady(prev => ({ ...prev, facebookAds: false }));
    setIsSubmitClicked(prev => ({ ...prev, facebookAds: false }));
  };

  const handleSubmit = () => {
    if (isSubmitClicked) {
      // if (isReadyToSubmit('businessAccounts') && isReadyToSubmit(integType)) {
      //   let submitData = {
      //     channelName: dataBySelectType[integType].channelName,
      //     userId: userId,
      //     accessToken: accessToken,
      //     businessAccount: {
      //       id: findSelectedValue(businessAccounts)?.value,
      //       name: findSelectedValue(businessAccounts)?.label,
      //     },
      //     [dataBySelectType[integType].channelName]: {
      //       id: findSelectedValue(integrationData[integType])?.value,
      //       name: findSelectedValue(integrationData[integType])?.label,
      //     },
      //   };
      //   if (integType === 'owned_pages') {
      //     submitData = {
      //       ...submitData,
      //       [dataBySelectType[integType].channelName]: {
      //         ...submitData[dataBySelectType[integType].channelName],
      //         accessToken: integrationData.pageAccessTokens.find(
      //           item =>
      //             item.id ===
      //             findSelectedValue(integrationData[integType])?.value
      //         ).accessToken,
      //       },
      //     };
      //   }
      //   sendAdsData(submitData);
      // } else {
      //   setIsSubmitClicked(prev => ({ ...prev, facebookAds: false }));
      // }
    } else return;
  };

  // const sendAdsData = async data => {
  //   try {
  //     const request = await fetch(`${APIs.integrations}/${workspaceId}`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${sessionJWT}`,
  //       },
  //       body: JSON.stringify(data),
  //     });
  //     const response = await request.json();
  //     if (request?.status === 201) {
  //       clearState();
  //       window.location.reload(true);
  //     } else if (request?.status === 400) {
  //       let message;
  //       if (response[0] === 'channel already integrated') {
  //         message = 'Facebook Ads is already integrated';
  //       } else {
  //         message =
  //           'Please check if you have a Facebook business id, an ad account, and a page';
  //       }
  //       setShowModal(false);
  //       openCautionPopup({
  //         message: message,
  //         confirmOption: 'refresh',
  //         buttonName: 'Close',
  //       });
  //     }
  //   } catch (error) {
  //     setShowModal(false);
  //     const message =
  //       'Something went wrong and we could not process your request';
  //     openCautionPopup({
  //       message: message,
  //       confirmOption: 'refresh',
  //       buttonName: 'Close',
  //     });
  //     throw new Error('Issue with Facebook Ads Integration', error.message);
  //   }
  // };

  useEffect(() => {
    handleSubmit();
  }, [isSubmitClicked]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SelectWrapper>
      <Header>Mailchimp Account</Header>
      <Select
        name="mailchimp"
        options={{ value: 'test', label: 'test label' }}
        onChange={e => selectHandler(e, 'businessAccounts')}
        styles={customStyles}
        placeholder="Select Business Account"
      />
      {/* {!isReadyToSubmit('businessAccounts') ? (
        <FormErrors>
          <Span>Choose your business account</Span>
        </FormErrors>
      ) : !isReadyToSelect ? (
        <Loading>Loading data...</Loading>
      ) : (
        <>
          <Header>{dataBySelectType[integType].title}</Header>
          <Select
            name={integType}
            options={sortOptions(integrationData[integType])}
            onChange={e => selectHandler(e, integType)}
            styles={customStyles}
            placeholder={dataBySelectType[integType].placeholder}
          />
          {!isReadyToSubmit(integType) && (
            <FormErrors>
              <Span>{dataBySelectType[integType].caution}</Span>
            </FormErrors>
          )}
        </>
      )} */}
    </SelectWrapper>
  );
}

const SelectWrapper = styled.div`
  margin-top: 50px;
`;

const Header = styled.div`
  margin-top: 1.875rem;
  padding-bottom: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  color: #000f1f;
`;

// const FormErrors = styled.div``;

// const Span = styled.span`
//   display: block;
//   padding-top: 0.1875rem;
//   padding-left: 1rem;
//   margin-bottom: -1.25rem;
//   font-size: 0.75rem;
//   color: red;
// `;

// const Loading = styled.div`
//   margin-top: 1.875rem;
//   color: ${({ theme }) => theme.pm.green};
//   font-weight: 500;
//   font-size: 1rem;
//   text-align: center;
// `;
