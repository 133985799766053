import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountContext } from '../../../../contextApi/AccountContext';
import UserList from './Members/UserList';
import PopupModal from '../../../../components/PopupModal';
import { APIs } from '../../../../config';
import styled from 'styled-components';
import CostAndUsage from './CostAndUsage/CostAndUsage';

export default function PaymentAndMembers({ membersInfo, costData, isAdmin }) {
  const { sessionJWT } = useContext(AccountContext);
  const { workspaceId } = useParams();

  const navigate = useNavigate();

  const [selectedMembersInfo, setSelectedMembersInfo] = useState({});

  const [isDisabled, setIsDisabled] = useState(false);
  const [popupModalStatus, setPopupModalStatus] = useState(false);
  const [emailInput, setEmailInput] = useState();

  const emailRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const emailValueCheck = emailRegex.test(emailInput);

  const closePopupModal = () => {
    setPopupModalStatus(false);
  };

  const emailValue = (e) => {
    const { value } = e.target;
    setEmailInput(value);
  };

  const openInviteMemberPopup = () => {
    PopupModal.popupMessage = `Are you sure you want to invite ${emailInput} to join this workspace?`;
    PopupModal.confirmBtnName = 'Confirm';
    PopupModal.handleConfirmAction = () => confirmInvite();
    PopupModal.closePopupModal = () => setPopupModalStatus(false);
    setPopupModalStatus(true);
  };

  const confirmInvite = () => {
    fetch(`${APIs('workspace', workspaceId)}/invite`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionJWT}`,
      },
      body: JSON.stringify({
        email: emailInput,
      }),
    });
    closePopupModal();
    setEmailInput('');
  };

  const openUpdateInfoPopup = (mode) => {
    PopupModal.popupMessage = `Are you sure you want to ${mode}?`;
    PopupModal.confirmBtnName = 'Confrim';
    PopupModal.handleConfirmAction = () => {
      if (mode === 'update access level') {
        updateMemberAccessLevel();
      }
    };
    PopupModal.closePopupModal = () => setPopupModalStatus(false);
    setPopupModalStatus(true);
  };

  const openErrorPopup = (err) => {
    PopupModal.popupMessage = err || 'Sorry, something has gone wrong';
    PopupModal.confirmBtnName = 'Confirm';
    PopupModal.handleConfirmAction = err
      ? () => window.location.reload(true)
      : navigate('/signin');

    PopupModal.closePopupModal = err
      ? () => window.location.reload(true)
      : navigate('/signin');

    setPopupModalStatus(true);
  };

  async function updateMemberAccessLevel() {
    const result = Promise.allSettled(
      Object.keys(selectedMembersInfo)?.map(async (id) => {
        try {
          const response = await fetch(`${APIs('member', workspaceId)}`, {
            headers: {
              Authorization: `Bearer ${sessionJWT}`,
              'Content-Type': 'application/json',
            },
            method: 'PATCH',
            body: JSON.stringify({
              id: id,
              isAdmin: selectedMembersInfo[id],
            }),
          });

          const data = response.json();
          const message = await data;

          return message;
        } catch (err) {
          console.error(err);
        }
      })
    );

    const final = await result;
    const messages = final.map((data) => data.value);
    if (messages.find((data) => data === undefined)) {
      openErrorPopup();
    } else if (messages.every((value) => Object.keys(value)[0] !== 'detail')) {
      window.location.reload(true);
    } else {
      let errors = messages
        ?.filter((value) => Object.keys(value)[0] === 'detail')
        ?.map((message) => message.detail)
        ?.join(', ');
      setPopupModalStatus(false);
      openErrorPopup(errors);
    }
  }

  const checkDeff = () => {
    if (selectedMembersInfo) {
      let ids = Object.keys(selectedMembersInfo);
      ids.forEach((id) => {
        if (
          membersInfo.find((member) => member.id === id).isAdmin ===
          selectedMembersInfo[id]
        ) {
          delete selectedMembersInfo[id];
        } else return;
      });
    } else return;
  };

  useEffect(() => {
    checkDeff();
    setIsDisabled(
      Object.keys(selectedMembersInfo)?.length === 0 ? true : false
    );
  }, [selectedMembersInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Container>
        <LeftArea>
          <Box>
            <TitleBoxNoButton>
              <Title>Invite Member</Title>
              <Line />
            </TitleBoxNoButton>
            <AlignCenterBox customMarginTop='6px'>
              <EmailInPut
                onChange={emailValue}
                value={emailInput}
                type='text'
                placeholder='Email Address'
              />
              <Button
                disabled={!emailValueCheck}
                onClick={() => openInviteMemberPopup()}
              >
                Invite
              </Button>
            </AlignCenterBox>
          </Box>
          <Box>
            <TitleBoxWithButton>
              <AlignCenterBox>
                <Title>Members</Title>
                <Button
                  disabled={isDisabled}
                  onClick={() => openUpdateInfoPopup('update access level')}
                >
                  Update access level
                </Button>
              </AlignCenterBox>
              <Line />
            </TitleBoxWithButton>
            <UserList
              members={membersInfo}
              setSelectedMembersInfo={setSelectedMembersInfo}
            />
          </Box>
        </LeftArea>

        <RightArea>
          {/* <Box>
              <TitleBoxNoButton>
                <Title>Cost & Usage</Title>
              </TitleBoxNoButton>
              <CostAndUsage costData={costData} />
            </Box> */}
        </RightArea>
      </Container>
      <PopupModal
        popupModalStatus={popupModalStatus}
        popupMessage={PopupModal.popupMessage}
        confirmBtnName={PopupModal.confirmBtnName}
        handleConfirmAction={PopupModal.handleConfirmAction}
        closePopupModal={PopupModal.closePopupModal}
      />
    </>
  );
}

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 84vw;
  margin-bottom: 4px;

  ::-webkit-scrollbar {
    display: none;
  }
`;
const RightArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
`;
const LeftArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  padding: 24px;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);

  height: auto;
`;

const AlignCenterBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: ${({ customMarginTop }) => customMarginTop || '0px'};
  width: 100%;
`;

const EmailInPut = styled.input`
  width: 100%;
  min-width: 120px;
  padding: 0.5rem 1rem;
  margin-right: 10px;
  border: 1px solid #f5f5f5;
  border-radius: 25px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  background: #fff;
  color: ${({ theme }) => theme.pb.midBlue};
  font-size: 0.875rem;
  line-height: 16px;

  &::placeholder {
    color: #afafaf;
  }

  :focus {
    outline: 1px solid ${(props) => props.theme.pb.cyanBlue};
  }
`;

const Title = styled.h1`
  display: flex;
  font-size: 16px;
  font-weight: 700;
`;

const Line = styled.div`
  margin: 16px 0px;
  border-bottom: 1px solid #eeeeee;
`;

const TitleBoxNoButton = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;

  background-color: #fff;
`;

const TitleBoxWithButton = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 14px;
  color: white;
  background: ${({ theme }) => theme.pb.cyanBlue};
  border: 1px solid ${({ theme }) => theme.pb.cyanBlue};
  border-radius: 42px;
  box-shadow: 0px 1px 2px 0px #00000040;
  font-size: 13.5px;
  text-align: center;
  cursor: pointer;

  &:disabled {
    border: 1px solid ${({ theme }) => theme.pb.lightGray};
    background: ${({ theme }) => theme.pb.lightGray};
    color: gray;
    cursor: default;
  }
`;
