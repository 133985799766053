import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Header from '../../../components/Header';

export default function RequestAccess({ workspaceName, isRequestSucceed }) {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Header>
        {isRequestSucceed && {
          title: 'Access Requested!',
          subtitle: `Your access request for " ${workspaceName} " has been sent.
          Please wait for admin to accept your request.`,
        }}
      </Header>
      <SignInButton
        onClick={() => {
          navigate('/workspacelist');
        }}
      >
        Go back
      </SignInButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 80vh;
`;

const SignInButton = styled.button`
  display: block;
  width: 400px;
  height: 40px;
  margin-top: 0.5rem;
  ${({ theme }) => theme.btnShadow};
  color: ${({ theme }) => theme.pb.darkGray};
  cursor: pointer;

  &:hover {
    ${({ theme }) => theme.mdbluecard}
    box-shadow: 0px 3px 7px rgba(9, 16, 55, 0.25);
    color: #fff;
  }

  &:active {
    ${({ theme }) => theme.mdbluecard};
    color: #fff;
  }
`;
