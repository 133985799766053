import React, { useState } from 'react';
import { ReactComponent as SortIcon } from '../assets/icons/sort.svg';
import styled from 'styled-components';

export default function SortButton({ type, data, setData }) {
  const [orderSign, setOrderSign] = useState(false);

  const orderBy = () => {
    setOrderSign(prev => !prev);
    setData(
      data.sort(function (a, b) {
        if (a[type] > b[type]) {
          return orderSign ? 1 : -1;
        }
        if (a[type] < b[type]) {
          return orderSign ? -1 : 1;
        }
        return 0;
      })
    );
  };

  return (
    <SortBtn
      onClick={() => {
        orderBy();
      }}
    />
  );
}

const SortBtn = styled(SortIcon)`
  margin-left: 0.2rem;
  margin-bottom: -0.1rem;
  fill: ${({ theme }) => theme.pb.darkGray};
  cursor: pointer;
`;
