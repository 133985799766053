import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AccountContext } from '../../../../../contextApi/AccountContext';
import { APIs } from '../../../../../config';
import Select from 'react-select';

import styled from 'styled-components/macro';
import { customStyles } from './../../../../../styles/ReactSelectStyle';

export default function FacebookAdsModal({
  integrationData,
  setIntegrationData,
  isSubmitClicked,
  setIsSubmitClicked,
  setIsFormReady,
  setShowModal,
  openCautionPopup,
}) {
  const { sessionJWT } = useContext(AccountContext);
  const { workspaceId } = useParams();
  const {
    userId,
    accessToken,
    businessAccounts,
    selectedIntegrateType: integType,
  } = integrationData;

  const dataBySelectType = {
    owned_ad_accounts: {
      category: 'paid',
      title: 'Ad Account',
      placeholder: 'Select Account',
      caution: 'Choose your business account',
      channelName: 'facebookAds',
      keyName: 'adAccount',
    },
    owned_pages: {
      category: 'organic',
      title: 'Facebook Page',
      placeholder: 'Select Page',
      caution: 'Choose your Facebook page',
      channelName: 'facebookPages',
      keyName: 'facebookPages',
    },
    instagram_business_accounts: {
      category: 'organic',
      title: 'Instagram Business Account',
      placeholder: 'Select Account',
      caution: 'Choose your Instagram business account',
      channelName: 'instagram',
      keyName: 'instagramAccounts',
    },
  };

  const isReadyToSelect =
    integrationData?.[integType]?.length > 0 ? true : false;

  const isReadyToSubmit = (type) => {
    if (!integrationData[type]?.every((data) => data.isPrimary === false)) {
      return true;
    } else return false;
  };

  const selectHandler = (e, type) => {
    if (type === 'owned_ad_accounts' || type === 'businessAccounts') {
      setIntegrationData((prev) => ({
        ...prev,
        [type]: [
          ...prev[type]
            .filter((item) => item.value !== e.value)
            .map((data) => ({ ...data, isPrimary: false })),
          { value: e.value, label: e.label, isPrimary: true },
        ],
        selectedId: Object.keys(dataBySelectType).includes(type)
          ? prev.selectedId
          : e.value,
      }));
    } else {
      const selectedValues = e.map((item) => item.value);

      setIntegrationData((prev) => ({
        ...prev,
        [type]: [
          ...prev[type]
            .filter((item) => !selectedValues.includes(item.value))
            .map((data) => ({ ...data, isPrimary: false })),
          ...e.map((selectedValue) => ({ ...selectedValue, isPrimary: true })),
        ],
        selectedId: Object.keys(dataBySelectType).includes(type)
          ? prev.selectedId
          : e.value,
      }));
    }
  };

  const sortOptions = (list) => {
    const options = list?.filter((data) => !data.isPrimary);
    return options;
  };

  const findSelectedValue = (list) => {
    return list?.filter((item) => item.isPrimary === true);
  };

  const clearState = () => {
    setIsFormReady((prev) => ({ ...prev, facebookAds: false }));
    setIsSubmitClicked((prev) => ({ ...prev, facebookAds: false }));
  };

  const handleSubmit = () => {
    if (isSubmitClicked) {
      if (isReadyToSubmit('businessAccounts') && isReadyToSubmit(integType)) {
        let submitData = {
          category: dataBySelectType[integType].category,
          channelName: dataBySelectType[integType].channelName,
          userId: userId,
          accessToken: accessToken,
          businessAccount: findSelectedValue(
            integrationData?.businessAccounts
          )?.map((selectedValue) => ({
            id: selectedValue?.value,
            name: selectedValue?.label,
          }))[0],
        };
        if (integType === 'owned_ad_accounts') {
          submitData = {
            ...submitData,
            [dataBySelectType[integType].keyName]: findSelectedValue(
              integrationData[integType]
            )?.map((selectedValue) => ({
              id: selectedValue?.value,
              name: selectedValue?.label,
            }))[0],
          };
        } else if (integType === 'owned_pages') {
          const noneAccessPages = findSelectedValue(integrationData[integType])
            ?.map((selectedValue) => {
              if (selectedValue.accessToken) {
                return false;
              } else {
                return selectedValue?.label;
              }
            })
            .filter((item) => item !== false);

          if (noneAccessPages.length > 0) {
            const message = `You don't have an access to get data of ${noneAccessPages.join(
              ', '
            )}. Remove ${noneAccessPages.join(
              ', '
            )} from the integration list or get an access to the page and try again.`;
            setShowModal(false);

            openCautionPopup({
              message: message,
              confirmOption: 'refresh',
              buttonName: 'Close',
            });
            return;
          } else {
            submitData = {
              ...submitData,
              [dataBySelectType[integType].keyName]: findSelectedValue(
                integrationData[integType]
              )?.map((selectedValue) => ({
                id: selectedValue?.value,
                name: selectedValue?.label,
                accessToken,
              })),
            };
          }
        } else if (integType === 'instagram_business_accounts') {
          submitData = {
            ...submitData,
            [dataBySelectType[integType].keyName]: findSelectedValue(
              integrationData[integType]
            )?.map((selectedValue) => ({
              id: selectedValue?.value,
              username: selectedValue?.label,
            })),
          };
        }
        sendAdsData(submitData);
      } else {
        setIsSubmitClicked((prev) => ({ ...prev, facebookAds: false }));
      }
    } else return;
  };

  const sendAdsData = async (selectedData) => {
    try {
      const url = () => {
        if (dataBySelectType[integType].channelName === 'facebookAds') {
          return `${APIs('integration', workspaceId)}?channelName=${
            dataBySelectType[integType].channelName
          }&id=${selectedData.adAccount.id}`;
        } else
          return `${APIs('integration', workspaceId)}?channelName=${
            dataBySelectType[integType].channelName
          }`;
      };
      const urlStr = url();

      const request = await fetch(urlStr, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionJWT}`,
        },
        body: JSON.stringify(selectedData),
      });
      const response = request.json();
      const data = await response;

      if (request.ok) {
        clearState();
        window.location.reload(true);
      } else if (request?.status === 400) {
        let message;
        if (data?.[0] === 'channel already integrated') {
          message = 'Facebook Ads is already integrated';
        } else if (data?.detail === 'no channel name') {
          message = 'Something went wrong, please contact Playbook ';
        } else {
          message =
            'Please check if you have a Facebook business id, an ad account, and a page';
        }
        setShowModal(false);
        openCautionPopup({
          message: message,
          confirmOption: 'refresh',
          buttonName: 'Close',
        });
      }
    } catch (error) {
      setShowModal(false);
      const message =
        'Something went wrong and we could not process your request';
      openCautionPopup({
        message: message,
        confirmOption: 'refresh',
        buttonName: 'Close',
      });
      throw new Error('Issue with Facebook Ads Integration', error.message);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [isSubmitClicked]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SelectWrapper>
      <Header>Business Account</Header>
      <Select
        name='businessAccounts'
        options={sortOptions(businessAccounts)}
        onChange={(e) => selectHandler(e, 'businessAccounts')}
        styles={customStyles}
        placeholder='Select Business Account'
      />
      {!isReadyToSubmit('businessAccounts') ? (
        <FormErrors>
          <Span>Choose your business account</Span>
        </FormErrors>
      ) : !isReadyToSelect ? (
        <Loading>Loading data...</Loading>
      ) : (
        <>
          <Header>{dataBySelectType[integType].title}</Header>
          <Select
            name={integType}
            options={sortOptions(integrationData[integType])}
            onChange={(e) => selectHandler(e, integType)}
            styles={customStyles}
            placeholder={dataBySelectType[integType].placeholder}
            isMulti={integType !== 'owned_ad_accounts'}
          />
          {!isReadyToSubmit(integType) && (
            <FormErrors>
              <Span>{dataBySelectType[integType].caution}</Span>
            </FormErrors>
          )}
        </>
      )}
    </SelectWrapper>
  );
}

const SelectWrapper = styled.div`
  margin-top: 50px;
`;

const Header = styled.div`
  margin-top: 1.875rem;
  padding-bottom: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  color: #000f1f;
`;

const FormErrors = styled.div``;

const Span = styled.span`
  display: block;
  padding-top: 0.1875rem;
  padding-left: 1rem;
  margin-bottom: -1.25rem;
  font-size: 0.75rem;
  color: red;
`;

const Loading = styled.div`
  margin-top: 1.875rem;
  color: ${({ theme }) => theme.pm.green};
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
`;
