import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../contextApi/AccountContext';
import { APIs } from '../../../config';
import LoadingPage from '../../../components/LoadingPage';

import styled from 'styled-components';
import { Link, Button } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import CustomizedSteppers from './components/PocStepper';
import PopupModal from '../../../components/PopupModal';
import ModalLoading from '../../../components/ModalLoading';

import getChannelLogo from '../../../hooks/getChannelLogo';
import Facebook from '../Integration/DataIntegration/Facebook/Facebook';
import GoogleAds from '../Integration/DataIntegration/GoogleAds/GoogleAds';
import Appsflyer from '../Integration/DataIntegration/Appsflyer/Appsflyer';
import DataCard from '../Integration/DataIntegration/Components/Datacard';
import IntegrationModal from '../Integration/DataIntegration/Components/IntegrationModal';
import InputBox from './components/InputBox';
import POCReport from './POCReport';

export default function ProofConcept() {
  // Set if loading is true
  // If poc item is in dynamoDB,
  // if not, page with start button, start button writes an item to dynamoDB
  // If yes, check steps are completed
  // If steps are all completed, check status for the poc
  // If running then, let user know models are still creating
  // If models are done, then show reports

  const { sessionJWT } = useContext(AccountContext);
  const { workspaceId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [pocStarted, setPocStarted] = useState(false);
  const [pocData, setPocData] = useState({});
  const [pocStep, setPocStep] = useState(0);
  const [hasIntegrated, setHasIntegrated] = useState({});
  const [showPopupModal, setShowPopupModal] = useState(false);
  const [integrationData, setIntegrationData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [content, setContent] = useState('');
  const [dataCardIndex, setDataCardIndex] = useState('');
  const [iOSInput, setIOSInput] = useState('');
  const [androidInput, setAndroidInput] = useState('');
  const [pocResults, setPocResults] = useState([]);
  const [hasFailedResult, setHasFailedResult] = useState(false);

  const navigate = useNavigate();

  //Get POC item
  useEffect(() => {
    const fetchPoc = async () => {
      try {
        setIsLoading(true);

        const request = await fetch(`${APIs('poc', workspaceId)}`, {
          headers: { Authorization: `Bearer ${sessionJWT}` },
        });

        const data = await request.json();

        if (Object.keys(data).length > 0) {
          setPocStarted(true);
          setPocData(data);
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching POC data:', error);
        setIsLoading(false);
      }
    };

    if (sessionJWT && workspaceId) {
      fetchPoc();
    }
  }, [sessionJWT, workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchPocResult = async () => {
      try {
        setIsLoading(true);
        const request = await fetch(`${APIs('pocResult', workspaceId)}`, {
          headers: { Authorization: `Bearer ${sessionJWT}` },
        });

        const data = await request.json();

        if (data.length > 0) {
          setPocResults(data);
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching POC results:', error);
        setIsLoading(false);
      }
    };

    if (pocStarted) {
      fetchPocResult();
    }
  }, [pocStarted]); // eslint-disable-line react-hooks/exhaustive-deps

  const findChannel = (list, channel) => {
    return list.find((el) => el.channelName === channel);
  };

  useEffect(() => {
    const fetchIntegratedList = async () => {
      try {
        const res = await fetch(APIs('integratedList', workspaceId), {
          headers: { Authorization: `Bearer ${sessionJWT}` },
        });

        const data = await res.json();

        const integratedChannels = {
          facebookAds: findChannel(data, 'facebookAds'),
          googleAds: findChannel(data, 'googleAds'),
          appsflyer: findChannel(data, 'appsflyer'),
        };

        setHasIntegrated(integratedChannels);

        if (integratedChannels.appsflyer) {
          if (integratedChannels.facebookAds && integratedChannels.googleAds) {
            setPocStep(2);
          } else {
            setPocStep(1);
          }
        } else {
          setPocStep(0);
        }
      } catch (error) {
        console.error('Error fetching integrated list:', error);
      }
    };

    if (workspaceId && sessionJWT) {
      fetchIntegratedList();
    }
  }, [workspaceId, sessionJWT]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pocResults.length > 0) {
      const failedResults = pocResults.find((obj) => obj.status === 'failed');
      if (failedResults) {
        setHasFailedResult(true);
      }
    }
  }, [pocResults]);

  const sendPocData = async (data) => {
    try {
      const request = await fetch(`${APIs('poc', workspaceId)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionJWT}`,
        },
        body: JSON.stringify(data),
      });
      if (request.ok) {
        setPocStarted(true);
      } else if (request.status === 400) {
        return;
      }
    } catch (error) {
      throw new Error('Issue with starting poc', error.message);
    }
  };

  const handleRunMMM = () => {
    const data = {
      baseProportion: {
        android: androidInput / 100,
        ios: iOSInput / 100,
      },
    };
    sendPocData(data);
  };

  const closePopupModal = () => {
    setShowPopupModal(false);
    clearStorage();
  };

  const showModalLoader = () => {
    PopupModal.closePopupModal = () => window.location.reload(true);
    PopupModal.popupMessage = (
      <LoadingWrapper>
        <ModalLoading />
      </LoadingWrapper>
    );
    PopupModal.confirmBtnName = 'Cancel';
    PopupModal.handleConfirmAction = () => window.location.reload(true);
    PopupModal.extraBtnName = null;
    PopupModal.handleExtraAction = null;
    setShowPopupModal(true);
  };

  const openCautionPopup = ({
    message,
    confirmOption,
    buttonName,
    extraButton,
  }) => {
    const confirmAction = () => {
      if (confirmOption === 'stay') {
        return () => {
          setShowPopupModal(false);
        };
      } else if (confirmOption === 'refresh') {
        return () => {
          window.location.reload(true);
        };
      } else {
        return confirmOption;
      }
    };

    PopupModal.closePopupModal = () => {
      closePopupModal();
      window.location.reload(true);
    };
    PopupModal.popupMessage =
      message || 'Sorry, something went wrong. Please contact Playbook.';
    PopupModal.confirmBtnName = buttonName || 'Confirm';
    PopupModal.handleConfirmAction = confirmAction();

    if (extraButton) {
      PopupModal.extraBtnName = extraButton.buttonName;
      PopupModal.handleExtraAction = extraButton.buttonAction;
    } else {
      PopupModal.extraBtnName = null;
      PopupModal.handleExtraAction = null;
    }

    setShowPopupModal(true);
  };

  const clearStorage = () => {
    sessionStorage.clear();
    window.localStorage.removeItem('workspaceId');
    navigate('.', { state: null });
    setIntegrationData({});
    setShowModal(false);
  };

  const AD_DATA = createAdData(
    hasIntegrated,
    setShowModal,
    setContent,
    integrationData,
    setIntegrationData,
    openCautionPopup,
    closePopupModal,
    showModalLoader
  );

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          {pocResults.length > 0 ? (
            hasFailedResult ? (
              <DashboardWrapper>
                <ErrorTitle>🤖 Sorry... </ErrorTitle>
                <ErrorDescription>
                  Error occured while modeling. We will get back to you soon.
                </ErrorDescription>
              </DashboardWrapper>
            ) : (
              <GraphWrapper>
                {pocResults.map((item, idx) => (
                  <POCReport key={idx} result={item} />
                ))}
              </GraphWrapper>
            )
          ) : (
            <DashboardWrapper>
              <CustomizedSteppers currentStep={pocStep} />
              <CardWrapper>
                {pocStep === 0 && (
                  <DataCard
                    key='appsflyer'
                    idx={0}
                    item={AD_DATA[0]}
                    setDataCardIndex={setDataCardIndex}
                    dataCardIndex={dataCardIndex}
                    openCautionPopup={openCautionPopup}
                  />
                )}
                {pocStep === 1 &&
                  AD_DATA.slice(1).map((item, idx) => (
                    <DataCard
                      key={idx}
                      idx={idx}
                      item={item}
                      setDataCardIndex={setDataCardIndex}
                      dataCardIndex={dataCardIndex}
                      openCautionPopup={openCautionPopup}
                    />
                  ))}
              </CardWrapper>
              {pocStep === 2 && (
                <>
                  <Description>
                    Type the approximate organic contribution percentage for
                    both iOS & Android app installs
                  </Description>

                  <PlatformWrapper>
                    <Platform>
                      <PlatformTitle>iOS</PlatformTitle>
                      <InputBox value={iOSInput} setValue={setIOSInput} />
                    </Platform>
                    <Platform>
                      <PlatformTitle>Android</PlatformTitle>
                      <InputBox
                        value={androidInput}
                        setValue={setAndroidInput}
                      />
                    </Platform>
                  </PlatformWrapper>
                  <CustomButton onClick={handleRunMMM}>Run MMM</CustomButton>
                </>
              )}
            </DashboardWrapper>
          )}

          <PopupModal
            popupModalStatus={showPopupModal}
            closePopupModal={PopupModal.closePopupModal}
            popupMessage={PopupModal.popupMessage}
            confirmBtnName={PopupModal.confirmBtnName}
            handleConfirmAction={PopupModal.handleConfirmAction}
            extraBtnName={PopupModal.extraBtnName}
            handleExtraAction={PopupModal.handleExtraAction}
          />
          {showModal && (
            <IntegrationModal
              content={content}
              integrationData={integrationData}
              setIntegrationData={setIntegrationData}
              setShowModal={setShowModal}
              hasIntegrated={hasIntegrated}
              setHasIntegrated={setHasIntegrated}
              openCautionPopup={openCautionPopup}
              closePopupModal={closePopupModal}
              clearStorage={clearStorage}
            />
          )}
        </>
      )}
    </>
  );
}

const LoadingWrapper = styled.div`
  width: 80vw;
  height: 124px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardWrapper = styled.div`
  width: 100%;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  transition: all ease 0.3s;
`;

const DashboardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  width: calc(100% - 260px);
  left: 260px;
`;

const GraphWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: calc(100% - 260px);
  left: 260px;
`;

const PlatformWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Platform = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlatformTitle = styled.div`
  color: ${({ theme }) => theme.pb.realBlack};
  font-size: 14px;
  padding: 5px;
  line-height: 19px;
  font-family: 'Arial';
  margin-left: 10px;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.pb.realBlack};
  width: 460px;
  font-size: 18px;
  line-height: 28px;
  font-family: 'Arial';
  text-align: center;
`;

const ErrorTitle = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.realBlack};
  font-size: 24px;
  font-family: 'Arial';
  padding: 5px;
`;

const ErrorDescription = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.realBlack};
  font-size: 14px;
  padding: 5px;
  width: 480px;
  line-height: 19px;
  font-family: 'Arial';
  text-align: center;
`;

const CustomButton = MuiStyled(Button)({
  height: '40px',
  width: '180px',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  padding: '6px 12px',
  borderRadius: '8px',
  backgroundColor: '#1A1F27',
  lineHeight: 1.5,
  fontFamily: 'Arial',
  outline: 'none',
  color: 'white',
  '&:hover': {
    backgroundColor: '#242B35',
    boxShadow: 'none',
  },
  cursor: 'pointer',
});

const createAdData = (
  hasIntegrated,
  setShowModal,
  setContent,
  integrationData,
  setIntegrationData,
  openCautionPopup,
  closePopupModal,
  showModalLoader
) => {
  const { facebookAds, googleAds, appsflyer } = hasIntegrated;

  return [
    {
      id: 'appsflyer',
      title: 'Appsflyer',
      content: 'Connect Appsflyer',
      button: <Appsflyer setShowModal={setShowModal} setContent={setContent} />,
      img: getChannelLogo('appsflyer'),
      isActivated: appsflyer,
    },
    {
      id: 'facebookAds',
      title: 'Facebook Ads',
      content: 'Connect your Facebook Ad Account with Playbook',
      button: (
        <Facebook
          setShowModal={setShowModal}
          setContent={setContent}
          integrationData={integrationData}
          setIntegrationData={setIntegrationData}
          openCautionPopup={openCautionPopup}
          closePopupModal={closePopupModal}
          showModalLoader={showModalLoader}
          type='owned_ad_accounts'
        />
      ),
      img: getChannelLogo('facebookAds'),
      isActivated: facebookAds,
    },
    {
      id: 'googleAds',
      title: 'Google Ads',
      content: 'Connect your Google Ads Account with Playbook',
      button: <GoogleAds />,
      img: getChannelLogo('googleAds'),
      isActivated: googleAds,
    },
  ];
};
