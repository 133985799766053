import React from 'react';
import TimezoneSelect from 'react-timezone-select';
import styled from 'styled-components';
import { customStyles } from '../../../../styles/ReactSelectStyle';

function Timezone({ defaultTimezone, updatedTimezone, setTimezone }) {
  return (
    <Section>
      <TimezoneSelect
        value={updatedTimezone || defaultTimezone}
        onChange={setTimezone}
        styles={{
          ...customStyles,
          control: base => ({
            ...base,
            width: '100%',
            minWidth: '170px',
            padding: '0px 6px',
            margin: '5px 0',
            border: '0.5px solid #F5F5F5',
            boxShadow: '0px 1px 3px rgba(9, 16, 55, 0.4)',
            borderRadius: '25px',
            background: '#fff',
            cursor: 'pointer',
          }),
        }}
      />
    </Section>
  );
}

export default Timezone;

const Section = styled.section`
  margin-right: 10px;
`;
