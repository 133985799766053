import React, { useState, useEffect, useContext } from 'react';
import LoadingPageFull from '../../../../../components/LoadingPageFull';
import { AccountContext } from '../../../../../contextApi/AccountContext';
import { useNavigate } from 'react-router-dom';
import { APIs } from '../../../../../config';
import PopupModal from '../../../../../components/PopupModal';

export default function TiktokOrganicAuth() {
  const { getSession, sessionJWT } = useContext(AccountContext);
  const [workspaceId, setWorkspaceId] = useState(
    localStorage.getItem('workspaceId')
  );
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const redirectURI = `/myworkspace/${workspaceId}/integration`;

  useEffect(() => {
    getSession();
    setWorkspaceId(localStorage.getItem('workspaceId'));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sessionJWT) {
      handleAuthCode();
    }
  }, [sessionJWT]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    !isLoading && navigate(redirectURI);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const clearStorage = () => {
    sessionStorage.clear();
    window.localStorage.removeItem('workspaceId');
    navigate('.', { state: null });
  };
  const closePopupModal = () => {
    clearStorage();
  };

  const openCautionPopup = ({
    message,
    confirmOption,
    buttonName,
    extraButton,
  }) => {
    const confirmAction = () => {
      if (confirmOption === 'refresh') {
        return () => {
          window.location.reload(true);
        };
      } else {
        return confirmOption;
      }
    };

    PopupModal.closePopupModal = () => {
      closePopupModal();
      window.location.reload(true);
    };
    PopupModal.popupMessage =
      message || 'Sorry, something went wrong. Please contact Playbook.';
    PopupModal.confirmBtnName = buttonName || 'Confirm';
    PopupModal.handleConfirmAction = confirmAction();

    if (extraButton) {
      PopupModal.extraBtnName = extraButton.buttonName;
      PopupModal.handleExtraAction = extraButton.buttonAction;
    } else {
      PopupModal.extraBtnName = null;
      PopupModal.handleExtraAction = null;
    }
  };

  const handleAuthCode = async () => {
    try {
      const search = new URL(window.location.href).search;

      if (search) {
        const authCode = search.split('=')[1].split('&')[0];
        sendToken(workspaceId, authCode);
      }
    } catch (error) {
      setIsLoading(false);
      throw new Error('Issue with Login', error.message);
    }
  };

  const sendToken = async (workspaceId, code) => {
    try {
      const request = await fetch(
        `${APIs('integration', workspaceId)}/channels/tiktok?code=${code}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionJWT}`,
          },
        }
      );

      //modify below to adjust for tiktok account not ads
      const result = await request.json();
      if (request.ok) {
        setIsLoading(false);
      } else if (request.status === 400) {
        let message;
        if (result?.[0] === 'API Error') {
          message = 'TikTok integration failed';
        } else {
          message = result[0];
        }
        openCautionPopup({
          message: message,
          confirmOption: 'refresh',
          buttonName: 'Close',
        });
      }
    } catch (error) {
      const message =
        'Something went wrong and we could not process your request';
      openCautionPopup({
        message: message,
        confirmOption: 'refresh',
        buttonName: 'Close',
      });
      console.log(error.message);
      setIsLoading(false);
      throw new Error('Issue with TikTok Integration', error.message);
    }
  };

  return <LoadingPageFull />;
}
