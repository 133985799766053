import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AccountContext } from '../../../../../contextApi/AccountContext';
import { ChannelsContext } from '../../../../../contextApi/ChannelsContext';
import { APIs } from '../../../../../config';
import deletePermissionFB from './FacebookDeletePermission';
import getFBAdAccountsData from './getFBAdAccountsData';
import getFBPagesData from './getFBPagesData';
import getInstagramData from './getInstagramData';
import ErrorMessageBox from '../../../../../components/ErrorMessageBox';
import styled from 'styled-components';

const Facebook = ({
  setShowModal,
  setContent,
  integrationData,
  setIntegrationData,
  openCautionPopup,
  closePopupModal,
  showModalLoader,
  type,
}) => {
  // facebook API
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: process.env.REACT_APP_FACEBOOK_APP_ID,
      cookie: true,
      xfbml: true,
      version: 'v17.0',
    });
    window.FB.AppEvents.logPageView();
  };

  (function (d, s, id) {
    let js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');

  const APIOptionByType = {
    owned_ad_accounts: {
      fields: 'id,name',
      scope: 'public_profile,email,business_management,ads_management,ads_read',
      coreScope: 'ads_read',
      errorMessage:
        'We could not find any of your ad account data connected to selected business account. Please choose another business account or create an ad account.',
    },
    owned_pages: {
      fields: 'id,name,access_token',
      scope:
        'public_profile,email,business_management,pages_show_list,pages_read_engagement',
      coreScope: 'pages_show_list,pages_read_engagement',
      errorMessage:
        "We could not find any of your page data or you don't have an access to page that connected to selected business account. Please choose another business account or create a page.",
    },
    instagram_business_accounts: {
      fields: 'instagram_business_accounts{id,username}',
      scope:
        'public_profile,email,business_management,ads_management,instagram_basic,instagram_manage_insights',
      coreScope: 'instagram_basic,instagram_manage_insights',
      errorMessage:
        'We could not find any of your Instagram business account data connected to selected Facebook business account. Please choose another Facebook business account or create an Instagram business account.',
    },
  };

  const reconnectFromTheBaseMessage = {
    warningBoxTitle: 'What is Reconnect?',
    warningBoxDescription:
      'You can disconnect all of your Facebook related accounts from Playbook and connect again. Please be aware that this action will disconnect all of your Facebook Integrations from Playbook. We only recommend using Reconnect when connecting new business accounts to Playbook.',
  };

  const permissionErrorMessage =
    'You have not approved some permissions below. To fully use all features of Playbook, We recommend you to allow all permissions.';

  const businessAccountErrorMessage = {
    errorDescription:
      'We could not find any of your business account data. Please create a business account or connect your account.',
  };

  const { sessionJWT } = useContext(AccountContext);
  const { integratedList, getIntegratedList } = useContext(ChannelsContext);
  const { workspaceId } = useParams();

  const disconnectAllFBChannel = ({ callbackFunction }) => {
    const facebookChannels = integratedList
      .map((item) => item.channelName)
      .filter((channel) =>
        ['facebookAds', 'facebookPages', 'instagram'].includes(channel)
      );

    const callAPI = async (channelName) => {
      try {
        const request = await fetch(
          `${APIs('integration', workspaceId)}?channelName=${channelName}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${sessionJWT}`,
            },
          }
        );

        const response = request.json();
        const data = await response;

        if (request.ok) {
          getIntegratedList();
        } else if (request.status === 400) {
          openCautionPopup({
            message: data.detail,
            confirmOption: 'refresh',
            buttonName: 'Confirm',
          });
        } else if (request.status === 500) {
          openCautionPopup({
            message: `Sorry! Error(500) occured during disconnect ${channelName}. Please contact Playbook`,
            confirmOption: 'refresh',
            buttonName: 'Confirm',
          });
        }
      } catch (err) {
        console.error('Delete integrations error: ', err);
        openCautionPopup({
          message: `Sorry! Error occured during disconnect ${channelName}. Please contact Playbook (${err})`,
          confirmOption: 'refresh',
          buttonName: 'Confirm',
        });
      }
    };

    for (let channelName of facebookChannels) {
      callAPI(channelName);
    }

    callbackFunction && callbackFunction();
  };

  const getLoginStatusFB = ({ integrationType, scope }) => {
    window.FB.getLoginStatus(function (response) {
      if (response.status === 'connected') {
        window.FB.logout(function (response) {
          if (!response.error) {
            loginFB({
              integrationType,
              scope: scope || APIOptionByType[integrationType].scope,
            });
          } else {
            const message =
              'Please try again or complete Facebook login to integrate channel.';
            openCautionPopup({
              message: message,
              confirmOption: 'refresh',
              buttonName: 'Confirm',
            });
          }
        });
      } else {
        loginFB({
          integrationType,
          scope: scope || APIOptionByType[integrationType].scope,
        });
      }
    }, true);
  };

  const loginFB = ({ integrationType, scope }) => {
    closePopupModal();
    window.FB.login(
      function (response) {
        if (!response.error) {
          if (response.status === 'connected') {
            const { userID, accessToken } = response.authResponse;
            checkPermissions({ integrationType, userID, accessToken });
          } else if (response.status === 'unknown') {
            const message =
              'Please try again or complete Facebook login to integrate channel.';
            openCautionPopup({
              message: message,
              confirmOption: 'refresh',
              buttonName: 'Confirm',
            });
          } else if (response.status === 'not_authorized') {
            const message =
              'Please connect Panomix app with your Facebook account to integrate channel';
            openCautionPopup({
              message: message,
              confirmOption: 'refresh',
              buttonName: 'Confirm',
            });
          }
        } else {
          console.error(response.error);
        }
      },
      {
        scope: scope,
        return_scopes: true,
        auth_type: 'reauthenticate',
      }
    );
  };

  const reconnectFB = ({ integrationType, scope }) => {
    setShowModal(false);
    closePopupModal();
    showModalLoader();
    getLoginStatusFB({ integrationType, scope });
  };

  const checkPermissions = ({ integrationType, userID, accessToken }) => {
    window.FB.api(
      `/${userID}/permissions`,
      'GET',
      { access_token: `${accessToken}` },
      function (response) {
        if (!response?.error) {
          const grantedScopeList = response.data
            .map(
              (permission) =>
                permission.status === 'granted' && permission.permission
            )
            .filter((item) => item !== false);
          const neededScopeList =
            APIOptionByType[integrationType].scope.split(',');

          if (
            neededScopeList.every((scope) => grantedScopeList.includes(scope))
          ) {
            setIntegrationData((prev) => ({
              ...prev,
              accessToken: accessToken,
              userId: userID,
            }));

            getBusinessList({ integrationType, userID, accessToken });
          } else {
            deletePermissionFB({
              userID,
              accessToken,
              deleteScope: APIOptionByType[integrationType].coreScope,
              openCautionPopup,
            });
            const declinedScopeList = neededScopeList.filter(
              (scope) => !grantedScopeList.includes(scope)
            );
            openCautionPopup({
              message: ErrorMessageBox({
                errorDescription: permissionErrorMessage,
                warningBoxTitle: 'Unapproved Permissions',
                warningBoxDescription: declinedScopeList.join(', '),
              }),
              extraButton: {
                buttonName: 'Reconnect',
                buttonAction: () => {
                  reconnectFB({
                    integrationType,
                    scope: APIOptionByType[integrationType].scope,
                  });
                },
              },
              confirmOption: 'refresh',
              buttonName: 'Cancel',
            });
          }
        } else {
          console.error('permission API failed', response.error);
          let message = `Get user permission list error :: ${response.error.message}`;
          openCautionPopup({
            message: message,
            confirmOption: 'refresh',
            buttonName: 'Confirm',
          });
        }
      }
    );
  };

  const getBusinessList = ({ integrationType, userID, accessToken }) => {
    setIntegrationData((prev) => ({
      ...prev,
      selectedIntegrateType: integrationType,
    }));
    window.FB.api(
      `/${userID}/businesses`,
      'GET',
      { access_token: `${accessToken}`, fields: 'name' },
      function (response) {
        if (!response.error) {
          if (response.data?.length === 0) {
            deletePermissionFB({
              userID,
              accessToken,
              deleteScope: 'all',
              openCautionPopup,
            });
            openCautionPopup({
              message: businessAccountErrorMessage,
              extraButton: {
                buttonName: 'Reconnect',
                buttonAction: () => {
                  reconnectFB({
                    integrationType,
                    scope: APIOptionByType[integrationType].scope,
                    authType: 'reauthenticate',
                  });
                },
              },
              confirmOption: 'refresh',
              buttonName: 'Cancel',
            });
          } else {
            const businessData = response.data.map((account) => ({
              value: account.id,
              label: account.name,
              isPrimary: false,
            }));
            setIntegrationData((prev) => ({
              ...prev,
              businessAccounts: businessData,
            }));
            setContent('facebookAds');
            setShowModal(true);
          }
        } else {
          console.error(response.error);
          let message = `Business Account Error :: ${response.error.message}`;
          openCautionPopup({
            message: message,
            confirmOption: 'refresh',
            buttonName: 'Confirm',
          });
        }
      }
    );
  };

  const getData = (integrationType, businessAccountId, accessToken) => {
    const props = {
      APIOptionByType,
      businessAccountId,
      accessToken,
      integrationData,
      setIntegrationData,
      openCautionPopup,
      setShowModal,
      reconnectFromTheBaseMessage,
      disconnectAllFBChannel,
      reconnectFB,
    };

    const getDataTypes = {
      owned_ad_accounts: () => getFBAdAccountsData(props),
      owned_pages: () => getFBPagesData(props),
      instagram_business_accounts: () => getInstagramData(props),
    };

    return getDataTypes[integrationType]();
  };

  useEffect(() => {
    if (
      integrationData?.selectedId &&
      integrationData?.selectedIntegrateType &&
      integrationData?.businessAccounts
    ) {
      getData(
        integrationData?.selectedIntegrateType,
        integrationData?.selectedId,
        integrationData?.accessToken
      );
    } else return;
  }, [integrationData?.selectedId, integrationData?.selectedIntegrateType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <IntegrateButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          getLoginStatusFB({
            integrationType: type,
            scope: APIOptionByType[type].scope,
          });
          setIntegrationData({
            selectedIntegrateType: type,
          });
        }}
      >
        <IntegrateText>Integrate</IntegrateText>
      </IntegrateButton>
    </Wrapper>
  );
};

export default Facebook;

const Wrapper = styled.div``;

const IntegrateButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 32px;
  padding: 8px 14px;
  border: 1px solid ${({ theme }) => theme.pb.cyanBlue};
  border-radius: 43px;
  background-color: white;
  cursor: pointer;
`;

const IntegrateText = styled.span`
  width: 56px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.pb.cyanBlue};
`;
