import React, { useState } from 'react';
import { FormControl, TextField } from '@mui/material';

export default function DurationSelect(props) {
  const [days, setDays] = useState(30);
  const { setSimulationOptions } = props;
  const handleChange = (event) => {
    const daysValue = parseInt(event.target.value);
    if (isNaN(daysValue) && event.target.value !== '') {
      return; // Exit early if the input value is not a number and not an empty string
    }
    setDays(event.target.value);
    setSimulationOptions((prev) => ({ ...prev, duration: daysValue || 30 }));
  };

  return (
    <div>
      <FormControl sx={{ width: 220 }}>
        <TextField
          id='days'
          label='Days'
          type='number'
          InputLabelProps={{
            shrink: true,
          }}
          value={days}
          onChange={handleChange}
        />
      </FormControl>
    </div>
  );
}
