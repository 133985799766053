import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Dashboards from './Dashboards/Dashboards';
import Settings from './Settings/Settings';
import Models from './Models/Models';
import DataIntegration from './Integration/DataIntegration/DataIntegration';
import ModelDetails from './ModelDetails/ModelDetails';
import Integration from './Integration/Integration';
import DashboardDetails from './DashboardDetails/DashboardDetails';
import ProofConcept from './ProofConcept/ProofConcept';

const PlaybookSection = () => {
  return (
    <Routes>
      <Route path='/poc' element={<ProofConcept />} />
      <Route path='/dashboard' element={<Dashboards />} />
      <Route path='/dashboard/:dashboardName' element={<DashboardDetails />} />
      <Route path='/integration' element={<Integration />} />
      <Route path='/integration/connect' element={<DataIntegration />} />
      <Route path='/model' element={<Models />} />
      <Route path='/model/:modelType' element={<ModelDetails />} />
      <Route path='/settings' element={<Settings />} />
    </Routes>
  );
};

export default PlaybookSection;
