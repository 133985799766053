import React from 'react';
import styled from 'styled-components';
import OverflowTip from '../../../../../components/OverflowTip';
import UserLevel from './UserLevel';

export default function UserList({ members, setSelectedMembersInfo }) {
  return (
    <Wrapper>
      {members.map(user => (
        <UserListWrap key={user.id}>
          <Email>{user.email}</Email>
          <UserFlexWrap>
            <Name>
              <OverflowTip data={user.preferredName} />
            </Name>
            <UserLevel
              setSelectedMembersInfo={setSelectedMembersInfo}
              defaultLevel={user.isAdmin}
              userId={user.id}
            />
          </UserFlexWrap>
        </UserListWrap>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: auto;
  max-height: 152px;
  overflow-y: scroll;
  padding-right: 3px;
  padding-bottom: 20%;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const UserListWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  border-bottom: 1px solid ${props => props.theme.pb.lightGray};
`;

const Email = styled.div`
  width: 40%;
  color: black;
  font-size: 14px;
  line-height: 18px;
`;

const UserFlexWrap = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
`;

const Name = styled.div`
  width: 50%;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.pb.darkGray};
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
