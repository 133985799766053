import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../../../../contextApi/AccountContext';
import { ChannelsContext } from '../../../../contextApi/ChannelsContext';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { APIs } from '../../../../config';
import styled from 'styled-components';
import DataCard from './Components/Datacard';
import Facebook from './Facebook/Facebook';
import GoogleAds from './GoogleAds/GoogleAds';
import GoogleAnalytics from './GoogleAnalytics/GoogleAnalytics';
import Youtube from './Youtube/Youtube';
import TiktokAds from './TikTok/TiktokAds';
import NaverAds from './NaverAds/NaverAds';
import KakaoAds from './KakaoAds/KakaoAds';
import Shopify from './Shopify/Shopify';
import Mailchimp from './Mailchimp/Mailchimp';
import Cafe24 from './Cafe24/Cafe24';
import getChannelLogo from '../../../../hooks/getChannelLogo';

import IntegrationModal from './Components/IntegrationModal';
import PopupModal from '../../../../components/PopupModal';
import ModalLoading from '../../../../components/ModalLoading';
import NuriLounge from './NuriLounge/NuriLounge';
import Singular from './Singular/Singular';
import Appsflyer from './Appsflyer/Appsflyer';
import TiktokOrganic from './TikTokOrganic/TiktokOrganic';

const DataIntegration = () => {
  const { sessionJWT } = useContext(AccountContext);
  const { integratedList } = useContext(ChannelsContext);
  const { workspaceId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [content, setContent] = useState('');
  const [integrationData, setIntegrationData] = useState({});

  const [showPopupModal, setShowPopupModal] = useState(false);

  const [dataCardIndex, setDataCardIndex] = useState('');

  //changes the button if there's data stored in session storage
  const [hasIntegrated, setHasIntegrated] = useState({});

  const closePopupModal = () => {
    setShowPopupModal(false);
    clearStorage();
  };

  const showModalLoader = () => {
    PopupModal.closePopupModal = () => window.location.reload(true);
    PopupModal.popupMessage = (
      <LoadingWrapper>
        <ModalLoading />
      </LoadingWrapper>
    );
    PopupModal.confirmBtnName = 'Cancel';
    PopupModal.handleConfirmAction = () => window.location.reload(true);
    PopupModal.extraBtnName = null;
    PopupModal.handleExtraAction = null;
    setShowPopupModal(true);
  };

  const openCautionPopup = ({
    message,
    confirmOption,
    buttonName,
    extraButton,
  }) => {
    const confirmAction = () => {
      if (confirmOption === 'stay') {
        return () => {
          setShowPopupModal(false);
        };
      } else if (confirmOption === 'refresh') {
        return () => {
          window.location.reload(true);
        };
      } else {
        return confirmOption;
      }
    };

    PopupModal.closePopupModal = () => {
      closePopupModal();
      window.location.reload(true);
    };
    PopupModal.popupMessage =
      message || 'Sorry, something went wrong. Please contact Playbook.';
    PopupModal.confirmBtnName = buttonName || 'Confirm';
    PopupModal.handleConfirmAction = confirmAction();

    if (extraButton) {
      PopupModal.extraBtnName = extraButton.buttonName;
      PopupModal.handleExtraAction = extraButton.buttonAction;
    } else {
      PopupModal.extraBtnName = null;
      PopupModal.handleExtraAction = null;
    }

    setShowPopupModal(true);
  };

  const clearStorage = () => {
    sessionStorage.clear();
    window.localStorage.removeItem('workspaceId');
    navigate('.', { state: null });
    setIntegrationData({});
    setShowModal(false);
  };

  const findChannel = (list, channel) => {
    return list.find((el) => el.channelName === channel);
  };

  // console.log(
  //   integratedList
  //     ?.map((item) => item.category === "sot")
  //     ?.filter((item) => item !== false)?.length
  // );

  useEffect(() => {
    if (workspaceId && sessionJWT) {
      fetch(APIs('integratedList', workspaceId), {
        headers: { Authorization: `Bearer ${sessionJWT}` },
      })
        .then((res) => res.json())
        .then((data) => {
          setHasIntegrated({
            facebookAds: findChannel(data, 'facebookAds'),
            facebookPages: findChannel(data, 'facebookPages'),
            instagram: findChannel(data, 'instagram'),
            googleAds: findChannel(data, 'googleAds'),
            googleAnalytics: findChannel(data, 'googleAnalytics'),
            youtube: findChannel(data, 'youtube'),
            tiktokAds: findChannel(data, 'tiktokAds'),
            tiktok: findChannel(data, 'tiktok'),
            shopify: findChannel(data, 'shopify'),
            mailchimp: findChannel(data, 'mailchimp'),
            naverAds: findChannel(data, 'naverAds'),
            kakaoAds: findChannel(data, 'kakaoAds'),
            nuriLounge: findChannel(data, 'nuriLounge'),
            cafe24: findChannel(data, 'cafe24'),
            singular: findChannel(data, 'singular'),
            appsflyer: findChannel(data, 'appsflyer'),
          });
        });
    }
  }, [workspaceId, sessionJWT]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location.state === null) {
      return;
    } else {
      const { showModal, content, userData } = location?.state;
      if (userData?.errorMessage) {
        openCautionPopup({
          message: userData?.errorMessage,
          confirmOption: 'refresh',
          buttonName: 'Close',
        });
        navigate('.', { state: null });
      } else {
        setIntegrationData(userData);
        setContent(content);
        setShowModal(showModal);
      }
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const AD_DATA = createAdData(
    hasIntegrated,
    setShowModal,
    setContent,
    integrationData,
    setIntegrationData,
    openCautionPopup,
    closePopupModal,
    showModalLoader
  );

  return (
    <>
      <MainWrapper>
        {/* <Box sx={{ width: '100%', my: 4 }}>
          <Stepper activeStep={integrationStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box> */}
        <CardWrapper>
          {AD_DATA.map((item, idx) => (
            <DataCard
              key={idx}
              idx={idx}
              item={item}
              setDataCardIndex={setDataCardIndex}
              dataCardIndex={dataCardIndex}
              openCautionPopup={openCautionPopup}
            />
          ))}
        </CardWrapper>
      </MainWrapper>
      <PopupModal
        popupModalStatus={showPopupModal}
        closePopupModal={PopupModal.closePopupModal}
        popupMessage={PopupModal.popupMessage}
        confirmBtnName={PopupModal.confirmBtnName}
        handleConfirmAction={PopupModal.handleConfirmAction}
        extraBtnName={PopupModal.extraBtnName}
        handleExtraAction={PopupModal.handleExtraAction}
      />
      {showModal && (
        <IntegrationModal
          content={content}
          integrationData={integrationData}
          setIntegrationData={setIntegrationData}
          setShowModal={setShowModal}
          hasIntegrated={hasIntegrated}
          setHasIntegrated={setHasIntegrated}
          openCautionPopup={openCautionPopup}
          closePopupModal={closePopupModal}
          clearStorage={clearStorage}
        />
      )}
    </>
  );
};

export default DataIntegration;

const MainWrapper = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 260px);
  margin-left: 260px;
  padding: 40px;
  transition: all ease 0.7s;
  ${({ theme }) => theme.animation.fadeInBottom}
`;

const CardWrapper = styled.div`
  width: 100%;
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  transition: all ease 0.3s;
`;

const LoadingWrapper = styled.div`
  width: 80vw;
  height: 124px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const createAdData = (
  hasIntegrated,
  setShowModal,
  setContent,
  integrationData,
  setIntegrationData,
  openCautionPopup,
  closePopupModal,
  showModalLoader
) => {
  const {
    facebookAds,
    facebookPages,
    instagram,
    googleAds,
    googleAnalytics,
    youtube,
    tiktokAds,
    tiktok,
    naverAds,
    kakaoAds,
    shopify,
    mailchimp,
    nuriLounge,
    cafe24,
    singular,
    appsflyer,
  } = hasIntegrated;

  return [
    {
      id: 'facebookAds',
      title: 'Facebook Ads',
      content: 'Connect your Facebook Ad Account with Playbook',
      button: (
        <Facebook
          setShowModal={setShowModal}
          setContent={setContent}
          integrationData={integrationData}
          setIntegrationData={setIntegrationData}
          openCautionPopup={openCautionPopup}
          closePopupModal={closePopupModal}
          showModalLoader={showModalLoader}
          type='owned_ad_accounts'
        />
      ),
      img: getChannelLogo('facebookAds'),
      isActivated: facebookAds,
    },
    {
      id: 'facebookPages',
      title: 'Facebook Pages',
      content: 'Connect your Facebook Business Account with Playbook',
      button: (
        <Facebook
          setShowModal={setShowModal}
          setContent={setContent}
          integrationData={integrationData}
          setIntegrationData={setIntegrationData}
          openCautionPopup={openCautionPopup}
          closePopupModal={closePopupModal}
          showModalLoader={showModalLoader}
          type='owned_pages'
        />
      ),
      img: getChannelLogo('facebookAds'),
      isActivated: facebookPages,
    },
    {
      id: 'instagram',
      title: 'Instagram Accounts',
      content: 'Connect your Instagram Business Account with Playbook',
      button: (
        <Facebook
          setShowModal={setShowModal}
          setContent={setContent}
          integrationData={integrationData}
          setIntegrationData={setIntegrationData}
          openCautionPopup={openCautionPopup}
          closePopupModal={closePopupModal}
          showModalLoader={showModalLoader}
          type='instagram_business_accounts'
        />
      ),
      img: getChannelLogo('instagram'),
      isActivated: instagram,
    },
    {
      id: 'googleAds',
      title: 'Google Ads',
      content: 'Connect your Google Ads Account with Playbook',
      button: <GoogleAds />,
      img: getChannelLogo('googleAds'),
      isActivated: googleAds,
    },
    {
      id: 'googleAnalytics',
      title: 'Google Analytics',
      content: 'Connect your Google Analytics with Playbook',
      button: <GoogleAnalytics />,
      img: getChannelLogo('googleAnalytics'),
      isActivated: googleAnalytics,
    },
    {
      id: 'youtube',
      title: 'Youtube Accounts',
      content: 'Connect your Youtube Account with Playbook',
      button: <Youtube />,
      img: getChannelLogo('youtube'),
      isActivated: youtube,
    },
    {
      id: 'tiktokAds',
      title: 'TikTok Ads',
      content: 'Connect your TikTok Ad Account with Playbook',
      button: <TiktokAds />,
      img: getChannelLogo('tiktokAds'),
      isActivated: tiktokAds,
    },
    {
      id: 'tiktok',
      title: 'TikTok',
      content: 'Connect your TikTok account with Playbook',
      button: <TiktokOrganic />,
      img: getChannelLogo('tiktokAds'),
      isActivated: tiktok,
    },
    {
      id: 'naverAds',
      title: 'Naver Search Ads',
      content: 'Connect your Naver Search Ad Account with Playbook',
      button: <NaverAds setShowModal={setShowModal} setContent={setContent} />,
      img: getChannelLogo('naverAds'),
      isActivated: naverAds,
    },
    {
      id: 'kakaoAds',
      title: 'Kakao Ads',
      content: 'Connect your Kakao Ad Account with Playbook',
      button: <KakaoAds setShowModal={setShowModal} setContent={setContent} />,
      img: getChannelLogo('kakaoAds'),
      isActivated: kakaoAds,
    },
    {
      id: 'shopify',
      title: 'Shopify',
      content: 'Connect Shopify Account with Playbook',
      button: <Shopify setShowModal={setShowModal} setContent={setContent} />,
      img: getChannelLogo('shopify'),
      isActivated: shopify,
    },
    {
      id: 'mailchimp',
      title: 'Mailchimp',
      content: 'Connect Mailchimp Email Campaigns',
      button: (
        <Mailchimp
          setShowModal={setShowModal}
          setContent={setContent}
          integrationData={integrationData}
          setIntegrationData={setIntegrationData}
          openCautionPopup={openCautionPopup}
        />
      ),
      img: getChannelLogo('mailchimp'),
      isActivated: mailchimp,
    },
    {
      id: 'nuriLounge',
      title: 'Nuri Lounge',
      content: 'Connect Nuri Lounge Influencer Network',
      button: (
        <NuriLounge setShowModal={setShowModal} setContent={setContent} />
      ),
      img: getChannelLogo('nuriLounge'),
      isActivated: nuriLounge,
    },
    {
      id: 'cafe24',
      title: 'Cafe 24',
      content: 'Connect Cafe24 account with Playbook',
      button: <Cafe24 setShowModal={setShowModal} setContent={setContent} />,
      img: getChannelLogo('cafe24'),
      isActivated: cafe24,
    },
    {
      id: 'singular',
      title: 'Singular',
      content: 'Connect Singular',
      button: <Singular setShowModal={setShowModal} setContent={setContent} />,
      img: getChannelLogo('singular'),
      isActivated: singular,
    },
    {
      id: 'appsflyer',
      title: 'Appsflyer',
      content: 'Connect Appsflyer',
      button: <Appsflyer setShowModal={setShowModal} setContent={setContent} />,
      img: getChannelLogo('appsflyer'),
      isActivated: appsflyer,
    },
  ];
};
