import React from 'react';
import styled from 'styled-components';
import { ReactComponent as TrendingDown } from '../../../../../src/icons/TrendingDown.svg';
import { ReactComponent as TrendingUp } from '../../../../../src/icons/TrendingUp.svg';

export default function DeltaCard(props) {
  const { title, value, prevValue } = props;
  const difference = value - prevValue;
  const current =
    value !== undefined && value !== null
      ? value.toLocaleString(undefined, { maximumFractionDigits: 2 })
      : 'NA';
  const percentageDiff = ((difference / prevValue) * 100).toFixed(1);
  const diffColor = difference >= 0 ? '#3BA0EB' : '#FD5B84';

  return (
    <CardWrapper>
      <TotalElement>
        <TotalLabel>{title}</TotalLabel>
        <TotalNumber>{current}</TotalNumber>
        <CompareArea>
          {difference >= 0 ? <TrendingUp /> : <TrendingDown />}
          <PercentArea diffColor={diffColor}>{percentageDiff}%</PercentArea>
          <CompareText>vs As-Is</CompareText>
        </CompareArea>
      </TotalElement>
    </CardWrapper>
  );
}

const CardWrapper = styled.div`
  margin: 2px;
`;
const TotalElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 18px 24px 18px;
  min-width: 260px;
  min-height: 200px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
`;

const TotalNumber = styled.span`
  margin-top: 14px;
  color: ${({ theme }) => theme.neutralColor.dark2};
  font-size: 32px;
  line-height: 28px;
  font-weight: 500;
  font-family: 'Arial';
`;
const CompareArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 14px;
  gap: 6px;
`;
const PercentArea = styled.span`
  font-size: 16px;
  font-family: 'Arial'
  line-height: 18px;
  font-weight: 500;
  color: ${({ diffColor }) => diffColor};
`;

const CompareText = styled.span`
  font-family: 'Arial';
  font-size: 16px;
  line-height: 18px;
  color: ${({ theme }) => theme.neutralColor.dark0};
`;

const TotalLabel = styled.span`
  color: ${({ theme }) => theme.neutralColor.dark2};
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  font-family: 'Arial';
`;
