import React from 'react';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
export default function MediaRadarChart(props) {
  const { labels, title, apiData } = props;
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'KPI',
        data: apiData,
      },
    ],
  };

  const options = {
    elements: {
      bar: {
        borderWidth: 2,
        maxBarThickness: 50,
      },
    },
    responsive: true,
    scales: {
      y: {
        suggestedMin: Math.min(...apiData), // Adjusted suggestedMin to be the minimum data value minus 10
        suggestedMax: Math.max(...apiData) + 10, // Adjusted suggest
        beginAtZero: true,
        ticks: {
          min: 0,
          autoSkip: false, // Added autoSkip property set to false
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
        color: 'black',
        font: {
          size: 14,
          family: 'Arial',
          weight: 400,
        },
      },
    },
  };

  return (
    <Container>
      <Bar options={options} data={data} />
    </Container>
  );
}

const Container = styled.div`
  ${({ theme }) => theme.animation.fadeInBottom}
  display: flex;
`;

// /apiData.map((row) => row.contribution)

// backgroundColor: 'rgba(255, 99, 132, 0.2)',
//         borderColor: 'rgb(255, 99, 132)',
//         pointBackgroundColor: 'rgb(255, 99, 132)',
//         pointBorderColor: '#fff',
//         pointHoverBackgroundColor: '#fff',
//         pointHoverBorderColor: 'rgb(255, 99, 132)',
