import React from 'react';
import styled from 'styled-components';

const MiniModal = ({ modalPosition, modalTitle, options }) => {
  return (
    <MiniModalLayout modalPosition={modalPosition}>
      <MiniModalWrap>
        {modalTitle && <Title>{modalTitle}</Title>}
        <MiniModalBtnGroups>
          {options.map((option, idx) => (
            <MiniModalActionBtn key={idx} onClick={option.onClickAction}>
              {option.optionName}
            </MiniModalActionBtn>
          ))}
        </MiniModalBtnGroups>
      </MiniModalWrap>
    </MiniModalLayout>
  );
};

export default MiniModal;

const MiniModalLayout = styled.div`
  position: absolute;
  top: ${({ modalPosition }) => modalPosition.top};
  right: ${({ modalPosition }) => modalPosition.right};
  z-index: 4;
`;

const MiniModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;

  width: 110px;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 1px 3px rgb(9 16 55 / 40%);
  border-radius: 8px;
`;

const MiniModalBtnGroups = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.pb.black};
`;

const Title = styled.span`
  margin-bottom: 5px;
  padding: 5px 5px 8px 5px;
  border-bottom: solid 1px ${({ theme }) => theme.pb.semiLightGray};

  font-size: 12px;
  font-weight: 700;
`;

const MiniModalActionBtn = styled.div`
  width: 100px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 6px;

  font-size: 12px;
  font-weight: 500;

  &:hover {
    background-color: ${({ theme }) => theme.pb.semiLightGray};
  }

  &:last-child {
    margin-bottom: 0px;
  }

  cursor: pointer;
`;
