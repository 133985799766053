import * as React from 'react';
import SelectUnstyled, {
  selectUnstyledClasses,
} from '@mui/base/SelectUnstyled';
import OptionUnstyled, {
  optionUnstyledClasses,
} from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { styled, textAlign } from '@mui/system';
import { capitalizeChar } from '../../../../hooks/textFormatter';
import { ListSubheader } from '@mui/material';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const StyledButton = styled('button')(
  ({ theme }) => `
  position: relative;
  font-family: Arial;
  font-size: 14px;
  box-sizing: border-box;
  height: calc(1.8em + 22px);
  margin-top: 10px;
  margin-bottom: 10px;
  width: 240px;
  text-overflow: ellipsis;
  overflow: auto;
  border-radius: 8px;
  text-align: center;
  line-height: 1.5;
  background: ${({ theme }) => theme.neutralColor.dark3};
  border: 0.5px solid grey;
  color: black;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    border-color: ${blue[400]};
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-family: Arial;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;

  width: 240px;
  border-radius: 8px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${({ theme }) => theme.neutralColor.dark1};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const slots = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.slots,
  };

  return <SelectUnstyled {...props} ref={ref} slots={slots} />;
});

export default function KPISelector({ defaultValue, options, onClickHandler }) {
  const renderMenuItem = (item) => (
    <StyledOption
      key={item.sk}
      value={item.sk}
      onClick={() => onClickHandler(item)}
    >
      {capitalizeChar(item.kpi.sourceName)}
    </StyledOption>
  );

  const renderSubheader = (channel) => (
    <ListSubheader
      sx={{ textAlign: 'left' }}
      key={channel}
      value={channel}
      disableSticky
    >
      {capitalizeChar(channel)}
    </ListSubheader>
  );

  const renderGroupedItems = () => {
    const channels = new Set(options.map((item) => item.sourceOfTruth));
    const items = [];
    for (let channel of channels) {
      items.push(renderSubheader(channel));
      const channelItems = options.filter(
        (item) => item.sourceOfTruth === channel
      );
      for (let kpi of channelItems) {
        items.push(renderMenuItem(kpi));
      }
    }
    return items;
  };

  return (
    <CustomSelect defaultValue={defaultValue}>
      {renderGroupedItems()}
    </CustomSelect>
  );
}
