export const GoogleAdsApi = async ({
  setIsLoading,
  token,
  customer,
  setData,
}) => {
  try {
    let counter = 0;
    let errCounter = 0;
    customer.forEach(async (id) => {
      const request = await fetch(
        `https://googleads.googleapis.com/v13/${id}/googleAds:search`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'developer-token': process.env.REACT_APP_ADS_DEV_TOKEN,
          },
          body: JSON.stringify({
            query:
              'SELECT customer.id, customer.resource_name, customer.descriptive_name FROM customer WHERE customer.manager=FALSE',
          }),
        }
      );

      const resp = await request.json();

      if (!resp.error) {
        counter += 1;
        const { results } = resp;
        results &&
          (await setData((prev) =>
            prev.concat(
              results.map((result) => ({
                value: result.customer.id,
                label: result.customer.descriptiveName,
                isPrimary: false,
              }))
            )
          ));
      } else if (
        resp.error.code === 403 &&
        resp.error.status === 'PERMISSION_DENIED'
      ) {
        errCounter += 1;
      } else if (
        resp.error.code === 401 &&
        resp.error.status === 'UNAUTHENTICATED'
      ) {
        errCounter += 1;
        let message = 'Make sure to log in with a valid Google Ads account';
        setData((prev) => ({ ...prev, errorMessage: message }));
      } else {
        errCounter += 1;
        let message =
          'Something went wrong and we could not process your request';
        setData((prev) => ({ ...prev, errorMessage: message }));
      }

      if (customer.length > 0) {
        if (errCounter >= customer.length) {
          let message =
            'The customer cannot be used because it is not enabled.';
          setData({ errorMessage: message });
          setIsLoading(false);
        } else if (counter + errCounter === customer.length) {
          setIsLoading(false);
        }
      }
    });
  } catch (error) {
    setData({ errorMessage: error.message });
    throw new Error('GoogleAdsApi error getting ads data', error.message);
  }
};
