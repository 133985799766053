import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import theme from '../../../../../styles/theme';

export default function UserLevel({
  setSelectedMembersInfo,
  defaultLevel,
  userId,
}) {
  const setLevel = [
    { value: true, label: 'Admin' },
    { value: false, label: 'Member' },
    // { value: 'delete', label: 'Delete' },
  ];

  return (
    <StyledSelect
      options={setLevel}
      styles={style}
      defaultValue={{
        value: defaultLevel,
        label: setLevel.find(item => item.value === defaultLevel)?.label,
      }}
      onChange={e =>
        setSelectedMembersInfo(prev => ({ ...prev, [userId]: e.value }))
      }
    />
  );
}

const StyledSelect = styled(Select)`
  font-size: 14px;
`;

const style = {
  control: provided => ({
    ...provided,
    borderStyle: 'none',
    fontSize: '15px',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: `${theme.pb.cyanBlue}`,
    padding: '0 5px 0 0',
  }),
  placeholder: provided => ({
    ...provided,
    color: `${theme.pb.gray}`,
  }),
  singleValue: provided => ({
    ...provided,
    color: `${theme.pb.midBlue}`,
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    width: '100%',
    backgroundColor: isFocused ? `${theme.pb.lightBlue}` : '',
    color: isFocused ? `${theme.pb.cyanBlue}` : `${theme.pb.darkGray}`,
    fontSize: '14px',
    lineHeight: '18px',
    cursor: 'pointer',
  }),
};
