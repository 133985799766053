import React, { useEffect, useState } from 'react';
import { styled as MUIStyled } from '@mui/material/styles';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';

export default function BudgetInputSlider({
  data,
  onChangeHandler,
  setCurrentValue,
}) {
  const { item, percentDiff, percentMin, percentMax } = data;
  const [value, setValue] = useState(percentDiff);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    const inputData = event.target.value;
    setValue(inputData === '' ? '' : Number(inputData));
  };

  useEffect(() => {
    if (percentDiff !== value) {
      setValue(percentDiff);
    }
  }, [data, percentDiff]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const difference = item.recommendedbudget - item.average_spend;
    const percentageDiff = ((difference / item.average_spend) * 100).toFixed(1);

    if (percentageDiff === value) {
      console.log('Same Budget');
      return; // Do not call onChangeHandler if the new recommendedBudget is the same as the original recommendedBudget
    }
    const newRecommendedBudget = Math.round(
      item.average_spend * (1 + value / 100)
    );
    const timer = setTimeout(() => {
      setCurrentValue(newRecommendedBudget);
      onChangeHandler({ adset: item, budget: newRecommendedBudget });
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ width: 250 }}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs>
          <CustomSlider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby='input-slider'
            min={percentMin}
            max={percentMax}
          />
        </Grid>
        <Grid item>
          <InputWrapper>
            <Input
              value={value}
              size='large'
              onChange={handleInputChange}
              inputProps={{
                step: 10,
                min: percentMin,
                max: percentMax,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
              isminus={String(value < 0)}
            />
            %
          </InputWrapper>
        </Grid>
      </Grid>
    </Box>
  );
}

const CustomSlider = ({ ...props }) => (
  <Slider
    sx={{
      color: '#000000',
      height: 4,
      '& .MuiSlider-track': {
        border: 'none',
      },
      '& .MuiSlider-thumb': {
        height: 10,
        width: 10,
        backgroundColor: '#fff',
        border: '1px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
          boxShadow: 'inherit',
        },
        '&:before': {
          display: 'none',
        },
      },
      '& .MuiSlider-valueLabel': {
        lineHeight: 1,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 11,
        height: 11,
        borderRadius: '50% 50% 50% 0',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
          transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
          transform: 'rotate(45deg)',
        },
      },
    }}
    {...props}
  />
);

const InputWrapper = styled.div`
  padding-bottom: 20px;
  font-size: 14px;
  color: ${({ theme }) => theme.pb.black};
`;

const Input = MUIStyled(MuiInput)`
  width: 44px;
  font-size: 14px;
  color: ${({ isminus }) => (isminus === 'true' ? '#FF2869' : '#0B8FFC')};
`;
