import React, { useState, useEffect } from 'react';
import LoadingPageFull from '../../../../../components/LoadingPageFull';
import { useNavigate } from 'react-router-dom';
import { YoutubeApi } from './YoutubeApi';

function YoutubeAuth() {
  const navigate = useNavigate();
  const workspaceId = localStorage.getItem('workspaceId');
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const {
    REACT_APP_YOUTUBE_REDIRECT_URI,
    REACT_APP_YOUTUBE_CLIENT_ID,
    REACT_APP_YOUTUBE_CLIENT_SECRET,
  } = process.env;

  const redirectURI = `/myworkspace/${workspaceId}/integration`;

  useEffect(() => {
    handleAuthCode();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    !isLoading &&
      navigate(redirectURI, {
        state: { showModal: true, content: 'youtube', userData: data },
      });
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAuthCode = async () => {
    try {
      const url = new URL(window.location.href);
      const search = url.search;

      if (search) {
        const newAuthCode = search.split('=')[1].split('&')[0];
        sessionStorage.setItem('code', newAuthCode);
        GoogleTokenLink();
      }
    } catch (error) {
      throw new Error('Issue with Login', error.message);
    }
  };

  const GoogleTokenLink = async () => {
    try {
      const request = await fetch('https://oauth2.googleapis.com/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client_id: REACT_APP_YOUTUBE_CLIENT_ID,
          client_secret: REACT_APP_YOUTUBE_CLIENT_SECRET,
          code: sessionStorage.getItem('code'),
          grant_type: 'authorization_code',
          redirect_uri: REACT_APP_YOUTUBE_REDIRECT_URI,
        }),
      });
      const response = await request.json();
      if (!response.error) {
        storeTokenData(response);
      } else if (request.status === 400 && response.error === 'invalid_grant') {
        let message = 'User cancelled login or did not fully authorize';
        setData({ errorMessage: message });
        return setIsLoading(false);
      } else {
        let message =
          'Something went wrong and we could not process your request';
        setData({ errorMessage: message });
        return setIsLoading(false);
      }
    } catch (error) {
      let message =
        'Something went wrong and we could not process your request';
      setData({ errorMessage: message });
      setIsLoading(false);
      throw new Error('Issue with Login', error.message);
    }
  };

  const newExpirationDate = () => {
    const expiration = new Date();
    expiration.setHours(expiration.getHours() + 1);
    return expiration;
  };

  const storeTokenData = (response) => {
    const { access_token, refresh_token, expires_in } = response;
    if (access_token && refresh_token) {
      sessionStorage.setItem('accessToken', access_token);
      sessionStorage.setItem('refreshToken', refresh_token);
      const expirationDate = newExpirationDate();
      sessionStorage.setItem('expirationDate', expirationDate);
      sessionStorage.setItem('expiresIn', expires_in);
      YoutubeApi({ setIsLoading, setData });
    }
  };

  return <LoadingPageFull />;
}

export default YoutubeAuth;
