import React from 'react';
import styled, { css } from 'styled-components';

function UserAvatar({ type, profileImage, isConnectedUser, email }) {
  const iconStyle = {
    cards: {
      initial_num: 1,
      wrapper: { width: '2.25rem', height: '2.25rem', paddingTop: '0.2rem' },
      bgColor: 'white',
      width: '100%',
      height: '100%',
      border: {
        raidus: '50%',
        width: '1.3px',
        style: 'solid',
        color: ({ theme }) => theme.pb.darkGray,
      },
      font: { color: ({ theme }) => theme.pb.darkGray, size: '0.92rem' },
    },
    myAccount: {
      initial_num: 2,
      wrapper: { paddingTop: '0.5rem' },
      width: '100px',
      height: '100px',
      bgColor: ({ theme }) => theme.pb.gray,
      border: {
        raidus: '50%',
        width: '1.3px',
        style: 'solid',
        color: ({ theme }) => theme.pb.gray,
      },
      font: { color: 'white', size: '2.6rem' },
    },
    workspace: {
      initial_num: 1,
      wrapper: { paddingTop: '0.5rem' },
      width: '100px',
      height: '100px',
      bgColor: ({ theme }) => theme.pb.gray,
      border: {
        raidus: '13px',
        width: '1.3px',
        style: 'solid',
        color: ({ theme }) => theme.pb.gray,
      },
      font: { color: 'white', size: '2.6rem' },
    },
    sidebar: {
      initial_num: 1,
      wrapper: { paddingTop: '0' },
      width: '28px',
      height: '20px',
      bgColor: ({ theme }) => theme.pb.gray,
      border: {
        raidus: '0px',
        width: '1.3px',
        style: 'solid',
        color: ({ theme }) => theme.pb.gray,
      },
      font: { color: 'white', size: '2.6rem' },
    },
  };

  const initials = email.toUpperCase();

  return (
    <ImageWrapper isConnectedUser={isConnectedUser} iconStyle={iconStyle[type]}>
      {profileImage ? (
        <img src={profileImage} alt="user" value={email} />
      ) : (
        <Avatar iconStyle={iconStyle[type]}>
          {initials.slice(0, iconStyle[type].initial_num)}
        </Avatar>
      )}
    </ImageWrapper>
  );
}

const connectedUserStyles = css`
  ${({ isConnectedUser }) =>
    isConnectedUser
      ? css`
          display: flex;
          margin: -8px;
          width: 36px;
          height: 36px;
          padding: 4px;
          overflow: hidden;
          border-radius: 50%;

          img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 1px solid #fff;
            object-fit: cover;
          }
        `
      : css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: ${({ iconStyle }) => iconStyle.width};
          height: ${({ iconStyle }) => iconStyle.height};

          img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: ${({ iconStyle }) => iconStyle.border.raidus};
            object-fit: cover;
          }
        `}
`;

const ImageWrapper = styled.div`
  ${({ theme }) => theme.flexcenter};
  position: relative;
  width: ${({ iconStyle }) => iconStyle.wrapper.width};
  height: ${({ iconStyle }) => iconStyle.wrapper.height};
  text-align: center;

  ${connectedUserStyles}
  cursor: pointer;
`;

const Avatar = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ iconStyle }) => iconStyle.width};
  height: ${({ iconStyle }) => iconStyle.height};
  border-radius: ${({ iconStyle }) => iconStyle.border.raidus};
  padding-top: ${({ iconStyle }) => iconStyle.wrapper.paddingTop};
  font-size: ${({ iconStyle }) => iconStyle.font.size};
  line-height: ${({ iconStyle }) => iconStyle.font.size};
  color: ${({ iconStyle }) => iconStyle.font.color};
  border-width: ${({ iconStyle }) => iconStyle.border.width};
  border-style: ${({ iconStyle }) => iconStyle.border.style};
  border-color: ${({ iconStyle }) => iconStyle.border.color};
  background-color: ${({ iconStyle }) => iconStyle.bgColor};
`;

export default UserAvatar;
