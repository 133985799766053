import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../contextApi/AccountContext';
import { APIs } from '../../../config';
import styled from 'styled-components';
import OverflowTip from '../../../components/OverflowTip';

export default function InvitationsCard({ info, openCautionPopup }) {
  const { sessionJWT } = useContext(AccountContext);

  const navigate = useNavigate();

  const workspaceId = info.id;

  const joinWorkspace = async () => {
    if (sessionJWT) {
      try {
        const request = await fetch(`${APIs('workspace', workspaceId)}/join`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${sessionJWT}`,
          },
        });
        const response = await request.json();
        if (request.ok) {
          navigate(`/myworkspace/${workspaceId}/dashboard`);
        } else if (request.status !== 500) {
          openCautionPopup({
            message: response.detail,
            confirmOption: 'stay',
          });
        } else {
          openCautionPopup({
            message: 'Sorry, something went wrong. Please contact Playbook',
            confirmOption: 'goToSignIn',
          });
        }
      } catch (err) {
        console.error(err);
        openCautionPopup({
          message: 'Sorry, something went wrong. Please contact Playbook',
          confirmOption: 'goToSignIn',
        });
      }
    } else return;
  };

  return (
    <InvitationsCardBox>
      <InvitationsImg src={info.icon} className={info.icon || 'showFirstChar'}>
        {info.firstChar}
      </InvitationsImg>
      <TextWrap onClick={() => joinWorkspace()}>
        <InvitationsTitle>
          <OverflowTip data={info.name} />
        </InvitationsTitle>
        <Join>
          <JoinText>Join</JoinText>
          <Arrow>→</Arrow>
        </Join>
      </TextWrap>
    </InvitationsCardBox>
  );
}

const JoinText = styled.p`
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #3c6dba;
  opacity: 0;
`;

const Arrow = styled.p`
  font-size: 25px;
`;

const InvitationsCardBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 150px;
  padding-left: 30px;
  border-top: 1px solid #f5f5f5;
  &:hover {
    ${JoinText} {
      opacity: 1;
    }
    ${Arrow} {
      color: #3c6dba;
    }
  }
`;

const InvitationsImg = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.showFirstChar {
    background-color: #194384;
    font-size: 45px;
    color: white;
  }
`;

const TextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 440px;
  cursor: pointer;
`;

const InvitationsTitle = styled.p`
  display: flex;
  align-items: center;
  padding-left: 20px;
  width: 380px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  color: #000000;
  :hover {
    white-space: normal;
  }
`;

const Join = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70px;
`;
