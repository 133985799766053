import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const TiktokAds = () => {
  const { workspaceId } = useParams();

  const { REACT_APP_TIKTOK_APP_ID, REACT_APP_TIKTOK_REDIRECT_URI } =
    process.env;

  const authRequestURL = `https://ads.tiktok.com/marketing_api/auth/?app_id=${REACT_APP_TIKTOK_APP_ID}&state=your_custom_params&redirect_uri=${REACT_APP_TIKTOK_REDIRECT_URI}`;

  const AuthLink = () => {
    window.localStorage.setItem('workspaceId', workspaceId);
    window.location.assign(authRequestURL);
  };

  return (
    <Wrapper>
      <TiktokButton id='TiktokAds' onClick={AuthLink}>
        <IntegrateText>Integrate</IntegrateText>
      </TiktokButton>
    </Wrapper>
  );
};

export default TiktokAds;

const Wrapper = styled.div`
  position: relative;
`;

const TiktokButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.pb.cyanBlue};
  border-radius: 43px;
  padding: 8px 14px;
  background-color: white;
  cursor: pointer;
`;

const IntegrateText = styled.span`
  width: 56px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.pb.cyanBlue};
`;
