import React from 'react';
import styled from 'styled-components';
import { Radar } from 'react-chartjs-2';
import 'chart.js/auto';
import { capitalizeChar } from '../../../../hooks/textFormatter';
export default function RadarChart(props) {
  const { title, labels, apiData, color } = props;

  const data = {
    labels: labels,
    datasets: [
      {
        data: apiData,
        fill: true,
        backgroundColor: color,
        borderColor: color,
        pointBackgroundColor: color,
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: color,
      },
    ],
  };

  const options = {
    elements: {
      line: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
        color: 'black',
        font: {
          size: 14,
          family: 'Arial',
          weight: 400,
        },
      },
    },
    scales: {
      r: {
        pointLabels: {
          font: {
            size: 12,
          },
        },
        ticks: {
          display: true, // Hides the labels in the middel (numbers)
        },
        min: 0,
        max: 100,
      },
    },
  };

  return (
    <Container>
      <Radar options={options} data={data} />
    </Container>
  );
}

const Container = styled.div`
  ${({ theme }) => theme.animation.fadeInBottom}
  display: flex;
`;

// /apiData.map((row) => row.contribution)

// backgroundColor: 'rgba(255, 99, 132, 0.2)',
//         borderColor: 'rgb(255, 99, 132)',
//         pointBackgroundColor: 'rgb(255, 99, 132)',
//         pointBorderColor: '#fff',
//         pointHoverBackgroundColor: '#fff',
//         pointHoverBorderColor: 'rgb(255, 99, 132)',
