import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserAvatar from '../../../components/UserAvatar';
import styled, { keyframes } from 'styled-components';
import OverflowTip from '../../../components/OverflowTip';

const WorkspaceCard = ({ workspaceInfo }) => {
  const {
    id: workspaceId,
    name: workspacename,
    icon,
    firstChar,
    signedContract,
  } = workspaceInfo;

  const navigate = useNavigate();

  const goToWorkspace = () => {
    if (signedContract) {
      navigate(`/myworkspace/${workspaceId}/dashboard`);
    } else {
      navigate(`/myworkspace/${workspaceId}/poc`);
    }
  };

  return (
    <WorkspaceBox onClick={() => goToWorkspace()}>
      <WorkspaceTitle>
        <OverflowTip data={workspacename} />
      </WorkspaceTitle>
      <WorkspaceImg className={icon || 'showFirstChar'}>
        {icon ? (
          <UserAvatar
            type='workspace'
            profileImage={icon}
            email={workspacename}
          />
        ) : (
          firstChar
        )}
      </WorkspaceImg>
    </WorkspaceBox>
  );
};

export default WorkspaceCard;

const ScaleUp = keyframes`
  from {
    transform: scale(0);
  } to {
    transform: scale(1);
  }
`;

const WorkspaceBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  margin: 4px 4px 4px 0;
  background-color: #ffffff;
  border-radius: 8px;
  border: 0.5px solid #f5f5f5;
  box-shadow: 0px 1px 3px 0px #09103766;
  animation: ${ScaleUp} 0.5s;
  cursor: pointer;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 3px 7px rgba(9, 16, 55, 0.15);
    background-color: ${({ theme }) => theme.pb.lightGray};
    transition: 0.25s;
  }
`;

const WorkspaceTitle = styled.div`
  width: 130px;
  height: 20px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 18px;
  :hover {
    white-space: normal;
  }
`;

const WorkspaceImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 13px;

  &.showFirstChar {
    background-color: ${({ theme }) => theme.pb.cyanBlue};
    font-size: 45px;
    color: white;
  }
`;
