import React, { useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../../contextApi/AccountContext';
import UserAvatar from '../../../../components/UserAvatar';
import PopupModal from '../../../../components/PopupModal';
import EditBtn from '../../../../components/EditBtn';
import styled from 'styled-components';
import Slack from '../../../../assets/icons/logo/Slack.svg';
import { APIs } from '../../../../config';

const MyAccount = ({ userInfo }) => {
  const navigate = useNavigate();
  const { sessionJWT, logout } = useContext(AccountContext);
  const [popupModalStatus, setPopupModalStatus] = useState(false);

  const { email, preferredName, profilePicture } = userInfo;

  const [newPreferredName, setNewPreferredName] = useState('');
  const inputTextRef = useRef(null);

  const [uploadedImg, setUploadedImg] = useState();
  const inputFileRef = useRef(null);

  const openChangeUserInfoPopup = (mode) => {
    PopupModal.popupMessage = `Are you sure you want to change ${mode}?`;
    PopupModal.confirmBtnName = 'Confrim';
    PopupModal.handleConfirmAction = () => changeUserInfo(mode);
    PopupModal.closePopupModal = () => setPopupModalStatus(false);
    setPopupModalStatus(true);
  };

  const openDeleteAccountPopup = () => {
    PopupModal.popupMessage =
      'Are you sure you want to remove this account from all workspaces?';
    PopupModal.confirmBtnName = 'Remove';
    PopupModal.handleConfirmAction = () => changeUserInfo('delete account');
    PopupModal.closePopupModal = () => setPopupModalStatus(false);
    setPopupModalStatus(true);
  };

  const openChangePWDPopup = () => {
    PopupModal.popupMessage = 'Are you sure you want to change your password?';
    PopupModal.confirmBtnName = 'Confirm';
    PopupModal.handleConfirmAction = changePassword;
    PopupModal.closePopupModal = () => setPopupModalStatus(false);
    setPopupModalStatus(true);
  };

  const openErrorPopup = (err) => {
    PopupModal.popupMessage = err || 'Sorry, something has gone wrong';
    PopupModal.confirmBtnName = 'Confirm';
    PopupModal.handleConfirmAction = err
      ? () => window.location.reload(true)
      : navigate('/signin');

    PopupModal.closePopupModal = err
      ? () => window.location.reload(true)
      : navigate('/signin');

    setPopupModalStatus(true);
  };

  const changeUserInfo = (mode) => {
    let formData = new FormData();
    formData.set('profilePicture', uploadedImg);

    let modeList = {
      'preferred name': {
        method: 'PATCH',
        dataType: 'json',
        data: JSON.stringify({ preferredName: newPreferredName }),
      },
      'profile image': {
        method: 'PATCH',
        data: formData,
      },
      'delete account': {
        method: 'DELETE',
      },
    };

    if (sessionJWT) {
      fetch(`${APIs('myaccount')}`, {
        method: modeList[mode].method,
        headers:
          modeList[mode].dataType === 'json'
            ? {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionJWT}`,
              }
            : {
                Authorization: `Bearer ${sessionJWT}`,
              },
        body: modeList[mode]?.data,
      })
        .then((res) => {
          if (res.ok) {
            if (mode === 'delete account') {
              navigate('/signin');
            } else {
              window.location.reload(true);
            }
          } else if (res.status !== 500) {
            res.json().then((res) => {
              setPopupModalStatus(false);
              openErrorPopup(res.detail);
            });
          } else {
            openErrorPopup();
          }
        })
        .catch((err) => console.error(err));
    } else return;
  };

  const changePassword = () => {
    if (sessionJWT) {
      logout();
      window.localStorage.clear();
      navigate('/changepassword');
    }
  };

  const preventBubbling = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <Container>
        <LeftArea>
          <InfoTitle>
            <Title>My Account</Title>
            <Content>{email}</Content>
          </InfoTitle>
          <Line />
          <BoxForAlignContent>
            <NameArea>
              <SubTitle>Preferred Name</SubTitle>
              <InputText
                type='text'
                value={newPreferredName}
                onChange={(e) => setNewPreferredName(e.target.value)}
                placeholder={preferredName}
                ref={inputTextRef}
              />
            </NameArea>
            {newPreferredName?.length > 0 &&
            newPreferredName !== preferredName ? (
              <SubmitBtn
                onClick={(e) => {
                  preventBubbling(e);
                  openChangeUserInfoPopup('preferred name');
                }}
              >
                Update
              </SubmitBtn>
            ) : (
              <EditBtn
                onClickHandler={(e) => {
                  preventBubbling(e);
                  inputTextRef.current.focus();
                }}
              />
            )}
          </BoxForAlignContent>
          <ImgBox>
            <EditImgWrapper>
              <ImgUploader
                type='file'
                id='userProfileImgUploader'
                accept='image/*'
                ref={inputFileRef}
                onChange={(e) => {
                  setUploadedImg(e.target.files[0]);
                }}
              />
              <TitleBtnWrapper>
                <SubTitle>Photo</SubTitle>
                {uploadedImg ? (
                  <SubmitBtn
                    onClick={(e) => {
                      preventBubbling(e);
                      openChangeUserInfoPopup('profile image');
                    }}
                  >
                    Update
                  </SubmitBtn>
                ) : (
                  <EditBtn
                    onClickHandler={(e) => {
                      preventBubbling(e);
                      inputFileRef.current.click();
                    }}
                  />
                )}
              </TitleBtnWrapper>
              <label htmlFor='userProfileImgUploader'>
                <WrapperForSpace>
                  <UserAvatar
                    type='myAccount'
                    profileImage={
                      uploadedImg
                        ? URL.createObjectURL(uploadedImg)
                        : profilePicture
                    }
                    email={userInfo.email}
                  />
                </WrapperForSpace>
              </label>
            </EditImgWrapper>

            <Button
              isRemove={true}
              onClick={(e) => {
                preventBubbling(e);
                openDeleteAccountPopup();
              }}
            >
              Remove Account
            </Button>
          </ImgBox>
        </LeftArea>
        <RightArea>
          <Option>
            <div>
              <Title>Password</Title>
            </div>
            <Button
              onClick={(e) => {
                preventBubbling(e);
                openChangePWDPopup();
              }}
            >
              Change Password
            </Button>
          </Option>
          <Option>
            <BoxForAlignContent>
              <ChannelImg src={Slack} />
              <div>
                <Title>Slack</Title>
                <Description>
                  Push messages on notifications &amp; event
                </Description>
              </div>
            </BoxForAlignContent>

            <Button disabled={true}>Coming soon</Button>
          </Option>
        </RightArea>
      </Container>
      <PopupModal
        popupModalStatus={popupModalStatus}
        popupMessage={PopupModal.popupMessage}
        confirmBtnName={PopupModal.confirmBtnName}
        handleConfirmAction={PopupModal.handleConfirmAction}
        closePopupModal={PopupModal.closePopupModal}
      />
    </>
  );
};

export default MyAccount;

const Container = styled.section`
  display: flex;
  justify-content: center;
  width: 84vw;
  height: 16.3rem;
  min-width: 300px;
  margin-bottom: 4px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const LeftArea = styled.div`
  width: 45%;
  min-width: 320px;
  padding: 1.5rem;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
`;

const InfoTitle = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  margin-right: 1.5rem;
  font-size: 16px;
  font-weight: 700;
`;

const Line = styled.div`
  width: 100%;
  margin: 16px 0;
  border-bottom: 1px solid #eeeeee;
`;

const BoxForAlignContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubTitle = styled.span`
  margin-right: 1rem;
  color: #666666;
  font-size: 16px;
`;

const Content = styled.span`
  color: #000f1f;
  font-size: 16px;
`;

const WrapperForSpace = styled.div`
  margin-top: 0.5rem;
`;

const TitleBtnWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NameArea = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
`;

const InputText = styled.input`
  width: 60%;
  color: ${({ theme }) => theme.pb.midBlue};
  font-size: 16px;
  text-overflow: ellipsis;

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.pb.cyanBlue};

    &::placeholder {
      color: #b6b6b6;
    }
  }

  &::placeholder {
    color: black;
  }
`;

const EditImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const ImgBox = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-top: 1rem;
`;

const ImgUploader = styled.input`
  display: none;
`;

const SubmitBtn = styled.button`
  display: flex;
  align-items: center;
  padding: 2px 6px;
  border: 1px solid ${({ theme }) => theme.pb.cyanBlue};
  border-radius: 10px;
  background: ${({ theme }) => theme.pb.cyanBlue};
  color: white;
  font-size: 10px;

  text-align: center;
  cursor: pointer;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 14px;
  border: 1px solid
    ${({ isRemove, theme }) =>
      isRemove ? theme.pb.lightGray : theme.pb.cyanBlue};
  border-radius: 42px;
  background: ${({ isRemove, theme }) =>
    isRemove ? theme.pb.lightGray : theme.pb.cyanBlue};
  box-shadow: 0px 1px 2px 0px #00000040;
  color: ${(props) => (props.isRemove ? '#808080' : '#fff')};
  font-size: 13.5px;
  text-align: center;
  cursor: pointer;

  &:hover {
    border: 1px solid
      ${({ isRemove, theme }) => (isRemove ? theme.pb.red : theme.pb.cyanBlue)};
    background: ${({ isRemove, theme }) =>
      isRemove ? theme.pb.red : theme.pb.cyanBlue};
    color: #fff;
  }

  &:disabled {
    border: 1px solid ${({ theme }) => theme.pb.lightGray};
    background: ${({ theme }) => theme.pb.lightGray};
    color: gray;
    cursor: default;
  }
`;

const RightArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
`;

const Option = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 49%;
  padding: 1.5rem;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
`;

const ChannelImg = styled.img`
  width: 46px;
  height: 46px;
  margin-right: 1rem;
  border-radius: 100%;
`;

const Description = styled.span`
  color: #666666;
  font-size: 12px;
`;
