import React from 'react';
import styled from 'styled-components';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
export default function PieChart({ title, label, pieData, pieKey }) {
  const data = {
    labels: pieData.map((item) => item.name),
    datasets: [
      {
        label: label,
        data: pieData.map((item) => item[pieKey]),
        hoverOffset: 4,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          font: {
            size: 12,
            weight: 400,
            family: 'Arial',
          },
        },
      },
    },
  };

  return (
    <Container>
      <Wrapper>
        <TitleArea>{title}</TitleArea>
        <Pie options={options} data={data} />
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  ${({ theme }) => theme.animation.fadeInBottom}

  display: flex;
`;

const TitleArea = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.pb.black};
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  font-family: 'Arial';
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px;
  height: auto;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  transition: all ease 0.7s;
  ${({ theme }) => theme.animation.fadeInBottom}
`;
