import React from 'react';
import styled from 'styled-components';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
export default function AttributionPie({ title, label, pieData, pieKey }) {
  const data = {
    labels: pieData.map((item) => item.sk),
    datasets: [
      {
        label: label,
        data: pieData.map((item) => item[pieKey]),
        hoverOffset: 4,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          font: {
            size: 12,
            weight: 400,
            family: 'Arial',
          },
        },
      },
    },
  };

  return (
    <Container>
      <Wrapper>
        <TitleArea>{title}</TitleArea>
        <Pie options={options} data={data} />
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  ${({ theme }) => theme.animation.fadeInBottom}

  display: flex;
`;

const TitleArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  color: ${({ theme }) => theme.pb.black};
  font-size: 16px;
  text-align: center;
  font-family: 'Arial';
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px;
  height: auto;
  border-radius: 8px;

  transition: all ease 0.7s;
  ${({ theme }) => theme.animation.fadeInBottom}
`;
