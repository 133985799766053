import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AccountContext } from '../../../contextApi/AccountContext';
import { APIs } from '../../../config';
import LoadingPage from '../../../components/LoadingPage';
import { capitalizeChar } from '../../../hooks/textFormatter';
import CampaignAdjustCard from './Components/CampaignAdjustCard';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import PopupModal from '../../../components/PopupModal';
import styled from 'styled-components';
import DeltaCard from './Components/DeltaCard';
import { Drawer } from '@mui/material';
import DrawerComponents from './Components/DrawerComponents';
import TotalDeltaCard from './Components/TotalDeltaCard';

export default function MMMDetails() {
  // MMM, PMMM, GMM
  // Option Area on top for MMM and PMMM
  // Get KPI List (dependencies: sessionJWT, workspaceId)

  const { sessionJWT } = useContext(AccountContext);
  const { workspaceId } = useParams();
  const [showPopupModal, setShowPopupModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [kpis, setKpis] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [adsets, setAdsets] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerItems, setDrawerItems] = useState();
  const [selectedCampaign, setSelectedCampaign] = useState();

  // Get KPIs to pass on to the budgetOptimize View
  useEffect(() => {
    // Call API to get choices and set state
    if (sessionJWT && workspaceId) {
      setIsLoading(true);
      const fetchKpis = async () => {
        const request = await fetch(`${APIs('model', workspaceId)}/mmm/kpi`, {
          headers: { Authorization: `Bearer ${sessionJWT}` },
        });
        const data = await request.json();

        if (data.length > 0) {
          setKpis(data);
        } else return;
      };
      fetchKpis();
    }
    setIsLoading(false);
  }, [sessionJWT, workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Call API to get choices and set state
    if (sessionJWT && workspaceId) {
      setIsLoading(true);
      const fetchAdsets = async () => {
        const request = await fetch(
          `${APIs('model', workspaceId)}/mmm/optimize`,
          {
            headers: { Authorization: `Bearer ${sessionJWT}` },
          }
        );
        const data = await request.json();

        if (data.length > 0) {
          setAdsets(data);
        } else return;
      };
      fetchAdsets();
    }
    setIsLoading(false);
  }, [kpis]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDistinctCampaigns = (adsets) => {
    const uniqueCampaignIds = [
      ...new Set(adsets.map((adset) => adset.campaignid)),
    ];
    return uniqueCampaignIds.map((campaignId) =>
      adsets.find((adset) => adset.campaignid === campaignId)
    );
  };
  useEffect(() => {
    setCampaigns(getDistinctCampaigns(adsets));
  }, [adsets]);

  const totalAsIsSpend = adsets?.reduce((sum, item) => {
    const totalSpendSum = item.asIs[0].totalSpend;
    return sum + totalSpendSum;
  }, 0);
  const totalSimulatedSpend = adsets?.reduce((sum, item) => {
    const totalSpendSum = item.recommended[0].totalSpend;
    return sum + totalSpendSum;
  }, 0);
  function calculateAsIsContributionSum(targetKPI) {
    return campaigns.reduce((sum, item) => {
      const filtered = item.asIs.filter((subItem) => subItem.kpi === targetKPI);
      const totalContribution =
        filtered.length > 0 ? filtered[0].totalContribution : 0;
      return sum + totalContribution;
    }, 0);
  }
  function calculateSimulatedContributionSum(targetKPI) {
    return campaigns.reduce((sum, item) => {
      const filtered = item.recommended.filter(
        (subItem) => subItem.kpi === targetKPI
      );
      const totalContribution =
        filtered.length > 0 ? filtered[0].totalContribution : 0;
      return sum + totalContribution;
    }, 0);
  }

  function calcAdsetBudget(campaign) {
    const filtered = adsets.filter(
      (adset) => adset.campaignid === campaign.campaignid
    );
    return filtered.reduce((sum, adset) => sum + adset?.asIs[0].totalSpend, 0);
  }

  function calcAdsetRecommendedBudget(campaign) {
    const filtered = adsets.filter(
      (adset) => adset.campaignid === campaign.campaignid
    );
    return filtered.reduce(
      (sum, adset) => sum + adset?.recommended[0].totalSpend,
      0
    );
  }

  const updateAdset = async (props) => {
    const { adset, budget } = props;
    const request = await fetch(`${APIs('model', workspaceId)}/mmm/optimize`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionJWT}`,
      },
      method: 'POST',
      body: JSON.stringify({
        raw: adset,
        budget: budget,
      }),
    });
    const data = await request.json();

    setAdsets((prevAdsets) => {
      const updatedList = prevAdsets.map((adset) => {
        if (adset.adsetid === data.adsetid) {
          return data;
        }
        return adset;
      });
      return updatedList;
    });
    const updatedCampaignAdsets = adsets.filter(
      (adset) => adset.campaignid === selectedCampaign
    );
    setDrawerItems(updatedCampaignAdsets);
  };

  useEffect(() => {
    const campaignAdsets = adsets.filter(
      (adset) => adset.campaignid === selectedCampaign
    );
    setDrawerItems(campaignAdsets);
  }, [adsets, selectedCampaign]); // eslint-disable-line react-hooks/exhaustive-deps

  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title
  );

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <Container>
            <CardArea length={kpis.length}>
              <TotalDeltaCard
                title='Total Simulated Ad Spends'
                value={totalSimulatedSpend}
                prevValue={totalAsIsSpend}
              />
              {kpis.map((kpi, idx) => (
                <TotalDeltaCard
                  key={idx}
                  title={`Simulated ${capitalizeChar(kpi.kpi.sourceName)}`}
                  value={calculateSimulatedContributionSum(kpi.kpi.sourceName)}
                  prevValue={calculateAsIsContributionSum(kpi.kpi.sourceName)}
                />
              ))}
            </CardArea>
            <ChipArea>
              <Stack spacing={1} direction='row'>
                <Chip label='7 Day Simulation Results' color='info' />
                <Chip
                  label='As-is, based on current average spends'
                  color='success'
                />
                <Chip label='Initial values are set with recommended spends' />
              </Stack>
            </ChipArea>

            {campaigns.length > 0 &&
              campaigns.map((item, index) => (
                <CardArea length={kpis.length} key={index}>
                  <CampaignAdjustCard
                    channelName={capitalizeChar(item.channelname)}
                    id={item.campaignid}
                    title={item.campaignname}
                    value={calcAdsetRecommendedBudget(item)}
                    prevValue={calcAdsetBudget(item)}
                    setOpenDrawer={setOpenDrawer}
                    setSelectedCampaign={setSelectedCampaign}
                  />
                  {kpis.map((kpi, idx) => (
                    <DeltaCard
                      key={idx}
                      title={capitalizeChar(kpi.kpi.sourceName)}
                      value={
                        item.recommended.find(
                          (i) => i.kpi === kpi.kpi.sourceName
                        )?.totalContribution || 0
                      }
                      prevValue={
                        item.asIs.find((i) => i.kpi === kpi.kpi.sourceName)
                          ?.totalContribution || 0
                      }
                    />
                  ))}
                </CardArea>
              ))}
            <Drawer
              anchor='bottom'
              open={openDrawer}
              onClose={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpenDrawer(false);
              }}
              PaperProps={{
                sx: { maxHeight: '60vh', overflowY: 'auto' },
              }}
            >
              <DrawerComponents
                items={drawerItems}
                kpis={kpis}
                budgetChangeHandler={updateAdset}
              />
            </Drawer>
          </Container>
          <PopupModal
            popupModalStatus={showPopupModal}
            popupMessage={PopupModal.popupMessage}
            confirmBtnName={PopupModal.confirmBtnName}
            handleConfirmAction={PopupModal.handleConfirmAction}
            extraBtnName={PopupModal.extraBtnName}
            handleExtraAction={PopupModal.handleExtraAction}
            closePopupModal={PopupModal.closePopupModal}
          />
        </>
      )}
    </>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 260px;
  margin-top: 20px;
  margin-bottom: 40px;
`;

const Divider = styled.div`
  width: auto;
  height: 2px;
  background-color: #c9cbcf;
  margin: 24px 24px 24px 24px;
`;

const CardArea = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ length }) => length + 1},
    1fr
  ); // Remove the extra semicolon here
  grid-gap: 4px;
  margin-left: 20px;
  margin-right: 20px;
`;

const ChipArea = styled.div`
  margin: 14px 20px 14px 20px;
`;
