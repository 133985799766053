import React, { useState, useEffect } from 'react';
import OverflowTip from '../../../../../components/OverflowTip';
import SortButton from '../../../../../components/SortButton';
import { changeDateFormatForShow } from '../../../../../hooks/changeDateFormat';
import {
  insertSpaceBetweenNumberAndCurrency,
  currencyOption,
} from '../../../../../hooks/handleCurrency';
import styled from 'styled-components';

// import { costData } from './DATA';

export default function CostAndUsage({ costData }) {
  const [sortedData, setSortedData] = useState([]);

  const TABLE_OPTIONS = [
    { label: 'Date', value: 'createdAt', width: '20%', isDate: true },
    { label: 'Type', value: 'type', width: '18%' },
    { label: 'Name', value: 'name', width: '34%' },
    { label: 'Amount', value: 'amount', width: '24%', isAmount: true },
  ];

  const formatValue = (type, value, currency) => {
    if (type === 'Date') {
      return changeDateFormatForShow({
        type: 'slash',
        period: value,
      });
    } else if (type === 'Amount') {
      return insertSpaceBetweenNumberAndCurrency(
        value.toLocaleString(undefined, currencyOption(currency))
      );
    } else {
      return value;
    }
  };

  useEffect(() => {
    if (costData?.length > 0) {
      setSortedData(costData);
    } else return;
  }, [costData]);

  return (
    <Wrapper>
      <Columns hasData={costData?.length > 0}>
        {TABLE_OPTIONS.map(option => (
          <Cell
            key={option.label}
            isColumn={true}
            isAmount={option.isAmount}
            width={option.width}
          >
            {option.label}{' '}
            <SortButton
              type={option.value}
              data={[...costData]}
              setData={setSortedData}
            />
          </Cell>
        ))}
      </Columns>
      <Rows>
        {costData?.length > 0 ? (
          sortedData?.map((row, idx) => (
            <Row key={idx}>
              {TABLE_OPTIONS.map(option => (
                <Cell
                  key={option.value}
                  width={option.width}
                  isAmount={option.isAmount}
                >
                  <OverflowTip
                    data={formatValue(
                      option.label,
                      row[option.value],
                      row.currency
                    )}
                  />
                </Cell>
              ))}
            </Row>
          ))
        ) : (
          <Warning>There is no data yet.</Warning>
        )}
      </Rows>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 345px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Columns = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #fff;
  border-bottom: ${({ hasData }) =>
    hasData ? '1px solid #c4c4c4' : '1px solid #eeeeee'};
  font-weight: 600;
  font-size: 14px;
`;

const Rows = styled.div``;

const Row = styled(Columns)`
  position: static;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  border-bottom: 1px solid #eeeeee;

  :last-child {
    border-bottom: 0px;
    padding-bottom: 0px;
  }
`;

const Cell = styled.div`
  width: ${({ width }) => width};
  padding: ${({ isColumn }) =>
    isColumn ? '28px 0px 13px 0px' : '13px 0px 13px 0px'};
  text-align: ${({ isAmount }) => (isAmount ? 'right' : 'left')};
`;

const Warning = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px;
  padding-top: 8px;
  font-size: 12px;

  color: gray;
`;
