import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AccountContext } from '../../../contextApi/AccountContext';
import { APIs } from '../../../config';
import { styled as MuiStyled } from '@mui/material/styles';
import { Button } from '@mui/material';

import LoadingPage from '../../../components/LoadingPage';
import styled from 'styled-components';
import ChannelSelect from './Components/ChannelSelect';
import CampaignTypeSelect from './Components/CampaignTypeSelect';
import DurationSelect from './Components/DurationSelect';
import PopupModal from '../../../components/PopupModal';
import SimulationCard from './Components/SimulationCard';
import SimulationTable from './Components/SimulationTable';
import KPISelect from './Components/KPISelect';

export default function PMMMDetails() {
  const { sessionJWT } = useContext(AccountContext);
  const { workspaceId } = useParams();
  const [showPopupModal, setShowPopupModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [simulation, setSimulation] = useState(null);
  const [channelOptions, setChannelOptions] = useState(null);
  const [kpis, setKpis] = useState(null);
  const [simulationOptions, setSimulationOptions] = useState({
    channels: [],
    filters: {},
    kpis: [],
    duration: 30,
  });
  const openCautionPopup = ({ message, confirmOption, buttonName }) => {
    const confirmAction = () => {
      if (confirmOption === 'stay') {
        return () => {
          setShowPopupModal(false);
        };
      } else if (confirmOption === 'refresh') {
        return () => {
          window.location.reload(true);
        };
      } else {
        return confirmOption;
      }
    };

    PopupModal.closePopupModal = () => {
      setShowPopupModal(false);
    };
    PopupModal.popupMessage =
      message || 'Sorry, something went wrong. Please contact Playbook.';
    PopupModal.confirmBtnName = buttonName || 'Confirm';
    PopupModal.handleConfirmAction = confirmAction();

    setShowPopupModal(true);
  };
  const sendData = async () => {
    setIsLoading(true);
    try {
      const request = await fetch(
        `${APIs('model', workspaceId)}/mmm/simulation`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionJWT}`,
          },
          body: JSON.stringify(simulationOptions),
        }
      );
      const response = request.json();
      const data = await response;

      if (request.ok) {
        setSimulation(data);
      } else if (request?.status === 400) {
        setShowPopupModal(false);
        openCautionPopup({
          message: 'API Error',
          confirmOption: 'refresh',
          buttonName: 'Close',
        });
      }
    } catch (error) {
      setShowPopupModal(false);
      const message =
        'Something went wrong and we could not process your request';
      openCautionPopup({
        message: message,
        confirmOption: 'refresh',
        buttonName: 'Close',
      });
      throw new Error('Issue with Simulation', error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (sessionJWT && workspaceId) {
      const fetchIntegration = async () => {
        const request = await fetch(
          `${APIs('integration', workspaceId)}/dataSources?category=paid`,
          {
            headers: { Authorization: `Bearer ${sessionJWT}` },
          }
        );
        const data = await request.json();
        if (data.length > 0) {
          const channels = data.map((item) => item.channelName);
          setChannelOptions(channels);
          setSimulationOptions((prev) => ({ ...prev, channels: channels }));
        } else return;
      };
      fetchIntegration();
    }
  }, [sessionJWT, workspaceId]);

  useEffect(() => {
    // Call API to get choices and set state
    if (sessionJWT && workspaceId) {
      const fetchChoices = async () => {
        const request = await fetch(
          `${APIs('model', workspaceId)}/mmm/kpiList`,
          {
            headers: { Authorization: `Bearer ${sessionJWT}` },
          }
        );
        const data = await request.json();

        if (data.length > 0) {
          setKpis(data);
        } else return;
      };
      fetchChoices();
    }
    setIsLoading(false);
  }, [sessionJWT, workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <HeaderContainer>
            <HeaderArea>
              <HeaderTitle>Recommended Campaigns & ROI</HeaderTitle>
              <OptionContainer>
                <OptionWrapper>
                  <OptionTitle>Channels</OptionTitle>
                  {channelOptions && (
                    <ChannelSelect
                      inputLabel='Select Channels'
                      optionKey='channels'
                      selectOptions={channelOptions}
                      setSimulationOptions={setSimulationOptions}
                    />
                  )}
                  {/* <OptionTitle>Campaign Filters</OptionTitle>
                  {channelOptions && (
                    <CampaignTypeSelect
                      inputLabel='Filter Campaign Types'
                      channelOptions={channelOptions}
                      simulationOptions={simulationOptions}
                      setSimulationOptions={setSimulationOptions}
                    />
                  )} */}
                </OptionWrapper>
                <OptionWrapper>
                  <OptionTitle>Reach Goals Within</OptionTitle>
                  <DurationSelect setSimulationOptions={setSimulationOptions} />
                </OptionWrapper>
                <OptionWrapper>
                  <OptionTitle>Set target KPIs</OptionTitle>
                  {kpis && (
                    <KPISelect
                      kpis={kpis}
                      simulationOptions={simulationOptions}
                      setSimulationOptions={setSimulationOptions}
                    />
                  )}
                </OptionWrapper>
              </OptionContainer>
              <ButtonArea>
                <CustomButton
                  key='AddDataSource'
                  variant='contained'
                  disableRipple
                  onClick={sendData}
                >
                  Simulate
                </CustomButton>
              </ButtonArea>
            </HeaderArea>
          </HeaderContainer>
          {simulation && (
            <SimulationCardWrapper>
              <SimulationCard result={simulation} />
              <SimulationTable result={simulation} />
            </SimulationCardWrapper>
          )}
          <PopupModal
            popupModalStatus={showPopupModal}
            popupMessage={PopupModal.popupMessage}
            confirmBtnName={PopupModal.confirmBtnName}
            handleConfirmAction={PopupModal.handleConfirmAction}
            extraBtnName={PopupModal.extraBtnName}
            handleExtraAction={PopupModal.handleExtraAction}
            closePopupModal={PopupModal.closePopupModal}
          />
        </>
      )}
    </>
  );
}

const HeaderContainer = styled.nav`
  position: sticky;
  display: flex;
  flex-direction: column;
  top: 0;
  width: calc(100%-260px);
  margin-left: 260px;
  box-shadow: 0px 1px 8px rgba(9, 16, 55, 0.63);
  background: #fff;
  z-index: 6;
  ::-webkit-scrollbar {
    display: none;
  }
  transition: all ease 0.7s;
  ${({ theme }) => theme.animation.fadeInBottom}
`;

const HeaderArea = styled.div`
  width: 100%;
  padding: 20px;
`;

const HeaderTitle = styled.div`
  font-size: 22px;
  font-family: 'Arial';
  font-weight: 400;
  padding: 10px;
  color: ${({ theme }) => theme.pb.black};
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
`;

const OptionWrapper = styled.div`
  position: flex;
  display: flex;
  flex-direction: column;
  min-width: 280px;

  gap: 10px;
`;

const OptionTitle = styled.div`
  font-size: 16px;
  font-family: 'Arial';
  color: ${({ theme }) => theme.neutralColor.dark1};
`;

const ButtonArea = styled.div`
  margin-top: 20px;
  position: fixed;
  right: 40px;
  top: 10px;
  gap: 10px;
`;

const CustomButton = MuiStyled(Button)({
  height: '32px',
  width: '100px',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  padding: '6px 12px',
  borderRadius: '8px',
  backgroundColor: '#1A1F27',
  lineHeight: 1.5,
  fontFamily: 'Arial',
  outline: 'none',
  color: 'white',
  '&:hover': {
    backgroundColor: '#242B35',
    boxShadow: 'none',
  },
  cursor: 'pointer',
});

const SimulationCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  transition: all ease 0.8s;
  width: calc(100%-260px);
  margin-left: 260px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
