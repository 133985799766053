import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AccountContext } from '../../../../../contextApi/AccountContext';
import { APIs } from '../../../../../config';
import Select from 'react-select';
import styled from 'styled-components';
import { customStyles } from '../../../../../styles/ReactSelectStyle';

export default function YoutubeModal({
  integrationData,
  setIntegrationData,
  isSubmitClicked,
  setIsSubmitClicked,
  setIsFormReady,
  setShowModal,
  openCautionPopup,
  clearStorage,
}) {
  const { sessionJWT } = useContext(AccountContext);
  const { workspaceId } = useParams();

  const isReadyToSubmit = () => {
    if (
      Array.isArray(integrationData) &&
      !integrationData?.every((data) => data.isPrimary === false)
    ) {
      return true;
    } else return false;
  };

  const selectHandler = (e) => {
    const selectedValues = e.map((item) => item.value);

    setIntegrationData((prev) => [
      ...prev
        .filter((item) => !selectedValues.includes(item.value))
        .map((data) => ({ ...data, isPrimary: false })),
      ...e.map((selectedValue) => ({ ...selectedValue, isPrimary: true })),
    ]);
  };

  const sortOptions = (list) => {
    const options = list?.filter((data) => !data.isPrimary);
    return options;
  };

  const findSelectedValue = (list) => {
    return list?.filter((item) => item.isPrimary === true);
  };

  const clearState = () => {
    setIsFormReady((prev) => ({ ...prev, youtube: false }));
    setIsSubmitClicked((prev) => ({ ...prev, youtube: false }));
  };

  const handleSubmit = () => {
    if (isSubmitClicked) {
      if (isReadyToSubmit()) {
        let submitData = {
          category: 'organic',
          channelName: 'youtube',
          accessToken: sessionStorage.getItem('accessToken'),
          refreshToken: sessionStorage.getItem('refreshToken'),
          channels: findSelectedValue(integrationData)?.map(
            (selectedValue) => ({
              id: selectedValue?.value,
              title: selectedValue?.label,
            })
          ),
        };

        sendAdsData(submitData);
      } else {
        clearState();
      }
    } else return;
  };

  const sendAdsData = async (data) => {
    try {
      const request = await fetch(
        `${APIs('integration', workspaceId)}?channelName=youtube`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionJWT}`,
          },
          body: JSON.stringify(data),
        }
      );
      const response = await request.json();
      if (request.ok) {
        clearStorage();
        window.location.reload(true);
      } else if (request.status === 400) {
        let message;
        if (response[0] === 'channel already integrated') {
          message = 'Youtube is already integrated';
        } else {
          message = response.detail;
        }
        setShowModal(false);
        openCautionPopup({
          message: message,
          confirmOption: 'refresh',
          buttonName: 'Close',
        });
      }
    } catch (error) {
      setShowModal(false);
      let message = `Issue with Youtube Integration (${error.message})`;
      openCautionPopup({
        message: message,
        confirmOption: 'refresh',
        buttonName: 'Close',
      });
      throw new Error('Issue with Youtube Integration', error.message);
    }
  };

  useEffect(() => {
    Array.isArray(integrationData) && handleSubmit();
  }, [isSubmitClicked]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SelectWrapper>
      <Header>Account</Header>
      <Select
        name='account'
        options={Array.isArray(integrationData) && sortOptions(integrationData)}
        onChange={(e) => selectHandler(e)}
        styles={customStyles}
        placeholder='Select Account'
        isMulti={true}
      />
      {!isReadyToSubmit() && (
        <FormErrors>
          <Span>Choose your account</Span>
        </FormErrors>
      )}
    </SelectWrapper>
  );
}

const SelectWrapper = styled.div`
  margin-top: 50px;
`;

const Header = styled.div`
  margin-top: 30px;
  padding-bottom: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  color: #000f1f;
`;

const FormErrors = styled.div``;

const Span = styled.span`
  display: block;
  box-sizing: inherit;
  padding-top: 0.1875rem;
  padding-left: 1rem;
  margin-bottom: -1.25rem;
  font-size: 0.75rem;
  color: red;
`;
