import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../../../contextApi/AccountContext';
import { WorkspaceContext } from '../../../contextApi/WorkspaceContext';
import { APIs } from '../../../config';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { URL } from '../../../assets/data/SERVICE_LINKS';
import { ReactComponent as DashboardSVG } from '../../../icons/Dashboards.svg';
import { ReactComponent as ModelSVG } from '../../../icons/Models.svg';
import { ReactComponent as IntegrationSVG } from '../../../icons/Integrations.svg';
import { ReactComponent as FAQSVG } from '../../../icons/FAQ.svg';
import { ReactComponent as SettingsSVG } from '../../../icons/Settings.svg';
import { ReactComponent as LogoutSVG } from '../../../icons/Logout.svg';
import { ReactComponent as POCSVG } from '../../../icons/LightBulb.svg';
import { ReactComponent as CreativesSVG } from '../../../icons/Creatives.svg';
import { Divider } from '@mui/material';
import SelectSimple from '../../../components/PlaybookSelects';
import ButtonSimple, {
  CustomButton,
} from '../../../components/PlaybookButtons';

export default function WorkspaceMenu() {
  let { workspaceId, menu } = useParams();
  const navigate = useNavigate();

  const { sessionJWT, logout } = useContext(AccountContext);
  const [workspaceDatas, setWorkspaceDatas] = useState([]);

  async function getWorkspaces() {
    try {
      const request = await fetch(`${APIs('workspaces')}`, {
        headers: { Authorization: `Bearer ${sessionJWT}` },
      });
      const response = request.json();
      const data = await response;

      if (request.ok) {
        setWorkspaceDatas(
          data?.workspaces?.map((item) => {
            return item.id === workspaceId
              ? { ...item, isSelected: true }
              : { ...item, isSelected: false };
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (sessionJWT) {
      getWorkspaces();
    } else return;
  }, [sessionJWT]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectWorkspace = (selectedWorkspaceId) => {
    setWorkspaceDatas((prev) =>
      prev.map((data) => {
        return data.id === selectedWorkspaceId
          ? { ...data, isSelected: true }
          : { ...data, isSelected: false };
      })
    );
    navigate(`/myworkspace/${selectedWorkspaceId}/dashboard`);
  };

  const ICONS = [
    {
      value: 'Proof of Concept',
      component: <POCSVG />,
      path: { type: 'playbook', url: 'poc' },
    },
    {
      value: 'Dashboards',
      component: <DashboardSVG />,
      path: { type: 'playbook', url: 'dashboard' },
      selected: false,
    },
    {
      value: 'Simulations',
      component: <ModelSVG />,
      path: { type: 'playbook', url: 'model' },
      selected: false,
    },
    {
      value: 'Integrations',
      component: <IntegrationSVG />,
      path: { type: 'playbook', url: 'integration' },
      selected: false,
    },
    {
      value: 'Settings',
      component: <SettingsSVG />,
      path: { type: 'playbook', url: 'settings' },
      selected: false,
    },
    {
      value: 'FAQ',
      component: <FAQSVG />,
      path: { type: 'other', url: URL.faqs.EN },
      selected: false,
    },
  ];
  const [iconInfo, setIconInfo] = useState(ICONS);

  useEffect(() => {
    setIconInfo((prev) =>
      prev.map((icon) => {
        return menu === icon.path.url
          ? { ...icon, selected: true }
          : { ...icon, selected: false };
      })
    );
  }, [window.location, menu]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickHandler = ({ id, value, path }) => {
    if (value === 'Logout') {
      signOut();
    } else {
      if (path.type === 'playbook') {
        navigate(`/myworkspace/${workspaceId}/${path.url}`);
      } else {
        window.open(path.url, '_blank');
      }
      setIconInfo((prev) =>
        prev.map((icon) => {
          return id === icon.id
            ? { ...icon, selected: true }
            : { ...icon, selected: false };
        })
      );
    }
  };

  const signOut = () => {
    logout();
    localStorage.removeItem('idToken');
    navigate('/signin');
  };

  return (
    <MenuAreaWrapper>
      <SelectSimple
        defaultValue={workspaceId}
        options={workspaceDatas}
        onClickHandler={selectWorkspace}
      ></SelectSimple>
      <MenuOptionWrapper>
        {iconInfo.slice(0, 4).map((icon, idx) => (
          <ButtonSimple
            key={idx}
            item={icon}
            onClickHandler={onClickHandler}
            isLocked={false}
          ></ButtonSimple>
        ))}
      </MenuOptionWrapper>

      <SettingWrapper>
        {iconInfo.slice(4).map((icon, idx) => (
          <ButtonSimple
            key={idx}
            item={icon}
            onClickHandler={onClickHandler}
          ></ButtonSimple>
        ))}
        <CustomButton
          key='logout'
          variant='contained'
          onClick={() => signOut()}
          startIcon={<LogoutSVG />}
        >
          Logout
        </CustomButton>
      </SettingWrapper>
    </MenuAreaWrapper>
  );
}

const MenuAreaWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
  height: 100vh;
  background-color: ${({ theme }) => theme.neutralColor.dark3};
  align-items: center;
`;

const MenuOptionWrapper = styled.div`
  position: fixed;
  top: 80px;
  width: 260px;
  justify-content: center;
`;

const MenuDivider = styled.hr`
  display: flex;
  position: relative;
  bottom: 95vh;
  margin: 10px;
  border: 0.5px solid white;
`;

const SettingWrapper = styled.div`
  position: fixed;
  bottom: 10px;
  width: 260px;
  align-items: center;
`;
