export const GetToken = async () => {
  if (tokenExpired()) {
    const refreshtoken = sessionStorage.getItem('refreshToken');
    const token = await getValidTokenFromServer(refreshtoken);
    sessionStorage.setItem('accessToken', token.access_Token);
    sessionStorage.setItem('refreshToken', token.refresh_token);
    sessionStorage.setItem('expirationDate', newExpirationDate());
    sessionStorage.setItem('expiresIn', token.expires_in);
    return token.access_Token;
  } else {
    return sessionStorage.getItem('accessToken');
  }
};

const newExpirationDate = () => {
  let expiration = new Date();
  expiration.setHours(expiration.getHours() + 1);
  return expiration;
};

const tokenExpired = () => {
  const now = Date.now();
  const expirationDate = sessionStorage.getItem('expirationDate');
  const expDate = new Date(expirationDate);

  if (now > expDate.getTime()) {
    return true; // token expired
  }
  return false; // valid token
};

const getValidTokenFromServer = async refreshToken => {
  // get new token from server with refresh token
  try {
    const request = await fetch('https://oauth2.googleapis.com/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refresh_token: refreshToken,
        client_id: process.env.REACT_APP_ADS_CLIENT_ID,
        client_secret: process.env.REACT_APP_ADS_CLIENT_SECRET,
        code: sessionStorage.getItem('code'),
        grant_type: 'refresh_token',
      }),
    });
    const token = await request.json();
    return token;
  } catch (error) {
    throw new Error('Issue getting new token', error.message);
  }
};
