import React, { useState, useEffect } from 'react';

import { capitalizeChar } from '../../../../hooks/textFormatter';
import OverflowTip from '../../../../components/OverflowTip';
import styled, { css } from 'styled-components';

export default function SimulationTable({ result }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    { value: 'channelName', label: 'Channel', capitalize: true },
    { value: 'name', label: 'Name', color: 'blue' },
    { value: 'cost', label: 'Cost' },
  ]);

  useEffect(() => {
    if (result.campaigns) {
      const UPDATED_COLUMNS = [...columns];
      for (let kpi of result.kpis) {
        UPDATED_COLUMNS.push({
          value: kpi.name,
          label: capitalizeChar(kpi.name),
        });
      }
      setColumns(UPDATED_COLUMNS);

      const updated = [];
      for (let campaign of result.campaigns) {
        const row = {
          channelName: campaign.channelName,
          name: campaign.name,
          cost: campaign.cost,
        };
        for (let kpi of campaign.kpis) {
          row[kpi.name] = kpi.value;
        }
        updated.push(row);
      }
      setData(updated);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Wrapper>
        <Columns>
          <Cell isSticky={true} isColumn={true}>
            {columns[0].label}
          </Cell>
          {columns.slice(1).map((column, idx) => (
            <Cell key={idx} isColumn={true}>
              {column.label}
            </Cell>
          ))}
        </Columns>

        {data?.map((item, idx) => (
          <Row key={idx}>
            <Cell color={columns[0].color} isSticky={true}>
              <OverflowTip data={item[columns[0].value]} />
            </Cell>
            {columns.slice(1).map((row) => (
              <Cell key={row.value}>
                <OverflowTip
                  data={
                    item[row.value] !== null
                      ? row?.capitalize
                        ? capitalizeChar(item[row.value])
                        : item[row.value]?.toLocaleString()
                      : '-'
                  }
                />
              </Cell>
            ))}
          </Row>
        ))}
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  ${({ theme }) => theme.animation.fadeInBottom}
  display: flex;
`;

const Wrapper = styled.div`
  ${({ theme }) => theme.whcard}
  ${({ theme }) => theme.animation.fadeInBottom}
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.3rem;
  background: '#fff';
  border-radius: 8px;
  transition: width 0.7s ease;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #f5f5f5;
  min-width: 1200px;
`;

const Row = styled(Columns)`
  &:last-child {
    border-bottom: 0px;
  }
`;

const Cell = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: ${({ isSticky }) => (isSticky ? 'flex-start' : 'flex-end')};

  width: ${({ isSticky }) => (isSticky ? '220px' : '240px')};
  padding: ${({ isColumn }) => (isColumn ? '20px 17px' : '17px')};
  color: ${({ color, theme }) =>
    color === 'blue' ? theme.pb.midBlue : theme.pb.black};
  font-weight: ${({ isColumn }) => isColumn && 600};
  font-size: 14px;
  line-height: 16px;
  text-align: ${({ isSticky }) => (isSticky ? 'left' : 'right')};

  background-color: #fff;
  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      left: 0px;
      text-align: left;
      text-overflow: ellipsis;
    `}
`;
