import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../contextApi/AccountContext';
import { ChannelsContext } from '../../../contextApi/ChannelsContext';

import LoadingPage from '../../../components/LoadingPage';
import styled from 'styled-components';
import { Link, Button } from '@mui/material';
import PlaybookCard from '../../../components/PlaybookCards';
import { APIs } from '../../../config';
import { styled as MuiStyled } from '@mui/material/styles';
import { ReactComponent as IntegrationSVG } from '../../../icons/Integrations.svg';
export default function Models() {
  const { sessionJWT } = useContext(AccountContext);
  const { workspaceId } = useParams();
  const { integratedList } = useContext(ChannelsContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [hasIntegration, setHasIntegration] = useState(false);
  const [feature, setFeature] = useState();

  const goToDetail = (modelType) => {
    navigate(`/myworkspace/${workspaceId}/model/${modelType}`);
  };

  useEffect(() => {
    if (workspaceId && sessionJWT) {
      setIsLoading(true);
      const fetchFeature = async () => {
        const request = await fetch(`${APIs('feature', workspaceId)}`, {
          headers: { Authorization: `Bearer ${sessionJWT}` },
        });
        const data = await request.json();
        setFeature(data);
      };
      fetchFeature();
      setIsLoading(false);
    }
  }, [workspaceId, sessionJWT]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (integratedList?.length === 0) {
      return;
    } else {
      setHasIntegration(true);
    }
  }, [integratedList, setHasIntegration]);

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <ModelWrapper>
          <ModelTitle>{hasIntegration ? '🤖 Hello!' : '🤖 Hello!'}</ModelTitle>
          {hasIntegration ? (
            <ModelDescription>
              Models are basis for dashboards and simulations! Learn more about
              them by visiting <span style={{ fontWeight: 'bold' }}>FAQ</span>!
            </ModelDescription>
          ) : (
            <ModelDescription>
              Let's connect your data! We will do the rest in creating{' '}
              <Link
                href='https://panomix.notion.site/Playbook-123aaf87136e418ba7c80e694ffe32de?p=b7a48ce1871b4291b003f7d8f9893e4c&pm=c'
                color='#0B8FFC'
              >
                models
              </Link>{' '}
              and{' '}
              <Link
                href='https://panomix.notion.site/Playbook-123aaf87136e418ba7c80e694ffe32de?p=b7a48ce1871b4291b003f7d8f9893e4c&pm=c'
                color='#05ED94'
              >
                dashboards
              </Link>
              .
            </ModelDescription>
          )}
          {hasIntegration ? (
            <ModelCardArea>
              {MODELS.map((item, idx) => (
                <PlaybookCard
                  key={idx}
                  title={item.title}
                  name={item.name}
                  isLocked={feature?.models ? feature.models[item.name] : true}
                  message={item.description}
                  tags={item.tags}
                  faq={item.faq}
                  onClickHandler={goToDetail}
                />
              ))}
            </ModelCardArea>
          ) : (
            <CustomButton
              key='AddDataSource'
              variant='contained'
              startIcon={<IntegrationSVG />}
              disableRipple
              onClick={() =>
                navigate(`/myworkspace/${workspaceId}/integration/connect`)
              }
            >
              Add Data Source
            </CustomButton>
          )}
        </ModelWrapper>
      )}
    </>
  );
}

const ModelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: calc(100% - 260px);
  left: 260px;
`;

const ModelTitle = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.cyanBlue};
  font-size: 24px;
  font-family: 'Arial';
  padding: 5px;
`;

const ModelDescription = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.realBlack};
  font-size: 14px;
  padding: 5px;
  width: 340px;
  line-height: 19px;
  font-family: 'Arial';
  text-align: center;
`;

const ModelCardArea = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  transition: all ease 0.3s;

  // @media (max-width: 1440px) {
  //   grid-template-columns: repeat(2, 1fr);
  // }
  // @media (min-width: 1900px) {
  //   grid-template-columns: repeat(4, 1fr);
  // }
`;

const MODELS = [
  {
    title: 'Campaign Optimizer',
    name: 'mmm',
    description: 'Maximize KPIs by simulating active campaigns.',
    tags: {
      category: 'ML',
      subCategory: 'Marketing',
    },
    faq: 'https://panomix.notion.site/Media-Mix-Modeling-b7a48ce1871b4291b003f7d8f9893e4c',
  },
  {
    title: 'Media Mix Planner(Beta)',
    name: 'pmmm',
    description: 'Set KPIs to reach & get recommended campaigns',
    tags: {
      category: 'ML',
      subCategory: 'Marketing',
    },
    faq: 'https://panomix.notion.site/Media-Mix-Modeling-b7a48ce1871b4291b003f7d8f9893e4c',
  },
];

const CustomButton = MuiStyled(Button)({
  height: '40px',
  width: '180px',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  padding: '6px 12px',
  borderRadius: '8px',
  backgroundColor: '#1A1F27',
  lineHeight: 1.5,
  fontFamily: 'Arial',
  outline: 'none',
  color: 'white',
  '&:hover': {
    backgroundColor: '#242B35',
    boxShadow: 'none',
  },
  cursor: 'pointer',
});
