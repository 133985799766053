import React, { useState } from 'react';
import { changeDateFormatForShow } from '../../../../hooks/changeDateFormat';
import { capitalizeChar } from '../../../../hooks/textFormatter';

import StaticSideModal from '../../../../components/StaticSideModal';
import ModalLoading from '../../../../components/ModalLoading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import theme from '../../../../styles/theme';
import styled from 'styled-components';
// import { performance as modelPerformance } from './MOCK/performance';

export default function Performance({
  isLoading,
  modelPerformance,
  kpiName,
  Line,
}) {
  // const isLoading = false;
  const [showModal, setShowModal] = useState(true);

  const MODEL_INFO = {
    title: 'Model Performance',
    description:
      'This chart compares the actual and predicted values for the target KPI over time. This graph can be used to interpret how well a model was trained by observing the closeness of the predicted graph to the actual graph.',
  };

  const openSideModal = () => {
    setShowModal(true);
  };

  const closeSideModal = () => {
    setShowModal(false);
  };

  const modalOptions = {
    title: 'About this chart',
    titleSize: '18px',
    icon: <FontAwesomeIcon icon={faInfoCircle} />,
    closeModal: () => closeSideModal(),
    content: MODEL_INFO.description,
    // modalHeight:'',
  };

  const CHART_COLOR = [
    {
      // blue
      backgroundColor: theme.pb.chartBlue,
      hoverBackgroundColor: theme.pb.chartBlue,
      borderColor: theme.pb.chartBlue,
      lightColor: theme.pb.chartBlue,
    },
    {
      //black
      backgroundColor: theme.pb.chartRed,
      hoverBackgroundColor: theme.pb.chartRed,
      borderColor: theme.pb.chartRed,
      lightColor: theme.pb.chartRed,
    },
  ];

  const data = {
    labels: modelPerformance?.variable?.map(
      (date) => changeDateFormatForShow({ period: date }).split(',')[0]
    ),
    datasets: modelPerformance?.value?.map((modelPerformanceData, i) => ({
      type: 'line',
      label: `${modelPerformanceData.label
        ?.slice(0, 1)
        ?.toUpperCase()}${modelPerformanceData.label?.slice(1)}`,
      data: modelPerformanceData.data,
      borderWidth: 2,
      borderColor: CHART_COLOR[i].borderColor,
      backgroundColor: CHART_COLOR[i].backgroundColor,
    })),
  };

  const options = {
    indexAxis: 'x',
    maintainAspectRatio: false,

    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          color: theme.pb.realBlack,
          font: {
            size: 14,
            family: 'Arial',
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: theme.pb.realBlack,
          font: {
            size: 14,
            family: 'Arial',
          },
        },
        title: {
          display: true,
          align: 'center',
          text: capitalizeChar(kpiName),
          color: 'black',
          font: { size: 16, weight: 400, family: 'Arial' },
          padding: { bottom: 14 },
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          font: {
            size: 14,
            weight: 400,
            family: 'Arial',
          },
          color: theme.pb.realBlack,
        },
      },

      tooltip: {
        enable: false,
        position: 'nearest',
        padding: 14,
        backgroundColor: '#fff',
        borderColor: (tooltip) => tooltip.tooltip.labelColors[0].borderColor,
        borderWidth: 0.5,
        titleFont: { size: 14, family: 'Arial' },
        titleColor: 'black',
        titleAlign: 'center',
        displayColors: true,
        bodyAlign: 'center',
        bodyColor: '#000',
        usePointStyle: true,
        boxWidth: 0,
        boxHeight: 0,
        yAlign: 'bottom',

        callbacks: {
          labelTextColor: (context) => {
            return data.datasets.borderColor;
          },
          labelPointStyle: (context) => {
            return { pointStyle: 'triangle', rotation: 0 };
          },
        },
      },
    },
    elements: {
      line: {
        tention: 0,
        borderwidth: 2,
      },
      point: {
        borderColor: data?.datasets?.borderColor,
        backgroundColor: data?.datasets?.lightColor,
        radius: 2,
        hoverRadius: 8,
      },
    },
  };

  return (
    <Wrapper>
      <ModelTitle>{MODEL_INFO.title}</ModelTitle>
      {isLoading ? (
        <LoadingWrapper>
          <ModalLoading />
        </LoadingWrapper>
      ) : Object?.hasOwn(modelPerformance, 'variable') ? (
        <ChartWrapper>
          <Line options={options} data={data} />
        </ChartWrapper>
      ) : (
        <Warning>this model has no chart data :(</Warning>
      )}
    </Wrapper>
  );
}

// const Container = styled.div`
//   ${({ theme }) => theme.animation.fadeInBottom}
//   display: flex;
//   width: calc(100vw - 260px);
// `;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0px 32px 0px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  transition: all ease 0.7s;
`;

const ModelTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.pb.black};
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  font-family: 'Arial';
`;

const DescIcon = styled.span`
  margin-left: 10px;
  font-size: 18px;
  color: ${({ theme }) => theme.pb.gray};

  :hover {
    color: ${({ theme }) => theme.pb.black};
  }

  transition: all ease 0.3s;
  cursor: pointer;
`;

const ChartWrapper = styled.div`
  width: 90%;
  height: 500px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Warning = styled.div`
  margin: 10px;
  padding-top: 8px;
  font-size: 12px;
  color: gray;
`;
