import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

export default function ShopifyModal({
  integrationData,
  isSubmitClicked,
  setIsSubmitClicked,
  isFormReady,
  setIsFormReady,
  setShowModal,
  openCautionPopup,
  convertToOptionForm,
  checkIsSelected,
}) {
  const [alert, setAlert] = useState(false);
  const [shopifyURL, setShopifyURL] = useState('');

  const {
    REACT_APP_SHOPIFY_CLIENT_ID,
    REACT_APP_SHOPIFY_REDIRECT_URI,
    REACT_APP_SHOPIFY_SCOPES,
  } = process.env;

  const onInputChange = event => {
    const { value } = event.target;
    setShopifyURL(value);
  };

  const requestAuth = () => {
    const authRequestURL = `https://${shopifyURL}/admin/oauth/authorize?client_id=${REACT_APP_SHOPIFY_CLIENT_ID}&scope=${REACT_APP_SHOPIFY_SCOPES}&redirect_uri=${REACT_APP_SHOPIFY_REDIRECT_URI}`;
    window.location.assign(authRequestURL);
  };

  const handleSubmit = () => {
    if (isSubmitClicked && shopifyURL.length === 0) {
      let message = 'Found no shop';
      setShowModal(prev => !prev);
      openCautionPopup({
        message: message,
        confirmOption: 'refresh',
        buttonName: 'Close',
      });
    } else if (shopifyURL && shopifyURL.length > 0) {
      setShopifyURL(shopifyURL.split('//')[1]);
      sessionStorage.setItem('shop', shopifyURL);
      requestAuth();
    }
  };
  useEffect(() => {
    setIsFormReady(prev => ({
      ...prev,
      shopify: !!shopifyURL,
    }));

    const notReady = () => {
      if (isSubmitClicked && !isFormReady) {
        setAlert(true);
        setIsSubmitClicked(prev => ({ ...prev, shopify: false }));
      }
    };

    if (isSubmitClicked) {
      !shopifyURL ? notReady() : handleSubmit();
    }
  }, [isSubmitClicked, shopifyURL]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SelectWrapper>
      <Input
        type="text"
        id="shopifyURL"
        placeholder="Write your Shopify URL (ex: https://mystore.myshopify.com)"
        value={shopifyURL}
        onChange={onInputChange}
      />
      {alert && !shopifyURL?.value && (
        <FormErrors>
          <Span>Please enter your shop URL</Span>
        </FormErrors>
      )}
    </SelectWrapper>
  );
}

const SelectWrapper = styled.div`
  margin-top: 50px;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: 40px;
  padding-left: 20px;
  margin-bottom: 2em;
  ${({ theme }) => theme.whcard};

  & + input {
    margin-top: 0.5rem;
  }

  &::placeholder {
    /* color: ${({ theme }) => theme.pb.darkGray}; */
    color: #afafaf;
  }
`;

const FormErrors = styled.div``;

const Span = styled.span`
  display: block;
  box-sizing: inherit;
  margin-top: -1.25rem;
  padding-left: 1rem;
  margin-bottom: -1.25rem;
  font-size: 0.75rem;
  color: red;
`;
