import React from 'react';
import SemiCircleProgressBar from 'react-progressbar-semicircle';
import theme from '../../../../styles/theme';
import styled from 'styled-components/macro';

export default function SimulationKPIInfo({ kpis }) {
  const million = 1000000;
  const chartStyle = {
    stroke: `${theme.pb.cyanBlue}`,
    strokeWidth: 8.5,
    background: '#c5e9dc',
    diameter: 155,
    orientation: 'up',
    direction: 'right',
  };

  const { stroke, strokeWidth, background, diameter, orientation, direction } =
    chartStyle;

  return (
    <Container>
      {kpis.map((factor) => (
        <CardBox key={factor.name}>
          <Title>{factor.name}</Title>
          <SemiCircleProgressBar
            stroke={stroke}
            strokeWidth={strokeWidth}
            background={
              factor.currentValue === 0
                ? `${theme.pb.semiLightGray}`
                : background
            }
            diameter={diameter}
            orientation={orientation}
            direction={direction}
            percentage={factor.proportion * 100}
          />
          <PercentValue percent={(factor.proportion * 100).toLocaleString()}>
            {(factor.proportion * 100).toLocaleString()}%
          </PercentValue>
          <Cost value={factor.estimated}>
            {factor.estimated.toLocaleString()}
          </Cost>
          <Goal>
            Goal :{' '}
            {factor.target > million
              ? `${Math.floor(factor.target / million).toLocaleString()}M`
              : factor.target.toLocaleString()}
          </Goal>
        </CardBox>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 0.8rem;
  width: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const CardBox = styled.div`
  position: relative;

  margin-right: 1rem;
  width: 180px;
  height: 130px;

  .semicircle-container {
    text-align: center;
  }
`;

const Title = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0.3rem;
  margin-right: 8px;
  color: ${({ theme }) => theme.pb.cyanBlue};
  font-size: 0.8rem;
  text-transform: capitalize;
  text-align: center;
`;

const PercentValue = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
  display: inline-block;

  ${({ theme }) => theme.positioncenter}

  margin: 13px 0 11px 2px;
  color: ${({ percent, theme }) =>
    percent === 0 ? theme.pb.darkGray : theme.pb.darkGreen};
  font-size: 1.5rem;
  line-height: 19px;
  text-align: center;
`;

const Cost = styled.p`
  width: 100%;
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-align: center;
  color: ${({ value, theme }) => (value === 0 ? theme.pb.gray : '')};
`;

const Goal = styled.p`
  width: 100%;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.pb.cyanBlue};
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-align: center;
`;
