import React, { useEffect } from 'react';
import styled from 'styled-components';
import RadarChart from './RadarChart';
import { SelectCampaign } from '../Components/Selector';
import { capitalizeChar } from '../../../../hooks/textFormatter';
import theme from '../../../../styles/theme';
export default function CampaignKpi(props) {
  const { selectedCampaign, setSelectedCampaign, campaignPredictions } = props;

  return (
    <Wrapper>
      <Title>Campaign KPI Analysis</Title>
      {selectedCampaign.id && (
        <SelectCampaign
          defaultValue={selectedCampaign.id}
          options={campaignPredictions}
          onClickHandler={(option) => setSelectedCampaign(option)}
        />
      )}
      <ChartContainer>
        <RadarChart
          title='KPI Contribution Percentile'
          labels={selectedCampaign.predictions.map((row) =>
            capitalizeChar(row.kpiName)
          )}
          apiData={selectedCampaign.predictions.map((row) =>
            (row.radarKPI * 100).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })
          )}
          color='rgba(54, 162, 235, 0.6)'
        />
        <RadarChart
          title='CPA Percentile'
          labels={selectedCampaign.predictions.map((row) =>
            capitalizeChar(row.kpiName)
          )}
          apiData={selectedCampaign.predictions.map((row) =>
            (row.radarCPA * 100).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })
          )}
          color='rgba(255, 99, 132, 0.6)'
        />
      </ChartContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  height: auto;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  transition: all ease 0.7s;
  ${({ theme }) => theme.animation.fadeInBottom}
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.pb.black};
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  font-family: 'Arial';
`;

const ChartContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2px;
  width: 100%;
  margin-top: 40px;
`;
