import React, { useState } from 'react';
import styled from 'styled-components/macro';
import FacebookAdsModal from '../Facebook/FacebookAdsModal';
import GoogleAdsModal from '../GoogleAds/GoogleAdsModal';
import GoogleAnalyticsModal from '../GoogleAnalytics/GoogleAnalyticsModal';
import YoutubeModal from '../Youtube/YoutubeModal';
import TiktokModal from '../TikTok/TiktokModal';
import ShopifyModal from '../Shopify/ShopifyModal';
import MailchimpModal from '../Mailchimp/MailchimpModal';
import NaverAdsModal from '../NaverAds/NaverAdsModal';
import KakaoAdsModal from '../KakaoAds/KakaoAdsModal';
import NuriLoungeModal from '../NuriLounge/NuriLoungeModal';
import Cafe24Modal from '../Cafe24/Cafe24Modal';
import SingularModal from '../Singular/SingularModal';
import AppsflyerModal from '../Appsflyer/AppsflyerModal';

export default function IntegrationModal({
  content,
  integrationData,
  setIntegrationData,
  setShowModal,
  openCautionPopup,
  clearStorage,
}) {
  //notify when the submit btn is clicked
  const [isSubmitClicked, setIsSubmitClicked] = useState({
    facebookAds: false,
    googleAds: false,
    googleAnalytics: false,
    youtube: false,
    tiktokAds: false,
    tiktok: false,
    shopify: false,
    mailchimp: false,
    naverAds: false,
    nuriLounge: false,
    cafe24: false,
    singular: false,
    appsflyer: false,
  });

  //allows the submit btn to activate when the form is ready
  const [isFormReady, setIsFormReady] = useState({
    facebookAds: false,
    googleAds: false,
    googleAnalytics: false,
    youtube: false,
    tiktokAds: false,
    tiktok: false,
    shopify: false,
    mailchimp: false,
    naverAds: false,
    nuriLounge: false,
    cafe24: false,
    singular: false,
    appsflyer: false,
  });

  const convertToOptionForm = (list) => {
    const options = list?.map((account) => ({
      value: account.id,
      label: account.name,
    }));
    return options;
  };

  const checkIsSelected = (list, selection) => {
    if (list?.length > 0) {
      if (selection?.value) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const selectMode = {
    facebookAds: (
      <FacebookAdsModal
        integrationData={integrationData}
        setIntegrationData={setIntegrationData}
        isSubmitClicked={isSubmitClicked.facebookAds}
        setIsSubmitClicked={setIsSubmitClicked}
        setIsFormReady={setIsFormReady}
        setShowModal={setShowModal}
        openCautionPopup={openCautionPopup}
      />
    ),
    googleAds: (
      <GoogleAdsModal
        integrationData={integrationData}
        setIntegrationData={setIntegrationData}
        isSubmitClicked={isSubmitClicked.googleAds}
        setIsSubmitClicked={setIsSubmitClicked}
        setIsFormReady={setIsFormReady}
        setShowModal={setShowModal}
        openCautionPopup={openCautionPopup}
      />
    ),
    googleAnalytics: (
      <GoogleAnalyticsModal
        integrationData={integrationData}
        setIntegrationData={setIntegrationData}
        isSubmitClicked={isSubmitClicked.googleAnalytics}
        setIsSubmitClicked={setIsSubmitClicked}
        isFormReady={isFormReady.googleAnalytics}
        setIsFormReady={setIsFormReady}
        setShowModal={setShowModal}
        openCautionPopup={openCautionPopup}
      />
    ),
    youtube: (
      <YoutubeModal
        integrationData={integrationData}
        setIntegrationData={setIntegrationData}
        isSubmitClicked={isSubmitClicked.youtube}
        setIsSubmitClicked={setIsSubmitClicked}
        isFormReady={isFormReady.youtube}
        setIsFormReady={setIsFormReady}
        setShowModal={setShowModal}
        openCautionPopup={openCautionPopup}
        clearStorage={clearStorage}
      />
    ),
    tiktokAds: (
      <TiktokModal
        integrationData={integrationData}
        setIntegrationData={setIntegrationData}
        isSubmitClicked={isSubmitClicked.tiktokAds}
        setIsSubmitClicked={setIsSubmitClicked}
        setIsFormReady={setIsFormReady}
        setShowModal={setShowModal}
        openCautionPopup={openCautionPopup}
      />
    ),
    shopify: (
      <ShopifyModal
        integrationData={integrationData}
        setIntegrationData={setIntegrationData}
        isSubmitClicked={isSubmitClicked.shopify}
        setIsSubmitClicked={setIsSubmitClicked}
        isFormReady={isFormReady.shopify}
        setIsFormReady={setIsFormReady}
        setShowModal={setShowModal}
        openCautionPopup={openCautionPopup}
        convertToOptionForm={convertToOptionForm}
        checkIsSelected={checkIsSelected}
      />
    ),
    mailchimp: (
      <MailchimpModal
        integrationData={integrationData}
        isSubmitClicked={isSubmitClicked.mailchimp}
        setIsSubmitClicked={setIsSubmitClicked}
        isFormReady={isFormReady.mailchimp}
        setIsFormReady={setIsFormReady}
        setShowModal={setShowModal}
        openCautionPopup={openCautionPopup}
        checkIsSelected={checkIsSelected}
      />
    ),
    naverAds: (
      <NaverAdsModal
        integrationData={integrationData}
        setIntegrationData={setIntegrationData}
        isSubmitClicked={isSubmitClicked.naverAds}
        setIsSubmitClicked={setIsSubmitClicked}
        isFormReady={isFormReady.naverAds}
        setIsFormReady={setIsFormReady}
        setShowModal={setShowModal}
        openCautionPopup={openCautionPopup}
      />
    ),
    kakaoAds: (
      <KakaoAdsModal
        integrationData={integrationData}
        setIntegrationData={setIntegrationData}
        isSubmitClicked={isSubmitClicked.naverAds}
        setIsSubmitClicked={setIsSubmitClicked}
        isFormReady={isFormReady.naverAds}
        setIsFormReady={setIsFormReady}
        setShowModal={setShowModal}
        openCautionPopup={openCautionPopup}
      />
    ),
    nuriLounge: (
      <NuriLoungeModal
        integrationData={integrationData}
        setIntegrationData={setIntegrationData}
        isSubmitClicked={isSubmitClicked.nuriLounge}
        setIsSubmitClicked={setIsSubmitClicked}
        isFormReady={isFormReady.nuriLounge}
        setIsFormReady={setIsFormReady}
        setShowModal={setShowModal}
        openCautionPopup={openCautionPopup}
      />
    ),
    cafe24: (
      <Cafe24Modal
        isSubmitClicked={isSubmitClicked.cafe24}
        setIsSubmitClicked={setIsSubmitClicked}
        setShowModal={setShowModal}
        openCautionPopup={openCautionPopup}
      />
    ),
    singular: (
      <SingularModal
        integrationData={integrationData}
        setIntegrationData={setIntegrationData}
        isSubmitClicked={isSubmitClicked.singular}
        setIsSubmitClicked={setIsSubmitClicked}
        isFormReady={isFormReady.singular}
        setIsFormReady={setIsFormReady}
        setShowModal={setShowModal}
        openCautionPopup={openCautionPopup}
      />
    ),
    appsflyer: (
      <AppsflyerModal
        integrationData={integrationData}
        setIntegrationData={setIntegrationData}
        isSubmitClicked={isSubmitClicked.appsflyer}
        setIsSubmitClicked={setIsSubmitClicked}
        isFormReady={isFormReady.appsflyer}
        setIsFormReady={setIsFormReady}
        setShowModal={setShowModal}
        openCautionPopup={openCautionPopup}
      />
    ),
  };

  const onSubmit = (e) => {
    setIsSubmitClicked({ ...isSubmitClicked, [content]: true });
  };

  const getTitle = (content) => {
    if (content === 'shopify') {
      return 'Connect your Shopify Account';
    } else if (content === 'naverAds') {
      return 'Connect with API';
    } else if (content === 'kakaoAds') {
      return 'Connect with API ';
    } else if (content === 'nuriLounge') {
      return 'Connect with API';
    } else if (content === 'singular') {
      return 'Connect with API';
    } else if (content === 'appsflyer') {
      return 'Connect with API';
    } else if (content === 'cafe24') {
      return 'Connect your Cafe24 Account';
    } else return 'Select Primary Account';
  };

  console.log('INTEGRATION MODAL::', integrationData, content);
  return (
    <ModalWrapper>
      <Title>{content && getTitle(content)}</Title>
      {content && <ContentWrapper>{selectMode[content]}</ContentWrapper>}
      <Submit disabled={!isFormReady} onClick={onSubmit}>
        Submit
      </Submit>
      <CancelBtn onClick={clearStorage}>Cancel</CancelBtn>
    </ModalWrapper>
  );
}

const ModalWrapper = styled.div`
  display: inline-grid;
  justify-content: center;
  align-content: space-around;
  padding: 36px;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  background: #fff;
  z-index: 5;
`;

const Title = styled.h1`
  position: fixed;
  top: -3px;
  left: 0;
  padding: 20px 20px 20px 36px;
  width: 100%;
  color: ${(props) => props.theme.pb.cyanBlue};
  background-color: #fff;
  font-size: 2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 56px;
  letter-spacing: -0.04em;
  text-align: left;
  border-radius: 8px;
  box-shadow: 0px 1px 2px ${(props) => props.theme.pb.lightGray};
`;

const ContentWrapper = styled.div`
  margin-top: 50px;
`;

const Submit = styled.button`
  margin: 50px 0px 10px 0px;
  text-align: center;
  border-radius: 42px;
  width: 520px;
  padding: 8px 14px 8px 14px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border: 1px solid ${({ theme }) => theme.pb.cyanBlue};
  font-size: 14px;
  background: ${({ theme }) => theme.pb.cyanBlue};
  color: white;
  cursor: pointer;
`;

const CancelBtn = styled.button`
  width: 100%;
  padding: 0.75rem 0;
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.pb.midBlue};
  cursor: pointer;
`;
