import React from 'react';
import styled from 'styled-components';

export default function ErrorMessageBox({
  errorDescription,
  warningBoxTitle,
  warningBoxDescription,
}) {
  return (
    <ErrorMessageBoxWrapper>
      <span>{errorDescription}</span>
      {warningBoxTitle && (
        <WarningBox>
          <BoldString>{warningBoxTitle}</BoldString>
          <br />
          <br />
          {warningBoxDescription}
        </WarningBox>
      )}
    </ErrorMessageBoxWrapper>
  );
}

const ErrorMessageBoxWrapper = styled.div``;

const WarningBox = styled.div`
  padding: 10px 8px 12px 8px;
  margin-top: 14px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.pb.semiLightGray};
`;

const BoldString = styled.span`
  font-weight: 700;
`;
