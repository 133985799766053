import React, { useState, createContext, useContext } from 'react';

export const AnimationContext = createContext();

export const AnimationProvider = ({ children }) => {
  const [slideAnime, setSlideAnime] = useState(false);

  return (
    <AnimationContext.Provider
      value={{
        slideAnime,
        setSlideAnime,
      }}
    >
      {children}
    </AnimationContext.Provider>
  );
};

export const useAnimationContext = () => {
  const context = useContext(AnimationContext);
  if (!context) {
    throw new Error('Cannot get animation context');
  }
  return context;
};
