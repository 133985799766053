import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const GoogleAds = () => {
  const { workspaceId } = useParams();
  const {
    REACT_APP_ADS_CLIENT_ID,
    REACT_APP_ADS_REDIRECT_URI,
    REACT_APP_ADS_SCOPES,
  } = process.env;

  const authRequestURL = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${REACT_APP_ADS_REDIRECT_URI}&prompt=consent&response_type=code&client_id=${REACT_APP_ADS_CLIENT_ID}&scope=${REACT_APP_ADS_SCOPES}&access_type=offline`;

  const GoogleAuthLink = () => {
    window.localStorage.setItem('workspaceId', workspaceId);
    window.location.assign(authRequestURL);
  };

  return (
    <Wrapper>
      <AdsButton id="Ads" onClick={GoogleAuthLink}>
        <IntegrateText>Integrate</IntegrateText>
      </AdsButton>
    </Wrapper>
  );
};

export default GoogleAds;

const Wrapper = styled.div`
  position: relative;
`;

const AdsButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.pb.cyanBlue};
  border-radius: 43px;
  padding: 8px 14px;
  background-color: white;
  cursor: pointer;
`;

const IntegrateText = styled.span`
  width: 56px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.pb.cyanBlue};
`;
