import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

export default function Mailchimp({
  setShowModal,
  setContent,
  integrationData,
  setIntegrationData,
  openCautionPopup,
}) {
  //TODO: response.status의 에러등에 대한 분기처리를 할 필요가 있음.

  const { workspaceId } = useParams();

  const { REACT_APP_MAILCHIMP_CLIENT_ID, REACT_APP_MAILCHIMP_REDIRECT_URI } =
    process.env;

  const authRequestURL = `https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=${REACT_APP_MAILCHIMP_CLIENT_ID}&redirect_uri=${REACT_APP_MAILCHIMP_REDIRECT_URI} `;

  const AuthLink = () => {
    window.localStorage.setItem('workspaceId', workspaceId);
    window.location.assign(authRequestURL);
  };

  return (
    <Wrapper>
      <Button id="Mailchimp" onClick={AuthLink}>
        <IntegrateText>Integrate</IntegrateText>
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.pb.cyanBlue};
  border-radius: 43px;
  padding: 8px 14px;
  background-color: white;
  cursor: pointer;
`;

const IntegrateText = styled.span`
  width: 56px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.pb.cyanBlue};
`;
