import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { SideModalContext } from '../contextApi/SideModalContext';
import { AnimationContext } from '../contextApi/AnimationContext';
import NewBtn from './NewBtn';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components/macro';
import WorkspaceCheckModal from './WorkspaceCheckModal';
import { WorkspaceContext } from '../contextApi/WorkspaceContext';

export default function EmptyContentPage({ currentPage }) {
  const { getWorkspaceData, workspaceData } = useContext(WorkspaceContext);
  const { setShowSideModal, setSideModalOption } = useContext(SideModalContext);
  const { setSlideAnime } = useContext(AnimationContext);
  const { workspaceId } = useParams();
  const navigate = useNavigate();

  const [workspaceCheckReady, setWorkspaceCheckReady] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const checkNeedContract = (buttonValue) => {
    return ['createModelByDate', 'organizeCreatePlan', 'createPlan'].includes(
      buttonValue
    );
  };

  useEffect(() => {
    getWorkspaceData('workspace');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setWorkspaceCheckReady(true);
  }, [workspaceData.workspace]);

  const buttonOnClickHandler = (type) => {
    if (type) {
      let [key, value] = Object.entries(type)[0];
      if (key === 'modalType') {
        if (checkNeedContract(value)) {
          if (workspaceData?.workspace?.signedContract) {
            return () => {
              setSideModalOption((prev) => ({
                ...prev,
                selectedOption: value,
              }));
              setShowSideModal(true);
              setSlideAnime(true);
            };
          } else {
            return () => setShowModal(true);
          }
        } else {
          return () => {
            setSideModalOption((prev) => ({ ...prev, selectedOption: value }));
            setShowSideModal(true);
            setSlideAnime(true);
          };
        }
      } else if (key === 'path') {
        return () => navigate(`/myworkspace/${workspaceId}/${value}`);
      }
    } else return;
  };

  const pageData = {
    dashboard: {
      otherBtnInfo: {
        name: 'Data Sources',
        img: <FontAwesomeIcon icon={faDatabase} size='1x' />,
      },
      title: 'Enable dashboard by connecting data sources',
      desc1: '',
      desc2: 'Facebook Ads, Google Ads, Instagram & more!',
      onClickHandler: { path: 'dashboard/integration' },
    },
    plansWithNoPlan: {
      buttonName: 'Plan',
      title: 'Any marketing plan?',
      desc1: 'Create a plan with',
      desc2: 'KPIs you want to achieve!',
      onClickHandler: { modalType: 'createPlan' },
    },
    plansWithNoSOT: {
      otherBtnInfo: {
        name: 'Data Sources',
        img: <FontAwesomeIcon icon={faDatabase} size='1x' />,
      },
      title: 'Any marketing plan?',
      desc1: 'Connect analytics tool first',
      desc2: 'to set KPIs you want to achieve!',
      onClickHandler: { path: 'dashboard/integration' },
    },
    noCampaigns: {
      otherBtnInfo: {
        name: 'Add Campaigns',
        img: <FontAwesomeIcon icon={faPlusCircle} size='1x' />,
      },
      title: 'Any marketing campaign?',
      desc1: 'Add your campaigns and gain insights',
      desc2: '',
      onClickHandler: { path: 'dashboard' },
    },
    modelsWithNoChannel: {
      otherBtnInfo: {
        name: 'Data Sources',
        img: <FontAwesomeIcon icon={faDatabase} size='1x' />,
      },
      title: 'Get MMM models by completing integration steps',
      desc1: '',
      desc2: 'If you already have, models will appear within 24 hours.',
      onClickHandler: { path: 'dashboard/integration' },
    },
    modelsWithNoModel: {
      buttonName: 'Model',
      title: 'Need the best marketing strategy?',
      desc1: 'Build prediction models with machine learning',
      desc2: 'and gain exceptional insight!',
      onClickHandler: { modalType: 'createModelByDate' },
    },
  };

  if (!workspaceCheckReady) return null;
  return (
    <>
      <CreateBtnWrapper currentPage={currentPage}>
        <CreateBtnTitle>{pageData[currentPage]?.title}</CreateBtnTitle>
        <CreateBtnDescription>
          {pageData[currentPage]?.desc1} <br /> {pageData[currentPage]?.desc2}
        </CreateBtnDescription>
        <NewBtn
          newBtnName={pageData[currentPage]?.buttonName}
          otherBtnInfo={pageData[currentPage]?.otherBtnInfo}
          onClickHandler={buttonOnClickHandler(
            pageData[currentPage]?.onClickHandler
          )}
        />
      </CreateBtnWrapper>
      <WorkspaceCheckModal
        showModal={showModal}
        setShowModal={setShowModal}
        type='contract'
      />
    </>
  );
}

const CreateBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ currentPage }) =>
    currentPage === 'campaigns' ? '65vh' : '70vh'};
`;

const CreateBtnTitle = styled.div`
  margin: 0.8rem;
  /* color: ${({ theme }) => theme.pb.darkBlue}; */
  color: black;
  letter-spacing: -1px;
  font-weight: 300;
  font-size: 48px;
`;

const CreateBtnDescription = styled.div`
  margin-bottom: 1rem;
  font-size: 18px;
  text-align: center;
`;

const BoldText = styled.span`
  font-weight: 800;
`;
