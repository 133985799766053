import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { APIs } from '../../../config';
import Header from '../../../components/Header';
import styled from 'styled-components';

export default function ApproveJoinWorkspace() {
  const { token } = useParams();
  const [approveResult, setApproveResult] = useState('noToken');
  const goToSignIn = () => {
    window.location = `${process.env.REACT_APP_API_END_POINT}`;
  };

  const confirmToken = async token => {
    try {
      const request = await fetch(`${APIs('approve')}/${token}`, {
        method: 'POST',
      });
      const response = await request.json();
      if (request.ok) {
        setApproveResult('success');
      } else if (request.status !== 500) {
        setApproveResult('expiredToken');
      } else {
        setApproveResult('fail');
        console.error(response.detail);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const headerMessage = {
    success: {
      title: 'Access Approved!!',
      subtitle: 'Sign in Playbook and meet new member',
    },
    noToken: {
      title: 'Oops!',
      subtitle: 'Your token is invalid',
    },
    expiredToken: {
      title: 'Oops!',
      subtitle: 'Your token is expired.',
    },
    fail: {
      title: 'Oops!',
      subtitle:
        'Sorry, there was a problem processing your request. Please contact Playbook for assistance.',
    },
  };

  useEffect(() => {
    if (!token) {
      setApproveResult('noToken');
    } else {
      confirmToken(token);
    }
    document.body.classList.remove('warning');
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <AlignCenter>
        <Header>{headerMessage[approveResult]}</Header>
        <SignInButton
          onClick={() => {
            goToSignIn();
          }}
        >
          Go to Sign In
        </SignInButton>
      </AlignCenter>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const AlignCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  margin-bottom: 100px;
`;

const SignInButton = styled.button`
  display: block;
  width: 400px;
  height: 40px;
  margin-top: 0.5rem;
  ${({ theme }) => theme.btnShadow};
  color: ${({ theme }) => theme.pb.grey};
  cursor: pointer;
  &:hover {
    ${({ theme }) => theme.mdbluecard}
    box-shadow: 0px 3px 7px rgba(9, 16, 55, 0.25);
    color: #fff;
  }
  &:active {
    ${({ theme }) => theme.mdbluecard};
    color: #fff;
  }
`;
