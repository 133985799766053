import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Shopify = ({ setShowModal, setContent }) => {
  const { workspaceId } = useParams();

  const AuthLink = () => {
    setShowModal(true);
    setContent('shopify');
    window.localStorage.setItem('workspaceId', workspaceId);
  };
  return (
    <Wrapper>
      <TiktokButton id="Shopify" onClick={AuthLink}>
        <IntegrateText>Integrate</IntegrateText>
      </TiktokButton>
    </Wrapper>
  );
};

export default Shopify;

const Wrapper = styled.div``;

const TiktokButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.pb.cyanBlue};
  border-radius: 43px;
  padding: 8px 14px;
  background-color: white;
  cursor: pointer;
`;

const IntegrateText = styled.span`
  width: 56px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.pb.cyanBlue};
`;
