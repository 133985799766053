export default function deletePermissionFB({
  userID = undefined,
  accessToken = undefined,
  deleteScope,
  openCautionPopup,
  callbackFunction = () => {},
}) {
  // facebook API
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: process.env.REACT_APP_FACEBOOK_APP_ID,
      cookie: true,
      xfbml: true,
      version: 'v17.0',
    });
    window.FB.AppEvents.logPageView();
  };

  (function (d, s, id) {
    let js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');

  const deletePermission = ({
    userID,
    accessToken,
    deleteScope,
    openCautionPopup,
    callbackFunction,
  }) => {
    try {
      if (deleteScope === 'all') {
        window.FB.api(
          `/${userID}/permissions`,
          'DELETE',
          {
            access_token: `${accessToken}`,
          },
          function (response) {
            if (response.error) {
              console.error('FB de-authorize api error:: ', response.error);
              const message = `Error occured during delete FB permissions:: ${response.error.message}`;
              openCautionPopup({
                message,
                confirmOption: 'refresh',
                buttonName: 'Confirm',
              });
              if (response.error === 200) {
                // FB Permissions error
              } else if (response.error === 100) {
                // FB Invalid parameter
              } else if (response.error === 240) {
                // FB desktop app cannot call this function for other users
              } else if (response.error === 190) {
                // FB invalid access token
              } else {
                // something else from FB
              }
            }
          }
        );
      } else {
        for (let permission of deleteScope.split(',')) {
          window.FB.api(
            `/${userID}/permissions/${permission}`,
            'DELETE',
            {
              access_token: `${accessToken}`,
            },
            function (response) {
              if (response.error) {
                console.error('FB de-authorize api error:: ', response.error);
                const message = `Error occured during delete FB permissions:: ${response.error.message}`;
                openCautionPopup({
                  message,
                  confirmOption: 'refresh',
                  buttonName: 'Confirm',
                });
                if (response.error === 200) {
                  // FB Permissions error
                } else if (response.error === 100) {
                  // FB Invalid parameter
                } else if (response.error === 240) {
                  // FB desktop app cannot call this function for other users
                } else if (response.error === 190) {
                  // FB invalid access token
                } else {
                  // something else from FB
                }
              }
            }
          );
        }
      }
    } catch (err) {
      console.error('FB de-authorize api error:: ', err);
      const message = `Error occured during delete FB permissions:: ${err}`;
      openCautionPopup({
        message,
        confirmOption: 'refresh',
        buttonName: 'Confirm',
      });
    } finally {
      callbackFunction();
      return true;
    }
  };

  if (!userID && !accessToken) {
    window.FB.login(
      function (response) {
        if (response.status === 'connected') {
          const { userID, accessToken } = response.authResponse;
          deletePermission({
            userID,
            accessToken,
            deleteScope,
            openCautionPopup,
            callbackFunction,
          });
        } else if (response.status === 'unknown') {
          const message =
            'Please try again or complete Facebook login to disconnect channel.';
          openCautionPopup({
            message: message,
            confirmOption: 'refresh',
            buttonName: 'Confirm',
          });
        } else if (response.status === 'not_authorized') {
          const message =
            'Please complete Facebook login to disconnect channel.';
          openCautionPopup({
            message: message,
            confirmOption: 'refresh',
            buttonName: 'Confirm',
          });
        }
      },
      {
        auth_type: 'reauthenticate',
      }
    );
  } else {
    deletePermission({
      userID,
      accessToken,
      deleteScope,
      openCautionPopup,
      callbackFunction,
    });
  }
}
