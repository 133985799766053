export const changeDateFormatForSubmit = period => {
  let formattedDate = new Date(period);
  let month = formattedDate.getMonth() + 1;
  let date = formattedDate.getDate();

  return `${formattedDate.getFullYear()}-${month < 10 ? `0${month}` : month}-${
    date < 10 ? `0${date}` : date
  }`;
};

export const changeDateFormatForShow = ({ type = 'default', period }) => {
  let formattedDate;

  if (type === 'slash') {
    const parsedDate = period.split('T')[0];
    formattedDate = parsedDate.split('-').reverse().join('/');
  } else if (type === 'default') {
    const stringDate = new Date(period).toDateString();
    formattedDate = stringDate.slice(4, -5) + ',' + stringDate.slice(-5);
  }
  return formattedDate;
};

export const changeTimeFormatForShow = ({ type, time }) => {
  if (time) {
    if (type === 'withDate') {
      return `${time.split(/[T]/g)[0]} ${
        time.split(/[T]/g)[1].split(/[.]/g)[0]
      }`;
    } else if (type === 'withoutDate') {
      return time.split(/[T]/g)[1].split(/[.]/g)[0];
    }
  } else return;
};
