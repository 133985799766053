import React, { useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountContext } from '../../../../../contextApi/AccountContext';
import { APIs } from '../../../../../config';
import Select from 'react-select';
import styled from 'styled-components';
import { customStyles } from '../../../../../styles/ReactSelectStyle';

export default function GoogleAdsModal({
  integrationData,
  setIntegrationData,
  isSubmitClicked,
  setIsSubmitClicked,
  setIsFormReady,
  setShowModal,
  openCautionPopup,
}) {
  const { sessionJWT } = useContext(AccountContext);
  const { workspaceId } = useParams();

  const navigate = useNavigate();

  const isReadyToSubmit = () => {
    if (!integrationData?.every((data) => data.isPrimary === false)) {
      return true;
    } else return false;
  };

  const selectHandler = (e) => {
    setIntegrationData((prev) => [
      ...prev
        .filter((item) => item.value !== e.value)
        .map((data) => ({ ...data, isPrimary: false })),
      { value: e.value, label: e.label, isPrimary: true },
    ]);
  };

  const sortOptions = (list) => {
    const options = list?.filter((data) => !data.isPrimary);
    return options;
  };

  const findSelectedValue = (list) => {
    return list?.find((item) => item.isPrimary === true);
  };

  const clearState = () => {
    setIsFormReady((prev) => ({ ...prev, googleAds: false }));
    setIsSubmitClicked((prev) => ({ ...prev, googleAds: false }));
  };

  const handleSubmit = () => {
    if (isSubmitClicked) {
      if (isReadyToSubmit()) {
        let submitData = {
          category: 'paid',
          channelName: 'googleAds',
          accessToken: sessionStorage.getItem('accessToken'),
          refreshToken: sessionStorage.getItem('refreshToken'),
          customer: {
            id: findSelectedValue(integrationData).value,
            descriptiveName: findSelectedValue(integrationData).label,
          },
        };
        sendAdsData(submitData);
      } else {
        clearState();
      }
    } else return;
  };

  const sendAdsData = async (data) => {
    try {
      const request = await fetch(
        `${APIs('integration', workspaceId)}?channelName=googleAds&id=${
          data.customer.id
        }`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionJWT}`,
          },
          body: JSON.stringify(data),
        }
      );
      const response = await request.json();
      if (request && request.ok) {
        navigate('.', { state: null });
        window.location.reload(true);
      } else if (request && request.status === 400) {
        let message;
        if (response[0] === 'channel already integrated') {
          message = 'Google Ads is already integrated';
        } else {
          message =
            'Something went wrong and we could not process your request';
        }
        setShowModal(false);
        openCautionPopup({
          message: message,
          confirmOption: 'refresh',
          buttonName: 'Close',
        });
      }
    } catch (error) {
      setShowModal(false);
      let message =
        'Something went wrong and we could not process your request';
      openCautionPopup({
        message: message,
        confirmOption: 'refresh',
        buttonName: 'Close',
      });
      throw new Error('Issue with Google Ads Integration', error.message);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [isSubmitClicked]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SelectWrapper>
      <Header>Google Ads Account</Header>
      <Select
        name='account'
        options={sortOptions(integrationData)}
        onChange={(e) => selectHandler(e)}
        styles={customStyles}
        placeholder='Select Account'
      />
      {!isReadyToSubmit() && (
        <FormErrors>
          <Span>Choose your account</Span>
        </FormErrors>
      )}
    </SelectWrapper>
  );
}

const SelectWrapper = styled.div`
  margin-top: 50px;
`;

const Header = styled.div`
  margin-top: 30px;
  padding-bottom: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  color: #000f1f;
`;

const FormErrors = styled.div``;

const Span = styled.span`
  display: block;
  box-sizing: inherit;
  padding-top: 0.1875rem;
  padding-left: 1rem;
  margin-bottom: -1.25rem;
  font-size: 0.75rem;
  color: red;
`;
