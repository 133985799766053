import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { URL } from '../../../assets/data/SERVICE_LINKS';
import { ReactComponent as DashboardSVG } from '../../../assets/icons/Dashboard.svg';
import { ReactComponent as PlanSVG } from '../../../assets/icons/Plan.svg';
import { ReactComponent as ModelSVG } from '../../../assets/icons/Model.svg';
import { ReactComponent as FAQsSVG } from '../../../assets/icons/FAQs.svg';
import { ReactComponent as SettingsSVG } from '../../../assets/icons/SettingIcon.svg';

export default function MenuZone() {
  let { workspaceId, menu } = useParams();
  const navigate = useNavigate();

  const ICONS = [
    {
      component: <DashboardSVG />,
      padding: '3px',
      path: { type: 'playbook', url: 'dashboard' },
      selected: false,
    },
    {
      component: <ModelSVG />,
      padding: '3px',
      path: { type: 'playbook', url: 'model' },
      selected: false,
    },
    {
      component: <FAQsSVG />,
      path: {
        type: 'other',
        url: URL.faqs.EN,
      },
      selected: false,
    },
    {
      component: <SettingsSVG />,
      padding: '4px',
      path: { type: 'playbook', url: 'settings' },
      selected: false,
    },
  ];

  const [iconInfo, setIconInfo] = useState(ICONS);

  useEffect(() => {
    setIconInfo((prev) =>
      prev.map((icon) => {
        return menu === icon.path.url
          ? { ...icon, selected: true }
          : { ...icon, selected: false };
      })
    );
  }, [window.location, menu]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickHandler = ({ id, path }) => {
    if (path.type === 'playbook') {
      navigate(`/myworkspace/${workspaceId}/${path.url}`);
    } else {
      window.open(path.url, '_blank');
    }

    setIconInfo((prev) =>
      prev.map((icon) => {
        return id === icon.id
          ? { ...icon, selected: true }
          : { ...icon, selected: false };
      })
    );
  };

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  return (
    <Container>
      <TopIcons>
        {iconInfo.slice(0, 2).map((icon, idx) => (
          <SVGWrapper
            key={idx}
            onClick={() => onClickHandler({ id: idx, path: icon.path })}
            selected={icon.selected}
            padding={icon.padding}
            src={icon.imgSrc}
          >
            {icon.component}
          </SVGWrapper>
        ))}
      </TopIcons>
      <BottomIcons>
        {iconInfo.slice(2).map((icon, idx) => (
          <SVGWrapper
            key={idx}
            onClick={() => onClickHandler({ id: idx, path: icon.path })}
            selected={icon.selected}
            padding={icon.padding}
            src={icon.imgSrc}
          >
            {icon.component}
          </SVGWrapper>
        ))}
      </BottomIcons>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding: 36.5px 9px 24px 9px;
`;

const TopIcons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  gap: 37px;
`;

const SVGWrapper = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  width: 38px;
  height: 38px;
  padding: ${({ padding }) => padding || '7px'};

  fill: ${({ selected, theme }) => (selected ? theme.pb.cyanBlue : '#fff')};
  background-color: ${({ selected }) => (selected ? '#fff' : 'transparent')};

  &:hover {
    fill: ${({ theme }) => theme.pb.cyanBlue};
    background-color: #fff;
  }

  cursor: pointer;
  transition: all ease 0.3s;
`;

const BottomIcons = styled(TopIcons)`
  gap: 20px;
`;
