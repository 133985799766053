import { GetToken } from '../Components/GoogleGetToken';

export const GoogleAnalyticsApi = async ({
  setToken,
  setData,
  setIsLoading,
}) => {
  try {
    const token = await GetToken();

    const request = await fetch(
      'https://www.googleapis.com/analytics/v3/management/accountSummaries',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const response = await request.json();

    if (!response.error) {
      if (response.totalResults > 0) {
        let accounts = response.items.map(account => ({
          value: account.id,
          label: account.name,
          isPrimary: false,
          webProperties: account?.webProperties?.map(prop => ({
            accountId: account.id,
            value: prop.id,
            label: prop.name,
            isPrimary: false,
          })),
          profiles: account?.webProperties?.map(prop =>
            prop?.profiles?.map(profile => ({
              accountId: account?.id,
              propertyId: prop?.id,
              value: profile?.id,
              label: profile?.name,
              isPrimary: false,
            }))
          )?.[0],
        }));

        setToken(token);
        setData(accounts);
      } else {
        let message = 'User does not have any Google Analytics account.';
        setData({ errorMessage: message });
        setIsLoading(false);
      }
    } else if (
      response.error.code === 403 &&
      response.error.message ===
        'User does not have any Google Analytics account.'
    ) {
      setData({ errorMessage: response.error.message });
      setIsLoading(false);
    } else {
      let message =
        'Something went wrong and we could not process your request';
      setData({ errorMessage: message });
      setIsLoading(false);
    }
  } catch (error) {
    setData({ errorMessage: error.message });
    setIsLoading(false);
    throw new Error(
      'GoogleAnalyticsApi error getting analytics data',
      error.message
    );
  }
};
