import React, { useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountContext } from '../../../../../contextApi/AccountContext';
import { APIs } from '../../../../../config';
import Select from 'react-select';
import styled from 'styled-components';
import { customStyles } from '../../../../../styles/ReactSelectStyle';

export default function TiktokModal({
  integrationData,
  setIntegrationData,
  isSubmitClicked,
  setIsSubmitClicked,
  setIsFormReady,
  setShowModal,
  openCautionPopup,
}) {
  const { sessionJWT } = useContext(AccountContext);
  const { workspaceId } = useParams();

  const navigate = useNavigate();

  const isReadyToSubmit = () => {
    if (
      !integrationData?.accountData?.every((data) => data.isPrimary === false)
    ) {
      return true;
    } else return false;
  };

  const selectHandler = (e) => {
    setIntegrationData((prev) => ({
      ...prev,
      accountData: [
        ...prev.accountData
          .filter((item) => item.value !== e.value)
          .map((data) => ({ ...data, isPrimary: false })),
        { value: e.value, label: e.label, isPrimary: true },
      ],
    }));
  };

  const sortOptions = (list) => {
    const options = list?.filter((data) => !data.isPrimary);
    return options;
  };

  const findSelectedValue = (list) => {
    return list?.find((item) => item.isPrimary === true);
  };

  const clearState = () => {
    setIsFormReady((prev) => ({ ...prev, tiktokAds: false }));
    setIsSubmitClicked((prev) => ({ ...prev, tiktokAds: false }));
  };

  const handleSubmit = () => {
    if (isSubmitClicked) {
      if (isReadyToSubmit()) {
        let submitData = {
          category: 'paid',
          channelName: 'tiktokAds',
          accessToken: integrationData.accessToken,
          currency: integrationData.currencyData.find(
            (item) =>
              item.id === findSelectedValue(integrationData?.accountData).value
          ).currency,
          account: {
            id: String(findSelectedValue(integrationData?.accountData).value),
            name: findSelectedValue(integrationData?.accountData).label,
          },
        };
        sendAdsData(submitData);
      } else {
        clearState();
      }
    } else return;
  };

  const sendAdsData = async (data) => {
    if (workspaceId) {
      try {
        const request = await fetch(
          `${APIs('integration', workspaceId)}?channelName=tiktokAds&id=${
            data.account.id
          }`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionJWT}`,
            },
            body: JSON.stringify(data),
          }
        );
        const response = await request.json();
        if (request.ok) {
          navigate('.', { state: null });
          window.location.reload(true);
        } else if (request && request.status === 400) {
          let message;
          if (response[0] === 'channel already integrated') {
            message = 'TikTok is already integrated';
          } else {
            message =
              'Something went wrong and we could not process your request';
          }
          setShowModal(false);
          openCautionPopup({
            message: message,
            confirmOption: 'refresh',
            buttonName: 'Close',
          });
        }
      } catch (error) {
        setShowModal(false);
        let message =
          'Something went wrong and we could not process your request';
        openCautionPopup({
          message: message,
          confirmOption: 'refresh',
          buttonName: 'Close',
        });
        throw new Error('Issue with TikTok Integration', error);
      }
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [isSubmitClicked]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SelectWrapper>
      <Header>Tiktok Ads Account</Header>
      <Select
        name='account'
        options={sortOptions(integrationData?.accountData)}
        onChange={(e) => selectHandler(e)}
        styles={customStyles}
        placeholder='Select Account'
      />
      {!isReadyToSubmit() && (
        <FormErrors>
          <Span>Choose your account</Span>
        </FormErrors>
      )}
    </SelectWrapper>
  );
}

const SelectWrapper = styled.div`
  margin-top: 50px;
`;

const Header = styled.div`
  margin-top: 30px;
  padding-bottom: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  color: #000f1f;
`;

const FormErrors = styled.div``;

const Span = styled.span`
  display: block;
  box-sizing: inherit;
  padding-top: 0.1875rem;
  padding-left: 1rem;
  margin-bottom: -1.25rem;
  font-size: 0.75rem;
  color: red;
`;
