import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { AccountContext } from '../../../contextApi/AccountContext';
import { WorkspaceContext } from '../../../contextApi/WorkspaceContext';
import { APIs } from '../../../config';
import { changeDateFormatForSubmit } from '../../../hooks/changeDateFormat';
import PopupModal from '../../../components/PopupModal';

import PaidChannelAnalytics from './PaidChannelAnalytics';
import OrganicChannelAnalytics from './OrganicChannelAnalytics';
import CreativeAnalytics from './CreativeAnalytics';
import KpiAnalytics from './KpiAnalytics';
import ModelPerformance from './ModelPerformance';

export default function DashboardDetails() {
  const { sessionJWT } = useContext(AccountContext);
  const { workspaceId, dashboardName } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState({});
  const [showPopupModal, setShowPopupModal] = useState(false);
  const [requiredIntegration, setRequiredIntegration] = useState();
  const [hasRequired, setHasRequired] = useState(false);

  let THREE_MONTHS_AGO = new Date();
  THREE_MONTHS_AGO.setMonth(THREE_MONTHS_AGO.getMonth() - 1);
  const [selectedPeriod, setSelectedPeriod] = useState([
    THREE_MONTHS_AGO,
    new Date(),
  ]);
  const BaseUrl = `${APIs('dashboard', workspaceId)}`;

  const getDateLabels = (range) => {
    const dates = [];
    let tempDate = new Date(range[0]);
    while (tempDate <= range[1]) {
      dates.push(changeDateFormatForSubmit(new Date(tempDate)));
      tempDate.setDate(tempDate.getDate() + 1);
    }
    return dates;
  };

  const [labelDates, setLabelDates] = useState(
    getDateLabels([THREE_MONTHS_AGO, new Date()])
  );

  useEffect(() => {
    if (sessionJWT && workspaceId) {
      getIntegrationData(dashboardName);
    } else return;
  }, [sessionJWT, workspaceId, dashboardName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedPeriod.length === 2) {
      setLabelDates(getDateLabels(selectedPeriod));
    } else return;
  }, [selectedPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  const CHART_OPTIONS = () => {
    const options = {
      kpiAnalytics: [
        {
          type: 'kpiTotal',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, kpiTotal: data }));
          },
          url: `${BaseUrl}/charts?queryName=kpiTotal&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'kpiPrevTotal',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, kpiPrevTotal: data }));
          },
          url: `${BaseUrl}/chartsPrev?queryName=kpiTotal&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'totalAdSpends',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, totalAdSpends: data }));
          },
          url: `${BaseUrl}/charts?queryName=totalAdSpends&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'totalAdSpendsPrev',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, totalAdSpendsPrev: data }));
          },
          url: `${BaseUrl}/chartsPrev?queryName=totalAdSpends&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'avgTotalUser',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, avgTotalUser: data }));
          },
          url: `${BaseUrl}/charts?queryName=avgTotalUser&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'avgTotalUserPrev',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, avgTotalUserPrev: data }));
          },
          url: `${BaseUrl}/chartsPrev?queryName=avgTotalUser&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'avgNewUser',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, avgNewUser: data }));
          },
          url: `${BaseUrl}/charts?queryName=avgNewUser&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'avgNewUserPrev',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, avgNewUserPrev: data }));
          },
          url: `${BaseUrl}/chartsPrev?queryName=avgNewUser&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'sessions',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, sessions: data }));
          },
          url: `${BaseUrl}/charts?queryName=sessionTotal&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'sessionsPrev',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, sessionsPrev: data }));
          },
          url: `${BaseUrl}/chartsPrev?queryName=sessionTotal&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'revenueGA',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, revenueGA: data }));
          },
          url: `${BaseUrl}/charts?queryName=revenueGA&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'revenueGAPrev',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, revenueGAPrev: data }));
          },
          url: `${BaseUrl}/chartsPrev?queryName=revenueGA&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'revenueCafe',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, revenueCafe: data }));
          },
          url: `${BaseUrl}/charts?queryName=revenueCafe&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'revenueCafePrev',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, revenueCafePrev: data }));
          },
          url: `${BaseUrl}/chartsPrev?queryName=revenueCafe&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'salesGA',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, salesGA: data }));
          },
          url: `${BaseUrl}/charts?queryName=salesGA&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'salesGAPrev',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, salesGAPrev: data }));
          },
          url: `${BaseUrl}/chartsPrev?queryName=salesGA&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'salesCafe',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, salesCafe: data }));
          },
          url: `${BaseUrl}/charts?queryName=salesCafe&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'salesCafePrev',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, salesCafePrev: data }));
          },
          url: `${BaseUrl}/chartsPrev?queryName=salesCafe&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
      ],
      paidChannelAnalytics: [
        {
          type: 'totalAdSpends',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, totalAdSpends: data }));
          },
          url: `${BaseUrl}/charts?queryName=totalAdSpends&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'totalAdImpressions',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, totalAdImpressions: data }));
          },
          url: `${BaseUrl}/charts?queryName=totalAdImpressions&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'totalAdClicks',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, totalAdClicks: data }));
          },
          url: `${BaseUrl}/charts?queryName=totalAdClicks&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'cpm',
          setFunc: (data) => {
            if (data.length > 0 && data[0].cpm) {
              setChartData((prev) => ({
                ...prev,
                cpm: (data[0].cpm * 1000).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                }),
              }));
            }
          },
          url: `${BaseUrl}/charts?queryName=cpm&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'cpc',
          setFunc: (data) => {
            if (data.length > 0 && data[0].cpc) {
              setChartData((prev) => ({
                ...prev,
                cpc: data[0].cpc.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                }),
              }));
            }
          },
          url: `${BaseUrl}/charts?queryName=cpc&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'channels',
          setFunc: (data) => {
            setChartData((prev) => ({
              ...prev,
              channels: data,
            }));
          },
          url: `${BaseUrl}/charts?queryName=channels&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'campaigns',
          setFunc: (data) => {
            setChartData((prev) => ({
              ...prev,
              campaigns: data,
            }));
          },
          url: `${BaseUrl}/charts?queryName=campaigns&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'campaignImpressions',
          setFunc: (data) => {
            setChartData((prev) => ({
              ...prev,
              campaignImpressions: data,
            }));
          },
          url: `${BaseUrl}/charts/campaign?metric=impressions&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'campaignSpends',
          setFunc: (data) => {
            setChartData((prev) => ({
              ...prev,
              campaignSpends: data,
            }));
          },
          url: `${BaseUrl}/charts/campaign?metric=spend&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
      ],
      organicChannelAnalytics: [
        {
          type: 'totalMedia',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, totalMedia: data }));
          },
          url: `${BaseUrl}/charts?queryName=totalMedia&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'totalMediaImpressions',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, totalMediaImpressions: data }));
          },
          url: `${BaseUrl}/charts?queryName=totalMediaImpressions&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
        {
          type: 'totalMediaViews',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, totalMediaViews: data }));
          },
          url: `${BaseUrl}/charts?queryName=totalMediaViews&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
      ],
      modelPerformance: [],
      creativeAnalytics: [
        {
          type: 'creativePercentile',
          setFunc: (data) => {
            setChartData((prev) => ({ ...prev, totalMedia: data }));
          },
          url: `${BaseUrl}/charts?queryName=totalMedia&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
        },
      ],
    };
    return options[dashboardName];
  };

  const getIntegrationData = (dashboardName) => {
    const params = {
      paidChannelAnalytics: '/dataSources?category=paid',
      organicChannelAnalytics: '/dataSources?category=organic',
      creativeAnalytics: '/dataSources?category=paid',
      kpiAnalytics: '/dataSources?category=sot',
      modelPerformance: '/dataSources?category=sot',
    };
    const fetchIntegration = async () => {
      const request = await fetch(
        `${APIs('integration', workspaceId)}${params[dashboardName]}`,
        {
          headers: { Authorization: `Bearer ${sessionJWT}` },
        }
      );
      const data = await request.json();
      if (data.length > 0) {
        setRequiredIntegration(data);
        setHasRequired(true);
      } else return;

      const requestSot = await fetch(
        `${APIs('integration', workspaceId)}/dataSources?category=sot`,
        {
          headers: { Authorization: `Bearer ${sessionJWT}` },
        }
      );
      const sot = await requestSot.json();
      if (sot.length === 0) {
        setHasRequired(false);
      } else return;
    };
    fetchIntegration();
  };

  const getData = async ({ setFunc, url }) => {
    try {
      const request = await fetch(url, {
        headers: { Authorization: `Bearer ${sessionJWT}` },
      });
      const response = await request.json();

      if (request.ok) {
        setFunc(response);
      } else if (request.status !== 500) {
        openCautionPopup({ message: response.detail, confirmOption: 'stay' });
      } else {
        openCautionPopup({ confirmOption: 'refresh' });
      }
    } catch (err) {
      console.error(err);
      openCautionPopup({ confirmOption: 'refresh' });
    }
  };

  const openCautionPopup = ({ message, confirmOption, buttonName }) => {
    const confirmAction = () => {
      if (confirmOption === 'stay') {
        return () => {
          setShowPopupModal(false);
        };
      } else if (confirmOption === 'refresh') {
        return () => {
          window.location.reload(true);
        };
      } else {
        return confirmOption;
      }
    };

    PopupModal.closePopupModal = () => {
      setShowPopupModal(false);
    };
    PopupModal.popupMessage =
      message || 'Sorry, something went wrong. Please contact Playbook.';
    PopupModal.confirmBtnName = buttonName || 'Confirm';
    PopupModal.handleConfirmAction = confirmAction();

    setShowPopupModal(true);
  };

  useEffect(() => {
    if (sessionJWT && workspaceId && selectedPeriod.length === 2) {
      setIsLoading(true);
      const options = CHART_OPTIONS();
      options.forEach((option) => {
        getData({
          setFunc: option.setFunc,
          url: option.url,
        });
      });
      setIsLoading(false);
    } else return;
  }, [sessionJWT, workspaceId, dashboardName, selectedPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  if (dashboardName === 'paidChannelAnalytics') {
    return (
      <PaidChannelAnalytics
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        sessionJWT={sessionJWT}
        workspaceId={workspaceId}
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
        labelDates={labelDates}
        chartData={chartData}
        hasRequired={hasRequired}
        requiredIntegration={requiredIntegration}
      />
    );
  } else if (dashboardName === 'organicChannelAnalytics') {
    return (
      <OrganicChannelAnalytics
        isLoading={isLoading}
        hasRequired={hasRequired}
        sessionJWT={sessionJWT}
        workspaceId={workspaceId}
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
      />
    );
  } else if (dashboardName === 'creativeAnalytics') {
    return (
      <CreativeAnalytics
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        sessionJWT={sessionJWT}
        workspaceId={workspaceId}
        hasRequired={hasRequired}
      />
    );
  } else if (dashboardName === 'kpiAnalytics') {
    return (
      <KpiAnalytics
        isLoading={isLoading}
        hasRequired={hasRequired}
        sessionJWT={sessionJWT}
        chartData={chartData}
        workspaceId={workspaceId}
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
        labelDates={labelDates}
      />
    );
  } else if (dashboardName === 'modelPerformance') {
    return (
      <ModelPerformance
        hasRequired={hasRequired}
        sessionJWT={sessionJWT}
        workspaceId={workspaceId}
      />
    );
  } else return;
}
