import React, { useContext } from 'react';
import { LanguageContext } from '../../contextApi/LanguageContext';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { COPYRIGHT, TERMS_AND_POLYCY, ADDRESS } from './FOOTER_DATA';
import PanomixLogo from '../../assets/icons/logo/PanomixLogoWhite.svg';
import styled from 'styled-components';

export default function Footer() {
  const { language } = useContext(LanguageContext);
  const isUnder1440 = useMediaQuery({ minWidth: 980, maxWidth: 1440 });
  const isPadSize = useMediaQuery({ maxWidth: 979 });
  const isBelow425 = useMediaQuery({ maxWidth: 425 });
  const navigate = useNavigate();

  return (
    <Wrapper isPadSize={isPadSize}>
      <AlignBox isPadSize={isPadSize} isUnder1440={isUnder1440}>
        <Left isPadSize={isPadSize}>
          <Logo
            isPadSize={isPadSize}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              window.location.replace('https://panomix.io');
            }}
            src={PanomixLogo}
          />
          <LinkArea>
            {TERMS_AND_POLYCY.slice(0, isBelow425 ? 2 : 3).map(
              ({ name, link }, idx) => (
                <TermWrapper key={idx}>
                  <LinkButton
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      navigate(link);
                    }}
                  >
                    {name[language]}
                  </LinkButton>

                  {!isBelow425
                    ? idx < 2 && <span style={{ color: 'gray' }}>|</span>
                    : idx === 0 && <span style={{ color: 'gray' }}>|</span>}
                </TermWrapper>
              )
            )}
          </LinkArea>

          {isBelow425 && (
            <LinkButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                navigate(TERMS_AND_POLYCY[2].link);
              }}
            >
              {TERMS_AND_POLYCY[2].name[language]}
            </LinkButton>
          )}
        </Left>

        <Copyright isPadSize={isPadSize}>{COPYRIGHT[language]}</Copyright>
        <Address isPadSize={isPadSize}>{ADDRESS[language]}</Address>
      </AlignBox>
    </Wrapper>
  );
}
const Wrapper = styled.footer`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: ${({ isPadSize }) => (isPadSize ? 'auto' : '60px')};

  color: gray;
  background-color: #282c34;

  z-index: 5;
`;

const AlignBox = styled.div`
  display: flex;
  flex-direction: ${({ isPadSize }) => (isPadSize ? 'column' : 'row')};
  align-items: center;
  justify-content: ${({ isPadSize }) =>
    isPadSize ? 'center' : 'space-between'};

  width: 100%;
  max-width: 1300px;
  padding: ${({ isUnder1440, isPadSize }) =>
    isUnder1440 ? (isPadSize ? '0px' : '0px 120px') : '0px'};
`;

const Left = styled.div`
  display: flex;
  flex-direction: ${({ isPadSize }) => (isPadSize ? 'column' : 'row')};
  align-items: center;
  margin-top: ${({ isPadSize }) => (isPadSize ? '15px' : '0px')};
  margin-left: ${({ isPadSize }) => (isPadSize ? '0px' : '20px')};
`;

const Logo = styled.img`
  margin-right: ${({ isPadSize }) => (isPadSize ? '0px' : '40px')};
  margin-bottom: ${({ isPadSize }) => (isPadSize ? '10px' : '0px')};
  width: 100px;
  cursor: pointer;
`;

const LinkArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 12px;
`;

const TermWrapper = styled.div``;

const LinkButton = styled.button`
  color: gray;
  font-size: 12px;
  font-weight: 400;

  cursor: pointer;
`;

const Copyright = styled.span`
  color: gray;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
`;

const Address = styled.span`
  padding-bottom: ${({ isPadSize }) => (isPadSize ? '10px' : ' 0px')};

  color: gray;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
`;
