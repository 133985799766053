import React, { useState, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountContext } from '../../../../contextApi/AccountContext';
import Timezone from './Timezone';
import EditBtn from '../../../../components/EditBtn';
import PopupModal from '../../../../components/PopupModal';
import UserAvatar from '../../../../components/UserAvatar';
import { APIs } from '../../../../config';
import styled from 'styled-components';
import CurrencySelector from './CurrencySelector';

const Settings = ({ workspaceInfo }) => {
  const navigate = useNavigate();
  const { sessionJWT } = useContext(AccountContext);
  const { workspaceId } = useParams();

  const { name, icon, timezone, currency } = workspaceInfo;

  const [workspaceName, setWorkspaceName] = useState();

  const inputTextRef = useRef(null);

  const [uploadedImg, setUploadedImg] = useState();
  const inputFileRef = useRef(null);

  const [updatedTimezone, setUpdatedTimezone] = useState();
  const [updatedCurrency, setUpdatedCurrency] = useState();

  const [popupModalStatus, setPopupModalStatus] = useState(false);
  const closePopupModal = () => {
    setPopupModalStatus(false);
  };

  const openErrorPopup = (err) => {
    PopupModal.popupMessage = err || 'Sorry, something has gone wrong';
    PopupModal.confirmBtnName = 'Confirm';
    PopupModal.handleConfirmAction = err
      ? () => window.location.reload(true)
      : navigate('/signin');

    PopupModal.closePopupModal = err
      ? () => window.location.reload(true)
      : navigate('/signin');

    setPopupModalStatus(true);
  };

  const openChangeWrkSpcInfoPopup = (mode) => {
    PopupModal.popupMessage = `Are you sure you want to change workspace ${mode}?`;
    PopupModal.confirmBtnName = 'Confrim';
    PopupModal.handleConfirmAction = () => changeWrkSpcInfo(mode);
    PopupModal.closePopupModal = () => setPopupModalStatus(false);
    setPopupModalStatus(true);
  };

  const changeWrkSpcInfo = (mode) => {
    let formData = new FormData();
    formData.set('icon', uploadedImg);

    let modeList = {
      name: {
        data: { name: workspaceName },
      },
      icon: {
        dataType: 'file',
        data: formData,
      },
      timezone: {
        data: { timezone: updatedTimezone?.value },
      },
      currency: {
        data: { currency: updatedCurrency?.value },
      },
    };

    if (sessionJWT && workspaceId) {
      fetch(`${APIs('workspace', workspaceId)}`, {
        method: 'PATCH',
        headers:
          modeList[mode].dataType === 'file'
            ? {
                Authorization: `Bearer ${sessionJWT}`,
              }
            : {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionJWT}`,
              },
        body:
          modeList[mode].dataType === 'file'
            ? modeList[mode]?.data
            : JSON.stringify(modeList[mode]?.data),
      })
        .then((res) => {
          if (res.ok) {
            window.location.reload(true);
          } else if (res.status !== 500) {
            res.json().then((res) => {
              closePopupModal();
              openErrorPopup(res.detail || res.name);
            });
          } else {
            openErrorPopup();
          }
        })
        .catch((err) => console.error(err));
    } else return;
  };

  return (
    <>
      <Container>
        <LeftArea>
          <InfoTitle>
            <Title>Workspace</Title>
          </InfoTitle>
          <Line />
          <BoxForAlignContent>
            <NameArea>
              <SubTitle>Workspace Name</SubTitle>
              <InputText
                type='text'
                name='workspaceName'
                value={workspaceName || ''}
                onChange={(e) => setWorkspaceName(e.target.value)}
                placeholder={name}
                ref={inputTextRef}
              />
            </NameArea>
            {workspaceName?.length > 0 && workspaceName !== name ? (
              <SubmitBtn onClick={() => openChangeWrkSpcInfoPopup('name')}>
                Update
              </SubmitBtn>
            ) : (
              <EditBtn onClickHandler={() => inputTextRef.current.focus()} />
            )}
          </BoxForAlignContent>
          <ImgBox>
            <EditImgWrapper>
              <ImgUploader
                type='file'
                id='workspaceIconUploader'
                accept='image/*'
                ref={inputFileRef}
                onChange={(e) => {
                  setUploadedImg(e.target.files[0]);
                }}
              />
              <AlignCenterBox>
                <SubTitle>Photo</SubTitle>
                {uploadedImg ? (
                  <SubmitBtn onClick={() => openChangeWrkSpcInfoPopup('icon')}>
                    Update
                  </SubmitBtn>
                ) : (
                  <EditBtn
                    onClickHandler={() => inputFileRef.current.click()}
                  />
                )}
              </AlignCenterBox>
              <label htmlFor='workspaceIconUploader'>
                <WrapperForSpace>
                  <UserAvatar
                    type='workspace'
                    profileImage={
                      uploadedImg ? URL.createObjectURL(uploadedImg) : icon
                    }
                    email={name}
                  />
                </WrapperForSpace>
              </label>
            </EditImgWrapper>
          </ImgBox>
        </LeftArea>

        <RightArea>
          <Option>
            <div>
              <Title>Workspace Timezone</Title>
              <Description>Choose timezone to fit your schedule</Description>
            </div>
            <AlignCenterBox>
              <Timezone
                defaultTimezone={timezone}
                updatedTimezone={updatedTimezone}
                setTimezone={setUpdatedTimezone}
              />
              <Button
                disabled={
                  !(
                    updatedTimezone?.value && updatedTimezone.value !== timezone
                  )
                }
                onClick={() => openChangeWrkSpcInfoPopup('timezone')}
              >
                Update
              </Button>
            </AlignCenterBox>
          </Option>

          <Option>
            <div>
              <Title>Workspace Currency</Title>
              <Description>Choose default currency for display.</Description>
            </div>
            <AlignCenterBox>
              <CurrencySelector
                defaultCurrency={currency}
                updatedCurrency={updatedCurrency}
                setCurrency={setUpdatedCurrency}
              />
              <Button
                disabled={
                  !(
                    updatedCurrency?.value && updatedCurrency.value !== currency
                  )
                }
                onClick={() => openChangeWrkSpcInfoPopup('currency')}
              >
                Update
              </Button>
            </AlignCenterBox>
          </Option>
        </RightArea>
      </Container>
      <PopupModal
        popupModalStatus={popupModalStatus}
        popupMessage={PopupModal.popupMessage}
        confirmBtnName={PopupModal.confirmBtnName}
        handleConfirmAction={PopupModal.handleConfirmAction}
        closePopupModal={PopupModal.closePopupModal}
      />
    </>
  );
};

export default Settings;

const Container = styled.section`
  display: flex;
  justify-content: center;
  width: 84vw;
  height: 16.3rem;
  margin-bottom: 4px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const LeftArea = styled.div`
  width: 45%;
  min-width: 320px;
  padding: 1.5rem;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
`;

const InfoTitle = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  margin-right: 1.5rem;
  font-size: 16px;
  font-weight: 700;
`;

const Line = styled.div`
  width: 100%;
  margin: 16px 0;
  border-bottom: 1px solid #eeeeee;
`;

const BoxForAlignContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubTitle = styled.span`
  margin-right: 1rem;
  color: #666666;
  font-size: 16px;
`;

const NameArea = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
`;

const InputText = styled.input`
  width: 60%;
  color: ${({ theme }) => theme.pb.midBlue};
  font-size: 16px;
  text-overflow: ellipsis;

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.pb.midBlue};

    &::placeholder {
      color: #b6b6b6;
    }
  }

  &::placeholder {
    color: black;
  }
`;

const EditImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const ImgUploader = styled.input`
  display: none;
`;

const SubmitBtn = styled.button`
  display: flex;
  align-items: center;
  padding: 2px 6px;
  border: 1px solid ${({ theme }) => theme.pb.midBlue};
  border-radius: 10px;
  background: ${({ theme }) => theme.pb.midBlue};
  color: white;
  font-size: 10px;

  text-align: center;
  cursor: pointer;
`;

const WrapperForSpace = styled.div`
  margin-top: 0.7rem;
`;

const ImgBox = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-top: 1rem;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 14px;
  border: 1px solid ${({ theme }) => theme.pb.cyanBlue};
  border-radius: 42px;
  background: ${({ theme }) => theme.pb.cyanBlue};
  box-shadow: 0px 1px 2px 0px #00000040;
  color: white;
  font-size: 13.5px;
  text-align: center;
  cursor: pointer;

  &:disabled {
    border: 1px solid ${({ theme }) => theme.pb.lightGray};
    background: ${({ theme }) => theme.pb.lightGray};
    color: gray;
    cursor: default;
  }
`;

const Description = styled.span`
  color: #666666;
  font-size: 12px;
`;

const RightArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
`;

const Option = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 49%;
  padding: 1.5rem;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
`;

const AlignCenterBox = styled.div`
  display: flex;
  align-items: center;
`;
