import React from 'react';
import styled from 'styled-components';
import InvitationsCard from './InvitationsCard';

export default function PendingInvitations({
  invitedWorkspaces,
  openCautionPopup,
}) {
  return (
    <PendingInvitationsBox>
      <InvitationsTitleBox>
        <InvitationsTitle>Pending Invitations</InvitationsTitle>
      </InvitationsTitleBox>
      {invitedWorkspaces.map((info, idx) => {
        return (
          <InvitationsCard
            key={idx}
            info={info}
            openCautionPopup={openCautionPopup}
          />
        );
      })}
    </PendingInvitationsBox>
  );
}

const PendingInvitationsBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px #09103766;
  border: 0.5px solid #f5f5f5;
`;
const InvitationsTitleBox = styled.div`
  display: flex;
  align-items: center;
  width: 596px;
  height: 60px;
  padding: 25px 0 25px 30px;
`;

const InvitationsTitle = styled.h3`
  font-size: 18px;
`;
