import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Scatter } from 'react-chartjs-2';
import 'chart.js/auto';
import theme from '../../../../styles/theme';
import KPISelector from '../Components/KPISelector';
import { ReactComponent as FilterSVG } from '../../../../icons/Filter.svg';
import CampaignFilter from '../Components/CampaignFilter';

export default function CampaignPerfScatter(props) {
  const { kpiScatter, setKpiScatter, kpiOptions, campaignXyr, setCampaignXyr } =
    props;
  const [campaignColors, setCampaignColors] = useState();
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    if (campaignXyr && campaignColors === undefined) {
      const backgroundColors = [];
      for (let i = 0; i < campaignXyr.length; i++) {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        backgroundColors.push('rgba(' + r + ', ' + g + ', ' + b + ', 0.6)');
      }
      setCampaignColors(backgroundColors);
    } else return;
  }, [campaignColors, campaignXyr]); // eslint-disable-line react-hooks/exhaustive-deps

  const data = {
    datasets: [
      {
        label: 'Campaign',
        data: campaignXyr,
        pointStyle: 'circle',
        backgroundColor: campaignColors,
        borderColor: campaignColors,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          color: theme.pb.realBlack,
          font: {
            size: 14,
            family: 'Arial',
          },
        },
        title: {
          display: true,
          align: 'center',
          text: 'CPA',
          color: 'black',
          font: { size: 16, weight: 400, family: 'Arial' },
          padding: { bottom: 14 },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: theme.pb.realBlack,
          font: {
            size: 14,
            family: 'Arial',
          },
        },
        title: {
          display: true,
          align: 'center',
          text: 'Contribution',
          color: 'black',
          font: { size: 16, weight: 400, family: 'Arial' },
          padding: { bottom: 14 },
        },
      },
    },
    elements: {
      point: {
        radius: 10,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${
              context.raw.name
            } | Total Contribution: ${context.raw.y.toLocaleString()} | CPA: ${context.raw.x.toLocaleString()}`;
          },
        },
      },
    },
  };
  const handleChoiceClick = (choice) => {
    setKpiScatter(choice);
  };
  if (campaignXyr && campaignColors) {
    return (
      <>
        <Wrapper>
          <FilterWrapper>
            <FilterSVG
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpenFilter(true);
              }}
            />
          </FilterWrapper>
          <ModelTitle>Campaign Performance Scatter</ModelTitle>
          <KPISelector
            defaultValue={kpiScatter.sk}
            options={kpiOptions}
            onClickHandler={handleChoiceClick}
          />
          <Scatter options={options} data={data} />
        </Wrapper>
        {openFilter && (
          <CampaignFilter
            setOpenFilter={setOpenFilter}
            campaignXyr={campaignXyr}
            setCampaignXyr={setCampaignXyr}
          />
        )}
      </>
    );
  } else return null;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px;
  height: auto;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  transition: all ease 0.7s;
  ${({ theme }) => theme.animation.fadeInBottom}
  @media (max-width: 800px) {
    margin-left: 20px;
  }
`;

const FilterWrapper = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
`;

const ModelTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.pb.black};
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  font-family: 'Arial';
`;
