import React from 'react';
import SimulationBasicInfo from './SimulationBasicInfo';
import SimulationKPIInfo from './SimulationKPIInfo';
import styled from 'styled-components';

export default function SimulationCard({ result }) {
  return (
    <SimulationContainer>
      <SimulationNameArea>
        <SimulationName>Simulated Results</SimulationName>
      </SimulationNameArea>
      <SimulationInformation>
        <SimulationBasicInfo result={result} basicAreaWidth={20} />
        <SimulationKPIInfo kpis={result.kpis} />
      </SimulationInformation>
    </SimulationContainer>
  );
}

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 260px;

  padding: 0px 6px 4px 6px;
`;

const SimulationContainer = styled.div`
  ${({ theme }) => theme.whcard}
  ${({ theme }) => theme.animation.fadeInBottom}
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.3rem;
  background: '#fff';
  border-radius: 8px;
  transition: width 0.7s ease;
  margin-left: 10px;
  margin-right: 10px;
`;

const SimulationNameArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const SimulationName = styled.div`
  margin-right: 0.2rem;
  max-width: 230px;

  color: #000f1f;
  font-weight: 600;
  font-size: 1rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SimulationInformation = styled.div`
  display: flex;
  justify-content: start;
  align-items: flex-start;
`;
