import React, { useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { capitalizeChar } from '../../../../hooks/textFormatter';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ChannelSelect(props) {
  const { inputLabel, optionKey, selectOptions, setSimulationOptions } = props;
  const [options, setOptions] = useState(selectOptions);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setOptions(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    setSimulationOptions((prev) => ({ ...prev, [optionKey]: options }));
  };

  return (
    <div>
      <FormControl sx={{ width: 220 }}>
        <InputLabel id='demo-multiple-checkbox-label'>{inputLabel}</InputLabel>
        <Select
          labelId='demo-multiple-checkbox-label'
          id='demo-multiple-checkbox'
          multiple
          value={options}
          onChange={handleChange}
          input={<OutlinedInput label={inputLabel} />}
          renderValue={(selected) => {
            const numSelected = selected.length;
            return <div>{numSelected} selected</div>;
          }}
          MenuProps={MenuProps}
        >
          {selectOptions.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={options.indexOf(name) > -1} />
              <ListItemText primary={capitalizeChar(name)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
