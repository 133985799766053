import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { capitalizeChar } from '../../../../hooks/textFormatter';
export default function StackedAreaChart({
  title,
  labels,
  models,
  xAxisKey,
  yAxisKey,
}) {
  const [timeSeries, setTimeSeries] = useState([]);

  const getTimeSeries = () => {
    const timeSeriesData = [];
    for (let model of models) {
      timeSeriesData.push({
        label: model.sk,
        data: model.timeSeriesData,
        fill: true,
        borderWidth: 1,
        pointRadius: 2,
      });
    }
    setTimeSeries(timeSeriesData);
  };

  useEffect(() => {
    getTimeSeries();
  }, [models]); // eslint-disable-line react-hooks/exhaustive-deps

  const data = {
    labels: labels,
    datasets: timeSeries,
  };
  const options = {
    elements: {
      line: {
        tension: 0.1,
        borderWidth: 2,
      },
    },
    parsing: {
      xAxisKey: xAxisKey,
      yAxisKey: yAxisKey,
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          font: {
            size: 12,
            family: 'Arial',
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: 'Arial',
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          font: {
            size: 12,
            weight: 400,
            family: 'Arial',
          },
        },
      },
    },
  };

  return (
    <Wrapper>
      <TitleArea>{capitalizeChar(title)}</TitleArea>
      <Line options={options} data={data} />
    </Wrapper>
  );
}

const TitleArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.pb.black};
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  font-family: 'Arial';
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px;
  height: auto;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  transition: all ease 0.7s;
  ${({ theme }) => theme.animation.fadeInBottom}
`;
