import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { APIs } from '../../../config';
import { WorkspaceContext } from '../../../contextApi/WorkspaceContext';
import { ReactComponent as IntegrationSVG } from '../../../icons/Integrations.svg';
import { styled as MuiStyled } from '@mui/material/styles';
import { Button } from '@mui/material';

import PopupModal from '../../../components/PopupModal';
import LoadingPage from '../../../components/LoadingPage';
import Performance from '../ModelDetails/Charts/Performance';
import ModelOverallTable from './Charts/ModelOverallTable';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

export default function ModelPerformance(props) {
  const { hasRequired, sessionJWT, workspaceId } = props;

  // Get organic channel list if empty set empty page to connect organic channels
  // if media is not found empty media page?

  const { workspaceData, getWorkspaceData } = useContext(WorkspaceContext);
  const [showPopupModal, setShowPopupModal] = useState(false);
  const [kpis, setKpis] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const openCautionPopup = () => {
    const goToPrev = () => navigate(`/myworkspace/${workspaceId}/dashboard`);
    PopupModal.closePopupModal = () => goToPrev();
    PopupModal.popupMessage =
      'Your dashboard is currently locked. Please contact us.';
    PopupModal.handleConfirmAction = () => goToPrev();
    setShowPopupModal(true);
  };

  useEffect(() => {
    if (workspaceData.feature && workspaceData.feature.dashboards) {
      if (workspaceData.feature.dashboards.modelPerformance) {
        openCautionPopup();
      }
    }
  }, [workspaceData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sessionJWT && workspaceId) {
      getWorkspaceData('feature');
    }
  }, [sessionJWT, workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sessionJWT && workspaceId) {
      setIsLoading(true);
      getWorkspaceData('feature');
      const fetchModels = async () => {
        const request = await fetch(`${APIs('model', workspaceId)}/mmm/kpi`, {
          headers: { Authorization: `Bearer ${sessionJWT}` },
        });
        const data = await request.json();
        if (data.length > 0) {
          setKpis(data);
        } else return;
      };
      fetchModels();
      setIsLoading(false);
    }
  }, [sessionJWT, workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(kpis);
  }, [kpis]);

  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title
  );

  if (hasRequired) {
    return (
      <>
        {isLoading ? (
          <LoadingPage />
        ) : (
          <>
            <Container>
              {kpis &&
                kpis.length > 0 &&
                kpis.map((kpi, idx) => (
                  <ChartArea key={idx}>
                    <Performance
                      isLoading={isLoading}
                      modelPerformance={kpi?.predictedVsActual || {}}
                      kpiName={kpi?.kpi?.displayName}
                      Line={Line}
                    />
                    <ModelOverallTable
                      title={kpi.kpi.sourceName}
                      models={kpi.models}
                    />
                  </ChartArea>
                ))}
            </Container>
            <PopupModal
              popupModalStatus={showPopupModal}
              popupMessage={PopupModal.popupMessage}
              confirmBtnName={PopupModal.confirmBtnName}
              handleConfirmAction={PopupModal.handleConfirmAction}
              extraBtnName={PopupModal.extraBtnName}
              handleExtraAction={PopupModal.handleExtraAction}
              closePopupModal={PopupModal.closePopupModal}
            />
          </>
        )}
      </>
    );
  } else
    return (
      <EmptyWrapper>
        <EmptyTitle>🤖 Hello!</EmptyTitle>
        <EmptyDescription>
          You need to connect at least one analytics channel for this dashboard.
        </EmptyDescription>
        <CustomButton
          key='AddDataSource'
          variant='contained'
          startIcon={<IntegrationSVG />}
          disableRipple
          onClick={() =>
            navigate(`/myworkspace/${workspaceId}/integration/connect`)
          }
        >
          Add Data Source
        </CustomButton>
      </EmptyWrapper>
    );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 260px; /* Sidebar width */
  margin-top: 20px;
  margin-bottom: 80px;
`;
const ChartArea = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4px;
  margin-top: 4px;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 1600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const EmptyWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: calc(100% - 260px);
  left: 260px;
`;
const EmptyTitle = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.cyanBlue};
  font-size: 24px;
  font-family: 'Arial';
  padding: 5px;
`;

const EmptyDescription = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.realBlack};
  font-size: 14px;
  padding: 5px;
  width: 340px;
  line-height: 19px;
  font-family: 'Arial';
  text-align: center;
`;

const CustomButton = MuiStyled(Button)({
  height: '40px',
  width: '180px',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  padding: '6px 12px',
  borderRadius: '8px',
  backgroundColor: '#1A1F27',
  lineHeight: 1.5,
  fontFamily: 'Arial',
  outline: 'none',
  color: 'white',
  '&:hover': {
    backgroundColor: '#242B35',
    boxShadow: 'none',
  },
  cursor: 'pointer',
});
