import React, { useRef, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    PopperProps={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -10],
          },
        },
      ],
    }}
  />
))(() => ({
  [`& .MuiTooltip-tooltip`]: {
    padding: '5px 8px 8px 8px',
    borderRadius: '4px',
    boxShadow: '0px 1px 2px 0px #00000040',
    backgroundColor: '#f5f5f5',
    color: '#666666',
  },
}));

export default function OverflowTip({ data }) {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();

  useEffect(() => {
    setIsOverflow(
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth
    );
  }, [data]);

  return (
    <StyledTooltip title={data} disableHoverListener={!isOverflowed}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {data}
      </div>
    </StyledTooltip>
  );
}
