import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AccountContext } from '../../../../../contextApi/AccountContext';

export default function Cafe24Modal({
  isSubmitClicked,
  setIsSubmitClicked,
  setShowModal,
  openCautionPopup,
}) {
  const [mallId, setMallId] = useState('');
  const { workspaceId } = useParams();
  const { sessionJWT, getSession } = useContext(AccountContext);

  const {
    REACT_APP_CAFE24_CLIENT_ID,
    REACT_APP_CAFE24_REDIRECT_URI,
    REACT_APP_CAFE24_SCOPES,
  } = process.env;

  const clearState = () => {
    setIsSubmitClicked((prev) => ({ ...prev, cafe24: false }));
  };

  const handleSubmit = () => {
    if (isSubmitClicked) {
      if (mallId !== '') {
        const authRequestURL = `https://${mallId}.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=${REACT_APP_CAFE24_CLIENT_ID}&redirect_uri=${REACT_APP_CAFE24_REDIRECT_URI}&scope=${REACT_APP_CAFE24_SCOPES}&state=${mallId}`;
        window.location.assign(authRequestURL);
        setShowModal(false);
        window.localStorage.setItem('workspaceId', workspaceId);
        window.localStorage.setItem('mallId', mallId);
        window.localStorage.setItem('sessionJWT', sessionJWT);
      } else {
        clearState();
        openCautionPopup({
          message: 'Mall Id cannot be empty',
          confirmOption: 'refresh',
          buttonName: 'Close',
        });
      }
    }
  };
  useEffect(() => {
    getSession();
    if (sessionJWT) {
      handleSubmit();
    }
  }, [isSubmitClicked]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(mallId);
  }, [mallId]);

  return (
    <ModalContent>
      <ModalTitle>Cafe24 Mall ID</ModalTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
      >
        <InputWrapper>
          <InputLabel htmlFor='accountId'>Mall ID*</InputLabel>
          <InputField
            type='text'
            id='accountId'
            value={mallId}
            onChange={(event) => setMallId(event.target.value)}
            required
          />
        </InputWrapper>
      </form>
    </ModalContent>
  );
}

const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 24px;
`;

const ModalTitle = styled.div`
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: 'Arial';
`;

const InputWrapper = styled.div`
  margin-bottom: 16px;
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 8px;
`;

const InputField = styled.input`
  display: block;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 8px;
  font-family: 'Arial';
`;
