import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { APIs } from '../../../config';
import { WorkspaceContext } from '../../../contextApi/WorkspaceContext';
import { ReactComponent as IntegrationSVG } from '../../../icons/Integrations.svg';
import { styled as MuiStyled } from '@mui/material/styles';
import { Link, Button } from '@mui/material';

import LoadingPage from '../../../components/LoadingPage';
import PopupModal from '../../../components/PopupModal';
import DateSelect from '../../../components/InputComponents/DateSelect';

import { changeDateFormatForSubmit } from '../../../hooks/changeDateFormat';

import { capitalizeChar } from '../../../hooks/textFormatter';
import { Descriptions } from 'antd';
import BarChart from './Charts/BarChart';
import RadarChart from './Charts/RadarChart';

export default function OrganicChannelAnalytics(props) {
  const {
    isLoading,
    hasRequired,
    sessionJWT,
    workspaceId,
    selectedPeriod,
    setSelectedPeriod,
  } = props;

  // Get organic channel list if empty set empty page to connect organic channels
  // if media is not found empty media page?

  const { workspaceData, getWorkspaceData } = useContext(WorkspaceContext);
  const [showPopupModal, setShowPopupModal] = useState(false);
  const [media, setMedia] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedMediaModels, setSelectedMediaModels] = useState(null);

  const navigate = useNavigate();

  const openCautionPopup = () => {
    const goToPrev = () => navigate(`/myworkspace/${workspaceId}/dashboard`);
    PopupModal.closePopupModal = () => goToPrev();
    PopupModal.popupMessage =
      'Your dashboard is currently locked. Please contact us.';
    PopupModal.handleConfirmAction = () => goToPrev();
    setShowPopupModal(true);
  };

  const Post = ({ content, idx }) => {
    const [error, setError] = useState(false);

    const handleImageError = () => {
      setError(true);
    };

    if (!content.thumbnail || error) {
      // Skip rendering the post if media_url is missing or there's an error
      return null;
    }
    return (
      <div style={{ width: '140px', height: '140px' }}>
        <ThumbnailImage
          src={content.thumbnail}
          alt='Media Thumnail'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleItemClick(idx);
          }}
          onError={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleImageError();
          }}
        />
      </div>
    );
  };

  const handleItemClick = (index) => {
    setMedia((prevMedia) => {
      const updatedMedia = prevMedia.map((item, i) => {
        if (i === index) {
          return { ...item, selected: true };
        } else {
          return { ...item, selected: false };
        }
      });
      setSelectedMedia(updatedMedia[index]); // update selectedMedia to the clicked item
      return updatedMedia;
    });
  };

  useEffect(() => {
    if (workspaceData.feature && workspaceData.feature.dashboards) {
      if (workspaceData.feature.dashboards.organicChannelAnalytics) {
        openCautionPopup();
      }
    }
  }, [workspaceData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sessionJWT && workspaceId) {
      getWorkspaceData('feature');
    }
  }, [sessionJWT, workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sessionJWT && workspaceId && selectedPeriod.length === 2) {
      const fetchMedia = async () => {
        const request = await fetch(
          `${APIs(
            'dashboard',
            workspaceId
          )}/charts?queryName=organicMedia&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
          {
            headers: { Authorization: `Bearer ${sessionJWT}` },
          }
        );
        const data = await request.json();
        if (data.length > 0) {
          const updatedMedia = data.map((item, index) => {
            if (index === 0) {
              return { ...item, selected: true };
            } else {
              return { ...item, selected: false };
            }
          });
          setMedia(updatedMedia);
          setSelectedMedia(updatedMedia[0]); // set selectedMedia to the first item
        } else {
          return;
        }
      };
      fetchMedia();
    }
  }, [sessionJWT, workspaceId, selectedPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedMedia && selectedMedia.id) {
      const fetchData = async () => {
        const request = await fetch(
          `${APIs('dashboard', workspaceId)}/predictions/${
            selectedMedia.channelName
          }/MEDIA/${selectedMedia.id}`,
          {
            headers: { Authorization: `Bearer ${sessionJWT}` },
          }
        );
        const data = await request.json();
        if (data.length > 0) {
          setSelectedMediaModels(data);
        } else {
          setSelectedMediaModels(null);
        }
      };
      fetchData();
    }
  }, [selectedMedia]); // eslint-disable-line react-hooks/exhaustive-deps

  if (hasRequired) {
    return (
      <>
        <DateSelectWrapper>
          <DateSelect
            dateRange={selectedPeriod}
            setDateRange={setSelectedPeriod}
            type='dashboard'
          />
        </DateSelectWrapper>
        {isLoading ? (
          <LoadingPage />
        ) : (
          media && (
            <Container>
              <PostWrapper>
                <PostContainer>
                  {media.map((content, idx) => (
                    <Post key={content.id} content={content} idx={idx} />
                  ))}
                </PostContainer>
              </PostWrapper>
              <MediaInfoWrapper>
                {selectedMedia && (
                  <Descriptions title='Media Info'>
                    <Descriptions.Item label='Channel'>
                      {selectedMedia.channelName}
                    </Descriptions.Item>

                    {selectedMedia.impressions && (
                      <Descriptions.Item label='Impressions'>
                        {selectedMedia.impressions.toLocaleString()}
                      </Descriptions.Item>
                    )}
                    {selectedMedia.views && (
                      <Descriptions.Item label='Video Views'>
                        {selectedMedia.views.toLocaleString()}
                      </Descriptions.Item>
                    )}
                    {selectedMedia.engagements && (
                      <Descriptions.Item label='Engagements'>
                        {selectedMedia.engagements.toLocaleString()}
                      </Descriptions.Item>
                    )}
                    {selectedMedia.saved && (
                      <Descriptions.Item label='Saved'>
                        {selectedMedia.saved.toLocaleString()}
                      </Descriptions.Item>
                    )}
                    {selectedMedia.likes && (
                      <Descriptions.Item label='Likes'>
                        {selectedMedia.likes.toLocaleString()}
                      </Descriptions.Item>
                    )}
                    {selectedMedia.subscribersGained && (
                      <Descriptions.Item label='Subscribers Gained'>
                        {selectedMedia.subscribersGained.toLocaleString()}
                      </Descriptions.Item>
                    )}
                    {selectedMedia.subscribersLost && (
                      <Descriptions.Item label='Subscribers Gained'>
                        {selectedMedia.subscribersLost.toLocaleString()}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label='Published'>
                      {selectedMedia.publishedAt}
                    </Descriptions.Item>
                    <Descriptions.Item label='Link'>
                      <Link
                        href={selectedMedia.permalink}
                        target='_blank'
                        color='#0B8FFC'
                      >
                        Go to content
                      </Link>
                    </Descriptions.Item>
                  </Descriptions>
                )}
              </MediaInfoWrapper>
              <MediaContainer>
                <Element>
                  {selectedMediaModels ? (
                    <BarChart
                      title='Media KPI Contributions'
                      labels={selectedMediaModels.map((row) =>
                        capitalizeChar(row.kpiName)
                      )}
                      apiData={selectedMediaModels.map(
                        (row) => row.totalContribution
                      )}
                    />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        position: 'relative',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      🤖 This media has no models :(
                    </div>
                  )}
                </Element>
                <Element>
                  {selectedMediaModels ? (
                    <RadarChart
                      title='Media KPI Percentile'
                      labels={selectedMediaModels.map((row) =>
                        capitalizeChar(row.kpiName)
                      )}
                      apiData={selectedMediaModels.map(
                        (row) => row.radarKPI * 100
                      )}
                      color='rgba(255, 99, 132, 0.4)'
                    />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        position: 'relative',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      🤖 This media has no models :(
                    </div>
                  )}
                </Element>
              </MediaContainer>
            </Container>
          )
        )}
        <PopupModal
          popupModalStatus={showPopupModal}
          popupMessage={PopupModal.popupMessage}
          confirmBtnName={PopupModal.confirmBtnName}
          handleConfirmAction={PopupModal.handleConfirmAction}
          extraBtnName={PopupModal.extraBtnName}
          handleExtraAction={PopupModal.handleExtraAction}
          closePopupModal={PopupModal.closePopupModal}
        />
      </>
    );
  } else
    return (
      <EmptyWrapper>
        <EmptyTitle>🤖 Hello!</EmptyTitle>
        <EmptyDescription>
          You need to connect at least one organic channel & one analytics
          channel for this dashboard.
        </EmptyDescription>
        <CustomButton
          key='AddDataSource'
          variant='contained'
          startIcon={<IntegrationSVG />}
          disableRipple
          onClick={() =>
            navigate(`/myworkspace/${workspaceId}/integration/connect`)
          }
        >
          Add Data Source
        </CustomButton>
      </EmptyWrapper>
    );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 260px; /* Sidebar width */
`;

const EmptyWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: calc(100% - 260px);
  left: 260px;
`;

const DateSelectWrapper = styled.div`
  position: fixed;
  width: 230px;
  right: 20px;
  top: 20px;
  width: 230px;
  z-index: 2;
`;

const EmptyTitle = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.cyanBlue};
  font-size: 24px;
  font-family: 'Arial';
  padding: 5px;
`;

const EmptyDescription = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.realBlack};
  font-size: 14px;
  padding: 5px;
  width: 340px;
  line-height: 19px;
  font-family: 'Arial';
  text-align: center;
`;

const CustomButton = MuiStyled(Button)({
  height: '40px',
  width: '180px',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  padding: '6px 12px',
  borderRadius: '8px',
  backgroundColor: '#1A1F27',
  lineHeight: 1.5,
  fontFamily: 'Arial',
  outline: 'none',
  color: 'white',
  '&:hover': {
    backgroundColor: '#242B35',
    boxShadow: 'none',
  },
  cursor: 'pointer',
});

const PostWrapper = styled.div`
  display: flex;
  width: calc(100%-260px);
  overflow-x: scroll;
  white-space: nowrap;
  margin-top: 80px;
  margin-left: 20px;
  margin-right: 20px;
`;

const PostContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
`;
const MediaInfoWrapper = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  padding: 20px;
  height: auto;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  transition: all ease 0.7s;
  ${({ theme }) => theme.animation.fadeInBottom}
`;
const MediaContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: calc(100%-260px);
  height: auto;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  transition: all ease 0.7s;
  ${({ theme }) => theme.animation.fadeInBottom}
`;

const Element = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 22px 22px 15px 22px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
`;
