import React, { useState, useEffect, useContext } from 'react';
import LoadingPageFull from '../../../../../components/LoadingPageFull';
import { AccountContext } from '../../../../../contextApi/AccountContext';
import { useNavigate } from 'react-router-dom';
import { APIs } from '../../../../../config';

export default function TiktokAdsAuth() {
  const { getSession, sessionJWT } = useContext(AccountContext);
  const [workspaceId, setWorkspaceId] = useState(
    localStorage.getItem('workspaceId')
  );
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const redirectURI = `/myworkspace/${workspaceId}/integration`;

  useEffect(() => {
    getSession();
    setWorkspaceId(localStorage.getItem('workspaceId'));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sessionJWT) {
      handleAuthCode();
    }
  }, [sessionJWT]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    !isLoading &&
      navigate(redirectURI, {
        state: { showModal: true, content: 'tiktokAds', userData: data },
      });
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAuthCode = async () => {
    try {
      const search = new URL(window.location.href).search;

      if (search) {
        const authCode = search.split('=')[1].split('&')[0];
        sendToken(workspaceId, authCode);
      }
    } catch (error) {
      setIsLoading(false);
      throw new Error('Issue with Login', error.message);
    }
  };

  const sendToken = async (workspaceId, code) => {
    try {
      const request = await fetch(
        `${APIs('integration', workspaceId)}/channels/tiktokAds?code=${code}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionJWT}`,
          },
        }
      );

      const response = await request.json();
      if (!response.detail) {
        const accessToken = response?.access_token;
        const accountData = response?.accounts?.map((item) => ({
          value: item.account_id,
          label: item.name,
          isPrimary: false,
        }));
        const currencyData = response?.accounts?.map((item) => ({
          id: item.account_id,
          currency: item.currency,
        }));
        setData({
          accountData: accountData,
          accessToken: accessToken,
          currencyData: currencyData,
        });

        setIsLoading(false);
      } else {
        let message = response.detail;
        setData({ errorMessage: message });
        return setIsLoading(false);
      }
    } catch (error) {
      let message =
        'Something went wrong and we could not process your request';
      setData({ errorMessage: message });
      setIsLoading(false);
      throw new Error('Issue with Token', error.message);
    }
  };

  return <LoadingPageFull />;
}
