import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as XButton } from '../../../../assets/icons/IconSoloX.svg';

export default function CampaignFilter(props) {
  const { setOpenFilter, campaignXyr, setCampaignXyr } = props;
  const [filters, setFilters] = useState({});
  const [cpaValue, setCpaValue] = useState('');
  const [contributionValue, setContributionValue] = useState('');
  const [cpaSign, setCpaSign] = useState(false);
  const [contributionSign, setContributionSign] = useState(false);

  const handleCPAChange = (event) => {
    setCpaValue(event.target.value);
    setFilters((prev) => ({
      ...prev,
      x: {
        sign: signOptions?.[cpaSign ? 0 : 1].value,
        value: event.target.value,
      },
    }));
  };
  const handleContributionChange = (event) => {
    setContributionValue(event.target.value);
    setFilters((prev) => ({
      ...prev,
      y: {
        sign: signOptions?.[cpaSign ? 0 : 1].value,
        value: event.target.value,
      },
    }));
  };

  const signOptions = [
    { value: 'greaterThan', label: '≥' },
    { value: 'lessThan', label: '<' },
  ];

  const filterData = () => {
    const filteredData = campaignXyr.filter((item) => {
      for (const key in filters) {
        if (!filters.hasOwnProperty(key)) {
          continue; // Skip inherited properties
        }
        const { sign, value } = filters[key];

        if (value === '') {
          continue; // Skip the filter if value is empty
        }

        if (sign === 'lessThan' && item[key] >= value) {
          return false;
        }
        if (sign === 'greaterThan' && item[key] <= value) {
          return false;
        }
      }
      return true;
    });

    // Use the filteredData array as desired
    setCampaignXyr(filteredData);
    setOpenFilter(false);
  };

  return (
    <>
      <ModalOverlay>
        <ModalContent>
          <TitleArea>
            <div>Campaign Filter</div>

            <CloseBtn onClick={() => setOpenFilter(false)} />
          </TitleArea>
          <FilterArea>
            <ItemArea>CPA</ItemArea>
            <SignArea
              onClick={() => {
                setCpaSign((prev) => !prev);
              }}
            >
              {signOptions?.[cpaSign ? 0 : 1].label}
            </SignArea>
            <div>
              <NumberInput
                type='number'
                value={cpaValue}
                onChange={handleCPAChange}
                placeholder='Enter a number'
              />
            </div>
          </FilterArea>
          <FilterArea>
            <ItemArea>Contribution</ItemArea>
            <SignArea
              onClick={() => {
                setContributionSign((prev) => !prev);
              }}
            >
              {signOptions?.[contributionSign ? 0 : 1].label}
            </SignArea>
            <div>
              <NumberInput
                type='number'
                value={contributionValue}
                onChange={handleContributionChange}
                placeholder='Enter a number'
              />
            </div>
          </FilterArea>
          <SubmitArea>
            <SubmitBtn onClick={filterData}>Apply</SubmitBtn>
          </SubmitArea>
        </ModalContent>
      </ModalOverlay>
    </>
  );
}

const ModalOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

const ModalContent = styled.div`
  width: 500px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

const TitleArea = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Arial;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
  justify-content: space-between;
`;
const CloseBtn = styled(XButton)`
  align-self: end;
  width: 18px;
  height: 18px;
  fill: ${({ theme }) => theme.pb.black};
  cursor: pointer;
`;

const ItemArea = styled.div`
  font-family: Arial;
  font-size: 16px;
  padding: 5px;
  margin: 0px 40px 10px 0px;
  width: 100px;
  align-text: left;
  color: ${({ theme }) => theme.pb.darkGray};
`;

const FilterArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SignArea = styled.div`
  border: 0.5px solid grey;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px;
  height: 28px;
  text-align: center;
  width: 28px;
`;

const NumberInput = styled.input`
  border: 0.5px solid grey;
  border-radius: 4px;
  padding: 5px;
  width: 180px;
  height: 28px;
  font-family: Arial;
`;

const SubmitArea = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const SubmitBtn = styled.button`
  bottom: 40px;
  right: 40px;
  border: 0.5px solid black;
  border-radius: 8px;
  width: 100px;
  height: 30px;
  font-family: Arial;
  background-color: ${({ theme }) => theme.pb.black};
  color: white;
  cursor: pointer;
`;
