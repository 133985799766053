import React, { useState, createContext } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from '../UserPool';

const AccountContext = createContext();

const AccountProvider = ({ children }) => {
  const [sessionJWT, setSessionJWT] = useState('');
  const [userStatus, setUserStatus] = useState({
    userNotSignedIn: false,
    userTokenIncorrect: false,
    userHasSingedIn: false,
  });

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }
                  resolve(results);
                }
              });
            });
            resolve({ user, ...session, ...attributes });
            setSessionJWT({ ...session }.idToken.jwtToken);
          }
        });
      } else {
        reject('Need Sign in');
        setUserStatus(prev => (prev.userNotSignedIn = true));
      }
    });
  };

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: data => {
          resolve(data);
        },
        onFailure: err => {
          reject(err);
        },
        newPasswordRequired: data => {
          // console.log('newPasswordRequired: ', data);
          resolve(data);
        },
      });
    });
  };

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
        sessionJWT,
        userStatus,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export { AccountProvider, AccountContext };
