import React from 'react';
import styled from 'styled-components';
import Loader from './Loader';

export default function LoadingPageFull() {
  return (
    <PageContainer>
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

const LoaderContainer = styled.div`
  width: 3%;
  height: 85vh;
  display: flex;
  align-items: center;
`;
