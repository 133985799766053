import React, { useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { LanguageContext } from '../../../contextApi/LanguageContext';

import { CHANGE_PASSWORD_TEXT, NEW_PASSWORD_TEXT } from '../AUTH_TEXT';
import Pool from '../../../UserPool';
import PopupModal from '../../../components/PopupModal';
import styled from 'styled-components';

export default function ChangePassword({ setModalType }) {
  const isUnder1300 = useMediaQuery({ maxWidth: 1300 });
  const { language } = useContext(LanguageContext);

  const navigate = useNavigate();
  const codeInput = useRef();
  const [userInput, setUserInput] = useState({
    email: '',
    code: '',
    password: '',
    confirmPassword: '',
    stage: 1, //1 = email stage, 2= code stage, 3=pw completed
  });

  const { email, code, password, confirmPassword, stage } = userInput;

  const [popupModalStatus, setPopupModalStatus] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [confirmBtnName, setConfirmBtnName] = useState('');
  const closePopupModal = () => {
    setPopupModalStatus(false);
    resetInput();
    setPopupMessage('');
  };
  const setPopup = (popupMessage, confirmBtnName) => {
    setPopupMessage(popupMessage || null);
    setConfirmBtnName(confirmBtnName || null);
    setPopupModalStatus(true);
  };

  const handleConfirmAction = () => {
    setPopupModalStatus(false);
    stage === 2 && resetInput();
    stage === 3 && goToSignIn();
  };

  const goToSignIn = () => {
    navigate('/');
    setModalType('signIn');
  };

  const resetInput = () => {
    setUserInput(prev => ({
      ...prev,
      code: '',
      password: '',
      confirmPassword: '',
    }));
    codeInput.current.focus();
  };

  const emailCheck = email?.includes('@') && email?.includes('.');
  const pwdMatchCheck = password === confirmPassword;
  const pwdValidCheck = password?.match(
    /(?=.*\d)(?=.*[a-zA-ZS])(?=.*?[#?!@$%^&*-]).{8,}/
  );

  const getUser = () => {
    return new CognitoUser({ Username: email.toLowerCase(), Pool });
  };

  const sendCode = event => {
    event.preventDefault();

    getUser().forgotPassword({
      onSuccess: data => {
        setPopup(data, 'confirm');
      },
      onFailure: err => {
        setPopup(err.message, 'Confirm');
      },
      inputVerificationCode: data => {
        stage === 1 && setUserInput(prev => ({ ...prev, stage: 2 }));
        stage === 2 &&
          setPopup('A new code has been sent to your email', 'Confirm');
      },
    });
  };

  const changePassword = event => {
    event.preventDefault();

    getUser().confirmPassword(code, password, {
      onSuccess: data => {
        setUserInput(prev => ({ ...prev, stage: 3 }));
        setPopup('Your password has been changed successfully', 'Confirm');
      },
      onFailure: err => {
        setPopup(err.message, 'Confirm');
      },
    });
  };

  const onInputChange = event => {
    const { id, value } = event.target;
    setUserInput(prev => ({
      ...prev,
      [id]: value,
    }));
  };

  return (
    <>
      {stage === 1 && (
        <Wrapper isUnder1300={isUnder1300}>
          <Header>{CHANGE_PASSWORD_TEXT[language].headline}</Header>
          <Description needSpace={true}>
            {CHANGE_PASSWORD_TEXT[language].description}
          </Description>
          <Form isUnder1300={isUnder1300} onSubmit={sendCode}>
            <InputArea>
              <Input
                type="email"
                id="email"
                value={email}
                placeholder={CHANGE_PASSWORD_TEXT[language].id.placeholder}
                onChange={onInputChange}
              />
              {email && !emailCheck && (
                <Warning>{CHANGE_PASSWORD_TEXT[language].id.warning}</Warning>
              )}
            </InputArea>

            <SubmitButton type="submit" disabled={!emailCheck}>
              {CHANGE_PASSWORD_TEXT[language].confirmButton}
            </SubmitButton>
            <TextButton
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                goToSignIn();
              }}
            >
              <Text>{CHANGE_PASSWORD_TEXT[language].cancelButton}</Text>
            </TextButton>
          </Form>
        </Wrapper>
      )}

      {(stage === 2 || stage === 3) && (
        <Wrapper isUnder1300={isUnder1300}>
          <Header>{NEW_PASSWORD_TEXT[language].headline}</Header>
          <Description needSpace={true}>
            {NEW_PASSWORD_TEXT[language].description}
          </Description>
          <Form isUnder1300={isUnder1300} onSubmit={changePassword}>
            <InputArea>
              <Input
                id="code"
                value={code}
                placeholder={NEW_PASSWORD_TEXT[language].code.placeholder}
                onChange={onInputChange}
                ref={codeInput}
              />

              <TextButton onClick={sendCode}>
                <Text>{NEW_PASSWORD_TEXT[language].code.resend}</Text>
              </TextButton>
            </InputArea>

            <InputArea>
              <Input
                type="password"
                id="password"
                value={password}
                placeholder={NEW_PASSWORD_TEXT[language].pw.placeholder}
                onChange={onInputChange}
              />
              {password && !pwdValidCheck && (
                <Warning>{NEW_PASSWORD_TEXT[language].pw.warning}</Warning>
              )}
            </InputArea>

            <InputArea>
              <Input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                placeholder={NEW_PASSWORD_TEXT[language].confirmPw.placeholder}
                onChange={onInputChange}
              />
              {confirmPassword && !pwdMatchCheck && (
                <Warning>
                  {NEW_PASSWORD_TEXT[language].confirmPw.warning}
                </Warning>
              )}
            </InputArea>
            <SubmitButton
              type="submit"
              disabled={code && pwdValidCheck && pwdMatchCheck ? false : true}
            >
              {NEW_PASSWORD_TEXT[language].confirmButton}
            </SubmitButton>
            <TextButton
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                goToSignIn();
              }}
            >
              <Text>{NEW_PASSWORD_TEXT[language].cancelButton}</Text>
            </TextButton>
          </Form>
        </Wrapper>
      )}
      <PopupModal
        popupModalStatus={popupModalStatus}
        closePopupModal={closePopupModal}
        popupMessage={popupMessage}
        confirmBtnName={confirmBtnName}
        handleConfirmAction={handleConfirmAction}
      />
    </>
  );
}

const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ isUnder1300 }) => (isUnder1300 ? '35vw' : '100%')};
  max-width: 486px;
  max-height: 700px;
  padding: 55px 71px 45px 71px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.neutralColor.dark1};
  box-shadow: 0 0 10 rgba(0, 0, 0, 0.25);
  z-index: 20;
`;

const Header = styled.h2`
  margin-bottom: 30px;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: ${({ theme }) => theme.pb.green};
`;

const Form = styled.form`
  width: ${({ isUnder1300 }) => (isUnder1300 ? '25vw' : '100%')};
  max-width: 340px;
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-bottom: 16px;
`;

const Input = styled.input`
  width: 100%;
  max-width: 340px;
  height: 35px;
  padding: 10px 15px;
  border-radius: 5px;

  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralColor.dark4};
  background-color: ${({ theme }) => theme.pb.gray};

  &:focus {
    background-color: #fff;
  }

  &::placeholder {
    color: #6f6f6f;
  }
`;

const Warning = styled.p`
  width: 100%;
  max-width: 340px;
  padding: 10px 0px;

  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.pb.yellow};
`;

const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 340px;
  padding: 10px 15px;

  border-radius: 5px;
  background-color: ${({ color, theme }) =>
    color === 'black' ? theme.pb.black : theme.pb.cyanBlue};

  font-weight: 700;
  font-size: 16px;
  color: #fff;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 3px 7px rgba(9, 16, 55, 0.25);
  }

  &:disabled {
    cursor: default;
  }
`;

const TextButton = styled.button`
  width: 100%;
  margin-top: 14px;
`;

const Text = styled.span`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.pb.cyanBlue};

  &:hover {
    font-weight: 500;
    color: #fff;
  }

  cursor: pointer;
`;

const Description = styled.div`
  margin-bottom: ${({ needSpace }) => (needSpace ? '30px' : '8px')};
  color: ${({ theme }) => theme.pb.gray};
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
`;
