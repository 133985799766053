import { css } from 'styled-components';

const theme = {
  pm: {
    blue: '#0B8FFC',
    green: '#0EAA34',
  },

  pb: {
    blue: '#0B8FFC',
    green: '#05ED94',
    realBlack: '#000',
    black: '#1A1F27',
    darkBlue: '#194384',
    midBlue: '#007ade',
    lightBlue: '#e0f8ff',
    cyanBlue: '#0B8FFC',
    secondaryBlue: '#17BAED',
    darkGray: '#666666',
    gray: '#B3BCC8',
    semiLightGray: '#eaeaea',
    lightGray: '#F5F5F5',
    red: '#FF2869',
    darkGreen: '#11bf7c',
    yellowGreen: '#B9F489',
    yellow: '#FFED4E',
    lightGreen: '#d9fff0',
    shadow: 'rgba(0,0,0,0.25)',
    overlay: '#1e2533',
    chartBlue: '#3BA0EB',
    chartRed: '#FD5B84',
    chartGreen: '#51C2C0',
    chartGray: '#C9CBCF',
  },

  neutralColor: {
    dark0: '#88909B',
    dark1: '#44505F',
    dark2: '#242B35',
    dark3: '#1A1F27',
  },

  whcard: css`
    border: 1px solid #f5f5f5;
    box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
    border-radius: 24px;
    background-color: #fff;
  `,

  mdbluecard: {
    background: '#0B8FFC',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '46px',
  },

  btnShadow: {
    background: '#F5F5F5',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '46px',
  },

  notice: '#FF2869',
  info: '#0EAA34',

  warning: {
    bg: '#ffedf5',
    bgHover: '#f7599b',
    border: '#ffdedb',
    fontRed: '#8c0101',
    fontPink: '#d62971',
  },

  flexcenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  flexDefault: css`
    display: flex;
    align-items: center;
  `,

  positionbasic: css`
    position: relative;
    top: 0;
    left: 0;
  `,

  positionchildrenbasic: css`
    position: absolute;
    top: 0;
    left: 0;
  `,

  positioncenter: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,

  //fonts 신규 추가.
  fonts: {
    poppins: 'Poppins',
  },

  animation: {
    fadeInBottom: css`
      animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;

      @keyframes fade-in-bottom {
        0% {
          transform: translateY(50px);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
    `,

    scaleInVerTop: css`
      animation: scale-in-ver-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      @keyframes scale-in-ver-top {
        0% {
          transform: scaleY(0);
          transform-origin: 100% 0%;
          opacity: 1;
        }
        100% {
          transform: scaleY(1);
          transform-origin: 100% 0%;
          opacity: 1;
        }
      }
    `,

    scaleOutVerTop: css`
      animation: scale-out-ver-top 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53)
        both;
      @keyframes scale-out-ver-top {
        0% {
          transform: scaleY(1);
          transform-origin: 100% 0%;
          opacity: 1;
        }
        100% {
          transform: scaleY(0);
          transform-origin: 100% 0%;
          opacity: 1;
        }
      }
    `,
    scaleInHoriLeft: css`
      animation: scale-in-hori-left 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
      @keyframes scale-in-hori-left {
        0% {
          transform: scaleX(0);
          transform-origin: 100% 0%;
          opacity: 0.5;
        }
        100% {
          transform: scaleX(1);
          transform-origin: 100% 0%;
          opacity: 1;
        }
      }
    `,

    scaleOutHoriLeft: css`
      animation: scale-out-hori-left 0.8s cubic-bezier(0.55, 0.085, 0.68, 0.53)
        both;
      @keyframes scale-out-hori-left {
        0% {
          transform: scaleX(1);
          transform-origin: 100% 0%;
          opacity: 0.5;
        }
        100% {
          transform: scaleX(0);
          transform-origin: 100% 0%;
          opacity: 1;
        }
      }
    `,

    fadeInFwd: css`
      animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;

      @keyframes fade-in-fwd {
        0% {
          transform: translateZ(-80px);
          opacity: 0;
        }
        100% {
          transform: translateZ(0);
          opacity: 1;
        }
      }
    `,

    fadeOutBck: css`
      animation: fade-out-bck 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

      @keyframes fade-out-bck {
        0% {
          transform: translateZ(0);
          opacity: 1;
        }
        100% {
          transform: translateZ(-80px);
          opacity: 0;
        }
      }
    `,
  },
};

export default theme;
