import React from 'react';
import PlusButton from '../assets/icons/PlusButton.svg';
import styled from 'styled-components';

export default function NewBtn({ newBtnName, otherBtnInfo, onClickHandler }) {
  return (
    <Button onClick={onClickHandler}>
      {newBtnName && (
        <>
          <Plus src={PlusButton} />
          New {newBtnName}
        </>
      )}
      {otherBtnInfo && (
        <>
          <Icon>{otherBtnInfo.img}</Icon>
          {otherBtnInfo.name}
        </>
      )}
    </Button>
  );
}

const Button = styled.button`
  display: flex;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: #fff;
  ${({ theme }) => theme.mdbluecard}
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
`;

const Plus = styled.img`
  margin-right: 0.3125rem;
`;

const Icon = styled.div`
  margin-right: 0.4rem;
`;
