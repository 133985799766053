import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SimpleCard from './Charts/SimpleCard';
import LineChart from './Charts/LineChart';
import { APIs } from '../../../config';
import { WorkspaceContext } from '../../../contextApi/WorkspaceContext';

import LoadingPage from '../../../components/LoadingPage';
import PopupModal from '../../../components/PopupModal';
import DateSelect from '../../../components/InputComponents/DateSelect';

import { changeDateFormatForSubmit } from '../../../hooks/changeDateFormat';
import CampaignKpi from './Charts/CampaignKpi';
import PieChart from './Charts/PieChart';
import { styled as MuiStyled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { ReactComponent as IntegrationSVG } from '../../../icons/Integrations.svg';
import CampaignPerfTable from './Charts/CampaignPerfTable';
import CampaignPerfScatter from './Charts/CampaignPerfScatter';
import StackedLineChart from './Charts/StackedLineChart';
import ChannelPerfTable from './Charts/ChannelPerfTable';

export default function PaidChannelAnalytics(props) {
  const {
    isLoading,
    setIsLoading,
    sessionJWT,
    workspaceId,
    selectedPeriod,
    setSelectedPeriod,
    labelDates,
    chartData,
    hasRequired,
    requiredIntegration,
  } = props;

  const { workspaceData, getWorkspaceData } = useContext(WorkspaceContext);
  const [kpi, setKpi] = useState({});
  const [kpiScatter, setKpiScatter] = useState({});
  const [kpiChannel, setKpiChannel] = useState({});
  const [kpiOptions, setKpiOptions] = useState([]);

  const [campaignXyr, setCampaignXyr] = useState([]);
  const [campaignPerfTable, setCampaignPerfTable] = useState([]);
  const [campaignPredictions, setCampaignPredictions] = useState([]);
  const [selectedCampaignPrediction, setSelectedCampaignPrediction] = useState(
    {}
  );
  const [channelOptions, setChannelOptions] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState({});
  const [channelPredictions, setChannelPredictions] = useState([]);
  const [channelKpiStacked, setChannelKpiStacked] = useState([]);
  const [selectedAd, setSelectedAd] = useState({});
  const [adPredictions, setAdPredictions] = useState([]);
  const [selectedAdPrediction, setSelectedAdPrediction] = useState({});
  const [showPopupModal, setShowPopupModal] = useState(false);
  const navigate = useNavigate();

  const openCautionPopup = () => {
    const goToPrev = () => navigate(`/myworkspace/${workspaceId}/dashboard`);
    PopupModal.closePopupModal = () => goToPrev();
    PopupModal.popupMessage =
      'Your dashboard is currently locked. Please contact us.';
    PopupModal.handleConfirmAction = () => goToPrev();
    setShowPopupModal(true);
  };
  useEffect(() => {
    if (workspaceData.feature && workspaceData.feature.dashboards) {
      if (workspaceData.feature.dashboards.paidChannelAnalytics) {
        openCautionPopup();
      }
    }
  }, [workspaceData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sessionJWT && workspaceId) {
      setIsLoading(true);
      getWorkspaceData('feature');
      const fetchModels = async () => {
        const request = await fetch(`${APIs('model', workspaceId)}/mmm/kpi`, {
          headers: { Authorization: `Bearer ${sessionJWT}` },
        });
        const data = await request.json();
        if (data.length > 0) {
          setKpiOptions(data);
          setKpi(data[0]);
          setKpiScatter(data[0]);
          setKpiChannel(data[0]);
        } else return;
      };
      fetchModels();
      setIsLoading(false);
    }
  }, [sessionJWT, workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (requiredIntegration) {
      setChannelOptions(requiredIntegration);
      setSelectedChannel(requiredIntegration[0]);
    } else return;
  }, [requiredIntegration]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (kpiChannel && kpiChannel.kpi && selectedPeriod.length === 2) {
      setIsLoading(true);
      const fetchChannel = async () => {
        const request = await fetch(
          `${APIs('dashboard', workspaceId)}/kpi/${
            kpiChannel.kpi.sourceName
          }/source/${
            kpiChannel.sourceOfTruth
          }?startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(
            selectedPeriod[1]
          )}&category=PAID`,
          {
            headers: { Authorization: `Bearer ${sessionJWT}` },
          }
        );
        const data = await request.json();
        if (data.length > 0) {
          setChannelPredictions(data);
        } else return;
      };
      fetchChannel();
      setIsLoading(false);
    }
  }, [kpiChannel, selectedPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (kpi && kpi.kpi && selectedPeriod.length === 2) {
      setIsLoading(true);
      const fetchCampaign = async () => {
        const request = await fetch(
          `${APIs('dashboard', workspaceId)}/predictions/CAMPAIGN?kpi=${
            kpi.kpi.sourceName
          }&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(
            selectedPeriod[1]
          )}&sourceOfTruth=${kpi.sourceOfTruth}`,
          {
            headers: { Authorization: `Bearer ${sessionJWT}` },
          }
        );
        const data = await request.json();
        if (data.length > 0) {
          setCampaignPerfTable(data);
        } else return;
      };
      fetchCampaign();
      setIsLoading(false);
    }
  }, [kpi, selectedPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (kpiScatter && kpiScatter.kpi && selectedPeriod.length === 2) {
      const fetchXyr = async () => {
        const request = await fetch(
          `${APIs('dashboard', workspaceId)}/predictions/CAMPAIGN/top?kpi=${
            kpiScatter.kpi.sourceName
          }&startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(
            selectedPeriod[1]
          )}&sourceOfTruth=${kpiScatter.sourceOfTruth}`,
          {
            headers: { Authorization: `Bearer ${sessionJWT}` },
          }
        );
        const data = await request.json();
        if (data.length > 0) {
          setCampaignXyr(data);
        } else return;
      };
      fetchXyr();
    }
  }, [kpiScatter, selectedPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (kpi && kpi.kpi && selectedPeriod.length === 2) {
  //     const fetchAd = async () => {
  //       const request = await fetch(
  //         `${APIs('dashboard', workspaceId)}/predictions/AD?kpi=${
  //           kpi.kpi.sourceName
  //         }&startDate=${changeDateFormatForSubmit(
  //           selectedPeriod[0]
  //         )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
  //         {
  //           headers: { Authorization: `Bearer ${sessionJWT}` },
  //         }
  //       );
  //       const data = await request.json();
  //       if (data.length > 0) {
  //         setPredictions((prev) => ({
  //           ...prev,
  //           ads: data,
  //         }));
  //         setSelectedAd(data[0]);
  //       } else return;
  //     };
  //     fetchAd();
  //   }
  // }, [kpi, selectedPeriod]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (sessionJWT && workspaceId && selectedPeriod.length === 2) {
      const fetchPredictions = async () => {
        const request = await fetch(
          `${APIs(
            'dashboard',
            workspaceId
          )}/predictions/CAMPAIGN/all?startDate=${changeDateFormatForSubmit(
            selectedPeriod[0]
          )}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
          {
            headers: { Authorization: `Bearer ${sessionJWT}` },
          }
        );
        const data = await request.json();
        if (data.length > 0) {
          setCampaignPredictions(data);
          setSelectedCampaignPrediction(data[0]);
        } else return;
      };
      fetchPredictions();
    }
  }, [sessionJWT, workspaceId, selectedPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (selectedAd.id) {
  //     const fetchKpis = async () => {
  //       const request = await fetch(
  //         `${APIs('dashboard', workspaceId)}/predictions/${
  //           selectedAd.channel_name
  //         }/AD/${selectedAd.id}`,
  //         {
  //           headers: { Authorization: `Bearer ${sessionJWT}` },
  //         }
  //       );
  //       const data = await request.json();
  //       if (data.length > 0) {
  //         setAdPredictions(data);
  //         setSelectedAdPrediction(data[0]);
  //       } else return;
  //     };
  //     fetchKpis();
  //   }
  // }, [selectedAd]); // eslint-disable-line react-hooks/exhaustive-deps
  if (hasRequired) {
    return (
      <>
        <DateSelectWrapper>
          <DateSelect
            dateRange={selectedPeriod}
            setDateRange={setSelectedPeriod}
            type='dashboard'
          />
        </DateSelectWrapper>
        {isLoading ? (
          <LoadingPage />
        ) : (
          <Container>
            <TotalArea>
              <SimpleCard
                title='Ad Impressions'
                value={
                  chartData.totalAdImpressions &&
                  chartData.totalAdImpressions.length > 0 &&
                  chartData.totalAdImpressions[0].impressions
                    ? chartData.totalAdImpressions[0].impressions.toLocaleString()
                    : 'NA'
                }
                color='chartBlue'
              />
              <SimpleCard
                title='Ad Clicks'
                value={
                  chartData.totalAdClicks &&
                  chartData.totalAdClicks.length > 0 &&
                  chartData.totalAdClicks[0].clicks
                    ? chartData.totalAdClicks[0].clicks.toLocaleString()
                    : 'NA'
                }
                color='chartGreen'
              />
              <SimpleCard
                title='Ad Spend'
                value={
                  chartData.totalAdSpends &&
                  chartData.totalAdSpends.length > 0 &&
                  chartData.totalAdSpends[0].spends
                    ? chartData.totalAdSpends[0].spends.toLocaleString()
                    : 'NA'
                }
                color='chartRed'
              />
              <SimpleCard
                title='CPM'
                value={chartData.cpm ? chartData.cpm : 'NA'}
                color='chartBlue'
              />
              <SimpleCard
                title='CPC'
                value={chartData.cpc ? chartData.cpc : 'NA'}
                color='chartGreen'
              />
            </TotalArea>
            <DoubleChartArea>
              {kpiChannel.kpi && channelPredictions.length > 0 && (
                <StackedLineChart
                  kpiChannel={kpiChannel}
                  setKpiChannel={setKpiChannel}
                  kpiOptions={kpiOptions}
                  title='KPI by Channel'
                  labels={labelDates}
                  channelPredictions={channelPredictions}
                  xAxisKey='date'
                  yAxisKey='contribution'
                />
              )}
              {chartData.channels && (
                <ChannelPerfTable channelData={chartData.channels} />
              )}
            </DoubleChartArea>
            <DoubleChartArea>
              {kpiScatter.kpi && campaignXyr.length > 0 && (
                <CampaignPerfScatter
                  kpiScatter={kpiScatter}
                  setKpiScatter={setKpiScatter}
                  kpiOptions={kpiOptions}
                  campaignXyr={campaignXyr}
                  setCampaignXyr={setCampaignXyr}
                />
              )}

              {kpi.kpi && campaignPerfTable.length > 0 && (
                <CampaignPerfTable
                  kpi={kpi}
                  setKpi={setKpi}
                  kpiOptions={kpiOptions}
                  campaignPerfTable={campaignPerfTable}
                />
              )}
            </DoubleChartArea>
            <DoubleChartArea>
              {selectedCampaignPrediction.id && (
                <CampaignKpi
                  selectedCampaign={selectedCampaignPrediction}
                  setSelectedCampaign={setSelectedCampaignPrediction}
                  campaignPredictions={campaignPredictions}
                />
              )}
              {chartData.campaignImpressions && (
                <LineChart
                  title='Campaign Impressions'
                  labels={labelDates}
                  timeSeriesData={chartData.campaignImpressions}
                  xAxisKey='date'
                  yAxisKey='impressions'
                />
              )}
            </DoubleChartArea>

            {/* {chartData.campaignSpends && (
              <LineChart
                title='Campaign Spends'
                labels={labelDates}
                timeSeriesData={chartData.campaignSpends}
                xAxisKey='date'
                yAxisKey='spend'
              />
            )}
            <DoubleChartArea>
              {chartData.campaigns && (
                <PieChart
                  title='Campaign Impressions'
                  label='Total Impressions'
                  pieData={chartData.campaigns}
                  pieKey='impressions'
                />
              )}
              {chartData.campaigns && (
                <PieChart
                  title='Campaign Spends'
                  label='Total Spends'
                  pieData={chartData.campaigns}
                  pieKey='spends'
                />
              )}
            </DoubleChartArea> */}
          </Container>
        )}
        <PopupModal
          popupModalStatus={showPopupModal}
          popupMessage={PopupModal.popupMessage}
          confirmBtnName={PopupModal.confirmBtnName}
          handleConfirmAction={PopupModal.handleConfirmAction}
          extraBtnName={PopupModal.extraBtnName}
          handleExtraAction={PopupModal.handleExtraAction}
          closePopupModal={PopupModal.closePopupModal}
        />
      </>
    );
  } else
    return (
      <EmptyWrapper>
        <EmptyTitle>🤖 Hello!</EmptyTitle>
        <EmptyDescription>
          You need to connect at least one paid channel & one analytics for this
          dashboard.
        </EmptyDescription>
        <CustomButton
          key='AddDataSource'
          variant='contained'
          startIcon={<IntegrationSVG />}
          disableRipple
          onClick={() =>
            navigate(`/myworkspace/${workspaceId}/integration/connect`)
          }
        >
          Add Data Source
        </CustomButton>
      </EmptyWrapper>
    );
}

const EmptyWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: calc(100% - 260px);
  left: 260px;
`;

const EmptyTitle = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.cyanBlue};
  font-size: 24px;
  font-family: 'Arial';
  padding: 5px;
`;

const EmptyDescription = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.realBlack};
  font-size: 14px;
  padding: 5px;
  width: 340px;
  line-height: 19px;
  font-family: 'Arial';
  text-align: center;
`;

const CustomButton = MuiStyled(Button)({
  height: '40px',
  width: '180px',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  padding: '6px 12px',
  borderRadius: '8px',
  backgroundColor: '#1A1F27',
  lineHeight: 1.5,
  fontFamily: 'Arial',
  outline: 'none',
  color: 'white',
  '&:hover': {
    backgroundColor: '#242B35',
    boxShadow: 'none',
  },
  cursor: 'pointer',
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 260px; /* Sidebar width */
  margin-bottom: 80px;
`;

const TotalArea = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2px;
  margin-top: 80px;
  margin-left: 20px;
  margin-right: 20px;
`;
const ChartArea = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;

  @media (min-width: 1440px) {
    display: grid;
    flex-direction: null;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4px;
    margin-top: 4px;
  }
`;

const DateSelectWrapper = styled.div`
  position: fixed;
  width: 230px;
  right: 20px;
  top: 20px;
  width: 230px;
  z-index: 2;
`;

const DoubleChartArea = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4px;
  margin-top: 4px;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 1600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }
`;
