import React from 'react';
import styled from 'styled-components';

const Form = ({
  isReadyToSubmit,
  formInfo,
  input,
  setInputData,
  handleInput,
  submitInput,
  status,
}) => {
  const { title, type } = formInfo;
  const filStatus = !status;

  return (
    <Wrapper
      onSubmit={e => {
        e.preventDefault();
        submitInput(input, type);
      }}
    >
      <Text>{title}</Text>
      <Input
        name={type}
        onChange={e => {
          handleInput(type, e);
          setInputData(prev => ({
            ...prev,
            [type]: {
              ...prev[type],
              val: e.target.value,
            },
          }));
        }}
        value={input || ''}
        placeholder="Workspace Name"
        autoComplete="off"
      />
      {isReadyToSubmit || (
        <Notice isCorrect={false}>
          Please type more than three letters to create a workspace
        </Notice>
      )}
      {isReadyToSubmit && input && (
        <Notice isCorrect={filStatus}>
          {NOTICE[type][filStatus ? 'correct' : 'incorrect']}
        </Notice>
      )}
      <Button
        isStatusOn={!isReadyToSubmit || input}
        disabled={!(Boolean(input) && filStatus && isReadyToSubmit)}
      >
        {type === 'create' ? 'Create' : 'Request'}
      </Button>
    </Wrapper>
  );
};

export default Form;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-top: 2rem;
`;

const Text = styled.p`
  margin-bottom: 0.5rem;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.pb.black};
`;

const Input = styled.input`
  padding: 0.75rem 1.25rem;
  width: 100%;
  border: 0.5px solid #f5f5f5;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  border-radius: 20px;
  line-height: 16px;
  font-size: 14px;
`;

const Button = styled.button`
  padding: 0.8rem 11.25rem;
  margin-top: 0.7rem;
  width: 400px;
  font-weight: 500;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.pb.lightGray : theme.pb.midBlue};
  color: ${({ theme, disabled }) => (disabled ? theme.pb.darkGray : 'white')};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 42px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  transition: all ease 0.5s;

  &:hover {
    background-color: ${({ theme, disabled }) =>
      !disabled && theme.pb.cyanBlue};
  }
`;

const Notice = styled.p`
  margin: 0.5rem 1.25rem;
  font-size: 12px;
  color: ${({ isCorrect, theme }) => (isCorrect ? theme.info : theme.notice)};
`;

const NOTICE = {
  create: {
    correct: ' available',
    incorrect: ' workspace already exists',
  },
  join: {
    correct: ' Workspace found!',
    incorrect: ' Workspace not found',
  },
};
