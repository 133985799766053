import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { APIs } from '../../../../../config';
import { AccountContext } from '../../../../../contextApi/AccountContext';
import deletePermissionFB from '../Facebook/FacebookDeletePermission';
import EditBtn from '../../../../../components/EditBtn';
import MiniModal from '../../../../../components/MiniModal';
import PopupModal from '../../../../../components/PopupModal';
import { ReactComponent as Checked } from '../../../../../assets/icons/Checked.svg';
import styled, { keyframes } from 'styled-components/macro';

const DataCard = (props) => {
  const { idx, item, setDataCardIndex, dataCardIndex, openCautionPopup } =
    props;
  const facebookChannels = ['facebookAds', 'facebookPages', 'instagram'];
  const facebookChannelScopes = {
    facebookAds: 'ads_read',
    facebookPages: 'pages_show_list,pages_read_engagement,read_insights',
    instagram: 'instagram_basic,instagram_manage_insights',
  };
  const { id, img, title, button, content, isActivated } = item;
  const { sessionJWT } = useContext(AccountContext);
  const { workspaceId } = useParams();
  const [popupModalStatus, setPopupModalStatus] = useState(false);
  const [showMiniModal, setShowMiniModal] = useState(false);

  const disconnectIntegration = () => {
    const callDisconnectAPI = async () => {
      try {
        const request = await fetch(
          `${APIs('integration', workspaceId)}?channelName=${id}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${sessionJWT}`,
            },
          }
        );

        const response = request.json();
        const data = await response;

        if (request.ok) {
          window.location.reload(true);
        } else if (request.status === 400) {
          closePopupModal();
          openErrorPopupModal(data.detail);
        } else if (request.status === 500) {
          closePopupModal();
          openErrorPopupModal(
            'Sorry! something went wrong. Please contact Playbook'
          );
        }
      } catch (err) {
        console.error('Delete integrations error: ', err);
      }
    };

    if (sessionJWT) {
      if (facebookChannels.includes(id)) {
        deletePermissionFB({
          deleteScope: facebookChannelScopes[id],
          openCautionPopup: openCautionPopup,
          callbackFunction: callDisconnectAPI,
        });
      } else {
        callDisconnectAPI();
      }
    }
  };

  const closePopupModal = () => {
    setPopupModalStatus(false);
    setDataCardIndex(null);
  };

  const openConfirmDeletePopupModal = () => {
    setDataCardIndex(null);
    PopupModal.popupMessage = 'Are you sure you want to disconnect?';
    PopupModal.confirmBtnName = 'Confirm';
    PopupModal.handleConfirmAction = () => {
      disconnectIntegration();
      closePopupModal();
    };
    setPopupModalStatus(true);
  };

  const openErrorPopupModal = (error) => {
    setDataCardIndex(null);
    PopupModal.popupMessage = error;
    PopupModal.confirmBtnName = 'Close';
    PopupModal.handleConfirmAction = () => {
      closePopupModal();
    };
    setPopupModalStatus(true);
  };

  const toggleEditModal = () => {
    if (showMiniModal) {
      setShowMiniModal(false);
      setDataCardIndex('');
    } else {
      setDataCardIndex(idx);
    }
  };

  const EDIT_INTEGRATION_OPTIONS = [
    {
      optionName: 'Disconnect',
      onClickAction: () => {
        openConfirmDeletePopupModal();
      },
    },
  ];

  useEffect(() => {
    if (dataCardIndex === idx) {
      setShowMiniModal(true);
    } else {
      setShowMiniModal(false);
    }
  }, [dataCardIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Wrapper isActivated={isActivated} key={id}>
        <DataCardInfo>
          <TitleLogo>{img}</TitleLogo>
          <TitleText>{title}</TitleText>
          <ContentText>{content}</ContentText>
          {button}
          {/* {!isActivated ? (
            button
          ) : (
            <>
              <EditIcon onClick={toggleEditModal}>
                <EditBtn />
              </EditIcon>
              <CheckedIcon />
            </>
          )}
          {showMiniModal && (
            <MiniModal
              modalTitle='Change Status'
              modalPosition={{ top: '20px', right: '-108px' }}
              options={EDIT_INTEGRATION_OPTIONS}
            />
          )} */}
        </DataCardInfo>
      </Wrapper>
      <PopupModal
        popupModalStatus={popupModalStatus}
        closePopupModal={closePopupModal}
        handleConfirmAction={PopupModal.handleConfirmAction}
        popupMessage={PopupModal.popupMessage}
        confirmBtnName={PopupModal.confirmBtnName}
      />
    </>
  );
};

export default DataCard;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 8px;
  width: 280px;
  height: 220px;
  border-radius: 8px;
  border: 0.5px soild #f5f5f5;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  transition: 0.35s;

  &:hover {
    background-color: ${({ theme }) => theme.pb.chartGray};
  }
`;

const EditIcon = styled.div`
  position: absolute;
  top: 0px;
  right: -44px;
  z-index: 2;
`;

const DataCardInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const TitleText = styled.p`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  font-weight: normal;
`;

const ContentText = styled.p`
  width: 170px;
  white-space: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #000f1f;
  margin-top: 8px;
  margin-bottom: 13px;
  color: #666666;
`;

const TitleLogo = styled.div`
  margin-bottom: 12px;
`;

const ScaleUp = keyframes`
  from {
    transform: scale(0);
  } to {
    transform: scale(1);
  }
`;

const CheckedIcon = styled(Checked)`
  animation: ${ScaleUp} 0.5s;
`;
