import React from 'react';
import FacebookLogo from '../assets/icons/logo/Facebook.svg';
import InstagramLogo from '../assets/icons/logo/InstagramLogo.svg';
import YoutubeLogo from '../assets/icons/logo/Youtube.svg';
import GoogleAdsLogo from '../assets/icons/logo/GoogleAds.svg';
import GoogleAnalyticsLogo from '../assets/icons/logo/GoogleAnalytics.svg';
import ShopifyLogo from '../assets/icons/logo/Shopify.svg';
import TiktokLogo from '../assets/icons/logo/Tiktok.svg';
import MailchimpLogo from '../assets/icons/logo/mailchimp.svg';
import KakaoLogo from '../assets/icons/logo/KakaoAds.svg';
import NaverAdsLogo from '../assets/icons/logo/NaverAds.svg';
import NuriLoungeLogo from '../assets/icons/logo/NuriLounge.svg';
import Cafe24Logo from '../assets/icons/logo/Cafe24.svg';
import SingularLogo from '../assets/icons/logo/Singular.svg';
import AppsflyerLogo from '../assets/icons/logo/AppsFlyer.svg';

export default function getChannelLogo(channelName) {
  const logoList = {
    facebookAds: { source: FacebookLogo },
    instagram: { source: InstagramLogo, width: 42 },
    googleAds: { source: GoogleAdsLogo },
    googleAnalytics: { source: GoogleAnalyticsLogo },
    youtube: { source: YoutubeLogo },
    tiktokAds: { source: TiktokLogo },
    shopify: { source: ShopifyLogo, width: 40 },
    mailchimp: { source: MailchimpLogo },
    naverAds: { source: NaverAdsLogo },
    kakaoAds: { source: KakaoLogo },
    nuriLounge: { source: NuriLoungeLogo },
    cafe24: { source: Cafe24Logo },
    singular: { source: SingularLogo },
    appsflyer: { source: AppsflyerLogo },
  };

  return (
    <img
      src={logoList[channelName]?.source}
      alt={channelName}
      width={logoList[channelName]?.width}
      height={logoList[channelName]?.heigth}
    />
  );
}
