import React, { Component } from 'react';
import MotionLoader from './MotionLoader';

class Loader extends Component {
  performTimeConsumingTask = async () => {
    return new Promise(resolve =>
      setTimeout(() => {
        resolve('result');
      }, 1800)
    );
  };
  async componentDidMount() {
    const data = await this.performTimeConsumingTask();
    let loader = document.getElementsByClassName('loader');

    if (data !== null) {
      loader[0]?.classList.add('animated');
      loader[0]?.classList.add('slideOutRight');
    }
  }

  render() {
    return (
      <div className="loader" style={{ width: '100%' }}>
        <MotionLoader />
      </div>
    );
  }
}

export default Loader;
