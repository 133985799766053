import { useLottie } from 'lottie-react';
import animation from '../assets/motion/loader/data.json';

const Motion = () => {
  const options = {
    animationData: animation,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options);

  return View;
};

export default Motion;
