import React from 'react';
import styled from 'styled-components';

export default function SimulationBasicInfo({ result, basicAreaWidth }) {
  return (
    <Container basicAreaWidth={basicAreaWidth}>
      <SubTitle>Estimated Total Cost</SubTitle>
      <DateArea>
        <Details>{result.totalCost.toLocaleString()}</Details>
      </DateArea>
      <SubTitle>Suggested Campaigns</SubTitle>
      <DateArea>
        <Details>{result.campaigns.length.toLocaleString()}</Details>
      </DateArea>
    </Container>
  );
}

const Container = styled.div`
  width: ${({ basicAreaWidth }) => `${basicAreaWidth * 14}px`};
  gap: 10px;
`;

const SubTitle = styled.p`
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.pb.cyanBlue};
  font-size: 0.75rem;
`;

const Details = styled.span`
  padding-right: 0.25rem;
  font-size: 16px;
  margin-bottom: 0.5rem;
`;

const DateArea = styled.div`
  display: flex;
  flex-direction: column;
`;
