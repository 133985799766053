import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AccountContext } from '../../../contextApi/AccountContext';
import MMMDetails from './MMMDetails';
import PMMMDetails from './PMMMDetails';

export default function ModelDetails() {
  const { modelType } = useParams();

  if (modelType === 'mmm') return <MMMDetails />;
  else if (modelType === 'pmmm') return <PMMMDetails />;
  else return;
}
