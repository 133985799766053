export const BASE_URL = `${process.env.REACT_APP_API_END_POINT}`;

export const APIs = (type, workspaceId) => {
  const apiList = {
    signup: `${BASE_URL}/signup`,
    search: `${BASE_URL}/v2/workspace/search`,
    approve: `${BASE_URL}/v2/workspace/approve`,
    workspace: `${BASE_URL}/v2/workspace/${workspaceId}`,
    workspaces: `${BASE_URL}/v2/workspace`,
    feature: `${BASE_URL}/v2/workspace/${workspaceId}/feature`,
    integration: `${BASE_URL}/v2/workspace/${workspaceId}/integration`,
    integratedList: `${BASE_URL}/v2/workspace/${workspaceId}/integration/channels`,
    plan: `${BASE_URL}/v2/workspace/${workspaceId}/plan`,
    model: `${BASE_URL}/v2/workspace/${workspaceId}/model`,
    kpi: `${BASE_URL}/v2/workspace/${workspaceId}/kpi`,
    campaign: `${BASE_URL}/v2/workspace/${workspaceId}/campaign`,
    myaccount: `${BASE_URL}/v2/user`,
    member: `${BASE_URL}/v2/workspace/${workspaceId}/user`,
    cost: `${BASE_URL}/v2/workspace/${workspaceId}/cost`,
    dashboard: `${BASE_URL}/v2/workspace/${workspaceId}/dashboard`,
    mmmKPI: `${BASE_URL}/v2/workspace/${workspaceId}/model/mmm/kpi`,
    poc: `${BASE_URL}/v2/workspace/${workspaceId}/poc`,
    pocResult: `${BASE_URL}/v2/workspace/${workspaceId}/poc/results`,
  };
  return apiList[type];
};
