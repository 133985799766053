import { GetToken } from '../Components/GoogleGetToken';

export const YoutubeApi = async ({ setIsLoading, setData }) => {
  try {
    const token = await GetToken();
    const request = await fetch(
      'https://youtube.googleapis.com/youtube/v3/channels?mine=true&part=contentDetails%2CbrandingSettings%2Cid',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const resp = await request.json();
    if (!resp.error) {
      if (resp.items === undefined) {
        let message = 'Please create a channel first';
        setData((prev) => ({ ...prev, errorMessage: message }));
      } else {
        const account = resp.items.map((item) => {
          return {
            value: item.id,
            label: item.brandingSettings.channel.title,
            isPrimary: false,
          };
        });

        await setData(account);
      }
    } else if (
      resp.error.code === 403 &&
      resp.error.status === 'PERMISSION_DENIED'
    ) {
    } else if (
      resp.error.code === 401 &&
      resp.error.status === 'UNAUTHENTICATED'
    ) {
      let message = 'Make sure to log in with a valid Youtube account';
      setData((prev) => ({ ...prev, errorMessage: message }));
    } else {
      let message =
        'Something went wrong and we could not process your request';
      setData((prev) => ({ ...prev, errorMessage: message }));
    }
  } catch (error) {
    setData({ errorMessage: error.message });
    throw new Error('Youtube error getting channel data | ', error.message);
  } finally {
    setIsLoading(false);
  }
};
