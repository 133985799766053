import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AccountContext } from '../../../../../contextApi/AccountContext';
import { APIs } from '../../../../../config';

import styled from 'styled-components/macro';

export default function AppsflyerModal({
  integrationData,
  setIntegrationData,
  isSubmitClicked,
  setIsSubmitClicked,
  setIsFormReady,
  setShowModal,
  openCautionPopup,
}) {
  const { sessionJWT } = useContext(AccountContext);

  const { workspaceId } = useParams();

  const clearState = () => {
    setIsFormReady((prev) => ({ ...prev, appsflyer: false }));
    setIsSubmitClicked((prev) => ({ ...prev, appsflyer: false }));
  };

  const handleSubmit = () => {
    if (isSubmitClicked) {
      let submitData = {
        category: 'sot',
        channelName: 'appsflyer',
        accessToken: integrationData?.accessToken,
      };
      sendAdsData(submitData);
    }
  };

  const sendAdsData = async (selectedData) => {
    try {
      const request = await fetch(
        `${APIs('integration', workspaceId)}?channelName=appsflyer`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionJWT}`,
          },
          body: JSON.stringify(selectedData),
        }
      );
      const response = request.json();
      const data = await response;

      if (request.ok) {
        clearState();
        window.location.reload(true);
      } else if (request?.status === 400) {
        let message;
        if (data?.[0] === 'channel already integrated') {
          message = 'Alreay Integrated';
        } else {
          message = 'Something went wrong, please contact Playbook ';
        }
        setShowModal(false);
        openCautionPopup({
          message: message,
          confirmOption: 'refresh',
          buttonName: 'Close',
        });
      }
    } catch (error) {
      setShowModal(false);
      const message =
        'Something went wrong and we could not process your request';
      openCautionPopup({
        message: message,
        confirmOption: 'refresh',
        buttonName: 'Close',
      });
      throw new Error('Issue with Integration', error.message);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [isSubmitClicked]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalContent>
      <ModalTitle>API Credentials</ModalTitle>
      <form onSubmit={handleSubmit}>
        <InputWrapper>
          <InputLabel htmlFor='accessToken'>Access Token*</InputLabel>
          <InputField
            type='text'
            id='accessToken'
            value={integrationData.accessToken}
            onChange={(event) =>
              setIntegrationData((prev) => ({
                ...prev,
                accessToken: event.target.value,
              }))
            }
            required
          />
        </InputWrapper>
      </form>
    </ModalContent>
  );
}

const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 24px;
`;

const ModalTitle = styled.div`
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: 'Arial';
`;

const InputWrapper = styled.div`
  margin-bottom: 16px;
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 8px;
`;

const InputField = styled.input`
  display: block;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 8px;
  font-family: 'Arial';
`;
