import React, { useState, useEffect } from 'react';
import LoadingPageFull from '../../../../../components/LoadingPageFull';
import { useNavigate } from 'react-router-dom';
import { APIs } from '../../../../../config';
import PopupModal from '../../../../../components/PopupModal';

export default function Cafe24Auth() {
  const workspaceId = localStorage.getItem('workspaceId');
  const sessionJWT = localStorage.getItem('sessionJWT');
  const mallId = localStorage.getItem('mallId');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const redirectURI = `/myworkspace/${workspaceId}/integration`;

  useEffect(() => {
    handleAuthCode();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    !isLoading && navigate(redirectURI);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAuthCode = async () => {
    try {
      const search = new URL(window.location.href).search;
      console.log(search);

      if (search) {
        const status = search.split('&')[0].split('=')[0];
        console.log(status);
        if (status !== '?error') {
          const authCode = search.split('&')[0].split('=')[1];
          console.log(authCode);
          sendTokenData(workspaceId, authCode);
        } else {
          setIsLoading(false);
          throw new Error('Issue with Login');
        }
      }
    } catch (error) {
      setIsLoading(false);
      throw new Error('Issue with Login', error.message);
    }
  };
  const clearStorage = () => {
    sessionStorage.clear();
    window.localStorage.removeItem('workspaceId');
    navigate('.', { state: null });
  };
  const closePopupModal = () => {
    clearStorage();
  };

  const openCautionPopup = ({
    message,
    confirmOption,
    buttonName,
    extraButton,
  }) => {
    const confirmAction = () => {
      if (confirmOption === 'refresh') {
        return () => {
          window.location.reload(true);
        };
      } else {
        return confirmOption;
      }
    };

    PopupModal.closePopupModal = () => {
      closePopupModal();
      window.location.reload(true);
    };
    PopupModal.popupMessage =
      message || 'Sorry, something went wrong. Please contact Playbook.';
    PopupModal.confirmBtnName = buttonName || 'Confirm';
    PopupModal.handleConfirmAction = confirmAction();

    if (extraButton) {
      PopupModal.extraBtnName = extraButton.buttonName;
      PopupModal.handleExtraAction = extraButton.buttonAction;
    } else {
      PopupModal.extraBtnName = null;
      PopupModal.handleExtraAction = null;
    }
  };
  const sendTokenData = async (workspaceId, authCode) => {
    try {
      const clientId = process.env.REACT_APP_CAFE24_CLIENT_ID;
      const clientSecret = process.env.REACT_APP_CAFE24_CLIENT_SECRET;
      const REDIRECT_URI = process.env.REACT_APP_CAFE24_REDIRECT_URI;
      const b64Encoded = btoa(`${clientId}:${clientSecret}`);
      let submitData = {
        code: authCode,
        mallId: mallId,
        authorization: b64Encoded,
        redirectUri: REDIRECT_URI,
      };
      console.log(submitData);
      const request = await fetch(
        `${APIs('integration', workspaceId)}/channels/cafe24`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionJWT}`,
          },
          body: JSON.stringify(submitData),
        }
      );
      const result = await request.json();
      if (request.ok) {
        setIsLoading(false);
      } else if (request.status === 400) {
        let message;
        if (result?.[0] === 'API Error') {
          message = 'Cafe24 integration failed';
        } else {
          message = result[0];
        }
        openCautionPopup({
          message: message,
          confirmOption: 'refresh',
          buttonName: 'Close',
        });
      }
    } catch (error) {
      const message =
        'Something went wrong and we could not process your request';
      openCautionPopup({
        message: message,
        confirmOption: 'refresh',
        buttonName: 'Close',
      });
      console.log(error.message);
      setIsLoading(false);
      throw new Error('Issue with Cafe24 Integration', error.message);
    }
  };

  return <LoadingPageFull />;
}
