import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { SelectModel } from '../Components/Selector';
import { capitalizeChar } from '../../../../hooks/textFormatter';
import KPISelector from '../Components/KPISelector';

export default function CampaignPerfTable(props) {
  const { kpi, setKpi, kpiOptions, campaignPerfTable } = props;
  const columns = [
    {
      field: 'channel_name',
      headerName: 'Channel',
      valueGetter: (params) => `${capitalizeChar(params.row.channel_name)}`,
      flex: 1.5,
    },
    { field: 'name', headerName: 'Campaign Name', flex: 2 },
    {
      field: 'spends',
      headerName: 'Spends',
      type: 'number',
      flex: 1,
    },
    {
      field: 'clicks',
      headerName: 'Clicks',
      type: 'number',
      flex: 1,
    },
    {
      field: 'impressions',
      headerName: 'Impressions',
      type: 'number',
      flex: 1.2,
    },
    {
      field: 'contribution',
      headerName: 'KPI Contribution',
      type: 'number',
      flex: 1.4,
      description: 'Predicted Total of KPI',
    },
    {
      field: 'cpa',
      headerName: 'CPA',
      type: 'number',
      flex: 1.4,
      description: 'Predicted CPA of KPI',
    },
  ];
  const handleChoiceClick = (choice) => {
    setKpi(choice);
  };

  return (
    <Wrapper>
      <Title>Campaign Performance Table</Title>
      <KPISelector
        defaultValue={kpi.sk}
        options={kpiOptions}
        onClickHandler={handleChoiceClick}
      />
      <DataGrid
        style={{ width: '100%' }}
        rows={campaignPerfTable}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[10]}
        autoHeight
        disableRowSelectionOnClick
        getRowId={(row) => row.name}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px;
  height: auto;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  transition: all ease 0.7s;
  ${({ theme }) => theme.animation.fadeInBottom}
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.pb.black};
  font-size: 18px;
  font-weight: 400;
  text-align: center;
`;
