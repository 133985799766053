import theme from './theme';

export const customStyles = {
  singleValue: styles => ({
    ...styles,
    color: `${theme.pb.midBlue}`,
  }),

  container: styles => ({
    ...styles,
    width: '100%',
    fontSize: '13px',
    lineHeight: '20px',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  }),

  placeholder: styles => ({
    ...styles,
    fontSize: '13px',
    color: `${theme.pb.gray}`,
  }),

  option: (styles, { isFocused }) => ({
    ...styles,
    width: '100%',
    margin: '0',
    backgroundColor: isFocused ? `${theme.pb.lightBlue}` : '',
    color: isFocused ? `${theme.pb.cyanBlue}` : `${theme.pb.darkGray}`,

    fontSize: '13px',
    lineHeight: '20px',
    overflow: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  }),

  multiValue: styles => ({
    ...styles,
    padding: '0 5px',
    backgroundColor: '#FFF',
    border: `1px solid ${theme.pb.cyanBlue}`,
    borderRadius: '46px',
  }),

  multiValueLabel: styles => ({
    ...styles,
    color: `${theme.pb.midBlue}`,
    backgroundColor: 'none',
  }),

  multiValueRemove: styles => ({
    ...styles,
    color: `${theme.pb.midBlue}`,
    ':hover': {
      backgroundColor: 'transparent',
    },
  }),

  control: (base, state) => ({
    ...base,
    width: '100%',
    height: '36px',

    paddingLeft: '3px',
    border: state.isFocused
      ? `1px solid ${theme.pb.cyanBlue}`
      : `1px solid ${theme.pb.darkGray}`,
    borderRadius: '8px',
    background: '#fff',
    cursor: 'pointer',
    ':hover': {
      border: `1px solid ${theme.pb.cyanBlue}`,
    },
    '::-webkit-scrollbar': {
      display: 'none',
    },
  }),

  menu: provided => ({
    ...provided,
    borderRadius: '8px',
    overflow: 'hidden',
    msOverflowStyle: 'none',

    '::-webkit-scrollbar': {
      display: 'none',
    },
  }),

  dropdownIndicator: (base, { selectProps }) => ({
    ...base,
    transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
    color: selectProps.menuIsOpen
      ? `${theme.pb.cyanBlue}`
      : `${theme.pb.darkGray}`,
  }),

  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: 'none',
  }),
};
