import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { LanguageContext } from '../../../contextApi/LanguageContext';
import { SIGNIN_TEXT } from '../AUTH_TEXT';
import { AccountContext } from '../../../contextApi/AccountContext';
import PopupModal from '../../../components/PopupModal';
import styled from 'styled-components';

export default function SignIn({ setModalType }) {
  const isUnder1440 = useMediaQuery({ maxWidth: 1440 });
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();

  const [userInput, setUserInput] = useState({
    user_id: 0,
    username: '',
    password: '',
    errorMessage: null,
    nextStep: false,
  });

  let { username, password, errorMessage, nextStep } = userInput;
  const usernameInput = useRef();
  const { authenticate } = useContext(AccountContext);

  const usernameCheck = username?.includes('@') && username?.includes('.');
  const pwdValidCheck = password?.match(
    /(?=.*\d)(?=.*[a-zA-ZS])(?=.*?[#?!@$%^&*-]).{8,}/
  );
  const btnValidCheck = usernameCheck && pwdValidCheck;

  const [popupModalStatus, setPopupModalStatus] = useState(false);

  const closePopupModal = () => {
    setPopupModalStatus(prev => !prev);
  };

  const resetInput = () => {
    closePopupModal();
    setUserInput({
      username: '',
      password: '',
    });
    usernameInput.current.focus();
  };

  useEffect(() => {
    nextStep === true && navigate('/workspacelist');
  }, [nextStep]); // eslint-disable-line react-hooks/exhaustive-deps

  const onInputChange = event => {
    const { id, value } = event.target;
    setUserInput(prev => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleUserInputSubmit = async event => {
    event.preventDefault();

    await authenticate(username, password)
      .then(data => {
        setUserInput(prev => ({ ...prev, nextStep: true }));
      })
      .catch(err => {
        const { message } = err;
        if (message != null) {
          setUserInput(prev => ({ ...prev, errorMessage: message }));
          console.error('Failed to login', userInput.errorMessage);
          setPopupModalStatus(true);
          if (message === 'User is not confirmed.') {
            // setUserNotConfirmed(true);
          } else if (message === 'Password attempts exceeded.') {
            // have to set up /forgotpassword
            //   // navigate('/forgotpassword');
          } else if (message === 'User is disabled.') {
            // console.log('redirecting to set new password');
            //   navigate('/requestpassword');
          } else if (message === 'Incorrect username or password.') {
          } else {
            console.error('Failed to login', err);
          }
        }
      });
  };

  return (
    <>
      <Wrapper isUnder1440={isUnder1440}>
        <Header>{SIGNIN_TEXT[language].headline}</Header>
        <LoginForm isUnder1440={isUnder1440} onSubmit={handleUserInputSubmit}>
          <InputArea>
            <Title>{SIGNIN_TEXT[language].id.title}</Title>
            <Input
              type="email"
              id="username"
              placeholder={SIGNIN_TEXT[language].id.placeholder}
              value={username}
              onChange={onInputChange}
              ref={usernameInput}
            />
            {username && !usernameCheck && (
              <Warning>{SIGNIN_TEXT[language].id.warning}</Warning>
            )}
          </InputArea>
          <InputArea>
            <Title>{SIGNIN_TEXT[language].pw.title}</Title>
            <Input
              type="password"
              id="password"
              placeholder={SIGNIN_TEXT[language].pw.placeholder}
              value={password}
              onChange={onInputChange}
            />
            {password && !pwdValidCheck && (
              <Warning>{SIGNIN_TEXT[language].pw.warning}</Warning>
            )}
          </InputArea>

          <SubmitButton type="submit" disabled={!btnValidCheck ? true : false}>
            {SIGNIN_TEXT[language].signInButton}
          </SubmitButton>
          <TextButton
            onClick={e => {
              e.stopPropagation();
              setModalType('changePW');
            }}
          >
            <Text>{SIGNIN_TEXT[language].forgotPassword}</Text>
          </TextButton>
          <Border />
          <GoToSignUp>
            <Description>
              {SIGNIN_TEXT[language].signUp.description}
            </Description>
            <SubmitButton
              onClick={e => {
                e.stopPropagation();
                setModalType('signUp');
              }}
              color="black"
            >
              {SIGNIN_TEXT[language].signUp.buttonName}
            </SubmitButton>
          </GoToSignUp>
        </LoginForm>
      </Wrapper>
      <PopupModal
        popupModalStatus={popupModalStatus}
        closePopupModal={closePopupModal}
        popupMessage={errorMessage}
        handleConfirmAction={resetInput}
      />
    </>
  );
}

const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ isUnder1440 }) => (isUnder1440 ? '35vw' : '100%')};
  max-width: 486px;
  max-height: 510px;
  padding: 55px 71px 45px 71px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.neutralColor.dark1};
  box-shadow: 0 0 10 rgba(0, 0, 0, 0.25);
  transition: all ease 0.4s;
  z-index: 20;
`;

const Header = styled.h2`
  margin-bottom: 30px;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: ${({ theme }) => theme.pb.green};
`;

const LoginForm = styled.form`
  width: ${({ isUnder1440 }) => (isUnder1440 ? '25vw' : '100%')};
  max-width: 340px;
`;

const Title = styled.h4`
  margin-bottom: 8px;
  text-align: left;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  color: #fff;
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-bottom: 16px;
`;

const Input = styled.input`
  width: 100%;
  max-width: 340px;
  height: 35px;
  padding: 10px 15px;
  border-radius: 5px;

  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralColor.dark4};
  background-color: ${({ theme }) => theme.pb.gray};

  &:focus {
    background-color: #fff;
  }

  &::placeholder {
    color: #6f6f6f;
  }
`;

const Warning = styled.p`
  width: inherit;
  max-width: 340px;
  padding: 10px 0px;

  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.pb.yellow};
`;

const GoToSignUp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: 340px;
`;

const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 340px;
  padding: 10px 15px;

  border-radius: 5px;
  background-color: ${({ color, theme }) =>
    color === 'black' ? theme.pb.black : theme.pb.cyanBlue};

  font-weight: 700;
  font-size: 16px;
  color: #fff;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 3px 7px rgba(9, 16, 55, 0.25);
  }

  &:disabled {
    cursor: default;
  }
`;

const TextButton = styled.button`
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  margin-top: 8px;
`;

const Text = styled.span`
  color: ${({ theme }) => theme.pb.gray};
  font-size: 13px;
  font-weight: 400;
  text-align: center;

  &:hover {
    font-weight: 500;
    color: #fff;
  }
  cursor: pointer;
`;

const Border = styled.p`
  margin: 24px 0px;
  width: 100%;
  max-width: 340px;
  border-bottom: 1px solid ${({ theme }) => theme.pb.gray};
`;

const Description = styled.p`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.pb.gray};
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
`;
