import React, { useState, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { LanguageProvider } from '../../contextApi/LanguageContext';

import Background03 from '../../assets/images/BG/Background03.svg';

import PanomixNav from '../../components/Nav/PanomixNav';
import Footer from '../../components/Footer/Footer';

import Graphics from './Graphics';
import SignIn from './Modals/SignIn';
import SignUp from './Modals/SignUp';
import ChangePassword from './Modals/ChangePassword';

import CookiePolicy from './TermsAndPrivacy/CookiePolicy';
import Terms from './TermsAndPrivacy/Terms';
import PrivacyPolicy from './TermsAndPrivacy/PrivacyPolicy';

import styled from 'styled-components';
import PanomixMobileNav from '../../components/Nav/PanomixMobileNav';
import MobileGraphics from './MobileGraphics';
import MainContent from './MainContent';

export default function Main({ type, defaultModal }) {
  const isUnder1440 = useMediaQuery({ maxWidth: 1440 });

  const isMobile = useMediaQuery({
    maxWidth: 800,
  });
  const [modalType, setModalType] = useState(defaultModal);

  const MODAL_TYPES = {
    signIn: <SignIn setModalType={setModalType} />,
    signUp: <SignUp setModalType={setModalType} />,
    changePW: <ChangePassword setModalType={setModalType} />,
  };

  const PAGES = {
    cookies: <CookiePolicy />,
    terms: <Terms />,
    privacy: <PrivacyPolicy />,
  };

  return (
    <LanguageProvider>
      {defaultModal && (
        <Wrapper>
          {isMobile ? <PanomixMobileNav /> : <PanomixNav />}
          {isMobile ? (
            <MobileGraphics />
          ) : (
            <AlignBox isUnder1440={isUnder1440}>
              <MainContent />
              {MODAL_TYPES[modalType]}
            </AlignBox>
          )}
          <Footer />
        </Wrapper>
      )}
      {type && (
        <TermsAndPrivacyWrapper>
          <AlignBox needMarginTop={true}>{PAGES[type]}</AlignBox>
          <Footer />
        </TermsAndPrivacyWrapper>
      )}
    </LanguageProvider>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;

  background-color: black;

  width: 100vw;
  height: 100vh;
`;

const TermsAndPrivacyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: black;

  width: 100vw;
  height: auto;
`;

const AlignBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px;
  margin-top: ${({ needMarginTop }) => needMarginTop && '80px'};
`;
