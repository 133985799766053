import React from 'react';
import { useNavigate } from 'react-router-dom';
import { URL } from '../assets/data/SERVICE_LINKS';
import PlaybookLogo from '../assets/icons/logo/PlaybookLogoBlack.svg';
import { ReactComponent as XButton } from '../assets/icons/IconSoloX.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

export default function WorkspaceCheckModal({ showModal, setShowModal, type }) {
  const navigate = useNavigate();
  const goToWorkspaceList = () => {
    navigate('/workspacelist');
  };

  const OPTIONS_BY_TYPE = {
    contract: {
      description: (
        <>
          <p>Thank you for your interest in Playbook.</p>
          <p>Please complete our feedback form to receive additional compute time.</p>
        </>
      ),
      closeAction: () => {
        setShowModal(false);
      },
      buttonName: 'Complete Form',
      confirmAction: () => {
        setShowModal(false);
        window.open(URL.contactUs, '_blank');
      },
    },
    disabled: {
      hasWarning: true,
      description: (
        <>
          <p>Your workspace is disabled.</p>
          <p>Please contact us.</p>
        </>
      ),
      closeAction: () => {
        goToWorkspaceList();
      },
      buttonName: 'Contact Sales',
      confirmAction: () => {
        window.open(URL.contactUs, '_blank');
        goToWorkspaceList();
      },
    },
  };

  if (!showModal) return null;
  return (
    <Wrapper>
      <Overlay onClick={OPTIONS_BY_TYPE[type].closeAction} />
      <ContentArea>
        <CloseButton onClick={OPTIONS_BY_TYPE[type].closeAction} />
        <Logo src={PlaybookLogo} />
        {OPTIONS_BY_TYPE[type].hasWarning && (
          <WarningIcon>
            <FontAwesomeIcon icon={faWarning} size="2xl" />
          </WarningIcon>
        )}
        <Description>{OPTIONS_BY_TYPE[type].description}</Description>
        <ConfirmButton onClick={OPTIONS_BY_TYPE[type].confirmAction}>
          {OPTIONS_BY_TYPE[type].buttonName}
        </ConfirmButton>
      </ContentArea>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.pb.overlay};
  opacity: 0.5;
  z-index: 15;
`;

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  width: 400px;
  height: 300px;

  border-radius: 8px;
  padding: 60px 30px 30px 30px;
  background-color: #fff;
  box-shadow: 0px 1px 8px rgba(9, 16, 55, 0.63);
  z-index: 999;
`;

const CloseButton = styled(XButton)`
  position: absolute;
  top: 15px;
  right: 15px;

  width: 13px;
  height: 13px;
  fill: ${({ theme }) => theme.pb.cyanBlue};
  cursor: pointer;
`;

const Logo = styled.img``;

const WarningIcon = styled.div`
  color: red;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;

const ConfirmButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  padding: 6px 18px;
  border-radius: 43px;
  background-color: ${({ theme }) => theme.pb.cyanBlue};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  cursor: pointer;
`;
