import React from 'react';
import MinusButton from '../assets/icons/MinusButton.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

function ContentModal({
  isShowModal,
  title,
  deleteAction,
  errorData,
  contentData,
  confirmBtnName,
  handleConfirmAction,
  showCancelBtn,
  closeContentModal,
  shortModal,
}) {
  if (!isShowModal) return null;

  return (
    <ModalWrapper
      onKeyUp={e => {
        if (e.key === 'Escape') {
          return closeContentModal();
        }
      }}
    >
      <ModalOverlay onClick={closeContentModal} shortModal={shortModal} />
      <ModalMain shortModal={shortModal}>
        <ModalTitle deleteAction>
          <Title>{title ? title : 'Modal Title'}</Title>
          {deleteAction && (
            <DeleteBtn onClick={deleteAction}>
              <Icon src={MinusButton} />
              Delete
            </DeleteBtn>
          )}
        </ModalTitle>
        {errorData?.length > 0 && (
          <ErrorInfo>
            <ErrorTitle>
              <ErrorIcon>
                <FontAwesomeIcon icon={faWarning} size="sm" />
              </ErrorIcon>
              Errors: please check messages below.
            </ErrorTitle>

            {errorData?.map((data, idx) => {
              return <ErrorText key={idx}>﹒{data}</ErrorText>;
            })}
          </ErrorInfo>
        )}

        <ModalContent>
          <Content>{contentData ? contentData : 'This is content'}</Content>
        </ModalContent>
        <BtnWrapper>
          {handleConfirmAction && (
            <ConfirmBtn onClick={handleConfirmAction}>
              {confirmBtnName ? confirmBtnName : 'Confirm'}
            </ConfirmBtn>
          )}
          {showCancelBtn && (
            <CancelBtn onClick={closeContentModal}>Cancel</CancelBtn>
          )}
        </BtnWrapper>
      </ModalMain>
    </ModalWrapper>
  );
}

export default ContentModal;

const ModalWrapper = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
  cursor: default;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.pb.overlay};
  opacity: 0.5;
  z-index: 65;
`;

const ModalMain = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 612px;
  min-width: 545px;
  height: ${({ shortModal }) => (shortModal ? '38vh' : '78vh')};
  border-radius: 8px;
  padding: 0 64px 10px 64px;
  background-color: #fff;
  box-shadow: 0px 1px 8px rgba(9, 16, 55, 0.63);
  z-index: 99;
`;

const ModalTitle = styled.div`
  display: flex;
  justify-content: ${({ deleteAction }) =>
    deleteAction ? 'space-between' : 'start'};
  align-items: center;
  width: 612px;
  min-width: 545px;
  min-height: 80px;
  padding: 25px 64px;
  border-radius: 8px 8px 0 0;
  background-color: #fff;
  box-shadow: ${props => props.theme.mdbluecard.boxShadow};
  z-index: 4;
`;

const Title = styled.h1`
  font-size: 48px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.04em;
  margin-top: 12px;
  text-align: center;
  color: black;
`;

const DeleteBtn = styled.button`
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 8px 14px;
  background: #ff5349;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border: 1px solid #ff5349;
  border-radius: 42px;
  color: #fff;
  cursor: pointer;
`;

const Icon = styled.img`
  margin-right: 0.3125rem;
`;

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const ErrorInfo = styled.div`
  width: 478px;
  padding: 20px 20px 20px 20px;
  margin: 25px 25px 0 25px;
  border-radius: 8px;
  background-color: #ffdedb;
`;

const ErrorIcon = styled.span`
  margin-right: 6px;
  color: red;
`;

const ErrorTitle = styled.div`
  margin-bottom: 20px;
  text-align: left;
  color: #8c0101;
  font-size: 20px;
  font-weight: 500;
`;

const ErrorText = styled.div`
  margin-left: -6px;
  margin-top: 6px;
  color: #2e2e2e;
`;

// const DotIcon = styled.span`
//   padding-top: 10px;
//   text-align: center;
// `;

const Content = styled.div`
  min-width: 545px;
  max-width: 612px;
`;

const BtnWrapper = styled.div`
  width: 85%;
  max-height: 100px;
`;

const ConfirmBtn = styled.button`
  width: 100%;
  padding: 0.75rem 0;
  margin-top: 10px;
  border-radius: 43px;
  background-color: ${({ theme }) => theme.pb.cyanBlue};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  cursor: pointer;
`;

const CancelBtn = styled.button`
  width: 100%;
  padding: 0.75rem 0;
  margin-top: 10px;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.pb.cyanBlue};
  cursor: pointer;
`;
