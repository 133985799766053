import { GetToken } from '../Components/GoogleGetToken';

export const CustomerListApi = async ({
  setIsLoading,
  setToken,
  setCustomer,
  setData,
}) => {
  try {
    const token = await GetToken();
    const request = await fetch(
      'https://googleads.googleapis.com/v13/customers:listAccessibleCustomers',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'developer-token': process.env.REACT_APP_ADS_DEV_TOKEN,
        },
      }
    );

    const resp = await request.json();
    if (!resp.error) {
      if (resp.resourceNames === undefined) {
        let message = 'Please create Google Ads account first';
        setData({ errorMessage: message });
        setIsLoading(false);
      } else {
        setToken(token);
        setCustomer(resp.resourceNames);
      }
    } else if (
      resp.error.code === 401 &&
      resp.error.status === 'UNAUTHENTICATED'
    ) {
      let message = 'Make sure to log in with a valid Google Ads account';
      setData({ errorMessage: message });
      setIsLoading(false);
    } else {
      let message =
        'Something went wrong and we could not process your request';
      setData({ errorMessage: message });
      setIsLoading(false);
    }
  } catch (error) {
    setData({ errorMessage: error.message });
    setIsLoading(false);

    throw new Error('GoogleAdsApi error getting ads data', error.message);
  }
};
