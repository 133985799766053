import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountContext } from '../../contextApi/AccountContext';
import { WorkspaceProvider } from '../../contextApi/WorkspaceContext';
import { ChannelsProvider } from '../../contextApi/ChannelsContext';
import { AnimationProvider } from '../../contextApi/AnimationContext';
import { APIs } from '../../config';
import styled, { keyframes, css } from 'styled-components';
import MenuZone from './Sidebar/MenuZone';
import PlaybookSection from './PlaybookSection';
import PopupModal from '../../components/PopupModal';
import WorkspaceCheckModal from '../../components/WorkspaceCheckModal';
import WorkspaceMenu from './Sidebar/WorkspaceMenu';

export default function Workspace() {
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const { getSession, sessionJWT, userStatus } = useContext(AccountContext);
  const [popupModalStatus, setPopupModalStatus] = useState(false);
  const [workspaceStatus, setWorkspaceStatus] = useState('');

  const openAuthenticatePopup = () => {
    const goToSignIn = () => navigate('/signin');
    PopupModal.closePopupModal = () => goToSignIn();
    PopupModal.popupMessage = 'Please sign in first';
    PopupModal.handleConfirmAction = () => goToSignIn();
    setPopupModalStatus(true);
  };

  const getWorkspaceStatus = async () => {
    if (sessionJWT && workspaceId) {
      try {
        const request = await fetch(`${APIs('workspace', workspaceId)}`, {
          headers: {
            Authorization: `Bearer ${sessionJWT}`,
          },
        });
        const response = request.json();
        const data = await response;
        setWorkspaceStatus(data.status);
      } catch (err) {
        console.error(err);
      }
    } else return;
  };

  useEffect(() => {
    if (!sessionJWT) {
      getSession();
      if (userStatus.userNotSignedIn) {
        openAuthenticatePopup();
      }
    } else {
      getWorkspaceStatus();
    }
  }, [sessionJWT, workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <WorkspaceMenu />
      <WorkspaceProvider>
        <ChannelsProvider>
          <AnimationProvider>
            <PlaybookSection />
          </AnimationProvider>
        </ChannelsProvider>
      </WorkspaceProvider>
      <PopupModal
        popupModalStatus={popupModalStatus}
        closePopupModal={PopupModal.closePopupModal}
        popupMessage={PopupModal.popupMessage}
        handleConfirmAction={PopupModal.handleConfirmAction}
      />
      <WorkspaceCheckModal
        showModal={workspaceStatus === 'DISABLED'}
        type='disabled'
      />
    </>
  );
}

const sidebarHidden = keyframes`
  from{transform:translateX(0px)}
  to{transform:translateX(-57px)}`;

const sidebarRevealed = keyframes`
  from{transform:translateX(-57px)}
  to{transform:translateX(0px)}`;

const hideAnimator = css`
  animation: ${sidebarHidden} 0.8s;
`;

const revealAnimator = css`
  animation: ${sidebarRevealed} 0.4s;
`;

const hideSideBar = css`
  transform: translateX(-57px);
`;

const SidebarControlWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  ${({ hideSidebar }) =>
    hideSidebar === 'true' ? hideAnimator : revealAnimator};

  ${({ hideSidebar }) => hideSidebar === 'true' && hideSideBar};
`;

const SidebarWrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const BarBackground = styled.div`
  width: auto;
  height: 100vh;
  background-color: ${({ color, theme }) => theme.pb[color]};
`;

const SidebarController = styled.div`
  ${({ theme }) => theme.positioncenter}
  position: absolute;
  top: 48.5%;
  left: ${({ hideSidebar }) => (hideSidebar === 'true' ? '51.8%' : '49%')};
  width: 20px;
  height: 20px;
  background-color: #000;
  box-shadow: 1px 0px 6px 2px rgba(9, 16, 55, 0.63);
  border-radius: 50%;
  cursor: pointer;
`;

const ImgBack = styled.img`
  display: block;
  margin: 0.25rem auto;
  cursor: pointer;
`;
const ImgFront = styled.img`
  display: block;
  width: 11px;
  height: 11px;
  margin: 0.25rem auto;
  cursor: pointer;
`;

const SectionWrapper = styled.div`
  padding-left: ${(props) => (props.padding === 'true' ? '54px' : '108px')};
  transition: ${(props) => (props.padding === 'true' ? '0.8s' : '0.4s')};
`;
