import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as TrendingDown } from '../../../../../src/icons/TrendingDown.svg';
import { ReactComponent as TrendingUp } from '../../../../../src/icons/TrendingUp.svg';
import BudgetInputSlider from './BudgetInputSlider';

export default function BudgetSimulationCard(props) {
  const { title, value, prevValue, budgetChangeHandler, item } = props;

  const [currentValue, setCurrentValue] = useState(value);

  const difference = currentValue - prevValue;
  const percentageDiff = ((difference / prevValue) * 100).toFixed(1);
  const diffColor = difference >= 0 ? '#3BA0EB' : '#FD5B84';

  return (
    <CardWrapper>
      <TotalElement>
        <TotalLabel>{title}</TotalLabel>
        <TotalLabel>(id: {item.adsetid})</TotalLabel>

        <TotalNumber>{currentValue.toLocaleString(2)}</TotalNumber>
        <CompareArea>
          {difference >= 0 ? <TrendingUp /> : <TrendingDown />}
          <PercentArea diffColor={diffColor}>{percentageDiff}%</PercentArea>
          <CompareText>vs current daily budget</CompareText>
        </CompareArea>
        <BudgetInputSlider
          data={{
            item: item,
            percentDiff: percentageDiff,
            percentMin: -100,
            percentMax: 1000,
          }}
          onChangeHandler={budgetChangeHandler}
          setCurrentValue={setCurrentValue}
        />
      </TotalElement>
    </CardWrapper>
  );
}

const CardWrapper = styled.div`
  margin: 2px;
`;
const TotalElement = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 18px 24px 0px;
  width: 100%;
  background: none;
`;

const TotalNumber = styled.span`
  margin-top: 14px;
  color: ${({ theme }) => theme.neutralColor.dark2};
  font-size: 32px;
  line-height: 28px;
  font-weight: 500;
  font-family: 'Arial';
`;
const CompareArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 14px;
  margin-bottom: 10px;
  gap: 6px;
`;
const PercentArea = styled.span`
  font-size: 16px;
  font-family: 'Arial'
  line-height: 18px;
  font-weight: 500;
  color: ${({ diffColor }) => diffColor};
`;

const CompareText = styled.span`
  font-family: 'Arial';
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.neutralColor.dark0};
`;

const TotalLabel = styled.span`
  color: ${({ theme }) => theme.neutralColor.dark2};
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  font-family: 'Arial';
  margin-bottom: 8px;
`;
