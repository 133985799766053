import React from 'react';
import styled from 'styled-components';

export default function SimpleCard(props) {
  const { title, value, color } = props;

  return (
    <CardWrapper>
      <TotalElement>
        <TotalNumber>{value}</TotalNumber>
        <TotalLabel color={color}>{title}</TotalLabel>
      </TotalElement>
    </CardWrapper>
  );
}

const CardWrapper = styled.div`
  margin: 2px;
`;
const TotalElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 22px 22px 15px 22px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
`;

const TotalNumber = styled.span`
  margin-bottom: 7px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
`;

const TotalLabel = styled.span`
  color: ${({ color, theme }) => theme.pb?.[color]};
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
`;
