import React, { useEffect } from 'react';
import styled from 'styled-components';
import AttributionPie from './components/AttributionPie';

export default function POCReport({ result }) {
  // check status to failed or finished
  // if failed, show failed page with email info
  const splitString = result.sk.split('#');
  const facebook = result.results.find(
    (obj) => obj.channelName === 'facebookAds'
  );
  const google = result.results.find((obj) => obj.channelName === 'googleAds');

  return (
    <Container>
      <ChartArea>
        <MainWrapper>
          <Title>Model Result</Title>
          <ModelResultArea>
            <ResultKey>KPI</ResultKey>
            <ResultValue>{splitString[1]}</ResultValue>
          </ModelResultArea>
          <ModelResultArea>
            <ResultKey>Platform</ResultKey>
            <ResultValue>{splitString[3]}</ResultValue>
          </ModelResultArea>
          <ModelResultArea>
            <ResultKey>Model Runs</ResultKey>
            <ResultValue>
              {Number(result.modelsRun).toLocaleString()}
            </ResultValue>
          </ModelResultArea>
          <ModelResultArea>
            <ResultKey>Simulations</ResultKey>
            <ResultValue>
              {Number(result.modelsRun).toLocaleString()}
            </ResultValue>
          </ModelResultArea>
          <ModelResultArea>
            <ResultKey>Predicted Ad Savings</ResultKey>
            <ResultValue>
              {Math.abs(Number(result.optmBudget)).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </ResultValue>
          </ModelResultArea>
          <Divider />
          <ChannelResultArea>
            <ChannelValue rightAlign={false}>Channels</ChannelValue>
            <ChannelValue rightAlign={true}>Facebook Ads</ChannelValue>
            <ChannelValue rightAlign={true}>Google Ads</ChannelValue>
          </ChannelResultArea>
          <ChannelResultArea>
            <ChannelValue rightAlign={false}>Avg. Contribution</ChannelValue>
            <ChannelValue rightAlign={true}>
              {Number(facebook.contributionAvg).toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}
            </ChannelValue>
            <ChannelValue rightAlign={true}>
              {Number(google.contributionAvg).toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}
            </ChannelValue>
          </ChannelResultArea>
          <ChannelResultArea>
            <ChannelValue rightAlign={false}>Lower Bound</ChannelValue>
            <ChannelValue rightAlign={true}>
              {Number(facebook.contributionLowerBound).toLocaleString(
                undefined,
                {
                  maximumFractionDigits: 0,
                }
              )}
            </ChannelValue>
            <ChannelValue rightAlign={true}>
              {Number(google.contributionLowerBound).toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}
            </ChannelValue>
          </ChannelResultArea>
          <ChannelResultArea>
            <ChannelValue rightAlign={false}>Upper Bound</ChannelValue>
            <ChannelValue rightAlign={true}>
              {Number(facebook.contributionUpperBound).toLocaleString(
                undefined,
                {
                  maximumFractionDigits: 0,
                }
              )}
            </ChannelValue>
            <ChannelValue rightAlign={true}>
              {Number(google.contributionUpperBound).toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}
            </ChannelValue>
          </ChannelResultArea>
          <ChannelResultArea>
            <ChannelValue rightAlign={false}>Standard Deviation</ChannelValue>
            <ChannelValue rightAlign={true}>
              {Number(facebook.contributionStdev).toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}
            </ChannelValue>
            <ChannelValue rightAlign={true}>
              {Number(google.contributionStdev).toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}
            </ChannelValue>
          </ChannelResultArea>
          <ChannelResultArea>
            <ChannelValue rightAlign={false}>CPA</ChannelValue>
            <ChannelValue rightAlign={true}>
              {Number(facebook.cpa).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </ChannelValue>
            <ChannelValue rightAlign={true}>
              {Number(google.cpa).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </ChannelValue>
          </ChannelResultArea>
        </MainWrapper>

        <AttributionPie
          title='Top Model Channel Contribution'
          label='Total Contribution'
          pieData={result.results}
          pieKey='totalContribution'
        />
      </ChartArea>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
`;
const ChartArea = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4px;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const MainWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 40px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.pb.black};
  font-size: 24px;
  font-family: 'Poppins';
  text-align: left;
  margin-bottom: 20px;
`;

const ModelResultArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 18px;
`;

const ResultKey = styled.div`
  font-size: 16px;
  font-family: 'Arial';
  text-align: left;
`;

const ResultValue = styled.div`
  font-size: 16px; 
  font-family: 'Arial'
  text-align: right;
`;

const Divider = styled.div`
  margin-top: 20px;
  height: 1px;
  background-color: black;
  width: 100%;
`;

const ChannelResultArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 18px;
`;

const ChannelValue = styled.div`
  font-size: 16px;
  font-family: 'Arial';
  justify-self: ${(props) =>
    props.rightAlign ? 'end' : 'start'}; /* Conditional right alignment */
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

const ErrorTitle = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.realBlack};
  font-size: 24px;
  font-family: 'Arial';
  padding: 5px;
`;

const ErrorDescription = styled.div`
  margin: 0.9rem;
  color: ${({ theme }) => theme.pb.realBlack};
  font-size: 14px;
  padding: 5px;
  width: 340px;
  line-height: 19px;
  font-family: 'Arial';
  text-align: center;
`;
