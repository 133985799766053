import React, { useContext } from 'react';
import { LanguageContext } from '../../contextApi/LanguageContext';
import styled from 'styled-components';

export default function MainContent() {
  const { language } = useContext(LanguageContext);

  const MAIN_DATA = {
    title: {
      EN: 'Final Destination for Media Mix Modeling',
      KR: '미디어 믹스 모델링을 통한 마케팅 효율 극대화',
    },
    titleDescription: {
      EN: 'Measure and forecast campaigns with high performing & consistent MMM, powered by advanced features. Schedule a demo now or start free to explore!',
      KR: '높은 퍼포먼스와 일관된 MMM 모델을 통해 마케팅 캠페인을 측정하고 예측합니다. 지금 가입하여 더 자세히 알아보거나 라이브 데모를 요청하세요.',
    },
  };
  return (
    <HeadlineWrapper>
      <Title>{MAIN_DATA.title[language]}</Title>
      <TitleDescription>
        {MAIN_DATA.titleDescription[language]}
      </TitleDescription>
    </HeadlineWrapper>
  );
}

const HeadlineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 72px;
  font-weight: 400;
  line-height: 84px;
  width: ${(props) => (props.language === 'en' ? '980px' : '860px')};
  color: white;
  letter-spacing: -3px;
  max-width: 680px;
`;

const TitleDescription = styled.div`
  font-family: Arial;
  font-size: 20px;
  line-height: 26px;
  font-weight: 200;
  color: white;
  max-width: 650px;
  margin-top: 40px;
`;

const ButtonArea = styled.div`
  display: flex;
  align-itmes: center;
  flex-direction: row;
  gap: 20px;
  margin-top: 40px;
`;
