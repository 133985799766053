import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as XButton } from '../assets/icons/IconSoloX.svg';

function PopupModal({
  popupModalStatus,
  closePopupModal,
  popupMessage,
  confirmBtnName,
  handleConfirmAction,
  extraBtnName,
  handleExtraAction,
  fadeInAndOutAnimation,
  fitInCustomContainer,
}) {
  if (!popupModalStatus) return null;
  return (
    <PopupWrapper
      onKeyUp={(e) => {
        if (e.key === 'Escape') {
          return closePopupModal();
        }
      }}
    >
      {fitInCustomContainer || <PopupOverlay onClick={closePopupModal} />}
      <PopupMain fitInCustomContainer={fitInCustomContainer}>
        <PopupCloseBtn onClick={closePopupModal} />
        <PopupContents>
          <PopupMessage>
            {popupMessage ? popupMessage : 'Are you sure?'}
          </PopupMessage>
          <BtnWrapper hasExtraBtn={extraBtnName}>
            {extraBtnName && (
              <ExtraBtn onClick={handleExtraAction}>
                {extraBtnName ? extraBtnName : 'No'}
              </ExtraBtn>
            )}
            <ConfirmBtn
              onClick={handleConfirmAction}
              className={
                confirmBtnName === 'Remove' || confirmBtnName === 'Delete'
                  ? 'active'
                  : 'inActive'
              }
              hasExtraBtn={extraBtnName}
            >
              {confirmBtnName ? confirmBtnName : 'Confirm'}
            </ConfirmBtn>
          </BtnWrapper>
        </PopupContents>
      </PopupMain>
    </PopupWrapper>
  );
}

export default PopupModal;

const PopupWrapper = styled.div``;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.pb.overlay};
  opacity: 0.5;
  z-index: 55;
`;

const PopupMain = styled.div`
  display: flex;
  flex-direction: column;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  width: 300px;
  height: fit-content;

  ${({ fitInCustomContainer }) =>
    fitInCustomContainer &&
    css`
      position: absolute;
      bottom: -50%;
      width: 266px;
    `}

  border-radius: 8px;
  padding: 17px 17px;
  background-color: #fff;
  box-shadow: 0px 1px 8px rgba(9, 16, 55, 0.63);
  z-index: 999;
`;

const PopupCloseBtn = styled(XButton)`
  align-self: end;
  width: 0.5rem;
  height: 0.6rem;
  fill: ${({ theme }) => theme.pb.cyanBlue};
  cursor: pointer;
`;

const PopupContents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  height: inherit;
`;

const PopupMessage = styled.div`
  margin: 14px 0px;
  font-size: 14px;
  text-align: center;
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: ${({ hasExtraBtn }) => hasExtraBtn && '80%'};
`;

const ConfirmBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 25px;
  width: ${({ hasExtraBtn }) => hasExtraBtn && '40%'};
  padding: 0px 18px;
  border-radius: 43px;
  background-color: ${({ theme }) => theme.pb.cyanBlue};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  color: #fff;
  cursor: pointer;

  &.active {
    background-color: ${({ theme }) => theme.pb.red};
  }
`;

const ExtraBtn = styled(ConfirmBtn)`
  width: 40%;
  margin-right: 1.2rem;
`;
