export const SIGNIN_TEXT = {
  KR: {
    headline: '로그인',
    id: {
      title: '이메일',
      placeholder: '이메일을 입력해주세요.',
      warning: '이메일 형식이 맞지 않습니다.',
    },
    pw: {
      title: '비밀번호',
      placeholder: '비밀번호를 입력해주세요.',
      warning:
        '문자, 숫자, 특수문자를 포함한 8자리 이상의 비밀번호를 입력해주세요.',
    },
    signInButton: '로그인',
    forgotPassword: '비밀번호를 잊으셨나요?',
    signUp: {
      description: 'Playbook 계정이 없으신가요?',
      buttonName: '회원가입',
    },
  },
  EN: {
    headline: 'Sign In',
    id: {
      title: 'Email',
      placeholder: 'Type your email',
      warning: 'The email address format is not valid.',
    },
    pw: {
      title: 'Password',
      placeholder: 'Type your password',
      warning:
        'Use 8 or more characters with a mix of letters, numbers and symbols',
    },
    signInButton: 'Sign In',
    forgotPassword: 'Forgot Password?',
    signUp: {
      description: "Don't have a Playbook account?",
      buttonName: 'Sign Up',
    },
  },
};

export const SIGNUP_TEXT = {
  KR: {
    headline: '회원가입',
    successMessage: {
      title: '이메일을 확인해주세요',
      description: '이메일의 확인 버튼을 눌러 회원가입을 완료해주세요.',
      confirmButton: '첫 화면으로',
    },
    id: {
      placeholder: '이메일을 입력해주세요.',
      warning: '이메일 형식이 맞지 않습니다.',
    },
    pw: {
      placeholder: '비밀번호를 입력해주세요.',
      warning:
        '문자, 숫자, 특수문자를 포함한 8자리 이상의 비밀번호를 입력해주세요.',
    },
    confirmPw: {
      placeholder: '비밀번호를 다시 한 번 입력해주세요.',
      warning: '비밀번호가 동일하지 않습니다.',
    },
    confirmTerms: {
      description: '에 동의합니다.',
      terms: '서비스 이용약관',
      privacy: '개인정보 처리방침',
      warning: '서비스 이용약관과 개인정보 처리방침에 동의해주세요.',
    },
    signUpButton: '가입 요청',
    signIn: {
      description: '기존 회원이신가요?',
      buttonName: '로그인',
    },
  },

  EN: {
    headline: 'Sign Up',
    successMessage: {
      title: 'Check Your Email',
      description:
        'Thanks for signing up. Confirm your email address to activate your account.',
      confirmButton: 'Got it!',
    },
    id: {
      placeholder: 'Type your email',
      warning: 'The email address format is not valid.',
    },
    pw: {
      placeholder: 'Type your password',
      warning:
        'Use 8 or more characters with a mix of letters, numbers and symbols',
    },
    confirmPw: {
      placeholder: 'Confirm Password',
      warning: 'Those passwords didn’t match. Try again.',
    },
    confirmTerms: {
      description: 'I agree to',
      terms: 'Terms of Service',
      privacy: 'Privacy Policy',
      warning: ' Terms of Service & Privacy Policy must be checked.',
    },
    signUpButton: 'Sign Up',
    signIn: {
      description: 'Already a user?',
      buttonName: 'Sign In',
    },
  },
};

export const CHANGE_PASSWORD_TEXT = {
  KR: {
    headline: '비밀번호 재설정',
    description: '비밀번호를 다시 설정하기 위해 이메일을 입력해주세요.',
    id: {
      placeholder: '이메일을 입력해주세요.',
      warning: '이메일 형식이 맞지 않습니다.',
    },
    confirmButton: '다음 단계',
    cancelButton: '취소',
  },
  EN: {
    headline: 'Change Your Password',
    description: 'Please enter your email address to get a verification code.',
    id: {
      placeholder: 'Email Address',
      warning: 'The email address format is not valid.',
    },
    confirmButton: 'Continue',
    cancelButton: 'Cancel',
  },
};

export const NEW_PASSWORD_TEXT = {
  KR: {
    headline: '비밀번호 재설정',
    description: (
      <>
        이메일로 확인 코드를 보내드렸습니다. <br /> 해당 코드를 입력하시고,{' '}
        새로운 비밀번호를 설정해주세요.
      </>
    ),
    code: {
      placeholder: '확인코드',
      resend: '코드 재발송',
    },
    pw: {
      placeholder: '비밀번호를 입력해주세요.',
      warning:
        '문자, 숫자, 특수문자를 포함한 8자리 이상의 비밀번호를 입력해주세요.',
    },
    confirmPw: {
      placeholder: '비밀번호를 다시 한 번 입력해주세요.',
      warning: '비밀번호가 동일하지 않습니다.',
    },
    confirmButton: '비밀번호 등록',
    cancelButton: '취소',
  },
  EN: {
    headline: 'Enter New Password',
    description: (
      <>
        We've sent a code to your email. <br />
        Please enter the code and your new password below.
      </>
    ),
    code: {
      placeholder: 'Code',
      resend: 'Resend Code',
    },
    pw: {
      placeholder: 'Type your password',
      warning:
        'Use 8 or more characters with a mix of letters, numbers and symbols',
    },
    confirmPw: {
      placeholder: 'Confirm Password',
      warning: 'Those passwords didn’t match. Try again.',
    },
    confirmButton: 'Submit',
    cancelButton: 'Cancel',
  },
};
